import { create } from "zustand";
import ProviderConfigService from "../services/ProviderConfigService";
import createSelectors from "./selectors";
import OfferingService from "../services/OfferingService";
import { Auth } from "aws-amplify";
import SubmissionService from "../services/SubmissionService";

interface OfferingStore {
  offeringConfigs: {};
  offerings: any[];
  loading: boolean;
  initialized: boolean;
  saving: boolean;
  getByKey: (key: string) => any;
  initStore: (isAdmin?:boolean) => Promise<any>;
  getOptionList: () => Promise<any[]>;
  updateList: (newData: any) => void;
  save: (newData: any) => Promise<void>;
}

const useOfferingStoreBase = create<OfferingStore>()((set, get) => ({
  // const defaultVals = await OfferingService.fetchOfferings();
  offeringConfigs: {},
  offerings: [],
  loading: false,
  saving: false,
  initialized: false,
  getByKey: (key: string) => {
    const currList = get().offerings;
    if (!currList || currList.length === 0 || !key) {
      ////console.log("..... Store : getByKey <<<<<<<<<< None", key);
      ////console.log("..... Store : currList ", currList);
      return;
    }

    return currList.find(obj => key === obj.key);
  },
  getOptionList: async () => {
    //console.log("..... Store : getOptionList <<<<<<<<<< offering");
    let offs = get().offerings;
    // //console.log("..... Store : getOptionList >> ALL applications " + apps.length);
    let options = [{ name: "None", key: "NONE" }];
    set({ loading: true });
    if (!offs || (offs && offs.length == 0)) {
      await get().initStore();
      offs = get().offerings;
    }
    // //console.log("..... Store : getOptionList +++++ apps ++ length" + apps.length);
    offs.reduce((accumArr, thisObj) => {
      if (thisObj.name && thisObj.name !== "") {
        accumArr.push({ name: thisObj.name, key: thisObj.key });
      }
      // //console.log("..... Store : getOptionList +++++++ length" + accumAppArr.length);
      return accumArr;
    }, options);

    set({ loading: false });
    return options;
  },
  updateList: (newData, sortCol?: string) => {
    if (!newData) return;
    set({ loading: true });
    const currList = get().offerings;
    let offs: any[] = [];
    if (!currList || currList.length === 0) {
      //console.log("..... Store : updateList <<<<<<<<<< add");
      offs.push(newData);
    } else {
      //console.log("..... Store : updateList <<<<<<<<<< replace");
      const idx = currList.findIndex(o => {
        //console.log("..... Store : updateList <<<<<<<<<< findIndex o " + JSON.stringify(o.key));
        //console.log("..... Store : updateList <<<<<<<<<< findIndex newData " + JSON.stringify(newData.key));
        return o.key === newData.key;
      });

      if (idx < 0) {
        currList.push(newData);
        offs = currList;
      } else {
        //console.log("..... Store : updateList <<<<<<<<<< replace " + idx);
        offs = currList.map(obj => newData.key === obj.key ? newData : obj);
      }
      //console.log("..... Store : updateList <<<<<<<<<< findIndex o " + JSON.stringify(offs));
      // wfs = [...currList.slice(0, idx), newData, ...currList.slice(idx, currList.length)];
    }
    offs.sort((a, b) => a.updatedAt > b.updatedAt ? -1 : 1);
    set({ offerings: offs, loading: false });
    //arr1.map(obj => arr2.find(o => o.id === obj.id) || obj);
  },
  save: async (newData) => {
    set({ saving: true });
    let apiResponse;
    try {
      //console.log("..... Store : save <<<<<<<<<< offerings");
      if (newData.key) {
        //console.log("..... Store : save <<<<<<<<<< get existing offering key" + newData.key);
        const existingData = await OfferingService.fetchOfferingById(newData.key);
        const newval = {
          ...existingData,
          ...newData
        };
        //console.log("..... Store : save <<<<<<<<<< new val key" + newval.key);
        apiResponse = await OfferingService.editOffering(existingData.key, newval);
        //console.log("..... Store : save <<<<<<<<<< response key" + apiResponse.key);
      } else {
        apiResponse = await OfferingService.createOffering(newData);
      }
    } catch (e) {
      //console.log(e);
    } finally {
      set({ saving: false });
    }

    if (apiResponse) get().updateList(apiResponse);
  },
  initStore: async (isAdmin?:boolean) => {
    if (get().initialized && get().offerings.length > 0) return get().offerings;
    //console.log("..... Store : initStore <<<<<<<<<< offerings");
    set({ loading: true });
    try {
      let data = [];

          data = await OfferingService.fetchOfferings();
        const pConfigs = await ProviderConfigService.fetchProviderConfigsSelect("category", "OFFERING");
        set({ offeringConfigs: pConfigs });
      if (data && data.length > 1) {
        data.sort((a, b) => a.updatedAt > b.updatedAt ? 1 : -1);
      }
      set({ initialized:true, offerings: data });
      return data;
    } catch (e) {
      //console.log(e);
    } finally {
      set({ loading: false });
    }
  },

}));

const useOfferingStore = createSelectors(useOfferingStoreBase);
export default useOfferingStore;