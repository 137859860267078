import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";

import PropTypes from "prop-types";

import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React base styles
import typography from "assets/theme/base/typography";
import { usePublicTenantConfig } from "../../hooks";

function Footer({ company, links }) {
  const { href, name } = company;
  const { size } = typography;
  const { tenantConfig, reloadTenantConfig, loadingTenantConfig } = usePublicTenantConfig();

  const renderLinks = () =>
    links.map((link) => (
      <MDBox key={link.name} component="li" px={2} lineHeight={1}>
        <Link href={link.href} target="_blank">
          <MDTypography variant="button" fontWeight="regular" color="text">
            {link.name}
          </MDTypography>
        </Link>
      </MDBox>
    ));

  return (
    <MDBox>
      <MDBox
        width="100%"
        display="flex"
        flexDirection={{ xs: "column", lg: "row" }}
        justifyContent="space-between"
        alignItems="center"
        px={1.5}
        py={4}
      >
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          color="text"
          fontSize={size.sm}
          px={1.5}
        >
          &copy; {new Date().getFullYear()}, Consen.co by
          <Link href={href} target="_blank">
            <MDTypography variant="button" fontWeight="medium">
              &nbsp;{name}&nbsp;
            </MDTypography>
          </Link>
          for a better web.
        </MDBox>
        <MDBox
          component="ul"
          sx={({ breakpoints }) => ({
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            listStyle: "none",
            mt: 3,
            mb: 0,
            p: 0,

            [breakpoints.up("lg")]: {
              mt: 0
            }
          })}
        >
          {renderLinks()}
        </MDBox>
      </MDBox>

      {/*<MDBox>*/}
      {/*  <MDTypography variant="caption">*/}
      {/*    {JSON.stringify(tenantConfig)}*/}
      {/*  </MDTypography>*/}

      {/*</MDBox>*/}
    </MDBox>
  );
}

// Setting default values for the props of Footer
Footer.defaultProps = {
  company: { href: "https://www.consen.co/", name: "Ace|Oar, Inc" },
  links: [
    { href: "https://www.consen.co/", name: "Ace|Oar, Inc" },
    { href: "https://www.consen.co/presentation", name: "About Us" },
    { href: "https://www.consen.co/blog", name: "Blog" },
    { href: "https://www.consen.co/license", name: "License" }
  ]
};

// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object)
};

export default Footer;
