import React from "react";
import MDBox from "components/MDBox";
import CircularProgress from '@mui/material/CircularProgress';
import Grid from "@mui/material/Grid";
import Fade from '@mui/material/Fade';

interface SpinnerProps {
  label?: string;
  display?: string;
  color?: string;
  style?: any;
  absoluteTop?: string;
  minHeight?: number;
}

function Spinner(props: SpinnerProps) {
  return (
      <MDBox sx={{ display: props.display || 'flex', flexDirection: 'column', alignItems: 'center',minHeight: props.minHeight }}>
          <MDBox sx={{ height: 40 }}>
              <Fade
                  in={true}
                  // style={{
                  //     transitionDelay: loading ? '800ms' : '0ms',
                  // }}
                  unmountOnExit
              >
                  <CircularProgress
                      // variant="determinate"
                          sx={{
                          color: props.color || 'info',
                            position: props.absoluteTop? 'absolute' : 'relative',
                            top: props.absoluteTop,
                        }}
                  />
              </Fade>
          </MDBox>
        {props.label && <> &nbsp;&nbsp; {props.label}</>}
        </MDBox>
      // <Grid container alignItems="center" justifyContent="center">
      //   {/*<MDBox sx={{ display: props.display || 'flex', minHeight: props.minHeight }}>*/}
      //   <MDBox sx={{ display: props.display || 'flex', minHeight: props.minHeight,top: props.minHeight? '50%' : undefined, }}>
      //   <CircularProgress
      //     sx={{
      //     color: props.color || 'info',
      //       // position: props.minHeight? 'absolute' : 'relative',
      //       // top: props.minHeight? '50%' : undefined,
      //   }}
      //   />
      //     {props.label && <> &nbsp;&nbsp; {props.label} </>}
      // </MDBox>
      // </Grid>
  );
}

export default Spinner;
