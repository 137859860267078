import { useState } from "react";

import PropTypes from "prop-types";

import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";

// Custom styles for the MDDialog
import CustomDialogRoot from "./CustomDialogRoot";
import CustomDialogCloseIcon from "./CustomDialogCloseIcon";
import MDTypography from "../../../components/MDTypography";

function CustomDialog({ color, dismissible, children, ...rest }) {
  const [dialogStatus, setDialogStatus] = useState("mount");

  const handleDialogStatus = () => setDialogStatus("fadeOut");

  // The base template for the dialog
  const dialogTemplate = (mount = true) => (
    // <Fade in={mount} timeout={300}>

      <CustomDialogRoot ownerState={{ color }} {...rest}>
        <MDBox position="relative" my={1} mx={1}>
        {/* <Grid container spacing={3} alignItems="center"> */}
        {/*   <Grid item> */}
        {/*     <MDBox height="100%" mt={0.5} lineHeight={1}> */}
        {/*       <MDTypography variant="h5" fontWeight="medium"> */}
        {/*         Test */}
        {/*       </MDTypography> */}
        {/*       /!* <MDTypography variant="button" color="text" fontWeight="regular"> *!/ */}
        {/*       /!*   {description} *!/ */}
        {/*       /!* </MDTypography> *!/ */}
        {/*     </MDBox> */}
        {/*   </Grid> */}
        {/*   <Grid item xs={12} md={6} lg={5} sx={{ ml: "auto" }}> */}
        {/*     /!* {dismissible ? ( *!/ */}
        {/*     /!*   <CustomDialogCloseIcon onClick={mount ? handleDialogStatus : null}>&times;</CustomDialogCloseIcon> *!/ */}
        {/*     /!* ) : null} *!/ */}
        {/*   </Grid> */}
        {/* </Grid> */}

        <MDBox display="flex" flexDirection="column" alignItems="center" color="white"
               mx={1}
               sx={({
                      borders: { borderRadius, borderWidth, borderColor },
                      functions: { pxToRem },
                      palette: { light },
                      transitions,
                    }) => ({
                 // display: "flex",
                 // flexDirection:"column",
                 alignItems: "center",
                 // minWidth:"8rem",
                 // maxWidth:"19rem",
                 // minHeight:"6rem",
                 // borderRadius: borderRadius.lg,
                 // border:`${borderWidth[1]} solid ${borderColor}`,
                 // padding: `${pxToRem(16)} ${pxToRem(30)}`,
                 transition: transitions.create("background-color", {
                   easing: transitions.easing.easeInOut,
                   duration: transitions.duration.shorter,
                 }),
                 // backgroundColor: light.main
               })}
        >
          {children}
        </MDBox>
        </MDBox>
      </CustomDialogRoot>

    // </Fade>
  );

  switch (true) {
    case dialogStatus === "mount":
      return dialogTemplate();
    case dialogStatus === "fadeOut":
      setTimeout(() => setDialogStatus("unmount"), 400);
      // return dialogTemplate(false);
      return ;
    default:
      dialogTemplate();
      break;
  }

  return null;
}

// Setting default values for the props of CustomDialog
CustomDialog.defaultProps = {
  color: "light",
  dismissible: true,
};

// Typechecking props of the CustomDialog
CustomDialog.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  dismissible: PropTypes.bool,
  onClose:PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default CustomDialog;
