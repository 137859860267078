import { useCallback, useEffect, useState } from "react";
import { EForm } from "../models";
import EFormService from "../services/EFormService";
import { eFormOptions } from "../static-data/eform-data-static";

type UseEFormsHook = {
  loading: boolean;
  eForms: Array<EForm>;
  reloadEForms: Function;
};

export function useEForms(isProtected?:boolean): UseEFormsHook {
  const [loading, setLoading] = useState(false);
  const [eForms, setEForms] = useState([]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const data = isProtected? await EFormService.fetchProtectedEForms() :
      await EFormService.fetchEForms();
    console.log("useEForms eForms   >> " + JSON.stringify(data));
    setEForms(data);
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    loading,
    eForms,
    reloadEForms: fetchData,
  };
}



type UseEFormHook = {
  eForm: EForm | undefined;
  loading: boolean;
};
export function useEForm(eFormId: string): UseEFormHook {
  const [loading, setLoading] = useState(false);
  const [eForm, setEForm] = useState<EForm | undefined>(undefined);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const data = await EFormService.fetchEFormById(eFormId);
    // console.log(data);
    if (data ) {
      setEForm(data);
    }
    // else{
    //   console.error("useEForm - Expecting 1 but got Got " + JSON.stringify(data))
    // }
    setLoading(false);
  }, [eFormId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    loading,
    eForm,
  };
}
