import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Form, Formik, useFormik, FieldArray, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";


import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";


import { useTranslation } from "react-i18next";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { useChangeBackgroundColor, useDateTimeFormatter, usePublicTenantConfig } from "hooks";
import GlobalService from "../../../../services/GlobalService";
import LinearLoading from "../../../custom-components/LinearLoading";
import MDInput from "../../../../components/MDInput";
import { useMaterialUIController } from "../../../../context";
import AOTable from "../../../custom-components/Table";
import { Application } from "../../../../models";
import Link from "../../../admin/application-setup/components/Link";
import UtilsService from "../../../../services/UtilsService";
import StatusCell from "../../../custom-components/Table/StatusCell";
import DefaultCell from "../../../../layouts/ecommerce/orders/order-list/components/DefaultCell";
import IdCell from "../../user-list/components/IdCell";


interface FormValues {
  topicAreas?: {};
  topicAreaLabels: {};
}


function TopicAreaEdit({ tenantDetails, onEditCancel, onEditUpdate }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const dateFormatter = useDateTimeFormatter();

  const [savingData, setSavingData] = useState(false);
  const [selectedTA, setSelectedTA] = useState({});
  const [alertMessage, setAlertMessage] = useState("");

  const [initValue, setInitValue] = useState({
    topicAreas:
      tenantDetails.topicAreas ||
      [
        { topicAreaId: "Product", topicAreaName: "Product" },
        { topicAreaId: "Service", topicAreaName: "Service" },
        { topicAreaId: "Other", topicAreaName: "Other" }
      ],
    topicAreaLabels: {
      singular: "Offering",
      plural: "Offerings",
      ...tenantDetails?.topicAreaLabels
    }
  });


  useEffect(() => {
    console.log("TopicAreaEdit  page: >>>>>>>>>>>>> " + JSON.stringify(tenantDetails));
    // console.log("loadingTenantConfig : >>>>>>>>>>>>> " + JSON.stringify(tenantConfig));
  }, []);


  const formik = useFormik({ initialValues: initValue });

  const form = {
    formId: "topicAreas-edit-form",
    formField: {
      topicAreaLabels: {
        name: "topicAreaLabels",
        singular: {
          name: "singular",
          label: t("TopicAreaEdit.TopicAreaSingular"),
          type: "text",
          errorMsg: "singular is required.",
          placeholder: t("TopicAreaEdit.TopicAreaSingularHint")
        },
        plural: {
          name: "plural",
          label: t("TopicAreaEdit.TopicAreaPlural"),
          type: "text",
          errorMsg: "plural is required.",
          placeholder: t("TopicAreaEdit.TopicAreaPluralHint")
        }
      },
      topicAreas: {
        name: "topicArea",
        topicAreaId: {
          name: "TopicAreaId",
          label: t("TopicAreaEdit.TopicAreaId"),
          type: "text",
          placeholder: "TopicAreaId"
        },
        topicAreaName: {
          name: "TopicAreaName",
          label: t("TopicAreaEdit.TopicAreaName"),
          type: "text",
          placeholder: t("TopicAreaEdit.TopicAreaNameHint")
        }
      }


    }
  };
  const {
    formId, formField: {
      topicAreaLabels,
      topicAreas
    }
  } = form;
  const validations = Yup.object().shape({
    // [tenantName.name]: Yup.string().required(tenantName.errorMsg).min(6, tenantName.invalidMsg),
    // [bizName.name]: Yup.string().required(bizName.errorMsg).min(6, bizName.invalidMsg)
    // [singular.name]: Yup.string().required(singular.errorMsg).min(6, singular.invalidMsg),
    // [plural.name]: Yup.string().required(plural.errorMsg).min(6, plural.invalidMsg),
    // [authRequired.name]: Yup.string().required(authRequired.errorMsg).oneOf(
    //   ["Public","Private","Protected"],
    //   t("TopicAreaEdit.AuthenticationRequiredRequired")
    // ),
    // [tenantTier.name]: Yup.string().required(tenantTier.errorMsg).oneOf(
    //   ["Draft","Review","Published","Inactive"],
    //   t("TopicAreaEdit.TenantTierRequired")
    // ),
    // [categoryId.name]: Yup.string().required(categoryId.errorMsg).min(3, categoryId.invalidMsg),
  });


  // if (!loading) {
  //   crumbs.push({
  //     label: t("TopicAreaEdit.AddTenantDetails"),
  //     url: "",
  //   });
  // }
  const onSubmit = async (values: FormValues) => {
    try {
      setSavingData(true);
      console.log(JSON.stringify(values));
      if (tenantDetails.tenantId && tenantDetails.tenantId.length > 0) {
        const newval = {
          ...tenantDetails,
          ...values
        };
        await GlobalService.saveTenantDetails(tenantDetails.tenantId, newval);
      }
      if (onEditUpdate) onEditUpdate();
      // onEditTenantDetailsUpdate();
    } catch (err) {
      console.log(t("AddTopicAreasFailure"), err);
      setAlertMessage(t("AddTopicAreasFailure") + ": " + err?.message);
      setSavingData(false);
    } finally {
      setSavingData(false);

    }
  };
  const getTableData = (topicAreaData) => {
    return {
      columns: [

        { Header: "id", accessor: "topicAreaId", Cell: ({ value }) => <IdCell id={value} /> },
        { Header: "Name", accessor: "topicAreaName", Cell: ({ value }) => <DefaultCell value={value} /> }
        // { Header: "given name", accessor: "given_name", Cell: ({ value }) => <DefaultCell value={value} /> },
        // { Header: "Email", accessor: "email", Cell: ({ value }) => <DefaultCell value={value} /> },
        // {
        //   Header: "Status",
        //   accessor: "state",
        //   Cell: ({ value }) => {
        //     let status;
        //
        //     if (value === "Published") {
        //       status = <StatusCell icon="done" color="success" status="Confirmed" />;
        //     } else if (value === "Draft") {
        //       status = <StatusCell icon="edit" color="dark" status="Draft" />;
        //     } else {
        //       status = <StatusCell icon="close" color="error" status={value} />;
        //     }
        //
        //     return status;
        //   }
        // },
        //
        // {
        //   Header: "Updated",
        //   accessor: "updatedAt",
        //   Cell: ({ value }) => <DefaultCell value={dateFormatter(value)} />
        // }
        // {
        //   Header: "customer",
        //   accessor: "given_name",
        //   Cell: ({ value: [name, data] }) => (
        //     <CustomerCell image={data.image} color={data.color || "dark"} name={name} />
        //   ),
        // },
        // {
        //   Header: "product",
        //   accessor: "product",
        //   Cell: ({ value }) => {
        //     const [name, data] = value;
        //
        //     return (
        //       <DefaultCell
        //         value={typeof value === "string" ? value : name}
        //         suffix={data.suffix || false}
        //       />
        //     );
        //   },
        // },
        // { Header: "revenue", accessor: "given_name", Cell: ({ value }) => <DefaultCell value={value} /> },
      ],

      rows: topicAreaData
      // rows: [
      //   {
      //     id: "#10421",
      //     date: "1 Nov, 10:20 AM",
      //     status: "paid",
      //     customer: ["Orlando Imieto", { image: team2 }],
      //     product: "Nike Sport V2",
      //     revenue: "$140,20",
      //   },
      //
      // ],
    };
  };

  return (
    <Card id="categories" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">
          {t("TopicAreaEdit.TitleLabel", { tenant: tenantDetails.tenantName })}
        </MDTypography>
      </MDBox>

      <MDBox pb={3} px={3}>
        <Formik enableReinitialize={true}
                initialValues={formik.initialValues}
                validationSchema={validations}
                onSubmit={onSubmit}
        >
          {({ values, errors, touched, isSubmitting, setFieldValue, success }) => (
            <Form id={formId} autoComplete="off"
            >
              <MDBox mt={0}>

                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          type={topicAreaLabels.singular.type}
                          label={topicAreaLabels.singular.label}
                          name={topicAreaLabels.name}
                          value={values.topicAreaLabels ? values.topicAreaLabels.singular : ""}
                          placeholder={"TopicAreaLabel"}
                          // rows="4"
                          // multiline
                          fullWidth
                          error={errors.topicAreaLabels && errors.topicAreaLabels.singular && touched?.topicAreaLabels.singular}
                          success={values.topicAreaLabels && values.topicAreaLabels.singular && values.topicAreaLabels.singular.length > 5 && (!errors.topicAreaLabels || (errors.topicAreaLabels && !errors.topicAreaLabels?.singular))}
                          onChange={(e) => {
                            console.log(e.target.value);
                            setFieldValue("topicAreaLabels", {
                              ...values.topicAreaLabels,
                              singular: e.target.value
                            });
                          }}
                        />

                      </Grid>
                      <Grid item xs={12} sm={6}>

                        <FormField
                          type={topicAreaLabels.plural.type}
                          label={topicAreaLabels.plural.label}
                          name={topicAreaLabels.name}
                          value={values.topicAreaLabels ? values.topicAreaLabels.plural : ""}
                          placeholder={"TopicAreaLabelsLabel"}
                          // rows="4"
                          // multiline
                          fullWidth
                          error={errors.topicAreaLabels && errors.topicAreaLabels.plural && touched?.topicAreaLabels.plural}
                          success={values.topicAreaLabels && values.topicAreaLabels.plural && values.topicAreaLabels.plural.length > 5 && (!errors.topicAreaLabels || (errors.topicAreaLabels && !errors.topicAreaLabels?.plural))}
                          onChange={(e) => {
                            console.log(e.target.value);
                            setFieldValue("topicAreaLabels", {
                              ...values.topicAreaLabels,
                              plural: e.target.value
                            });
                          }}
                        />

                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>

                    <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      {t("TopicAreaEdit.Configure")}
                    </MDTypography>
                    {/*<MDBox mt={0}>*/}
                    {/*  <FieldArray name="topicAreas">*/}
                    {/*    {({ insert, remove, push }) => (*/}
                    {/*      <MDBox mx={2}>*/}
                    {/*        {values.topicAreas.length > 0 &&*/}
                    {/*          values.topicAreas.map((friend, index) => (*/}
                    {/*            <Grid container spacing={3} key={index}>*/}
                    {/*              <Grid item xs={4}>*/}
                    {/*                /!*<label htmlFor={`topicAreas.${index}.topicAreaName`}>TopicAreaName</label>*!/*/}
                    {/*                <FormField*/}
                    {/*                  name={`topicAreas.${index}.topicAreaName`}*/}
                    {/*                  label={topicAreas.topicAreaName.label}*/}
                    {/*                  type={topicAreas.topicAreaName.type}*/}
                    {/*                  placeholder={topicAreas.topicAreaName.placeholder}*/}
                    {/*                  fullWidth*/}
                    {/*                  // type="text"*/}
                    {/*                />*/}
                    {/*                <ErrorMessage*/}
                    {/*                  name={`topicAreas.${index}.topicAreaName`}*/}
                    {/*                  component="div"*/}
                    {/*                  className="field-error"*/}
                    {/*                />*/}
                    {/*              </Grid>*/}
                    {/*              <Grid item xs={4}>*/}
                    {/*                /!*<label htmlFor={`topicAreas.${index}.topicAreaId`}>TopicAreaId</label>*!/*/}
                    {/*                <FormField*/}
                    {/*                  name={`topicAreas.${index}.topicAreaId`}*/}
                    {/*                  label={topicAreas.topicAreaId.label}*/}
                    {/*                  placeholder="jane@acme.com"*/}
                    {/*                  fullWidth*/}
                    {/*                  // type="text"*/}
                    {/*                />*/}
                    {/*                <ErrorMessage*/}
                    {/*                  name={`topicAreas.${index}.topicAreaName`}*/}
                    {/*                  component="div"*/}
                    {/*                  className="field-error"*/}
                    {/*                />*/}
                    {/*              </Grid>*/}
                    {/*              <Grid item xs={4}>*/}
                    {/*                <MDButton*/}
                    {/*                  type="button"*/}
                    {/*                  className="secondary"*/}
                    {/*                  onClick={() => remove(index)}*/}
                    {/*                >*/}
                    {/*                  X*/}
                    {/*                </MDButton>*/}
                    {/*              </Grid>*/}
                    {/*            </Grid>*/}
                    {/*          ))}*/}
                    {/*        <MDButton*/}
                    {/*          type="button"*/}
                    {/*          variant="primary"*/}
                    {/*          onClick={() => push({ topicAreaName: "", topicAreaId: "" })}*/}
                    {/*        >*/}
                    {/*          Add Category*/}
                    {/*        </MDButton>*/}
                    {/*      </MDBox>*/}
                    {/*    )}*/}
                    {/*  </FieldArray>*/}

                    {/*</MDBox>*/}
                  </Grid>


                  <Grid item xs={12}>

                    <MDBox>
                      <FieldArray name="topicAreas">
                        {({ insert, remove, push }) => (
                          <MDBox mx={2}>
                            {values.topicAreas.length > 0 &&
                              values.topicAreas.map((friend, index) => (
                                <MDBox bgColor={darkMode ? "grey-900" : "grey-100"}
                                       borderRadius="lg"
                                       display="flex"
                                       justifyContent="space-between"
                                       alignItems={{ xs: "flex-start", sm: "center" }}
                                       flexDirection={{ xs: "column", sm: "row" }}
                                       my={1}
                                       py={1}
                                       pl={{ xs: 1, sm: 2 }}
                                       pr={1}>
                                  <MDBox width={{ xs: "100%", sm: "40%", md: "40%" }} mt={{ xs: 1, sm: 0 }}>
                                    {/*<label htmlFor={`topicAreas.${index}.topicAreaName`}>TopicAreaName</label>*/}
                                    <FormField
                                      name={`topicAreas.${index}.topicAreaName`}
                                      label={topicAreas.topicAreaName.label}
                                      type={topicAreas.topicAreaName.type}
                                      placeholder={topicAreas.topicAreaName.placeholder}
                                      fullWidth
                                      // type="text"
                                    />
                                    <ErrorMessage
                                      name={`topicAreas.${index}.topicAreaName`}
                                      component="div"
                                      className="field-error"
                                    />
                                  </MDBox>
                                  <MDBox width={{ xs: "100%", sm: "30%", md: "30%" }} mt={{ xs: 1, sm: 0 }}>
                                    {/*<label htmlFor={`topicAreas.${index}.topicAreaId`}>TopicAreaId</label>*/}
                                    <FormField
                                      name={`topicAreas.${index}.topicAreaId`}
                                      label={topicAreas.topicAreaId.label}
                                      placeholder="jane@acme.com"
                                      fullWidth
                                      // type="text"
                                    />
                                    <ErrorMessage
                                      name={`topicAreas.${index}.topicAreaName`}
                                      component="div"
                                      className="field-error"
                                    />
                                  </MDBox>
                                  <MDBox width={{ xs: "100%", sm: "15%", md: "15%" }} mt={{ xs: 1, sm: 0 }}>
                                    <MDButton iconOnly circular
                                      type="button" size="small"
                                              variant="contained"
                                              color="error"
                                      onClick={() => remove(index)}
                                    >
                                      <Icon sx={{ fontWeight: "bold" }}>close</Icon>
                                    </MDButton>
                                  </MDBox>
                                </MDBox>
                              ))}

                            <MDBox pt={0} ml="auto" justifyContent="right" display="flex"
                                   alignItems={{ xs: "flex-start", sm: "center" }}
                                   flexDirection={{ xs: "column", sm: "row" }}>
                              <MDButton
                                type="button"
                                variant="outlined"
                                color="error"
                                size="small"
                                onClick={() => push({ topicAreaName: "", topicAreaId: "" })}
                              >
                                Add {values.topicAreaLabels.singular} Type
                              </MDButton>
                              {/*<MDButton*/}
                              {/*  disabled={isSubmitting}*/}
                              {/*  type="submit"*/}
                              {/*  variant="gradient"*/}
                              {/*  color="primary" size="medium"*/}
                              {/*>*/}
                              {/*  {tenantDetails && tenantDetails.tenantId ? "Update" : "Create"}*/}
                              {/*</MDButton>*/}
                              {/*{savingData && <LinearLoading />}*/}
                            </MDBox>
                          </MDBox>
                        )}
                      </FieldArray>


                      {/*<MDTypography variant="button" fontWeight="medium" color="text">*/}
                      {/*  Add {values.topicAreaLabels.singular}*/}
                      {/*</MDTypography>*/}

                      {/*<MDBox width={{ xs: "100%", sm: "25%", md: "15%" }} mt={{ xs: 1, sm: 0 }}>*/}
                      {/*  <Tooltip title="Copy" placement="top">*/}
                      {/*    <MDInput size="small" value="1172913" />*/}
                      {/*  </Tooltip>*/}
                      {/*</MDBox>*/}
                      {/*<MDBox width={{ xs: "100%", sm: "25%", md: "15%" }} mt={{ xs: 1, sm: 0 }}>*/}
                      {/*  <Tooltip title="Copy" placement="top">*/}
                      {/*    <MDInput size="small" value="1172913" />*/}
                      {/*  </Tooltip>*/}

                      {/*</MDBox>*/}
                      {/*<MDBox width={{ xs: "100%", sm: "25%", md: "15%" }} mt={{ xs: 1, sm: 0 }}>*/}

                      {/*  <Tooltip title="Copy" placement="top">*/}
                      {/*    <MDButton size="small" value="1172913" />*/}
                      {/*  </Tooltip>*/}
                      {/*</MDBox>*/}
                    </MDBox>
                    {/*<FormField*/}
                    {/*  type={topicAreas.topicAreaName.type}*/}
                    {/*  label={topicAreas.topicAreaName.label}*/}
                    {/*  name={topicAreas.name}*/}
                    {/*  value={values.topicAreas ? values.topicAreas?.topicAreaName : ""}*/}
                    {/*  placeholder={"TopicAreaName"}*/}
                    {/*  // rows="4"*/}
                    {/*  // multiline*/}
                    {/*  fullWidth*/}
                    {/*  error={errors.topicAreas && errors.topicAreas?.topicAreaName && touched?.topicAreas?.topicAreaName}*/}
                    {/*  success={values.topicAreas.topicAreaName && values.topicAreas.topicAreaName.length > 5 && !errors?.topicAreas?.topicAreaName}*/}
                    {/*  onChange={(e) => {*/}
                    {/*    console.log(e.target.value);*/}
                    {/*    setFieldValue("topicAreas", {*/}
                    {/*      ...values.topicAreas,*/}
                    {/*      topicAreaName: e.target.value*/}
                    {/*    });*/}
                    {/*  }}*/}
                    {/*/>*/}
                  </Grid>


                  {/*<Grid item xs={12}>*/}
                  {/*  <AOTable table={getTableData(values.topicAreas)} entriesPerPage={false}  />*/}
                  {/*</Grid>*/}
                  <Grid item xs={12}>

                    {alertMessage && (
                      <MDAlert color="error" dismissible>
                        <MDTypography variant="body2" color="white">
                          {t("TopicAreaEdit.SaveError")}
                        </MDTypography>
                      </MDAlert>
                    )}

                    <MDBox ml="auto" justifyContent="space-between" display="flex"
                           alignItems={{ xs: "flex-end", sm: "center" }}
                           flexDirection={{ xs: "column", sm: "row" }}>

                      <MDButton
                        disabled={isSubmitting}
                        type="submit"
                        variant="gradient"
                        color="primary" size="large"
                      >
                        {tenantDetails && tenantDetails.tenantId ? "Update" : "Create"}
                      </MDButton>
                      {savingData && <LinearLoading />}
                    </MDBox>

                    {/*<MDTypography variant="caption">*/}
                    {/*  {JSON.stringify(values)}*/}
                    {/*</MDTypography>*/}
                  </Grid>
                </Grid>

              </MDBox>
            </Form>
          )}
        </Formik>


      </MDBox>

    </Card>
  );
}

export default TopicAreaEdit;
