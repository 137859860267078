export const defaultWFNodes = [
  {
    id: 'start-01',
    // sourcePosition: 'right',
    type: 'startNode',
    data: {
      label: 'START',
      icon: 'start',
      description: 'When Apply Clicked',
      color: 'info',
      // value: 'success'
    },
    position: { x: 0, y: 5 },
  },
  {
    id: 'tc-01',
    sourcePosition: 'right',
    targetPosition: 'left',
    type: 'tncNode',
    // style: { border: '1px solid #777', padding: 10 },
    data: {
      label: 'T&C',
      icon: 'note_alt',
      description: 'Display T&C',
      color: 'warning',
      value: 'success'
    },
    position: { x: 220, y: 5 },
  },
  {
    id: 'submission-01',
    sourcePosition: 'right',
    targetPosition: 'left',
    type: 'applicationNode',
    data: {
      label: 'Application',
      icon: 'post_add',
      description: 'Submission',
      color: 'primary',
      value: {name: "None", key:"NONE"}
    },
    position: { x: 400, y: 5 },
  },
  {
    id: 'end-01',
    sourcePosition: 'right',
    targetPosition: 'left',
    type: 'endNode',
    data: {
      label: 'END',
      icon: 'check_circle_outline',
      description: 'Process Done',
      color: 'success',
      // value: 'success'
    },
    position: { x: 650, y: 5 },
  }
];

export const defaultWFEdges = [
  {
    id: 'horizontal-e1-2',
    source: 'start-01',
    type: 'smoothstep',
    sourceHandle: 'b',
    target: 'tc-01',
    style: { stroke: '#15b615' },
    // label: 'On Apply Click',
    animated: true,
  },
  {
    id: 'horizontal-e1-3',
    source: 'tc-01',
    type: 'smoothstep',
    sourceHandle: 'b',
    target: 'submission-01',
    style: { stroke: '#55f' },
    // label: 'edge label',
    animated: true,
  },
  {
    id: 'horizontal-e1-4',
    source: 'submission-01',
    type: 'smoothstep',
    sourceHandle: 'b',
    target: 'end-01',
    style: { stroke: '#1010b9' },
    label: 'Review & Fulfill',
  },

];

export const defaultWFSteps = [
  {
    source: 'start-01',
    sourceType: 'start',
    target: 'tc-01',
    targetType: "tnc",
    seq: 0,
  },
  {
    source: 'tc-01',
    sourceType:'tnc',
    target: 'submission-01',
    targetType:'application',
    seq: 1,
  },
  {
    source: 'submission-01',
    sourceType:'application',
    target: 'end-01',
    targetType:'end',
    seq: 2,
  },

];