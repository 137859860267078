import { useEffect, useState, useCallback } from "react";
import {
  Submission,
  SubmissionAuditLog,
  SubmissionState,
  SubmissionVersion,
  SubmissionParticipant,
} from "../models";
import SubmissionService from "../services/SubmissionService";
import AuditTrailService from "../services/AuditTrailService";
import FriendlyURLGenerator from "../services/FriendlyURLGenerator";
// import SubmissionService from "../services/SubmissionService";

type UseSubmissionHook = {
  loading: boolean;
  submission?: Submission;
  reloadSubmission: Function;
  setSubmission: Function;
};

const ROW_TYPE_MAIN = "SBT";
const ROW_TYPE_PCP = "PCP";

export function useSubmission(submissionId: string): UseSubmissionHook {
  const [loading, setLoading] = useState(false);
  const [submission, setSubmission] = useState<Submission | undefined>(undefined);

  const fetchData = useCallback(
    async (updateLoading: boolean = true) => {
      updateLoading && setLoading(true);
      const data = await SubmissionService.fetchSubmissionById(submissionId);
      updateLoading && setLoading(false);
      if (data) {
        let widgets = await SubmissionService.fetchSubmissionWidgets(submissionId);
        console.log(submissionId+" Widgets : " + JSON.stringify(widgets));
        if (widgets) {
         widgets.sort((a, b) => a.order - b.order);
        }else{
          widgets = [];
        }
        data.widgets = widgets;
        setSubmission(data);
      }
    },
    [submissionId]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    loading,
    submission,
    setSubmission,
    reloadSubmission: fetchData,
  };
}

type UseSubmissionsHook = {
  loading: boolean;
  submissions: Array<Submission>;
  draftsSubmissions: Array<Submission>;
  submittedSubmissions: Array<Submission>;
  reviewSubmissions: Array<Submission>;
  archivedSubmissions: Array<Submission>;
  reloadSubmissions: Function;
};

export function useAdminSubmissions(): UseSubmissionsHook {
  const [loading, setLoading] = useState(false);
  const [submissions, setSubmissions] = useState<Submission[]>([]);
  const [draftsSubmissions, setDraftsSubmissions] = useState<Submission[]>([]);
  const [submittedSubmissions, setSubmittedSubmissions] = useState<Submission[]>(
    []
  );
  const [reviewSubmissions, setReviewSubmissions] = useState<Submission[]>(
    []
  );
  const [archivedSubmissions, setArchivedSubmissions] = useState<Submission[]>([]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const data = await SubmissionService.fetchSubmissions();
    console.log(JSON.stringify(data));

    if (data && data.length>0) {
      const sbtRows = data ;//.filter(r=>r.rowType===ROW_TYPE_MAIN);
      setSubmissions(sbtRows); // All SBT rows
      setDraftsSubmissions(
        sbtRows.filter((submission) => submission.rowType===ROW_TYPE_MAIN && submission.state === SubmissionState.Draft )
      );
      setSubmittedSubmissions(
        sbtRows.filter((submission) => submission.state === SubmissionState.Submitted)
      );
      setReviewSubmissions(
        sbtRows.filter((submission) => submission.state === SubmissionState.Review || submission.state === SubmissionState.InProgress)
      );
      setArchivedSubmissions(
        sbtRows.filter((submission) => submission.state === SubmissionState.Archived)
      );
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    loading,
    submissions,
    draftsSubmissions,
    submittedSubmissions,
    reviewSubmissions,
    archivedSubmissions,
    reloadSubmissions: fetchData,
  };
}

type UseSubmissionParticipantHook = {
  loading: boolean;
  submission?: SubmissionParticipant;
  reloadSubmission: Function;
  submissionNotFound?: boolean;
};

export function useSubmissionParticipant(
  friendlyURL: string
): UseSubmissionParticipantHook {
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [submission, setSubmission] = useState<SubmissionParticipant | undefined>(
    undefined
  );

  const setSubmissionAndSortWidgets = (submission: SubmissionParticipant) => {
    if (submission) {
      submission.widgets.sort((a, b) => a.order - b.order);
      setSubmission(submission);
    }
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const data = await SubmissionService.fetchSubmissionParticipantByURL(friendlyURL);
      setSubmissionAndSortWidgets(data);
    } catch (error) {
      try {
        /**
         * If fetching the submission by URL fails. Try loading the submission
         * by ID as a last resource. This allows us to be backwards compatible
         * so submissions that don't currently have a friendlyURL can still
         * load for public users. We may remove this in the future.
         */
        const submissionId = friendlyURL; // use the friendlyURL as an ID
        const data = await SubmissionService.fetchSubmissionParticipant(submissionId);
        setSubmissionAndSortWidgets(data);
      } catch (err) {
        /**
         * If fetching the submission by ID did not work either. Then consider
         * we can consider the submission to be NotFound.
         */
        setNotFound(true);
      } finally {
        setLoading(false);
      }
    } finally {
      setLoading(false);
    }
  }, [friendlyURL]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    loading,
    submission,
    reloadSubmission: fetchData,
    submissionNotFound: notFound,
  };
}

type UseVersionsHook = {
  loading: boolean;
  versions: Array<SubmissionVersion>;
};

export function useSubmissionVersions(
  parentSubmissionId: string | undefined
): UseVersionsHook {
  const [loading, setLoading] = useState<boolean>(false);
  const [versions, setVersions] = useState<SubmissionVersion[]>([]);

  useEffect(() => {
    if (parentSubmissionId) {
      const fetchData = async () => {
        setLoading(true);
        const data = await SubmissionService.fetchSubmissionVersions(
          parentSubmissionId
        );
        setVersions(data);
        setLoading(false);
      };
      fetchData();
    }
  }, [parentSubmissionId]);

  return {
    loading,
    versions,
  };
}

type UseHistoryHook = {
  loading: boolean;
  auditlogs: SubmissionAuditLog[];
};

export function useSubmissionHistory(
  parentSubmissionId?: string
): UseHistoryHook {
  const [loading, setLoading] = useState<boolean>(false);
  const [auditlogs, setAuditlogs] = useState<SubmissionAuditLog[]>([]);

  useEffect(() => {
    if (parentSubmissionId) {
      const fetchData = async () => {
        setLoading(true);
        const data = await SubmissionService.fetchSubmissionHistory(
          parentSubmissionId
        );

        // Filter out noisy logs first
        const logs = AuditTrailService.removeNosiyAuditLogs(
          data as SubmissionAuditLog[]
        );

        setAuditlogs(logs);
        setLoading(false);
      };
      fetchData();
    }
  }, [parentSubmissionId]);

  return {
    loading,
    auditlogs,
  };
}

type UseFriendlyUrlHook = {
  friendlyURL: string;
};

export function useFriendlyUrl(
  submission?: Submission,
  versions?: SubmissionVersion[]
): UseFriendlyUrlHook {
  const [friendlyURL, setFriendlyURL] = useState("");
  useEffect(() => {
    if (submission) {
      const submitted = versions?.find(
        (version) => version.state === SubmissionState.Submitted
      );

      if (submission.friendlyURL) {
        setFriendlyURL(submission.friendlyURL);
      } else if (submitted && submitted.friendlyURL) {
        setFriendlyURL(submitted.friendlyURL);
      } else {
        setFriendlyURL(
          FriendlyURLGenerator.generateFromName(submission.name)
        );
      }
    }
  }, [submission, versions]);

  return {
    friendlyURL,
  };
}
