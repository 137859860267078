import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import Card from "@mui/material/Card";

export default function CardSkeleton() {
  return (
    <Card>
    <Stack spacing={1}>
      {/* For variant="text", adjust the height via font-size */}
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />

      {/* For other variants, adjust the size with `width` and `height` */}
      <Skeleton variant="circular" width={40} height={40} />
      <Skeleton variant="rectangular"  height={120} />
      <Skeleton variant="rectangular"  height={220} />
      <Skeleton variant="rounded"  height={60} />
    </Stack>
    </Card>
  );
}
