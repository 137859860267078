import React, { useEffect } from "react";

import { useLocation,useParams, Link } from "react-router-dom";

import PropTypes from "prop-types";

import MDBox from "../../components/MDBox";

import { useMaterialUIController, setLayout } from "../../context";
import { usePublicTenantConfig } from "../../hooks";
import LinearLoading from "../../views/custom-components/LinearLoading";
// import Footer from "../../examples/Footer";
import SubmissionService from "../../services/SubmissionService";
import MDTypography from "../../components/MDTypography";
import MDBadge from "../../components/MDBadge";
import logoImage from "../../assets/images/cn-logo-full.svg";
import MDButton from "../../components/MDButton";
import PublicFooter from "./PublicFooter";

function PublicLayout({ background, children }) {
  const [, dispatch] = useMaterialUIController();
  const { pathname } = useLocation();
  const { tenantName } = useParams();
  const { tenantConfig,tenantConfigLoaded,
    reloadTenantConfig, loadingTenantConfig } = usePublicTenantConfig();

  useEffect(() => {
    // console.log("ProviderHome tenantName" );
    // console.log("ProviderHome current tenantConfig " + JSON.stringify(tenantConfig));
    if (tenantName && !loadingTenantConfig && tenantConfig.tenantName!==tenantName){
      window.sessionStorage.setItem('tenantName', tenantName);
      reloadTenantConfig();
    }
  }, [tenantName]);

  useEffect(() => {
    setLayout(dispatch, "public");
  }, [pathname]);
  //
  // if (!tenantConfigLoaded ) {
  //   return (
  //
  //     <MDBox>
  //       <LinearLoading />
  //     </MDBox>
  //
  //   );
  // }

  // const offerApply = async (offering) => {
  //   // if (!offering.workflow.workflowId) {
  //   //   console.log("ProviderHome : Workflow not defined " + JSON.stringify(offering.workflow));
  //   //   return;
  //   // }
  //   console.log("ProviderHome Workflow: " + JSON.stringify(offering.workflow));
  //   // @TODO: Detect if Login required , if yes && not logged in, navigate to login page
  //   // if no - @TODO: not supported?
  //   // Create new Submission
  //   const submission = await SubmissionService.createSubmission(offering);
  //   console.log("ProviderHome submission  " + JSON.stringify(submission));
  //
  //   let nextStep;
  //
  //   // const workflow = await WorkflowService.fetchWorkflowById(submission.workflow.workflowId);
  //   // console.log("ProviderHome Workflow  " + JSON.stringify( workflow));
  //   //
  //   // Initiate new Submission
  //
  //   // const submissionId = 'TO_BE_CREATED';
  //   navigate(`/app/submission/${submission.submissionKey}`, {
  //     // state: {
  //     //   notification: "alert",
  //     //   type: "success",
  //     //   message: `${t(
  //     //     widget.widgetType === WidgetType.Chart
  //     //       ? widget.content.chartType
  //     //       : widget.widgetType
  //     //   )} '${widget.name}' ${t("DashboardWasCopied")}`,
  //     // },
  //   });
  //
  //
  // };

  return (
    // @ts-ignore
    <MDBox
      // width="98vw"
      height="100%"
      minHeight="80vh"
      bgColor={background}
      // sx={{ overflowX: "hidden" }}
    >
      {tenantConfigLoaded?
        children
        :
            <MDBox>
              {loadingTenantConfig ?
              <LinearLoading />
                :
                <MDBox mt={8} display="flex" justifyContent="center"  flexDirection="column" alignItems="center" p={3}>
                  {/*<MDBox component="img" src={logoImage} width="40%" borderRadius="sm" mb={1} />*/}
                  <MDTypography variant="h1">404</MDTypography>
                  <MDTypography variant="h5">Unknown Provider</MDTypography>
                  <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }} pt={2}>
                    <Link to={"/provider-lookup"}>
                      <MDButton variant="text" color={"error"}>Lookup Provider</MDButton>
                    </Link>
                  </MDBox>
                </MDBox>
              }
            </MDBox>
      }
      <PublicFooter />
      {/*<MDBox>*/}
      {/*  <MDTypography variant="caption" >*/}
      {/*  PublicLayout <br/>*/}
      {/*  pathname : {pathname} <br/>*/}
      {/*  tenantName : {tenantName} <br/>*/}
      {/*  </MDTypography>*/}
      {/*</MDBox>*/}
    </MDBox>
  );
}

// Setting default values for the props for PublicLayout
PublicLayout.defaultProps = {
  background: "default",
};

// Typechecking props for the PublicLayout
PublicLayout.propTypes = {
  background: PropTypes.oneOf(["white", "light", "default"]),
  children: PropTypes.node.isRequired,
};

export const withPublicLayout = (component: React.ComponentType): React.FunctionComponent<{}> => {
  return function () {
    return <PublicLayout>{React.createElement(component)}</PublicLayout>;
  };
};

export default withPublicLayout;
