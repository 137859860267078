import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Material Dashboard 2 PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import logoCS from "assets/images/cs-gray-sym.svg";
import backgroundImage from "assets/images/bg-profile.jpeg";
import LoadingBar from "../LoadingBar/LoadingBar";
import Spinner from "../Spinner/Spinner";

function AdminHeader({ children, title, icon, description, loading , isMini}) {

  return (
    <MDBox position="relative" mb={4}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight={isMini? "6.75rem" :"9.75rem"}
        borderRadius={"xl"}
        sx={{
          background: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.brand.main, 1.0),
              rgba(gradients.brand.state, 0.7)
            )}`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}

      />
      <Card
        sx={{
          position: "relative",
          mt: -6,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            {loading ? <Spinner/>
              :
            <Icon fontSize="large" sx={{ color: ({ palette: { info } }) => info.main }}>{icon}</Icon>}

          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {title}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular">
                {description}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={5} sx={{ ml: "auto" }}>

            {children &&
              <MDBox display="flex"
                     justifyContent="flex-end"
                     alignItems="center"
                     sx={{ listStyle: "none" }}>
                {children}

              </MDBox>
            }

          </Grid>

        </Grid>

        {/*{children}*/}

      </Card>

    </MDBox>
  );
}

// Setting default props for the Header
AdminHeader.defaultProps = {
  children: "",
};

// Typechecking props for the Header
AdminHeader.propTypes = {
  children: PropTypes.node,
};

export default AdminHeader;
