import React, { useEffect, useState, useCallback } from "react";
import {Amplify, Hub} from "@aws-amplify/core";
// import Auth from "@aws-amplify/auth";
// import { Hub} from "@aws-amplify/core";
import { Auth} from "@aws-amplify/auth";
import { Storage} from "@aws-amplify/storage";
// import { Amplify } from 'aws-amplify';
import { TenantConfig, PublicTenantConfig } from "../models";
import EnvConfig from '../services/EnvConfig';
import UtilsService from "../services/UtilsService";
import GlobalService from "../services/GlobalService";
import { useNavigate } from "react-router-dom";

/**
 * Default tenantConfig to start with while we fetch the actual
 * TenantConfig from the Tenant.
 */
const defaultTenantConfig: TenantConfig = {
  authRequired: true
};

const defAmplifyConfig = {
  API: {
    endpoints: [
      {
        name: "regApiGatewayUrl",
        endpoint: EnvConfig.regApiGatewayUrl,
      },
    ],
  },
  Auth: {
  //   region: EnvConfig.region,
  //   userPoolId: EnvConfig.userPoolId,
  //   userPoolWebClientId: EnvConfig.appClientId,
  //   identityPoolId: EnvConfig.identityPoolId,
  },
  Storage: {
    AWSS3: {
      bucket: 'cs-sls-tenant-files-prod-pooled', //@TODO: replace from config
      region: 'us-east-1', //OPTIONAL -  Amazon service region
      // isObjectLockEnabled: true //OPTIONAl - Object Lock parameter
    }
  }
  // Storage: {
  //   AWSS3: {
  //     bucket: EnvConfig.datasetsBucket,
  //     region: EnvConfig.region,
  //   },
  // },
};

interface TenantConfigContextProps {
  tenantConfig: TenantConfig | PublicTenantConfig;
  reloadTenantConfig: Function;
  loadingTenantConfig: boolean;
  tenantConfigLoaded: boolean;
}

export const TenantConfigContext = React.createContext<TenantConfigContextProps>({
  reloadTenantConfig: (tenantId?:string, rememberTenant?:boolean) => {},
  tenantConfig: defaultTenantConfig,
  loadingTenantConfig: true,
  tenantConfigLoaded: false,
});

/**
 * The tenantConfig reducer takes the tenantConfig object coming from the
 * backend and returns a TenantConfig object containing those values
 * and fallbacks to default values for any missing field.
 *
 * This protects the frontend from crashing in the event where the
 * backend returns a TenantConfig object that is missing certain fields.
 */
// const tenantConfigReducer = (backendTenantConfig: TenantConfig): TenantConfig => {
//   return {
//     ...backendTenantConfig, // add all values to start with
//     // Check if we need to fallback to default values
//     enterpriseLoginLabel: "Enterprise Sign-In",
//   };
// };

const publicTenantConfigReducer = (
                                   backendTenantConfig: PublicTenantConfig
): PublicTenantConfig => {
  return {
    ...backendTenantConfig, // add all values to start with
    // Check if we need to fallback to default values
    enterpriseLoginLabel: "Enterprise Sign-In",
  };
};


/**
 * This provider wraps the root of our component's tree in <App />
 * to provide TenantConfig to all the children components in the tree. It
 * uses React Context so that tenantConfig are kept in a global state instead
 * of fetching them over and over on every screen.
 */
function TenantConfigProvider(props: { children: React.ReactNode }) {
  const navigate = useNavigate();
  const [lookupTenant, setLookupTenant] = useState({});
  const [tenantConfig, setTenantConfig] = useState<TenantConfigContextProps>({
    reloadTenantConfig: (tenantId?:string, rememberTenant?:boolean) => {},
    tenantConfig: defaultTenantConfig,
    loadingTenantConfig: true,
    tenantConfigLoaded: false,
  });


  //
  // const getAuthUserTenantId = useCallback(async () => {
  //   const authedTenantId=await Auth.currentSession()
  //     .then(cs=> {
  //       return cs.idToken && cs.idToken.payload["custom:tenantId"]
  //     })
  //     .catch(e=>console.log(e));
  //   return authedTenantId;
  // }, []);

  const fetchTenantConfig = useCallback(async (tenant?:string) => {
    /**
     * tenantName present only when directly lands on a provider page e.g. :tenantName/home and takes priority
     */
    const tenantName = window.sessionStorage.getItem('tenantName') || UtilsService.getSubdomain();
    if (tenantName  && !tenant){
      console.log("getting config by tenantName " + tenantName);
      setLookupTenant({lookupType: 'tenantName', lookupValue: tenantName});
      const tConfig = await GlobalService.fetchPublicTenantConfigByName(tenantName);
      UtilsService.setCurrentTenant(tConfig);
      // if (tConfig && tConfig.data){
      //   // window.sessionStorage.setItem('tenantId',tConfig.data.tenantId );
      //   // const localStorageTenant = UtilsService.getLocalStorageTenant();
      //   if (localStorageTenant) UtilsService.setCurrentTenant(tConfig.data);
      // }
      return tConfig;
    }

    let tenantId = tenant || defaultTenantConfig.tenantId
      || UtilsService.getLocalStorageTenant() || window.sessionStorage.getItem('tenantId');

    // if (!tenantId) {
    //   // console.log("fetchTenantConfig : Auth in a tenant context", Auth.currentUserInfo().promise());
    //   const tenantUsr = await Auth.currentUserInfo()
    //     .then(cs=> {
    //       console.log("fetchTenantConfig : Auth in a tenant context", cs);
    //       // if (cs.attributes && cs.attributes["custom:tenantId"]){
    //       //   const authTenantId = cs.attributes["custom:tenantId"];
    //       //   console.log("Found TenantId from Auth...");
    //       //   window.sessionStorage.setItem('tenantId', authTenantId);
    //       //   return authTenantId;
    //       // }
    //     })
    //     .catch(e=>console.log(e)); // just to refresh
    // }
    if (!tenantId) {
      console.log("not in a tenant context");
      return;
    }
    setLookupTenant({lookupType: 'tenantId', lookupValue: tenantId});
    const tConfig = await GlobalService.fetchPublicTenantConfig(tenantId);
    UtilsService.setCurrentTenant(tConfig);
    return tConfig;
  }, []);

  const loadTenantConfig = useCallback(async (tenantId?:string, rememberTenant?:boolean) => {
    console.log('loadTenantConfig passed tenant:' + tenantId + " remember: " + rememberTenant);
    if (tenantId) {
      defaultTenantConfig.tenantId = tenantId;
    }else {
      // const authedUserTenantId = await getAuthUserTenantId();
      // console.log('loadTenantConfig authedUserTenantId:' + authedUserTenantId );
    }

    try {
      /**
       * useCallback is used because it is important that the
       * reloadTenantConfig function does not change, because it
       * causes an infinite loop due to the tenantConfig-hook
       * having a useEffect on it.
       */
      setTenantConfig({
        reloadTenantConfig: loadTenantConfig,
        tenantConfig: defaultTenantConfig,
        loadingTenantConfig: true,
        tenantConfigLoaded: false,
      });

      const data:any = await fetchTenantConfig(tenantId);
      // console.log("Tenant config : " + JSON.stringify(data.data));

      if (!data) {
        setTenantConfig({
          reloadTenantConfig: loadTenantConfig,
          tenantConfig: defaultTenantConfig,
          loadingTenantConfig: false,
          tenantConfigLoaded: false,
        });
        // if (!tenantId) {
        //   navigate("/provider-lookup");
        // }
        return;
      }



      // console.log(JSON.stringify(data));
      // console.log(JSON.stringify("publicTenantConfigReducer \n" +
      //   JSON.stringify( publicTenantConfigReducer(data.data))));
      await amplifyConfig(data);

      setTenantConfig({
        tenantConfig: publicTenantConfigReducer( data.data),
        reloadTenantConfig: loadTenantConfig,
        loadingTenantConfig: false,
        tenantConfigLoaded: true,
      });
      if (rememberTenant){
        localStorage.setItem('tenantId', tenantId || "");
      }

      await Auth.currentSession()
          .then(cs=> {
            if (cs.idToken && cs.idToken.payload["custom:tenantId"]!==data.data.tenantId){
              console.log("User belongs to other Tenant, Signing Out...");
              Auth.signOut();
              window.sessionStorage.setItem('tenantId', data.data.tenantId);
              window.sessionStorage.setItem('tenantName', data.data.tenantName);
            }
          })
          .catch(e=>console.log(e)); // just to refresh

      console.log("Tenant loaded");

      // Refresh
      // await Auth.currentSession()
      //   .then(cs=> {
      //     if (cs.idToken && cs.idToken.payload["custom:tenantId"]!==data.data.tenantId){
      //       console.log("User belongs to other Tenant, Signing Out...");
      //       Auth.signOut();
      //       window.sessionStorage.setItem('tenantName', data.data.tenantId)
      //     }
      //   })
      //   .catch(e=>console.log(e)); // just to refresh

    } catch (err) {
      console.log("Failed to load tenantConfig from backend",err);
      setTenantConfig({
        reloadTenantConfig: loadTenantConfig,
        tenantConfig: defaultTenantConfig,
        loadingTenantConfig: false,
        tenantConfigLoaded: false,
      });
      // if (err.response && err.response.status===404) {
      //   navigate("/provider-lookup", {
      //     state: {
      //       notification: "alert",
      //       type: "error",
      //       message: `Provider Not Found!`,
      //     },
      //   });
      // }
    }



    // const sess = await Auth.currentSession();
    // console.log("Session : " + JSON.stringify(sess));
  }, [fetchTenantConfig]);

  async function amplifyConfig(res:any) {
    let configuration: { [k: string]: any } = defAmplifyConfig;

    // console.log(JSON.stringify(res.data));
    const cfg=res.data;

    if (!cfg.userPoolId || !cfg.appClientId) {
      return;
    }
    const region = cfg.userPoolId?.split('_')[0];

    const beApi = configuration.API.endpoints.filter(ep => ep.name === "BackendApi");
    if (beApi.length===0) {
      configuration.API.endpoints.push({
        name: "BackendApi",
        endpoint: cfg.apiGatewayUrl,
      });
    }

    configuration.Auth = {
      region: region,
      userPoolId: cfg.userPoolId,
      userPoolWebClientId: cfg.appClientId,
      // (required) only for Federated Authentication - Amazon Cognito Identity Pool ID
      // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
    };

    configuration.Storage.AWSS3.region = region;

    // console.log("Loading Amplify Config with : " + JSON.stringify(configuration));

    if (EnvConfig.samlProvider) {
      configuration.oauth = oauthConfig;
    }
    await Amplify.configure(configuration);
    await Auth.configure(configuration);
    await Storage.configure(configuration);
    // console.log ("Amplify Configured with: " + JSON.stringify(configuration));
  }

  const oauthConfig = {
    domain: EnvConfig.cognitoDomain,
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: EnvConfig.frontendDomain,
    redirectSignOut: EnvConfig.frontendDomain,
    responseType: "code", // or token
    // optional, for Cognito hosted ui specified options
    options: {
      // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
      AdvancedSecurityDataCollectionFlag: true,
    },
  };
  /**
   * Listen for authentication events so that when users
   * signIn or their token is refreshed, we refetch the
   * TenantConfig. This covers an edge case in which we fail
   * to fetch TenantConfig the first time because the user was
   * not authenticated yet.
   */
  const listenAuthEvents = useCallback(
    (event: any) => {
      const { payload } = event;
      switch (payload.event) {
        case "signIn":
        case "tokenRefresh":
          console.log("Detected AuthEvents: " + payload.event)
          if (payload.event==='signIn'){
            // const tenantId = Auth.
            // console.log("Full AuthEvents after signIn: " + JSON.stringify(payload.data));
            if (payload.data.attributes && payload.data.attributes["custom:tenantId"]){
              const authTenantId = payload.data.attributes["custom:tenantId"];
              console.log("Found TenantId from Auth SignIn...");
              window.sessionStorage.setItem('tenantId', authTenantId);
              window.sessionStorage.removeItem('tenantName');
              // return authTenantId;
            }
          }
          loadTenantConfig();
          break;
        default:
          break;
      }
    },
    [loadTenantConfig]
  );

  useEffect(() => {
    loadTenantConfig();
    Hub.listen("auth", listenAuthEvents);
    return () => Hub.remove("auth", listenAuthEvents);
  }, [loadTenantConfig, listenAuthEvents]);

  return (
    <TenantConfigContext.Provider value={tenantConfig}>
      {props.children}
    </TenantConfigContext.Provider>
  );
}

export default TenantConfigProvider;
