import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";
import DialogContent from "@mui/material/DialogContent";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";


// OrderDetails page components
import Header from "./components/Header";
import TrackOrder from "./components/TrackOrder";
import PaymentDetails from "./components/PaymentDetails";
import BillingInformation from "./components/BillingInformation";
import OrderSummary from "./components/OrderSummary";
import StatInfo from "./components/StatInfo";

function BasicContainerCard({ dataObj, children, leftButton, rightButton, showStat }) {

    // const {} = props.dataObj;

    return (
        <Card>
            <MDBox mt={4} px={4} mb={1}>

                <Header name={dataObj.name} desc={dataObj.description} state={dataObj.state}
                        updatedAt={dataObj.updatedAt} updatedBy={dataObj.updatedBy} />

                {showStat && <>
                    <Divider />
                    <StatInfo scope={dataObj.scope} />
                </>
                }
                <MDBox mt={3}>
                    {children}
                    {/*<Grid container spacing={3}>*/}
                    {/*  <Grid item xs={12} md={6} lg={3}>*/}
                    {/*    <TrackOrder />*/}
                    {/*  </Grid>*/}
                    {/*  <Grid item xs={12} md={6} lg={5}>*/}
                    {/*    <PaymentDetails />*/}
                    {/*    <MDBox mt={3}>*/}
                    {/*      <BillingInformation />*/}
                    {/*    </MDBox>*/}
                    {/*  </Grid>*/}
                    {/*  <Grid item xs={12} lg={3} sx={{ ml: "auto" }}>*/}
                    {/*    <OrderSummary />*/}
                    {/*  </Grid>*/}
                    {/*</Grid>*/}
                </MDBox>

                <MDBox py={3}>
                    <MDBox display="flex" justifyContent="space-between" flexDirection={{ xs: "column", sm: "row" }}>
                        {/*<MDButton variant="gradient" color="primary" sx={{ height: "100%" }}*/}
                        {/*          component={Link} to={`/admin/workflow/edit/${dataObj.key}`}>*/}
                        {/*    View Details*/}
                        {/*</MDButton>*/}
                        {leftButton}
                        {rightButton &&
                            <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                {rightButton}
                            </MDBox>}
                    </MDBox>
                </MDBox>
            </MDBox>
        </Card>


    );
}

export default BasicContainerCard;
