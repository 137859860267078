import React, { useState, useEffect, lazy, Suspense } from "react";

import { Link, useNavigate, useParams, useLocation } from "react-router-dom";

import Container from "@mui/material/Container";

// Pricing page components
import Header from "./components/Header";
import Footer from "../../LayoutContainers/PublicLayout/PublicFooter";
import TenancyCards from "./components/TenancyCards";
import TrustedBrands from "./components/TrustedBrands";
import Faq from "./components/Faq";
import MDButton from "../../components/MDButton";
import ConsumerCard from "./components/ConsumerCard";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "../../components/MDTypography";
import MDBox from "../../components/MDBox";
import ActionCard from "../custom-components/ActionCard";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ConsumerLayout from "LayoutContainers/ConsumerLayout";
import {
  useChangeBackgroundColor,
  useCurrentAuthenticatedUser,
  useOfferings,
  usePublicTenantConfig
} from "../../hooks";
import OfferingAdminCard from "../custom-components/OfferingAdminCard";
// import OfferingConsumerCard from "../custom-components/OfferingConsumerCard";
import ConsumerHeader from "../../LayoutContainers/ConsumerLayout/ConsumerHeader";
import Spinner from "../custom-components/Spinner/Spinner";
import WorkflowService from "../../services/WorkflowService";
import WorkflowUtils from "../../services/WorkflowUtils";
import SubmissionService from "../../services/SubmissionService";
import PageLayout from "../../LayoutContainers/PageLayout";
import LinearLoading from "../custom-components/LinearLoading";
import csSiteImg from "assets/images/wide_headers/15.png";
import withPublicLayout from "../../LayoutContainers/PublicLayout";
import useConsumerOfferingsStore from "../../store/consumer-offering-store";

const OfferingConsumerCard = lazy(() => import("../custom-components/OfferingConsumerCard"));

interface PathParams {
  tenantName: string;
}

function ProviderHome() {

  const { tenantName } = useParams<PathParams>();
  const { tenantConfig, tenantConfigLoaded, loadingTenantConfig, reloadTenantConfig } = usePublicTenantConfig();
  // @TODO : fetch only published + public data
  // const {offerings,reloadOfferings, loading} = useOfferings();
  const { username, tenant, isAdmin, isLoggedIn } =
    useCurrentAuthenticatedUser();
  const initOffsStore = useConsumerOfferingsStore.use.initStore();
  const offerings = useConsumerOfferingsStore.use.offerings();
  const loading = useConsumerOfferingsStore.use.loading();
  const offStoreWorking = useConsumerOfferingsStore.use.working();
  const applyForOffering = useConsumerOfferingsStore.use.applyForOffering();


  const [currentStatus, setCurrentStatus] = React.useState("");

  const navigate = useNavigate();

  useEffect(() => {
    console.log("ProviderHome tenantName" );
    // console.log("ProviderHome current tenantConfig " + JSON.stringify(tenantConfig));
    if (tenantName && !loadingTenantConfig && tenantConfig.tenantName !== tenantName) {
      window.sessionStorage.setItem("tenantName", tenantName);
      reloadTenantConfig();
    }
  }, [tenantName]);

  useEffect(() => {
    console.log("> ProviderHome loadingTenantConfig " + loadingTenantConfig);
    // console.log("-- ProviderHome tenantConfig " + JSON.stringify(tenantConfig));
    if (!loadingTenantConfig) {
      console.log(">> ProviderHome loadingTenantConfig " + loadingTenantConfig);
      if (tenantConfigLoaded) {
        // reloadOfferings();
        initOffsStore(tenantConfig.authRequired, tenantConfig.tenantId,isAdmin);
      } else {
        console.log(">>> ProviderHome tenantName " + tenantName);
        console.log(">>> ProviderHome loadingTenantConfig " + loadingTenantConfig);
        navigate("/provider-lookup");
      }

    }
  }, [loadingTenantConfig]);

  // useChangeBackgroundColor();


  useEffect(() => {
    setCurrentStatus(loading ? "loading-offerings" : "");
  }, [loading]);

  const handleApply = async (offeringKey) => {
    try {
      const submission = await applyForOffering(offeringKey);
      navigate(`/app/submission/${submission.metadata.submissionId}`);
    } catch (e) {
      console.error(e);
    }
  };

  const loginToApply = async (offeringKey) => {
    console.log("ProviderHome : offering key " , offeringKey);
    if (!offeringKey) {
      console.log("ProviderHome : offering not determined " + JSON.stringify(offeringKey));
      return;
    }
    navigate(`/app/auth/offering/details/${offeringKey}`);
  }


  if (!tenantConfigLoaded) {
    return (

      <MDBox minHeight="60vh">
        <ConsumerHeader title={`${tenantConfig.pubConfigs.homepageTitle}`}
                        subTitle={tenantConfig.pubConfigs.slogan}
          // image={csSiteImg}
        />
        <LinearLoading />

      </MDBox>

    );
  }

  return (
    // <PageLayout background={tenantConfig.branding.colors.background || "white"}>
    <MDBox>
      <ConsumerHeader title={tenantConfig.pubConfigs.homepageTitle || "Welcome"} isSmall
                      subTitle={tenantConfig.pubConfigs.slogan || `${tenantConfig.bizName} Homepage`}
        image={csSiteImg}
      />
      <Grid container sx={{ my: 8 }}>

        <Grid item xs={12}>
          <Card
            sx={{ mt: -12 }}
            // sx={({ borders: { borderWidth }, palette: { light } }) => ({
            //   // border: `${borderWidth[2]} solid ${light.main}`,
            //   boxShadow: "none",
            //   mt: -12,
            //   "&:hover, &:focus": {
            //     zIndex: "10",
            //   },
            // })}
          >
            <Grid container spacing={1} justifyContent="center" alignItems="center" sx={{ px: 4, my: 4 }}>
              <Grid item xs={12} md={10} align={"center"}>
                <MDBox py={1}>
                  <MDTypography variant="h4"  gutterBottom>
                    {tenantConfig.pubConfigs.homepageMessageTitle ?
                      tenantConfig.pubConfigs.homepageMessageTitle
                      :
                      "Achieve more - with us"
                    }
                  </MDTypography>
                  <MDTypography variant="body1" color={"text"}  gutterBottom>
                    {tenantConfig.pubConfigs.homepageMessage ?
                      tenantConfig.pubConfigs.homepageMessage
                      :
                      "We are a trusted company. We are working round the clock for our clients full satisfaction."
                    }
                  </MDTypography>

                </MDBox>
              </Grid>
              <Grid item xs={12} md={10} align={"center"}>
                <MDBox py={1}>
                  <MDTypography variant="h3" fontWeight="bold" gutterBottom>
                    {tenantConfig.topicAreaLabels?.plural}
                  </MDTypography>
                  {/* <MDBox mb={2}> */}
                  {/*   <MDTypography variant="body2" color="text"> */}
                  {/*     Sign in to view other exclusive offers */}
                  {/*   </MDTypography> */}
                  {/* </MDBox> */}
                  {/* <MDButton variant="gradient" color="error" size="large" component={Link} to={"/provider-lookup"}> */}
                  {/*   Signup Now */}
                  {/* </MDButton> */}
                  {/* <MDBox component="img" src={productImage} width="60%"  borderRadius="sm" mb={1} /> */}

                </MDBox>
              </Grid>
              <Grid item xs={12} md={12}>
                <MDBox align={"center"} p={4} minHeight="25rem">
                  {loading &&
                    <MDBox
                      position="relative"
                      borderRadius="lg"
                      mt={2}
                      mx={2}
                      height="30rem"
                      minHeight="18rem"
                      // className="card-header"
                      sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)" }}
                    >
                    <Grid item xs={12} md={6}>
                    <MDBox mb={1.5} mt={1.5}>
                      {/*<LinearLoading/>*/}
                      <Spinner label={`Loading available ${tenantConfig.topicAreaLabels?.plural}`} />
                    </MDBox>
                  </Grid>
                    </MDBox>
                  }
                  {/* {JSON.stringify(tenantConfig.topicAreas)} */}
                  {/*{tenantConfig.topicAreas && tenantConfig.topicAreas.plural &&*/}
                  {/*  <MDTypography variant="body1" fontWeight="bold" gutterBottom>*/}
                  {/*    Apply for {tenantConfig.topicAreas.plural}*/}
                  {/*  </MDTypography>*/}
                  {/*}*/}
                  <Grid id="offering-grid-container" container spacing={4} justifyContent="center">
                    {!loading && offerings && offerings.map((offering) => (
                      <Grid item xs={12} md={6} lg={3} key={offering.offeringId}>
                        <MDBox mb={1.5} mt={1.5}>
                          <Suspense fallback={<MDBox
                            position="relative"
                            borderRadius="lg"
                            mt={2}
                            mx={2}
                            height="20rem"
                            minHeight="14rem"
                            // className="card-header"
                            sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)" }}
                          >

                          </MDBox>}>
                            <OfferingConsumerCard dataObj={offering} title={offering.name}
                              // badge={{ color: "info", label: offering.categoryName }}
                                                  content={JSON.stringify(offering)}>
                              {/*<MDButton variant="outlined" color="secondary"*/}
                              {/*          component={Link}*/}
                              {/*          to={`/app/offering/details/${offering.shardId}:${offering.offeringId}`}>*/}
                              {/*  More...*/}
                              {/*</MDButton>*/}
                              <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                {isLoggedIn? <MDButton disabled={offStoreWorking}
                                    variant="contained" color="success" sx={{ height: "100%" }}
                                          onClick={() => handleApply(offering.key)}
                                >
                                  Apply
                                </MDButton>
                                  :
                                  <MDButton variant="contained" color="info" sx={{ height: "100%" }}
                                  onClick={() => loginToApply(offering.key)}
                                  >
                                  Login to apply
                                  </MDButton>
                                }
                                {/* <MDButton variant="contained" color="info" sx={{ height: "100%" }} */}
                                {/*           component={Link} to={`/app/apply-new/${offering.shardId}:${offering.offeringId}`} */}
                                {/* disabled={!offering.workflow}> */}
                                {/*   Apply */}
                                {/* </MDButton> */}
                              </MDBox>
                            </OfferingConsumerCard>
                          </Suspense>
                        </MDBox>
                      </Grid>
                    ))


                    }

                    {!loading && (!offerings || offerings.length < 1) &&
                      <MDBox mb={1.5} mt={1.5}>
                        <MDTypography variant="h4" fontWeight="bold">
                          No Available {tenantConfig.topicAreaLabels?.plural || "Offerings"} Found
                        </MDTypography>
                      </MDBox>
                    }

                  </Grid>
                </MDBox>
              </Grid>
              {/* <Grid item xs={12} md={10} align="center" > */}
              {/*   <MDTypography variant="h2" fontWeight="bold" gutterBottom> */}
              {/*     Welcome to ABC Provider */}
              {/*   </MDTypography> */}
              {/*   <MDBox mb={2}> */}
              {/*     <MDTypography variant="body2" color="text"> */}
              {/*       What application are you getting done today? */}
              {/*     </MDTypography> */}
              {/*   </MDBox> */}
              {/*   /!* <MDBox component="img" src={productImage} width="60%"  borderRadius="sm" mb={1} /> *!/ */}
              {/* </Grid> */}

              {/* <Grid item xs={12} sm={10} lg={6}> */}
              {/*   <ActionCard */}
              {/*     // image={csIconGray} */}
              {/*     title="Welcome Back!" */}
              {/*     badge={{ color: "info", label: "Existing Applicant" }} */}
              {/*     content="Please continue your application if you already have started one */}
              {/*     with a Vendor. */}
              {/*     Or simply login to your Application Account." > */}
              {/*     <MDButton variant="outlined" color="secondary"> */}
              {/*       Login */}
              {/*     </MDButton> */}
              {/*     <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}> */}
              {/*       <MDButton variant="gradient" color="info" sx={{ height: "100%" }} */}
              {/*                 component={Link} to={"/application-lookup"}> */}
              {/*         Continue Application */}
              {/*       </MDButton> */}
              {/*     </MDBox> */}
              {/*   </ActionCard> */}

              {/* </Grid> */}

            </Grid>


            <Grid container spacing={1} justifyContent="center" alignItems="center" sx={{ px: 4, my: 8 }}>
              <Grid item xs={12} md={10} align="center">
                <MDTypography variant="h2" fontWeight="bold" gutterBottom>
                  Join Us
                </MDTypography>
                <MDBox mb={2}>
                  <MDTypography variant="body2" color="text">
                    Signup to get access to our member offers!
                  </MDTypography>
                </MDBox>
                <MDButton variant="gradient" color="error" size="large" component={Link} to={"/app/dashboard"}>
                  Signup Now
                </MDButton>
                {/* <MDBox component="img" src={productImage} width="60%"  borderRadius="sm" mb={1} /> */}
              </Grid>


            </Grid>

            {/* <Faq /> */}
          </Card>
        </Grid>
      </Grid>
    </MDBox>
    // </PageLayout>
  );
}

export default withPublicLayout(ProviderHome);
