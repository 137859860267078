import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import orderImage from "assets/images/product-12.jpg";
import { useDateTimeFormatter } from "../../../../../hooks";

function StatInfo({state, scope, updatedAt,updatedBy}) {
  const dateFormatter = useDateTimeFormatter();
  return (
    <MDBox
      // bgColor={darkMode ? "grey-900" : "grey-100"}
      // borderRadius="lg"
      display="flex"
      justifyContent="space-between"
      alignItems={{ xs: "flex-start", sm: "center" }}
      flexDirection={{ xs: "column", sm: "row" }}
      my={1}
      py={1}
      pl={{ xs: 1, sm: 2 }}
      pr={1}
    >

      {/*{state &&*/}
      {/*<MDTypography variant="caption" color="text">*/}
      {/*  State: {state}*/}
      {/*</MDTypography>}*/}
      {/*{scope &&*/}
      {/*<MDTypography variant="caption" color="text">*/}
      {/*  Scope: {scope}*/}
      {/*</MDTypography>}*/}
      <MDTypography variant="caption" color="text">
        Updated {dateFormatter(updatedAt)} by {updatedBy}
      </MDTypography>
    </MDBox>
    // <Grid container spacing={3} alignItems="center">
    //   <Grid item xs={12} md={6}>
    //     <MDBox display="flex" alignItems="center">
    //       <MDBox mr={2}>
    //         <MDAvatar size="xxl" src={orderImage} alt="Gold Glasses" />
    //       </MDBox>
    //       <MDBox lineHeight={1}>
    //         <MDTypography variant="h6" fontWeight="medium">
    //           Gold Glasses
    //         </MDTypography>
    //         <MDBox mb={2}>
    //           <MDTypography variant="button" color="text">
    //             Order was delivered 2 days ago.
    //           </MDTypography>
    //         </MDBox>
    //         <MDBadge
    //           variant="gradient"
    //           color="success"
    //           size="xs"
    //           badgeContent="delivered"
    //           container
    //         />
    //       </MDBox>
    //     </MDBox>
    //   </Grid>
    //   <Grid item xs={12} md={6} sx={{ textAlign: "right" }}>
    //     <MDButton variant="gradient" color="dark" size="small">
    //       contact us
    //     </MDButton>
    //     <MDBox mt={0.5}>
    //       <MDTypography variant="button" color="text">
    //         Do you like the product? Leave us a review{" "}
    //         <MDTypography component="a" href="#" variant="button" color="text" fontWeight="regular">
    //           here
    //         </MDTypography>
    //         .
    //       </MDTypography>
    //     </MDBox>
    //   </Grid>
    // </Grid>
  );
}

export default StatInfo;
