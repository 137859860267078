import { create } from "zustand";
import ProviderConfigService from "../services/ProviderConfigService";
import createSelectors from "./selectors";
import ApplicationService from "../services/AppApiService";

interface ApplicationStore {
  applicationConfigs: {};
  applications: any[];
  loading: boolean;
  saving: boolean;
  initialized: boolean;
  initStore: () => Promise<void>;
  getByKey: (key:string) => any;
  fetchById: (key:string) => Promise<any>;
  updateList: (newData: any) => void;
  getOptionList: () => Promise<any[]>;
  save: (newData: any) => Promise<void>;
}

const useApplicationStoreBase = create<ApplicationStore>()((set, get) => ({
  // const defaultVals = await ApplicationService.fetchApplications();
  applicationConfigs: { },
  applications: [],
  loading: false,
  initialized: false,
  saving: false,
  getOptionList: async () => {
    //console.log("..... Store : getOptionList <<<<<<<<<< applications");
    let apps = get().applications;
    // //console.log("..... Store : getOptionList >> ALL applications " + apps.length);
    let appOptions = [{name: "None", key:"NONE"}];
    set({ loading: true });
    if (!apps || (apps && apps.length == 0)){
      await get().initStore();
      apps = get().applications;
    }
    // //console.log("..... Store : getOptionList +++++ apps ++ length" + apps.length);
    apps.reduce((accumAppArr, thisApp) => {
      if (thisApp.name && thisApp.name!=="" ) {
        accumAppArr.push({ name: thisApp.name, key: thisApp.key });
      }
      // //console.log("..... Store : getOptionList +++++++ length" + accumAppArr.length);
      return accumAppArr;
    }, appOptions);

    set({ loading: false });
    return appOptions;
  },
  fetchById: async (appId:string) => {
    set({ loading: true });
    if ( !appId)      return;
    //console.log("..... Store : fetchById <<<<<<<<<< appId", appId);
    try {
      const data = await ApplicationService.fetchApplicationById(appId);
      if (!data || !data.key) return;
      //console.log("..... Store : fetchById <<<<<<<<<< found", data);
      get().updateList(data);
      return data;
    }finally {
      set({ loading: false });
    }

  },
  getByKey: (key:string) => {
    if ( !key)      return;
    const currList = get().applications;
    if (!get().initialized){
      //console.log("..... Store : getByKey <<<<<<<<<< Not Initialized", key);
      //console.log("..... Store : currList ", currList);
      return;
    }
    return currList.find(obj => key === obj.key);
  },
  updateList: (newData, sortCol?:string) => {
    set({ loading: true });
    const currList = get().applications;
    let apps:any[] = [];
    if (!currList || currList.length===0){
      //console.log("..... Store : updateList <<<<<<<<<< add");
      apps.push(newData);
    }else{
      //console.log("..... Store : updateList <<<<<<<<<< replace");
      const idx = currList.findIndex(o => {
        //console.log("..... Store : updateList <<<<<<<<<< findIndex o " + JSON.stringify(o.key));
        //console.log("..... Store : updateList <<<<<<<<<< findIndex newData " + JSON.stringify(newData.key));
        return o.key === newData.key
      });

      if (idx < 0){
        currList.push(newData);
        apps = currList;
      }else {
        //console.log("..... Store : updateList <<<<<<<<<< replace " + idx);
        apps = currList.map(obj => newData.key === obj.key ? newData : obj);
      }
      //console.log("..... Store : updateList <<<<<<<<<< findIndex o " + JSON.stringify(apps));
      // apps = [...currList.slice(0, idx), newData, ...currList.slice(idx, currList.length)];
    }
    apps.sort((a, b) =>      a.updatedAt > b.updatedAt ? -1 : 1    );
    set({ applications: apps, loading: false });
    //arr1.map(obj => arr2.find(o => o.id === obj.id) || obj);
  },
  save: async (newData) => {
    set({ saving: true });
    let apiResponse;
    try {
      //console.log("..... Store : save <<<<<<<<<< applications");
      if (newData.key) {
        //console.log("..... Store : save <<<<<<<<<< get existing application key " + newData.key);
        // const existingData = await ApplicationService.fetchApplicationById(newData.key);
        const existingData = get().getByKey(newData.key);
        if (!existingData){
          //console.log(".....!! Store : save <<<<<<<<<< existing application not found" + newData.key);
          throw new Error(`Application not found with key : ${ newData.key}`);
        }
        const newval = {
          ...existingData,
          ...newData
        };
        //console.log("..... Store : save <<<<<<<<<< new val key " + existingData.key);
        apiResponse = await ApplicationService.editApplication(existingData.key, newval);
        //console.log("..... Store : save <<<<<<<<<< response key " + apiResponse.key);
      } else {
        apiResponse = await ApplicationService.createApplication(newData);
      }
    }
    catch (e) {
      //console.log(e);
    }
    finally {
      set({ saving: false });
    }

    if (apiResponse) get().updateList(apiResponse);
  },
  initStore: async () => {
    if (get().applications.length > 0) return;
    //console.log("..... Store : initStore <<<<<<<<<< applications");
    set({ loading: true });
    try {
      const appConfigs = await ProviderConfigService.fetchProviderConfigsSelect("category", "APPLICATION");
      const apps = await ApplicationService.fetchApplications();
      // sort by recent first
      //console.log("..... Store : initStore <<<<<<<<<< applications", apps);
      apps.sort((a, b) => a.updatedAt > b.updatedAt ? -1 : 1);
      set({ initialized:true, applicationConfigs: appConfigs, applications: apps });
    }
    catch (e) {
        console.log(e);
      }
    finally {
        set({ loading: false });
      }

  }
}));

const useApplicationStore = createSelectors(useApplicationStoreBase);
export default useApplicationStore;