import React, { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";
import MDInput from "components/MDInput";
import MDEditor from "../../../../../components/MDEditor";
import Divider from "@mui/material/Divider";

function OfferingPageInfo({offering}) {
  return (
    <MDBox>
      <MDBox mb={1}>
        {/* <MDTypography variant="h3" fontWeight="bold"> */}
        {/*   {offering.name} */}
        {/* </MDTypography> */}
      </MDBox>
      {/* <MDTypography variant="h4" color="text"> */}
      {/*   <Icon>star</Icon> */}
      {/*   <Icon>star</Icon> */}
      {/*   <Icon>star</Icon> */}
      {/*   <Icon>star</Icon> */}
      {/*   <Icon>star_half</Icon> */}
      {/* </MDTypography> */}

      {/* <MDBox mt={1} mb={1} ml={0.5}> */}
      {/*   <MDTypography variant="button" fontWeight="regular" color="text"> */}
      {/*     Details */}
      {/*   </MDTypography> */}
      {/* </MDBox> */}
      {offering.content && offering.content.text &&
        <MDBox px={2} mb={1}
               minHeight="15rem">
          {/* <Divider sx={{ margin: "1.0rem 0" }} /> */}
          {/*   {dataObj.content.text} */}
          <MDEditor
            // label={content.label}
            name="content"
            value={offering.content.text}
            fullWidth
            readOnly
            theme={"bubble"}
          />
        </MDBox>}
      {/* <Divider sx={{ margin: "1.0rem 0" }} /> */}

      {/* <MDBox component="ul" m={0} pl={4} mb={2}> */}
      {/*   <MDBox component="li" color="text" fontSize="1.25rem" lineHeight={1}> */}
      {/*     <MDTypography variant="body2" color="text" fontWeight="regular" verticalAlign="middle"> */}
      {/*       The most beautiful curves of this swivel stool adds an elegant touch to any environment */}
      {/*     </MDTypography> */}
      {/*   </MDBox> */}
      {/*   <MDBox component="li" color="text" fontSize="1.25rem" lineHeight={1}> */}
      {/*     <MDTypography variant="body2" color="text" fontWeight="regular" verticalAlign="middle"> */}
      {/*       Memory swivel seat returns to original seat position */}
      {/*     </MDTypography> */}
      {/*   </MDBox> */}
      {/*   <MDBox component="li" color="text" fontSize="1.25rem" lineHeight={1}> */}
      {/*     <MDTypography variant="body2" color="text" fontWeight="regular" verticalAlign="middle"> */}
      {/*       Comfortable integrated layered chair seat cushion design */}
      {/*     </MDTypography> */}
      {/*   </MDBox> */}
      {/*   <MDBox component="li" color="text" fontSize="1.25rem" lineHeight={1}> */}
      {/*     <MDTypography variant="body2" color="text" fontWeight="regular" verticalAlign="middle"> */}
      {/*       Fully assembled! No assembly required */}
      {/*     </MDTypography> */}
      {/*   </MDBox> */}
      {/* </MDBox> */}
      {/* <MDBox mt={3}> */}

      {offering.details && offering.details.text &&
        <><Divider sx={{ margin: "1.0rem 0" }} />
          <MDBox px={2} mb={2}
                 minHeight="15rem">
            {/* <Divider sx={{ margin: "1.0rem 0" }} /> */}
            {/*   {dataObj.content.text} */}
            <MDEditor
              // label={content.label}
              name="details_text"
              value={offering.details.text}
              fullWidth
              readOnly
              theme={"bubble"}
            />
          </MDBox>
        </>}


      {/* </MDBox> */}
      {/* <MDBox mt={3}> */}
      {/*   <Grid item xs={12} lg={5} container> */}
      {/*     {offering.workflow ? */}
      {/*     <MDButton variant="gradient" color="primary" disabled={!offering.workflow.workflowId} fullWidth> */}
      {/*       Proceed */}
      {/*     </MDButton> */}
      {/*       : */}
      {/*       <MDButton variant="outlined" color="info" fullWidth> */}
      {/*         Contact for Details */}
      {/*       </MDButton> */}
      {/*     } */}
      {/*   </Grid> */}
      {/* </MDBox> */}
      {/* <MDBox mt={3} mb={1} ml={0.5}> */}
      {/*   <MDTypography variant="button" fontWeight="regular" color="text"> */}
      {/*     {JSON.stringify(offering)} */}
      {/*   </MDTypography> */}
      {/* </MDBox> */}
    </MDBox>
  );
}

export default OfferingPageInfo;
