import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Auth } from "@aws-amplify/auth";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Backdrop from "@mui/material/Backdrop";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";


import DashboardLayout from "LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// ProductPage page components
import ProductImages from "layouts/ecommerce/products/product-page/components/ProductImages";
import ProductInfo from "layouts/ecommerce/products/product-page/components/ProductInfo";

// Data
import dataTableData from "layouts/ecommerce/products/product-page/data/dataTableData";
import ConsumerLayout from "../../../LayoutContainers/ConsumerLayout";
import ConsumerHeader from "../../../LayoutContainers/ConsumerLayout/ConsumerHeader";
import { useCurrentAuthenticatedUser, useOffering, useOfferings, usePublicTenantConfig } from "../../../hooks";
import OfferingPageInfo from "./components/OfferingPageInfo";
import WorkflowService from "../../../services/WorkflowService";
import MDButton from "../../../components/MDButton";
import PageLayout from "../../../LayoutContainers/PageLayout";
import withPublicLayout from "../../../LayoutContainers/PublicLayout";
import LinearLoading from "../../custom-components/LinearLoading";
import OfferingImages from "./components/OfferingImages";
import tenantLogo from "../../../assets/images/cs-site.svg";
import useConsumerOfferingsStore from "../../../store/consumer-offering-store";
import { UserRoles } from "../../../models";

function ConsumerLogin() {

  const navigate = useNavigate();
  const { username, userFirstName, tenant, isAdmin, isLoggedIn } =
    useCurrentAuthenticatedUser();
  const {    tenantConfig, tenantConfigLoaded,
    reloadTenantConfig, loadingTenantConfig  } = usePublicTenantConfig();
  // const {offerings,reloadOfferings, loading} = useOfferings();

  // const [user, setUser] = useState();


  // const fetchData = useCallback(async () => {
  //
  //   const user = await Auth.currentAuthenticatedUser().catch(e => {
  //     console.log(e);
  //   });
  //
  //   if (user) setUser(user);
  // }, []);

  // useEffect(() => {
  //   if (!isLoggedIn) return;
  //   // fetchData();
  //
  // }, [isLoggedIn]);

  useEffect(() => {
    if (!loadingTenantConfig) {

    }
  }, [loadingTenantConfig]);


  const handleApply = async (offeringKey) => {
    try {
      // const submission = await applyForOffering(offeringKey);
      // navigate(`/app/submission/${submission.metadata.key}`);
    } catch (e) {
      console.error(e);
    }
  };
  const loginToApply = async (offeringKey) => {
    console.log("ProviderHome : offering key ", offeringKey);
    if (!offeringKey) {
      console.log("ProviderHome : offering not determined " + JSON.stringify(offeringKey));
      return;
    }
    navigate(`/app/auth/offering/details/${offeringKey}`);
  };

  // const tenantPublicHeader = () => {
  //   return <ConsumerHeader isSmall
  //                          title={`${tenantConfig.pubConfigs.homepageTitle}`}
  //                          subTitle={tenantConfig.pubConfigs.slogan}
  //     // image={csSiteImg}
  //   />
  // }


  return (

    <>
      <ConsumerHeader isSmall
                      title={`${tenantConfig.pubConfigs.homepageTitle}`}
                      subTitle={tenantConfig.pubConfigs.slogan}
      >
        {/*{isLoggedIn ?*/}
        {/*  <MDButton variant="gradient" color="white" size={"large"} disabled={loadingTenantConfig}*/}
        {/*            onClick={() => handleApply(username)}>*/}
        {/*    Apply Now*/}
        {/*  </MDButton>*/}
        {/*  :*/}
        {/*  <MDButton variant="outlined" color="white" size={"large"}*/}
        {/*            onClick={() => loginToApply(username)}>*/}
        {/*    Login to Apply*/}
        {/*  </MDButton>*/}
        {/*}*/}
      </ConsumerHeader>
      <Container>
        <MDBox py={3}>


          <Card sx={{ overflow: "visible" }}>
            <MDBox p={3}>
              <MDBox mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={12}>
                    <MDTypography variant="h5" fontWeight="medium">
                      You're now logged in, {userFirstName}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} lg={6} sx={{ mx: "auto" }}>
                    {/*<ConsumerLoginInfo offering={offering}/>*/}
                    {isLoggedIn &&
                      <MDButton variant="gradient" color="success" disabled={loadingTenantConfig} size={"large"} fullWidth
                                onClick={() => navigate('/app/dashboard')}>
                        Visit my Dashboard
                      </MDButton>

                    }
                  </Grid>
                </Grid>
              </MDBox>



              <MDBox mt={8} mb={2}>
                {/*<MDBox mb={1} ml={2}>*/}
                {/*  <MDTypography variant="h5" fontWeight="medium">*/}
                {/*    Other Products*/}
                {/*  </MDTypography>*/}
                {/*</MDBox>*/}
                {/*<DataTable*/}
                {/*  table={dataTableData}*/}
                {/*  entriesPerPage={false}*/}
                {/*  showTotalEntries={false}*/}
                {/*  isSorted={false}*/}
                {/*/>*/}

                {/*<MDTypography variant="caption" fontWeight="regular">*/}
                {/*  /!*{JSON.stringify(user)}*!/*/}
                {/*</MDTypography>*/}
              </MDBox>
            </MDBox>
          </Card>
        </MDBox>


      </Container>
    </>
  );
}

export default withPublicLayout(ConsumerLogin);
