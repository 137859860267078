import React, {useEffect, useState} from 'react';
import validator from '@rjsf/validator-ajv8';
import Form from '@rjsf/mui';
import Icon from "@mui/material/Icon";
import { RJSFSchema } from '@rjsf/utils';
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import { Widget } from "models";
import { useMFile } from "hooks";
import MFileService from "../../../../../services/MFileService";
import LinearLoading from "../../../LinearLoading";
import Spinner from "../../../Spinner/Spinner";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "../../../../../context";
import { useTranslation } from "react-i18next";

interface Props {
  widget: Widget;
  hideTitle?: boolean;
}
function MFileWidget(props: Props) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { t } = useTranslation();
  const { widget } = props;
  const { content, showTitle, name } = widget;
  // const { mFile, loading : loadingMFile } = useMFile(content.mFileId);
  // const initStore = useMFileStore.use.initStore();
  // const loadingMFile = useMFileStore.use.loading();
  // const getById = useMFileStore.use.getById();
  const [mFile, setMFile] = useState(undefined);

  // const fetchData = useCallback(async () => {
  //   setLoading(true);
  //   const data = isProtected? await MFileService.fetchProtectedMFiles() :
  //     await MFileService.fetchMFiles();
  //   console.log("useMFiles mFiles   >> " + JSON.stringify(data));
  //   setMFiles(data);
  //   setLoading(false);
  // }, []);

  // useEffect(() => {
  //   if (!content.mFileId) return;
  //   initStore().then(()=>
  //   getById(content.mFileId).then((configMFile) => {
  //     setMFile(configMFile); // from provider config
  //     if (configMFile ) {
  //       // console.log("configMFile: " + JSON.stringify(configMFile));
  //       setSchema(configMFile.defaultVal);
  //       setUISchema(configMFile.config);
  //     }else{
  //       console.log("MFileWidget - "+ loadingMFile +", mFile not found for: " + JSON.stringify(content));
  //     }
  //   }))
  //
  // }, [getById]);

  // useEffect(() => {
  //   if (mFile ) {
  //     console.log("MFileWidget: " + JSON.stringify(mFile));
  //     setSchema(mFile.defaultVal);
  //     setUISchema(mFile.config);
  //     console.log("MFileWidget SET <<<<<<<<<<<<<<<< : ");
  //   }else{
  //     console.log("MFileWidget - "+ loadingMFile +", mFile not found for: " + JSON.stringify(content));
  //   }
  // }, [loadingMFile]);


  return (
    <MDBox mt={props.hideTitle ? 2 : 1} mb={2}
           aria-label={name}
           tabIndex={-1}
    >
      {!props.hideTitle && showTitle &&
        <MDBox mb={1}>
          <MDTypography variant="h4" fontWeight="medium">
            {name}
          </MDTypography>
        </MDBox>
      }
      {name && content ?

        <MDBox p={2}>

          <MDBox px={1} key={widget.key}
                 // component="li"
                 sx={({
                   cursor: "pointer"})}
                 // onClick={() => {
                 //   if (props.onSelect) {
                 //     props.onSelect(href);
                 //   }
                 // }}
                 aria-label={t("PlusAddContentItem")}
          >
            <MDTypography
              component="a"
              variant="button"
              fontWeight="regular"
              textTransform="capitalize"
              sx={({
                     borders: { borderRadius, borderWidth, borderColor },
                     functions: { pxToRem },
                     palette: { light },
                     transitions,
                   }) => ({
                display: "flex",
                flexDirection:"column",
                alignItems: "center",
                minWidth:"5rem",
                minHeight:"3rem",
                borderRadius: borderRadius.md,
                border:`${borderWidth[1]} solid ${borderColor}`,
                padding: `${pxToRem(16)} ${pxToRem(30)}`,
                transition: transitions.create("background-color", {
                  easing: transitions.easing.easeInOut,
                  duration: transitions.duration.shorter,
                }),

                "&:hover": {
                  backgroundColor: light.main,
                },
                cursor: "pointer"
              })}
            >
              <MDBox lineHeight={1} color={darkMode ? "white" : "secondary"} >

                <Icon fontSize="large">{content.fileType}</Icon>
              </MDBox>


              {/* <MDButton */}
              {/*   variant="contained" */}
              {/*   onClick={() => { */}
              {/*     if (props.onSelect) { */}
              {/*       props.onSelect(href); */}
              {/*     } */}
              {/*   }} */}
              {/*   aria-label={t("PlusAddContentItem")} */}
              {/* > */}
              {/*   <Icon fontSize="small">add</Icon> */}

              {/* </MDButton> */}
              {content.fileType}
            </MDTypography>

          </MDBox>


          {/*{JSON.stringify(props.widget)}*/}

        </MDBox>
        :
        <MDBox>
        <Spinner label={"Loading Eform ..."} />

      </MDBox>}

      {/*...........*/}
      {/*{JSON.stringify(mFile)}*/}
      {/*-------------------*/}
      {/*{JSON.stringify(schema)}*/}
    </MDBox>
  );
}

export default MFileWidget;
