import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { useForm } from "react-hook-form";
import { Form, Formik, useFormik } from "formik";

import { useTranslation } from "react-i18next";
import { useDashboard, useChangeBackgroundColor } from "hooks";
// import Breadcrumbs from "../components/Breadcrumbs";
import PrimaryActionBar from "../components/PrimaryActionBar";
import RadioButtonsTile from "../components/RadioButtonsTile";
// import Alert from "../components/Alert";
import MDButton from "../../../../components/MDButton";
import MDAlert from "../../../../components/MDAlert";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "LayoutContainers/DashboardLayout";

interface FormValues {
  widgetType: string;
}

interface PathParams {
  dashboardId: string;
}

function AddContent() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dashboardId } = useParams<PathParams>();
  const { dashboard, loading } = useDashboard(dashboardId);
  // const { register, handleSubmit, watch } = useForm<FormValues>();
  const [error, setError] = useState("");

  // const widgetType = watch("widgetType");
  const formik = useFormik({
    initialValues: {
      widgetType: ""
    }
  });

  // useEffect(() => {
  //   if (error && widgetType) {
  //     setError("");
  //   }
  // }, [widgetType, error]);

  const onSubmit = async (values: FormValues, actions) => {
    if (values.widgetType === "chart") {
      navigate(`/admin/dashboard/${dashboardId}/add-chart`);
    } else if (values.widgetType === "table") {
      navigate(`/admin/dashboard/${dashboardId}/add-table`);
    } else if (values.widgetType === "text") {
      navigate(`/admin/dashboard/${dashboardId}/add-text`);
    } else if (values.widgetType === "image") {
      navigate(`/admin/dashboard/${dashboardId}/add-image`);
    } else if (values.widgetType === "metrics") {
      navigate(`/admin/dashboard/${dashboardId}/add-metrics`);
    } else if (values.widgetType === "section") {
      navigate(`/admin/dashboard/${dashboardId}/add-section`);
    } else {
      setError(t("AddContentScreen.InvalidWidgetType"));
    }
  };

  const onCancel = () => {
    navigate(`/admin/dashboard/edit/${dashboardId}`);
  };

  const crumbs = [
    {
      label: t("Dashboards"),
      url: "/admin/dashboards",
    },
    {
      label: dashboard?.name,
      url: `/admin/dashboard/edit/${dashboardId}`,
    },
  ];

  useChangeBackgroundColor();

  if (!loading) {
    crumbs.push({
      label: t("AddContentScreen.Title"),
      url: "",
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/*<Breadcrumbs crumbs={crumbs} />*/}
      <div className="grid-row">
        <PrimaryActionBar className="tablet:grid-col-6 grid-col-12">
          <h1 id="addContentFormHeader" className="margin-top-0">
            {t("AddContentScreen.Title")}
          </h1>

          <Formik
            initialValues={formik.initialValues}
            onSubmit={onSubmit}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form id="application-edit-form" autoComplete="off">
            <fieldset className="usa-fieldset">
              <legend className="margin-y-1 text-semibold display-inline-block font-sans-lg">
                {t("AddContentScreen.Instructions")}
              </legend>

              {/*{error && <MDAlert type="error" message={error} slim></MDAlert>}*/}

              <RadioButtonsTile
                isHorizontally={false}
                options={[
                  {
                    id: "text",
                    value: "text",
                    name: "widgetType",
                    dataTestId: "textRadioButton",
                    label: t("Text"),
                    description: t("AddContentScreen.TextDescription"),
                  },
                  {
                    id: "metrics",
                    value: "metrics",
                    name: "widgetType",
                    dataTestId: "metricsRadioButton",
                    label: t("Metrics"),
                    description: t("AddContentScreen.MetricsDescription"),
                  },
                  {
                    id: "chart",
                    value: "chart",
                    name: "widgetType",
                    dataTestId: "chartRadioButton",
                    label: t("Chart"),
                    description: t("AddContentScreen.ChartDescription"),
                  },
                  {
                    id: "table",
                    value: "table",
                    name: "widgetType",
                    dataTestId: "tableRadioButton",
                    label: t("Table"),
                    description: t("AddContentScreen.TableDescription"),
                  },
                  {
                    id: "image",
                    value: "image",
                    name: "widgetType",
                    dataTestId: "imageRadioButton",
                    label: t("Image"),
                    description: t("AddContentScreen.ImageDescription"),
                  },
                  {
                    id: "section",
                    value: "section",
                    name: "widgetType",
                    dataTestId: "sectionRadioButton",
                    label: t("Section"),
                    description: t("AddContentScreen.SectionDescription"),
                  },
                ]}
              />
            </fieldset>
            <br />
            <hr />
            <MDButton
              variant="base"
              type="submit"
              disabledToolTip={
                !values.widgetType ? t("AddContentScreen.DisabledToolTip") : ""
              }

            >
              {t("ContinueButton")}
            </MDButton>
            <MDButton
              variant="unstyled"
              type="button"
              onClick={onCancel}

            >
              {t("Cancel")}
            </MDButton>
                <div className="tablet:grid-col-6">
                  {values.widgetType === "text" && (
                    <img
                      src={`${process.env.PUBLIC_URL}/images/text-content-item.svg`}
                      alt={t("AddContentScreen.TextImageAlt")}
                    />
                  )}

                  {values.widgetType === "metrics" && (
                    <img
                      src={`${process.env.PUBLIC_URL}/images/metrics-content-item.svg`}
                      alt={t("AddContentScreen.MetricsImageAlt")}
                    />
                  )}

                  {values.widgetType === "chart" && (
                    <img
                      src={`${process.env.PUBLIC_URL}/images/chart-content-item.svg`}
                      alt={t("AddContentScreen.ChartImageAlt")}
                    />
                  )}

                  {values.widgetType === "table" && (
                    <img
                      src={`${process.env.PUBLIC_URL}/images/table-content-item.svg`}
                      alt={t("AddContentScreen.TableImageAlt")}
                    />
                  )}

                  {values.widgetType === "image" && (
                    <img
                      src={`${process.env.PUBLIC_URL}/images/image-content-item.svg`}
                      alt={t("AddContentScreen.ImageAlt")}
                    />
                  )}

                  {values.widgetType === "section" && (
                    <img
                      src={`${process.env.PUBLIC_URL}/images/section-content-item.svg`}
                      alt={t("AddContentScreen.SectionAlt")}
                    />
                  )}
                </div>
              </Form>

            )}
          </Formik>
        </PrimaryActionBar>

      </div>
    </DashboardLayout>
  );
}

export default AddContent;
