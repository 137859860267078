import React from 'react';

import { useMaterialUIController } from "context";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useTranslation } from "react-i18next";
import DefaultInfoNodeCard from "../../workflow/custom-node/DefaultInfoNodeCard";
import { workflowNodeTypes } from "static-data/wf-node-types";

export default () => {

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const { t } = useTranslation();

  const wfNodeTypes = workflowNodeTypes;

  const renderSideNavWidgetItems = wfNodeTypes.map(({ icon, name, nodeType, color }, key) => {
    const itemKey = `item-${key}`;

    return (
      <MDBox px={1} pt={2} key={itemKey} component="li" m={{ xs: 0, sm: 1 }}
             // sx={({
             //   cursor: "hand"})}
             // onClick={() => {
             //   if (props.onSelect) {
             //     props.onSelect(nodeType);
             //   }
             // }}
             className="dndnode "
             onDragStart={(event) => onDragStart(event, nodeType)}
             draggable
             aria-label={t("PlusAddContentItem")}
             sx={({
                    borders: { borderRadius, borderWidth, borderColor },
                    functions: { pxToRem },
                    palette: { light },
                    transitions,
                  }) => ({
               // display: "flex",
               // flexDirection:"column",
               alignItems: "center",
               minWidth:"6rem",
               minHeight:"6rem",
               borderRadius: borderRadius.md,
               border:`${borderWidth[1]} solid ${borderColor}`,
               // padding: `${pxToRem(16)} ${pxToRem(30)}`,
               transition: transitions.create("background-color", {
                 easing: transitions.easing.easeInOut,
                 duration: transitions.duration.shorter,
               }),
               "&:hover": {
                 backgroundColor: light.main,
               },
               cursor: "grab"
             })}

      >
        <DefaultInfoNodeCard color={color || "warning"}
                             icon={icon || "info"}
                             title={name}

        />

      </MDBox>
    );
  });
  const onDragStart = (event, node) => {
    event.dataTransfer.setData('application/reactflow', node);
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <MDBox className="wf-toolbar" px={2} pb={4}
           alignItems="center"
           sx={({
               borders: { borderRadius, borderWidth, borderColor },
               functions: { pxToRem },
               palette: { light },
               transitions,
             }) => ({
             // display: "flex",
             // flexDirection:"column",
             alignItems: "center",
             minWidth:"8rem",
             maxWidth:"18.5rem",
             // minHeight:"6rem",
             // borderRadius: borderRadius.lg,
             // border:`${borderWidth[1]} solid ${borderColor}`,
             // padding: `${pxToRem(16)} ${pxToRem(30)}`,
             transition: transitions.create("background-color", {
               easing: transitions.easing.easeInOut,
               duration: transitions.duration.shorter,
             }),
             backgroundColor: light.main
           })}
    >
    <Card
      sx={{
        // borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        // alignItems: "center",
        minHeight:"40vh",
        position: "sticky",
        top: "1%",
      }}
    >
      <MDBox m={3}>
      <MDTypography variant="body2" fontWeight="medium" color={"info"}>
        Drag components to the right pane.
      </MDTypography>
      </MDBox>
      <MDBox
        component="ul"
        display="flex"
        flexDirection="row"
        px={1}
        m={1}
        // alignItems="center"
        // justifyContent="space-between"
        flexWrap="wrap"
        sx={{ listStyle: "none", }}
      >
        {renderSideNavWidgetItems}
      {/*<MDBox className="dndnode input"*/}
      {/*       onDragStart={(event) => onDragStart(event, 'textNode')} draggable>*/}
      {/*  Text*/}
      {/*</MDBox>*/}
      {/*<MDBox className="dndnode" onDragStart={(event) => onDragStart(event, 'cardNode')} draggable>*/}
      {/*  Card*/}
      {/*</MDBox>*/}
      {/*<MDBox className="dndnode output" onDragStart={(event) => onDragStart(event, 'selectorNode')} draggable>*/}
      {/*  Color*/}
      {/*</MDBox>*/}

      </MDBox>
    </Card>
    </MDBox>
  );
};
