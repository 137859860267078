/**
 All of the routes for the Material Dashboard 2 PRO React are added here,
 You can add a new route, customize the routes and delete the routes here.

 Once you add a new route on this file it will be visible automatically on
 the Sidenav.

 For adding a new route you can follow the existing routes in the routes array.
 1. The `type` key with the `collapse` value is used for a route.
 2. The `type` key with the `title` value is used for a title inside the Sidenav.
 3. The `type` key with the `divider` value is used for a divider between Sidenav items.
 4. The `name` key is used for the name of the route on the Sidenav.
 5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
 6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
 7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
 inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
 8. The `route` key is used to store the route location which is used for the react router.
 9. The `href` key is used to store the external links location.
 10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
 10. The `component` key is used to store the component of its route.
 */
import React, { lazy, Suspense  } from 'react';

import LandingPage from "views/landing-page";
import TeamSignup from "views/team-signup";
import TeamAuth from "views/team-auth";
// Material Dashboard 2 PRO React layouts
import Analytics from "layouts/dashboards/analytics";
import Sales from "layouts/dashboards/sales";
import ProfileOverview from "layouts/pages/profile/profile-overview";

import AllProjects from "layouts/pages/profile/all-projects";
import NewUser from "layouts/pages/users/new-user";
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
import Timeline from "layouts/pages/projects/timeline";
import PricingPage from "layouts/pages/pricing-page";
import Widgets from "layouts/pages/widgets";
import RTL from "layouts/pages/rtl";
import Charts from "layouts/pages/charts";
import Notifications from "layouts/pages/notifications";
import Kanban from "layouts/applications/kanban";
import Wizard from "layouts/applications/wizard";
import DataTables from "layouts/applications/data-tables";
import Calendar from "layouts/applications/calendar";
import NewProduct from "layouts/ecommerce/products/new-product";
import EditProduct from "layouts/ecommerce/products/edit-product";
import ProductPage from "layouts/ecommerce/products/product-page";
import OrderList from "layouts/ecommerce/orders/order-list";
import OrderDetails from "layouts/ecommerce/orders/order-details";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignUpCover from "layouts/authentication/sign-up/cover";
import ResetCover from "layouts/authentication/reset-password/cover";

import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import AdminDashboard from "./views/admin-dashboard";
import ApplicationList from "./views/admin/application-list";
import ProviderLookup from "./views/provider-lookup";
import ApplicationLookup from "./views/application-lookup";
import UserList from "./views/system/user-list";
import ProviderSettings from "./views/system/provider-settings";
import AddContent from "./views/admin/application-setup/containers/AddContent";
import AddSection from "./views/admin/application-setup/containers/AddSection";
import ApplicationDetailEdit from "./views/admin/application-edit/ApplicationDetailEdit";
import WidgetsEdit from "./views/admin/application-widgets-edit/widgets-edit";
import AdminHome from "./views/admin/admin-home";
import OfferingDetails from "./views/admin/offering-details";
// import AdminOfferings from "./views/admin/offering-list";
import ProviderHome from "./views/provider-home";
import AdminWorkflows from "./views/admin/workflow-list/admin-workflows";
import AdminEligibilities from "./views/admin/eligibility-list/admin-eligibilities";
import AdminPrescreenings from "./views/admin/prescreening-list/admin-prescreenings";
import AdminIntegrations from "./views/admin/integration-list/admin-integrations";
import AgentTasks from "./views/agent/task-list/agent-tasks";
import AdminWorkflowEdit from "./views/admin/workflow-flow-edit/admin-workflow-edit";
import AdminEForms from "./views/admin/eform-list/admin-eforms";
import OfferingPage from "./views/consumer/offering-page";
import SubmissionPage from "./views/consumer/submission";
import ConsumerDashboard from "./views/consumer/consumer-dashboard";
import AdminSubmissions from "./views/agent/admin-submissions";
import WorkflowDetailsEdit from "./views/admin/workflow-details-edit/WorkflowDetailsEdit";
import ConsumerLogin from "./views/consumer/Login";
import withSAMLAuthenticator from "./LayoutContainers/SAMLAuthenticator";
import SubmissionPreview from "./views/shared/SubmissionPreview";
import AdminReviewSubmission from "./views/agent/admin-review-submission";
import AdminPreScreeningList from "./views/admin/prescreening-list/pre-screen-list";
import EFormEdit from "./views/admin/eform-edit/eFormEdit";

const AdminOfferings = lazy(() => import('./views/admin/offering-list'));
// const AdminWorkflows = lazy(() => import('./views/admin/workflow-list/admin-workflows'));


const routes = [
  {
    name: "Landing",
    key: "landing",
    route: "/landing",
    component: <LandingPage />
  },
  {
    name: "Home",
    key: "home",
    route: "/:tenantName/home",
    public: true,
    component: <ProviderHome />
  },
  {
    name: "Home",
    key: "t-home",
    route: "/:tenantName/home",
    public: true,
    component: <ProviderHome />
  },
  {
    name: "Home",
    key: "root-for-tenant-home",
    route: "/",
    public: true,
    component: <ProviderHome />
  },
  {
    name: "Home",
    key: "home",
    route: "/home",
    public: true,
    component: <ProviderHome />
  },
  {
    name: "Submission",
    key: "submission",
    route: "/app/submission/:submissionId",
    component: <SubmissionPage />
  },
  {
    name: "Submission",
    key: "submission",
    route: "/app/submission/:submissionId/preview",
    component: <SubmissionPreview />
  },
  {
    name: "Submission",
    key: "submission-tn",
    route: "/:tenantName/app/submission/:submissionId",
    component: <SubmissionPage />,
    scope: 'none'
  },
  {
    name: "Team Signup ",
    key: "team-signup",
    route: "/team-signup",
    component: <TeamSignup />
  },
  {
    name: "Team Auth ",
    key: "team-auth",
    route: "/team-auth",
    component: <TeamAuth />
  },
  {
    name: "Provider Lookup",
    key: "provider-lookup",
    route: "/provider-lookup",
    component: <ProviderLookup />
  },
  {
    name: "Application Lookup",
    key: "application-lookup",
    route: "/application-lookup",
    component: <ApplicationLookup />
  },


  {
    type: "collapse",
    name: "Admin Home",
    key: "admin-home",
    route: "/admin",
    component: <AdminHome />,
    icon: <Icon fontSize="medium">home</Icon>,
    noCollapse: true,
    scope: "agent"
  },
  // {
  //   type: "collapse",
  //   name: "Dashboard",
  //   key: "dashboard",
  //   route: "/admin/dashboard",
  //   component: <AdminDashboard />,
  //   icon: <Icon fontSize="medium">dashboard</Icon>,
  //   noCollapse: true,
  //   scope: "agent"
  // },
  {
    type: "collapse",
    name: "Submissions",
    key: "submissions",
    route: "/admin/submissions",
    component: <AdminSubmissions />,
    icon: <Icon fontSize="medium">list_alt</Icon>,
    noCollapse: true,
    scope: "agent"
  },
  {
    type: "collapse",
    name: "Review Submission",
    key: "review-submission",
    route: "/admin/submission/:submissionId/review-submission",
    component: <AdminReviewSubmission />,
    icon: <Icon fontSize="medium">list_alt</Icon>,
    noCollapse: true,
    scope: "none"
  },
  {
    type: "collapse",
    name: "Docs & Files",
    key: "docs-files",
    route: "/admin/docs-files",
    component: <AdminSubmissions />,
    icon: <Icon fontSize="medium">folder_outlined</Icon>,
    noCollapse: true,
    scope: "none"
  },
  {
    type: "collapse",
    name: "Tasks",
    key: "tasks",
    route: "/admin/tasks",
    component: <AgentTasks />,
    icon: <Icon fontSize="medium">checklist</Icon>,
    noCollapse: true,
    scope: "agent"
  },
  { type: "divider", key: "divider-8" },
  { type: "title", title: "Admin", key: "title-Admin" },
  {
    type: "collapse",
    name: "Offerings",
    key: "offerings",
    route: "/admin/offerings",
    component: <Suspense fallback={<div>Loading... </div>}><AdminOfferings /> </Suspense>,
    icon: <Icon fontSize="medium">storefront_outlined</Icon>,
    noCollapse: true
  },
  // {
  //   type: "collapse",
  //   name: "Offering Edit",
  //   key: "offering-edit",
  //   route: "/admin/dashboard/edit/:dashboardId",
  //   component: <EditDashboard />,
  //   icon: <Icon fontSize="medium">account_tree_outlined</Icon>,
  //   noCollapse: true,
  //   scope: "none"
  // },
  {
    type: "collapse",
    name: "Offering Details",
    key: "offering-details",
    route: "/admin/offering/details/:offeringId",
    component: <OfferingDetails />,
    icon: <Icon fontSize="medium">account_tree_outlined</Icon>,
    noCollapse: true,
    scope: "none"
  },
  {
    type: "collapse",
    name: "Offering Edit Content",
    key: "offering-edit-content",
    route: "/admin/application/:dashboardId/edit-content",
    component: <AddContent />,
    icon: <Icon fontSize="medium">account_tree_outlined</Icon>,
    noCollapse: true,
    scope: "none"
  },
  {
    type: "collapse",
    name: "Offering Edit Section",
    key: "offering-edit-section",
    route: "/admin/application/:dashboardId/edit-section",
    component: <AddSection />,
    icon: <Icon fontSize="medium">account_tree_outlined</Icon>,
    noCollapse: true,
    scope: "none"
  },
  // {
  //   type: "collapse",
  //   name: "Pre-Screening",
  //   key: "pre-screening",
  //   route: "/admin/pre-screening",
  //   component: <AdminPreScreeningList />,
  //   icon: <Icon fontSize="medium">ballot_outlined</Icon>,
  //   noCollapse: true
  // },
  // {
  //   type: "collapse",
  //   name: "Eligibility",
  //   key: "eligibility",
  //   route: "/admin/eligibilities",
  //   component: <AdminEligibilities />,
  //   icon: <Icon fontSize="medium">attribution_outlined</Icon>,
  //   noCollapse: true
  // },
  {
    type: "collapse",
    name: "E-Forms",
    key: "e-forms-setup",
    route: "/admin/e-forms",
    component: <AdminEForms />,
    icon: <Icon fontSize="medium">list</Icon>,
    noCollapse: true
  },
  {
    type: "collapse",
    name: "E-Forms Edit",
    key: "e-forms-edit",
    route: "/admin/e-form/edit/:eformId",
    component: <EFormEdit />,
    icon: <Icon fontSize="medium">list</Icon>,
    noCollapse: true,
    scope: "none"
  },
  {
    type: "collapse",
    name: "Applications",
    key: "application-setup",
    route: "/admin/applications",
    component: <ApplicationList />,
    icon: <Icon fontSize="medium">art_track_outlined</Icon>,
    noCollapse: true
  },
  {
    type: "collapse",
    name: "Application Edit",
    key: "application-edit",
    route: "/admin/application/edit/:appId",
    component: <ApplicationDetailEdit />,
    icon: <Icon fontSize="medium">account_tree_outlined</Icon>,
    noCollapse: true,
    scope: "none"
  },
  {
    type: "collapse",
    name: "Application Widgets Edit",
    key: "application-widgets-edit",
    route: "/admin/application/:applicationId/widgets-edit",
    component: <WidgetsEdit />,
    icon: <Icon fontSize="medium">account_tree_outlined</Icon>,
    noCollapse: true,
    scope: "none"
  },
  {
    type: "collapse",
    name: "Workflow",
    key: "workflow",
    route: "/admin/workflows",
    component: <AdminWorkflows />,
    icon: <Icon fontSize="medium">airline_stops_outlined</Icon>,
    noCollapse: true
  },
  {
    type: "collapse",
    name: "Workflow Edit",
    key: "workflow-edit",
    route: "/admin/workflow/edit/:workflowKey",
    component: <WorkflowDetailsEdit />,
    icon: <Icon fontSize="medium">airline_stops_outlined</Icon>,
    noCollapse: true,
    scope: "none"
  },
  {
    type: "collapse",
    name: "Workflow Edit",
    key: "workflow-edit",
    route: "/admin/workflow/edit/:workflowId/edit-flow",
    component: <AdminWorkflowEdit />,
    icon: <Icon fontSize="medium">airline_stops_outlined</Icon>,
    noCollapse: true,
    scope: "none"
  },
  // {
  //   type: "collapse",
  //   name: "Integration",
  //   key: "integrations",
  //   route: "/admin/integrations",
  //   component: <AdminIntegrations />,
  //   icon: <Icon fontSize="medium">account_tree_outlined</Icon>,
  //   noCollapse: true
  // },
  { type: "divider", key: "divider-28" },
  { type: "title", title: "System Admin", key: "title-System-Admin" },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    route: "/admin/users",
    component: <UserList />,
    icon: <Icon fontSize="medium">people</Icon>,
    noCollapse: true
  },
  {
    type: "collapse",
    name: "Settings",
    key: "provider-settings",
    route: "/admin/provider-settings",
    component: <ProviderSettings />,
    icon: <Icon fontSize="medium">settings</Icon>,
    noCollapse: true
  },
  // { type: "divider", key: "divider-19" },
  {
    type: "collapse",
    name: "Dashboard",
    key: "consumer-dashboard",
    route: "/app/dashboard",
    component: <ConsumerDashboard />,
    icon: <Icon fontSize="medium">apps</Icon>,
    noCollapse: true,
    scope: "none"
  },
  {
    type: "collapse",
    name: "Consumer Offerings",
    key: "consumer-offerings",
    scope: "none",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Offering Details",
        key: "app-offering-details-tn",
        route: "/:tenantName/app/offering/details/:offeringId",
        component: <OfferingPage />
      },
      {
        name: "Offering Details",
        key: "app-offering-details-url-tn",
        route: "/:tenantName/app/offering/details/:friendlyURL",
        component: <OfferingPage />
      },
      {
        name: "Offering Details",
        key: "app-offering-details",
        route: "/app/offering/details/:offeringId",
        component: <OfferingPage />
      },
      {
        name: "Offering Details",
        key: "app-offering-details-url",
        route: "/app/offering/details/:friendlyURL",
        component: <OfferingPage />
      },
      {
        name: "Offering Details",
        key: "app-offering-details-auth",
        route: "/app/auth/offering/details/:offeringId",
        component: React.createElement(withSAMLAuthenticator( OfferingPage))
      },
      {
        name: "Sales",
        key: "sales",
        route: "/app/sales",
        component: <Sales />
      }
    ]
  },
  {
    type: "collapse",
    name: "Consumer Auth",
    key: "consumer-auth",
    scope: "none",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Consumer Login",
        key: "app-consumer-login",
        route: "/app/auth/consumer-login",
        component: React.createElement(withSAMLAuthenticator(ConsumerLogin ))
      },
      {
        name: "Consumer Login",
        key: "app-consumer-login-tn",
        route: "/:tenantName/app/auth/consumer-login",
        component: React.createElement(withSAMLAuthenticator(ConsumerLogin ))
      },
    ]
  },
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    scope: "none",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Analytics",
        key: "analytics",
        route: "/dashboards/analytics",
        component: <Analytics />
      },
      {
        name: "Sales",
        key: "sales",
        route: "/dashboards/sales",
        component: <Sales />
      }
    ]
  },
  // { type: "title", title: "Pages", key: "title-pages" },
  {
    type: "collapse",
    name: "Pages",
    key: "pages",
    scope: "none",
    icon: <Icon fontSize="medium">image</Icon>,
    collapse: [
      {
        name: "Profile",
        key: "profile",
        collapse: [
          {
            name: "Profile Overview",
            key: "profile-overview",
            route: "/pages/profile/profile-overview",
            component: <ProfileOverview />
          },
          {
            name: "All Projects",
            key: "all-projects",
            route: "/pages/profile/all-projects",
            component: <AllProjects />
          }
        ]
      },
      {
        name: "Users",
        key: "users",
        collapse: [
          {
            name: "New User",
            key: "new-user",
            route: "/pages/users/new-user",
            component: <NewUser />
          }
        ]
      },
      {
        name: "Account",
        key: "account",
        collapse: [
          {
            name: "Settings",
            key: "settings",
            route: "/pages/account/settings",
            component: <Settings />
          },
          {
            name: "Billing",
            key: "billing",
            route: "/pages/account/billing",
            component: <Billing />
          },
          {
            name: "Invoice",
            key: "invoice",
            route: "/pages/account/invoice",
            component: <Invoice />
          }
        ]
      },
      {
        name: "Projects",
        key: "projects",
        collapse: [
          {
            name: "Timeline",
            key: "timeline",
            route: "/pages/projects/timeline",
            component: <Timeline />
          }
        ]
      },
      {
        name: "Pricing Page",
        key: "pricing-page",
        route: "/pages/pricing-page",
        component: <PricingPage />
      },
      { name: "RTL", key: "rtl", route: "/pages/rtl", component: <RTL /> },
      { name: "Widgets", key: "widgets", route: "/pages/widgets", component: <Widgets /> },
      { name: "Charts", key: "charts", route: "/pages/charts", component: <Charts /> },
      {
        name: "Notfications",
        key: "notifications",
        route: "/pages/notifications",
        component: <Notifications />
      }
    ]
  },
  {
    type: "collapse",
    name: "Applications",
    key: "applications",
    scope: "none",
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: "Kanban",
        key: "kanban",
        route: "/applications/kanban",
        component: <Kanban />
      },
      {
        name: "Wizard",
        key: "wizard",
        route: "/applications/wizard",
        component: <Wizard />
      },
      {
        name: "Data Tables",
        key: "data-tables",
        route: "/applications/data-tables",
        component: <DataTables />
      },
      {
        name: "Calendar",
        key: "calendar",
        route: "/applications/calendar",
        component: <Calendar />
      }
    ]
  },
  {
    type: "collapse",
    name: "Ecommerce",
    key: "ecommerce",
    scope: "none",
    icon: <Icon fontSize="medium">shopping_basket</Icon>,
    collapse: [
      {
        name: "Products",
        key: "products",
        collapse: [
          {
            name: "New Product",
            key: "new-product",
            route: "/ecommerce/products/new-product",
            component: <NewProduct />
          },
          {
            name: "Edit Product",
            key: "edit-product",
            route: "/ecommerce/products/edit-product",
            component: <EditProduct />
          },
          {
            name: "Product Page",
            key: "product-page",
            route: "/ecommerce/products/product-page",
            component: <ProductPage />
          }
        ]
      },
      {
        name: "Orders",
        key: "orders",
        collapse: [
          {
            name: "Order List",
            key: "order-list",
            route: "/ecommerce/orders/order-list",
            component: <OrderList />
          },
          {
            name: "Order Details",
            key: "order-details",
            route: "/ecommerce/orders/order-details",
            component: <OrderDetails />
          }
        ]
      }
    ]
  },
  {
    type: "collapse",
    name: "Authentication",
    key: "authentication",
    scope: "none",
    icon: <Icon fontSize="medium">content_paste</Icon>,
    collapse: [
      {
        name: "Sign In",
        key: "sign-in",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/authentication/sign-in/basic",
            component: <SignInBasic />
          },
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/sign-in/cover",
            component: <SignInCover />
          },
          {
            name: "Illustration",
            key: "illustration",
            route: "/authentication/sign-in/illustration",
            component: <SignInIllustration />
          }
        ]
      },
      {
        name: "Sign Up",
        key: "sign-up",
        collapse: [
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/sign-up/cover",
            component: <SignUpCover />
          }
        ]
      },
      {
        name: "Reset Password",
        key: "reset-password",
        collapse: [
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/reset-password/cover",
            component: <ResetCover />
          }
        ]
      }
    ]
  },
  // { type: "divider", key: "divider-1" },
  // { type: "title", title: "Docs", key: "title-docs" },
  {
    type: "collapse",
    name: "Basic",
    key: "basic",
    scope: "none",
    icon: <Icon fontSize="medium">upcoming</Icon>,
    collapse: [
      {
        name: "Getting Started",
        key: "getting-started",
        collapse: [
          {
            name: "Overview",
            key: "overview",
            href: "https://www.consen.co/learning-lab/react/overview/material-dashboard/"
          },
          {
            name: "License",
            key: "license",
            href: "https://www.consen.co/learning-lab/react/license/material-dashboard/"
          },
          {
            name: "Quick Start",
            key: "quick-start",
            href: "https://www.consen.co/learning-lab/react/quick-start/material-dashboard/"
          },
          {
            name: "Build Tools",
            key: "build-tools",
            href: "https://www.consen.co/learning-lab/react/build-tools/material-dashboard/"
          }
        ]
      },
      {
        name: "Foundation",
        key: "foundation",
        collapse: [
          {
            name: "Colors",
            key: "colors",
            href: "https://www.consen.co/learning-lab/react/colors/material-dashboard/"
          },
          {
            name: "Grid",
            key: "grid",
            href: "https://www.consen.co/learning-lab/react/grid/material-dashboard/"
          },
          {
            name: "Typography",
            key: "base-typography",
            href: "https://www.consen.co/learning-lab/react/base-typography/material-dashboard/"
          },
          {
            name: "Borders",
            key: "borders",
            href: "https://www.consen.co/learning-lab/react/borders/material-dashboard/"
          },
          {
            name: "Box Shadows",
            key: "box-shadows",
            href: "https://www.consen.co/learning-lab/react/box-shadows/material-dashboard/"
          },
          {
            name: "Functions",
            key: "functions",
            href: "https://www.consen.co/learning-lab/react/functions/material-dashboard/"
          },
          {
            name: "Routing System",
            key: "routing-system",
            href: "https://www.consen.co/learning-lab/react/routing-system/material-dashboard/"
          }
        ]
      }
    ]
  },
  {
    type: "collapse",
    name: "Components",
    key: "components",
    scope: "none",
    icon: <Icon fontSize="medium">view_in_ar</Icon>,
    collapse: [
      {
        name: "Alerts",
        key: "alerts",
        href: "https://www.consen.co/learning-lab/react/alerts/material-dashboard/"
      },
      {
        name: "Avatar",
        key: "avatar",
        href: "https://www.consen.co/learning-lab/react/avatar/material-dashboard/"
      },
      {
        name: "Badge",
        key: "badge",
        href: "https://www.consen.co/learning-lab/react/badge/material-dashboard/"
      },
      {
        name: "Badge Dot",
        key: "badge-dot",
        href: "https://www.consen.co/learning-lab/react/badge-dot/material-dashboard/"
      },
      {
        name: "Box",
        key: "box",
        href: "https://www.consen.co/learning-lab/react/box/material-dashboard/"
      },
      {
        name: "Buttons",
        key: "buttons",
        href: "https://www.consen.co/learning-lab/react/buttons/material-dashboard/"
      },
      {
        name: "Date Picker",
        key: "date-picker",
        href: "https://www.consen.co/learning-lab/react/datepicker/material-dashboard/"
      },
      {
        name: "Dropzone",
        key: "dropzone",
        href: "https://www.consen.co/learning-lab/react/dropzone/material-dashboard/"
      },
      {
        name: "Editor",
        key: "editor",
        href: "https://www.consen.co/learning-lab/react/quill/material-dashboard/"
      },
      {
        name: "Input",
        key: "input",
        href: "https://www.consen.co/learning-lab/react/input/material-dashboard/"
      },
      {
        name: "Pagination",
        key: "pagination",
        href: "https://www.consen.co/learning-lab/react/pagination/material-dashboard/"
      },
      {
        name: "Progress",
        key: "progress",
        href: "https://www.consen.co/learning-lab/react/progress/material-dashboard/"
      },
      {
        name: "Snackbar",
        key: "snackbar",
        href: "https://www.consen.co/learning-lab/react/snackbar/material-dashboard/"
      },
      {
        name: "Social Button",
        key: "social-button",
        href: "https://www.consen.co/learning-lab/react/social-buttons/material-dashboard/"
      },
      {
        name: "Typography",
        key: "typography",
        href: "https://www.consen.co/learning-lab/react/typography/material-dashboard/"
      }
    ]
  },
  {
    type: "collapse",
    name: "Change Log",
    key: "changelog",
    scope: "none",
    href: "https://github.com/creativetimofficial/ct-material-dashboard-pro-react/blob/main/CHANGELOG.md",
    icon: <Icon fontSize="medium">receipt_long</Icon>,
    noCollapse: true
  }
];

export default routes;
