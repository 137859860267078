import React, { useCallback, useEffect, useMemo, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import DialogContent from "@mui/material/DialogContent";
import Tooltip from "@mui/material/Tooltip";


import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";


import DashboardLayout from "LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Data
import { useApplications } from "../../../hooks/application-hooks";
import BackendService from "../../../services/BackendService";
import { Application } from "../../../models";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import CustomDialog from "../../custom-components/CustomDialog";
import ApplicationAdd from "../application-add";
import AdminHeader from "../../custom-components/AdminHeader";
import LinearLoading from "../../custom-components/LinearLoading";
import DefaultCell from "../../../layouts/ecommerce/orders/order-list/components/DefaultCell";
import StatusCell from "../../custom-components/Table/StatusCell";
import { useDateTimeFormatter, usePublicTenantConfig } from "../../../hooks";
import AOTable from "../../custom-components/Table";

import Link from "../application-setup/components/Link";
import CSTable from "../../custom-components/Table/Table";
import UtilsService from "../../../services/UtilsService";
import ItemNameCell from "../../custom-components/Table/ItemNameCell";
import Card from "@mui/material/Card";
import Fade from "@mui/material/Fade";
import useApplicationStore from "../../../store/application-store";
import useWorkflowStore from "../../../store/workflow-store";
import AdminApplicationBox from "./AdminApplicationBox";
import ApplicationAddEdit from "../application/component/ApplicationAddEdit";
import Spinner from "../../custom-components/Spinner/Spinner";

function ApplicationList() {
  const [filter, setFilter] = useState("");
  const [selected, setSelected] = useState<Array<Application>>([]);
  const { tenantConfig, reloadTenantConfig, loadingTenantConfig } = usePublicTenantConfig();

  const initApplStore = useApplicationStore.use.initStore();
  // const getApplOptions = useApplicationStore.use.getOptionList();
  const updateList = useApplicationStore.use.updateList();
  const draftsApplications = useApplicationStore.use.applications();
  const loading = useApplicationStore.use.loading();
  const savingData = useApplicationStore.use.saving();
  const save = useApplicationStore.use.save();

  // WORKFLOW USAGE CHECK
  // const initWFStore = useWorkflowStore.use.initStore();
  // const workflowList = useWorkflowStore.use.workflows();
  // const loadingWF = useWorkflowStore.use.loading();
  // const getStoreWFOptions = useWorkflowStore.use.getOptionList();
  // const [wfOptions, setWfOptions] = useState([{ name: "None", key: "NONE" }]);

  const dateFormatter = useDateTimeFormatter();
  const [menu, setMenu] = useState(null);
  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const [openModalMode, setOpenModalMode] = useState("");


  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={closeMenu}>Status: Paid</MenuItem>
      <MenuItem onClick={closeMenu}>Status: Refunded</MenuItem>
      <MenuItem onClick={closeMenu}>Status: Canceled</MenuItem>
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <MDTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  const { t } = useTranslation();
  const { search } = useLocation();
  const navigate = useNavigate();
  // const {
  //   draftsApplications,
  //   publishedApplications,
  //   archivedApplications,
  //   reloadApplications,
  //   loading
  // } = useApplications();

  const [isOpenArchiveModal, setIsOpenArchiveModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isOpenCopyModal, setIsOpenCopyModal] = useState(false);
  const [selectedApplications, setSelectedApplications] = useState<Array<Application>>([]);

  useEffect(() => {
    initApplStore();
  }, [initApplStore]);

  // useEffect(() => {
  //   initWFStore();
  //   getStoreWFOptions().then(opts => {
  //     setWfOptions(opts);
  //   });
  // }, [initWFStore]);


  const closeArchiveModal = () => {
    setIsOpenArchiveModal(false);
  };

  const closeDeleteModal = () => {
    setIsOpenDeleteModal(false);
  };

  const closeCopyModal = () => {
    setIsOpenCopyModal(false);
  };

  const onArchiveApplications = (selected: Array<Application>) => {
    setSelectedApplications(selected);
    setIsOpenArchiveModal(true);
  };

  const onDeleteApplications = (selected: Array<Application>) => {
    setSelectedApplications(selected);
    setIsOpenDeleteModal(true);
  };

  const onCopyApplications = (selected: Array<Application>) => {
    setSelectedApplications(selected);
    setIsOpenCopyModal(true);
  };

  const archiveApplications = async () => {
    closeArchiveModal();

    if (selectedApplications.length) {
      for (const application of selectedApplications) {
        await BackendService.archive(application.id, application.updatedAt);
      }

      navigate("/admin/applications?tab=archived");
      // navigate.replace("/admin/applications?tab=archived", {
      //   alert: {
      //     type: "success",
      //     message: `${
      //       selectedApplications.length > 1
      //         ? selectedApplications.length
      //         : selectedApplications[0].name
      //     } ${
      //       selectedApplications.length > 1
      //         ? t("ApplicationListing.ApplicationPlural")
      //         : t("ApplicationListing.ApplicationSingular")
      //     } ${t("ApplicationListing.SuccessfullyArchived")}`,
      //   },
      // });

      await reloadApplications();
    }
  };

  const deleteApplications = async () => {
    closeDeleteModal();

    if (selectedApplications.length) {
      await BackendService.deleteApplications(
        selectedApplications.map((application) => application.id)
      );

      navigate("/admin/applications");
      // history.replace("/admin/applications", {
      //   alert: {
      //     type: "success",
      //     message: `${
      //       selectedApplications.length > 1
      //         ? selectedApplications.length
      //         : selectedApplications[0].name
      //     } ${
      //       selectedApplications.length > 1
      //         ? t("ApplicationListing.DraftApplicationPlural")
      //         : t("ApplicationListing.DraftApplicationSingular")
      //     } ${t("ApplicationListing.SuccessfullyDeleted")}`,
      //   },
      // });

      await reloadApplications();
    }
  };

  const copyApplications = async () => {
    closeCopyModal();

    if (selectedApplications.length) {
      for (let application of selectedApplications) {
        await BackendService.copyApplication(application.id);
      }

      navigate("/admin/applications");
      // history.replace("/admin/applications", {
      //   alert: {
      //     type: "success",
      //     message: `${
      //       selectedApplications.length > 1
      //         ? selectedApplications.length
      //         : selectedApplications[0].name
      //     } ${
      //       selectedApplications.length > 1
      //         ? t("ApplicationListing.ApplicationPlural")
      //         : t("ApplicationListing.ApplicationSingular")
      //     } ${t("ApplicationListing.SuccessfullyCopied")}`,
      //   },
      // });

      await reloadApplications();
    }
  };

  let activeTab = "drafts";
  const validTabs = ["drafts", "pending", "published", "archived"];

  const queryString = search.split("tab=");
  if (queryString.length > 1 && validTabs.includes(queryString[1])) {
    activeTab = queryString[1];
  }



  const applicationLabel = `${
    selectedApplications.length !== 1
      ? `${selectedApplications.length} ${t(
        "ApplicationListing.SelectedApplications"
      )}`
      : `"${selectedApplications[0].name}" ${t("ApplicationListing.Application")}`
  }`;

  const onSelect = useCallback((selectedDashboards: Array<Dashboard>) => {
    setSelected(selectedDashboards);
  }, []);

  const openEditModal = () => {
    setOpenModalMode("edit");
  };

  const closeModal =  () => {
    setOpenModalMode("");
  };

  const getTableData = (draftsApplications) => {
    return {
      columns: [
        {
          Header: "App",
          accessor: "name",
          Cell: ({ row }) => {
            const appl = row.original as Application;
            return (
              <Tooltip title={appl.key} placement="top">
                <MDTypography
                  component={Link}
                  to={`/admin/application/edit/${UtilsService.getEncodedHashReplacedURI(appl.key)}`}
                  variant="body2"
                  color="info"
                  fontWeight="bold"
                >
                  {appl.name ? appl.name : "(empty)"}
                </MDTypography>
              </Tooltip>
            );
          }
          // Cell: (props: any) => {
          //   const appl = props.row.original as Application;
          //   return (
          //     <MDTypography
          //       component={Link}
          //       to={`/admin/application/edit/${UtilsService.getEncodedHashReplacedURI(appl.key)}`}
          //       variant="h5"
          //       color="info"
          //       fontWeight="bold"
          //     >
          //             {appl.key}
          //
          //     </MDTypography>
          //   );
          // },
          // Cell: ({ row }) => {
          //   const appl = row.original as Application;
          //   <ItemNameCell name={appl.name} key={appl.key} />
          // },
        },
        { Header: "Category", accessor: "topicAreaId", Cell: ({ value }) => <DefaultCell value={value} /> },
        // { Header: "id", accessor: "key", Cell: ({ value }) => <IdCell id={value} /> },

        { Header: "Description", accessor: "description", Cell: ({ value }) => <DefaultCell value={value} /> },
        {
          Header: "Status",
          accessor: "state",
          Cell: ({ value }) => {
            let status;

            if (value === "Published") {
              status = <StatusCell icon="done" color="success" status="Confirmed" />;
            } else if (value === "Draft") {
              status = <StatusCell icon="edit" color="dark" status="Draft" />;
            } else {
              status = <StatusCell icon="close" color="error" status={value} />;
            }

            return status;
          }
        },
        // {
        //   Header: "Role",
        //   accessor: "user_role",
        //   Cell: ({ value }) => {
        //     let status;
        //
        //     if (value === "TenantAdmin") {
        //       status = <StatusCell icon="done" color="success" status="Admin" />;
        //     } else if (value === "refunded") {
        //       status = <StatusCell icon="replay" color="dark" status="Refunded" />;
        //     } else {
        //       status = <StatusCell icon="close" color="error" status={value} />;
        //     }
        //
        //     return status;
        //   }
        // },
        {
          Header: "Updated",
          accessor: "updatedAt",
          Cell: ({ value }) => <DefaultCell value={dateFormatter(value)} />
        },
        { Header: "Updated By", accessor: "updatedBy", Cell: ({ value }) => <DefaultCell value={value} /> },
        { Header: "Created By", accessor: "createdBy", Cell: ({ value }) => <DefaultCell value={value} /> }
        // {
        //   Header: "customer",
        //   accessor: "given_name",
        //   Cell: ({ value: [name, data] }) => (
        //     <CustomerCell image={data.image} color={data.color || "dark"} name={name} />
        //   ),
        // },
        // {
        //   Header: "product",
        //   accessor: "product",
        //   Cell: ({ value }) => {
        //     const [name, data] = value;
        //
        //     return (
        //       <DefaultCell
        //         value={typeof value === "string" ? value : name}
        //         suffix={data.suffix || false}
        //       />
        //     );
        //   },
        // },
        // { Header: "revenue", accessor: "given_name", Cell: ({ value }) => <DefaultCell value={value} /> },
      ],

      rows: draftsApplications
      // rows: [
      //   {
      //     id: "#10421",
      //     date: "1 Nov, 10:20 AM",
      //     status: "paid",
      //     customer: ["Orlando Imieto", { image: team2 }],
      //     product: "Nike Sport V2",
      //     revenue: "$140,20",
      //   },
      //
      // ],
    };
  };


  return (
      <DashboardLayout loading={loading}>
      <MDBox width="calc(100% - 48px)" position="absolute" top="1.75rem">
        <DashboardNavbar light absolute />
      </MDBox>
      <AdminHeader title="Applications" icon="art_track_outlined"
                   description="View the list of all applications" loading={loading}>
        {/*<MDBox mr={1}>*/}
        {/*<MDButton variant="gradient" color="info"*/}
        {/*          onClick={handleClickOpen}>*/}
        {/*  new application*/}
        {/*</MDButton>*/}
        {/*</MDBox>*/}
        <MDBox mr={2}>
          <MDButton variant="outlined" color="primary"
                    onClick={openEditModal}>
            new application
          </MDButton>
        </MDBox>

        {/*<LoadingBar/>*/}
      </AdminHeader>

      {/*<CustomDialog onClose={handleClose} open={open} fullWidth={true} maxWidth={"sm"}*/}
      {/*              aria-labelledby="customized-dialog-title">*/}
      {/*  <DialogContent>*/}
      {/*    <ApplicationAdd onClose={handleClose} />*/}
      {/*  </DialogContent>*/}
      {/*</CustomDialog>*/}

      <CustomDialog onClose={closeModal} open={openModalMode==='edit'} fullWidth={true} maxWidth={"sm"}
                    aria-labelledby="customized-dialog-title">
        <DialogContent>

          <ApplicationAddEdit applicationData={undefined}
                              onEditApplicationCancel={closeModal}
                              onEditApplicationUpdate={closeModal} />
        </DialogContent>
      </CustomDialog>

        {/*<Card>*/}
        {/*  <Spinner minHeight={200} absoluteTop={"40%"}/>*/}
        {/*</Card>*/}

      <MDBox my={3}>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            {/*<MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>*/}

            {/*  <MDBox display="flex">*/}

            {/*    <MDButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>*/}
            {/*      filters&nbsp;*/}
            {/*      <Icon>keyboard_arrow_down</Icon>*/}
            {/*    </MDButton>*/}
            {/*    {renderMenu}*/}
            {/*    <MDBox ml={1}>*/}
            {/*      <MDButton variant="outlined" color="dark">*/}
            {/*        <Icon>description</Icon>*/}
            {/*        &nbsp;export csv*/}
            {/*      </MDButton>*/}
            {/*    </MDBox>*/}
            {/*  </MDBox>*/}
            {/*</MDBox>*/}

            {loading ? <Card>
                  <Spinner minHeight={200} absoluteTop={"40%"}/>
                </Card>
              :
              <MDBox mb={3}>


                <Grid container spacing={3}>
                  {draftsApplications && draftsApplications.map((dataObj: Application) => (
                    <Grid item xs={12} key={dataObj.key}>
                      <AdminApplicationBox dataObj={dataObj} />
                    </Grid>
                  ))}
                </Grid>


              </MDBox>
            }
          </Grid>

        </Grid>

      </MDBox>

    </DashboardLayout>
  );
}

export default ApplicationList;

