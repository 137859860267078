// prop-types is library for typechecking of props
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "../../../../components/MDButton";

import { useTranslation } from "react-i18next";
import { useDateTimeFormatter } from "../../../../hooks";
import MDBadge from "../../../../components/MDBadge";
import { SubmissionState } from "../../../../models";
// Custom styles for ComplexProjectCard
function SubmissionViewCard({ submissionData, dropdown, clickAction }) {

  const { t } = useTranslation();
  const dateFormatter = useDateTimeFormatter();


  return (
    <Card>
      <MDBox p={2}>
        <MDBox display="flex" alignItems="center">
          {/* <MDAvatar */}
          {/*   src={image} */}
          {/*   alt={title} */}
          {/*   size="xl" */}
          {/*   variant="rounded" */}
          {/*   bgColor={color} */}
          {/*   sx={{ p: 1, mt: -6, borderRadius: ({ borders: { borderRadius } }) => borderRadius.xl }} */}
          {/* /> */}
          <MDBox ml={0} mt={0} lineHeight={0}>
            <MDTypography variant="h6" textTransform="capitalize" fontWeight="medium">


              <MDTypography  variant="h5" color="info"
                        component={Link} to={`/app/submission/${submissionData.submissionId}/preview`}>
                {submissionData.name || "No Name"} Application
              </MDTypography>
{/* <MDButton variant="text" color="info" sx={{ height: "100%" }} */}
{/*                         component={Link} to={`/app/submission/${submissionData.submissionId}/preview`}> */}
{/*                 {submissionData.name || "No Name"} Application */}
{/*               </MDButton> */}


              {" "}
              <MDBadge variant="contained" color={submissionData.state === "Draft" ? "info" : "secondary"}
                                                          badgeContent={submissionData.state} />
            </MDTypography>
            <MDBox display="flex">
              <MDTypography variant="caption" fontWeight="regular" color="secondary">
                Last Updated {dateFormatter(submissionData.updatedAt)}
              </MDTypography>
              </MDBox>
          </MDBox>
          {dropdown && (
            <MDTypography
              color="secondary"
              onClick={dropdown.action}
              sx={{
                ml: "auto",
                mt: -1,
                alignSelf: "flex-start",
                py: 1.25,
              }}
            >
              <Icon fontSize="default" sx={{ cursor: "pointer", fontWeight: "bold" }}>
                more_vert
              </Icon>
            </MDTypography>
          )}
          {dropdown.menu}
        </MDBox>
        <MDBox my={2} lineHeight={1}>
          <MDTypography variant="button" fontWeight="light" color="text">
            {/* {JSON.stringify(submissionData)} */}
            {submissionData.description}
          </MDTypography>
        </MDBox>
        <Divider />
        {/* <MDTypography  variant="caption" > */}
        {/*   {JSON.stringify(submissionData)} */}
        {/* </MDTypography> */}
        <MDBox display="flex" flexDirection="column" >

            <MDBox display="flex" flexDirection="column" lineHeight={0}>
              <MDTypography variant="button" fontWeight="regular">
                {submissionData.participantType} Applicant
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="secondary">
                {submissionData.submissionId}
              </MDTypography>
            </MDBox>


            <MDBox display="flex" flexDirection="column" lineHeight={2} mt={2}>
              {/* <MDTypography variant="button" fontWeight="medium"> */}
              {/*   {dateTime} */}
              {/* </MDTypography> */}
              {/* <MDTypography variant="button" fontWeight="regular" color="secondary"> */}
              {/*   Due date */}
              {/* </MDTypography> */}

              {submissionData.state===SubmissionState.Draft &&
                <MDButton variant="gradient" color="primary" size="small"
                          onClick={()=>clickAction(submissionData)}>
                  {t("Continue")}
                </MDButton>
              }

            </MDBox>

        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of SubmissionViewCard
SubmissionViewCard.defaultProps = {
  color: "dark",
  dateTime: "",
  members: [],
  dropdown: false,
};

// Typechecking props for the ProfileInfoCard
SubmissionViewCard.propTypes = {
  // color: PropTypes.oneOf([
  //   "primary",
  //   "secondary",
  //   "info",
  //   "success",
  //   "warning",
  //   "error",
  //   "dark",
  //   "light",
  // ]),
  // image: PropTypes.string,
  // title: PropTypes.string.isRequired,
  // dateTime: PropTypes.string,
  // description: PropTypes.node.isRequired,
  // members: PropTypes.arrayOf(PropTypes.string),
  dropdown: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func,
      menu: PropTypes.node,
    }),
  ]),
};

export default SubmissionViewCard;
