import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Form, Formik, useFormik } from "formik";
import PropTypes from "prop-types";
import { Link, useNavigate, useParams } from "react-router-dom";


import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

import Autocomplete from "@mui/material/Autocomplete";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";


import { useTranslation } from "react-i18next";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { useSettings, useTopicAreas } from "hooks";
import Spinner from "../../../custom-components/Spinner/Spinner";
import MDInput from "components/MDInput";

import { WidgetType } from "models";
import {
  useDashboard,
  useFullPreview,
  useChangeBackgroundColor,
  useWindowSize
} from "hooks";

import AppApiService from "services/AppApiService";
import UtilsService from "../../../../services/UtilsService";
import Divider from "@mui/material/Divider";


interface FormValues {
  sk: string;
  name: string;
  showTitle: boolean;
  widgetType: string;
  content: {};
  section: string;
  updatedAt: string;
}

interface PathParams {
  applicationId: string;
}

function TextWidgetEdit( { widgetData, onEditWidgetCancel, onEditWidgetUpdate }) {
  const navigate = useNavigate();
  const { applicationId } = useParams<PathParams>();
  const { t } = useTranslation();
  const [savingData, setSavingData] = useState(false);
  const { fullPreview, fullPreviewButton } = useFullPreview();
  const [alertMessage, setAlertMessage] = useState("");
  const [initValue, setInitValue] = useState(widgetData || {
    name: widgetData?.name || "",
    showTitle: widgetData?.showTitle || true,
    widgetType: widgetData?.widgetType || "Text",
    content: widgetData?.content || {text:""},
    section: widgetData?.section
  });
  const formik = useFormik({initialValues: initValue});

  const form = {
    formId: "widget-edit-form",
    formField: {
      name: {
        name: "name",
        label: t("AddTextScreen.TextTitle"),
        type: "text",
        errorMsg: "Title is required.",
        invalidMsg: "Title is invalid.",
        placeholder: t("AddTextScreen.TextTitleHint")
      },
      showTitle: {
        name: "showTitle",
        label: "show Title",
        type: "text",
        errorMsg: "showTitle is required."
      },
      widgetType: {
        name: "widgetType",
        label: "widget Type",
        type: "text",
        errorMsg: "widgetType is required.",
        placeholder: "widgetType"
      },
      content: {
        name: "content",
        label: "Text Content",
        type: "text",
        placeholder: "Some description"
      }
    }
  };
  const { formId, formField: { name, showTitle, widgetType, content } } = form;
  const validations = Yup.object().shape({
    [name.name]: Yup.string().required(name.errorMsg).min(6, name.invalidMsg),
    [showTitle.name]: Yup.boolean().required(showTitle.errorMsg)
  });

  useEffect(() => {
    // console.log("widgetData: " + JSON.stringify(widgetData));
    setInitValue(widgetData);

  }, [widgetData]);



  // useChangeBackgroundColor();

  // if (!loading) {
  //   crumbs.push({
  //     label: t("AddTextScreen.AddText"),
  //     url: "",
  //   });
  // }
  const onSubmit = async (values: FormValues) => {
    try {
      setSavingData(true);
      // console.log(JSON.stringify(values));
      if (values.sk && values.sk.length>10){
        await updateWidget(values);
      }else{
        await createWidget(values);
      }

      setSavingData(false);
      onEditWidgetUpdate();
    } catch (err) {
      console.log(t("AddContentFailure"), err);
      setAlertMessage(t("AddContentFailure") + ": " + err?.message);
      setSavingData(false);
    }
  };

  const createWidget = async (values: FormValues) => {
      console.log(JSON.stringify(values));
      await AppApiService.createWidget(applicationId,
        values.name,
        values.widgetType,
        values.showTitle,
        values.content
      );
  };

  const updateWidget = async (values: FormValues) => {
      console.log(JSON.stringify(values));
      await AppApiService.editWidget(applicationId,
        UtilsService.generateEncodedHashReplacedKey(widgetData.shardId, widgetData.sk),
        values.name,
        values.showTitle,
        values.content,
        new Date()
      );
  };

  return (
    <>


        <MDBox>
          {/*{applicationId}*/}
          <MDBox mb={1} mt={1}>
            <MDTypography variant="h5">
              {t("AddTextScreen.AddText")}
            </MDTypography>
          </MDBox>
          <MDBox>

            <MDBox hidden={fullPreview}>

              <Formik enableReinitialize={true}
                initialValues={formik.initialValues}
                validationSchema={validations}
                onSubmit={onSubmit}
              >
                {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                  <Form id={formId} autoComplete="off"
                        >
                    <MDBox mt={2}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <MDBox mt={0}>
                            <Grid container spacing={3}>
                              <Grid item xs={12}>
                                <MDTypography variant="h6" fontWeight="regular" color="text">
                                  {t("AddTextScreen.Configure")}
                                </MDTypography>
                              </Grid>
                              <Grid item xs={12}>
                                <FormField
                                  type={name.type}
                                  label={name.label}
                                  name={name.name}
                                  value={values.name}
                                  placeholder={"Title"}
                                  error={errors.name && touched.name}
                                  success={values.name.length > 5 && !errors.name}
                                />
                              </Grid>
                              <Grid item xs={12} alignItems="right">
                                <MDBox
                                  display="flex"
                                  alignItems="center"
                                  // justifyContent="flex-end"
                                  width={{ xs: "100%", sm: "auto" }}
                                  mt={{ xs: 1, sm: 0 }}
                                >
                                  <MDBox lineHeight={0} mx={0}>
                                    <MDTypography variant="button" color="text">
                                      {t("AddTextScreen.ShowTitle")}
                                      {/*{values.showTitle ? "Show" : "Hide"}*/}
                                    </MDTypography>
                                  </MDBox>
                                  <MDBox mr={1}>
                                    <Switch checked={values.showTitle}
                                            inputProps={{ "aria-label": "controlled" }}
                                            onChange={(e, value) => {
                                              setFieldValue("showTitle", value);
                                            }} />
                                  </MDBox>
                                </MDBox>
                              </Grid>
                              <Grid item xs={12}>

                                <FormField
                                  type={content.type}
                                  label={content.label}
                                  name={content.name}
                                  value={values.content ? values.content?.text : ""}
                                  placeholder={"Text"}
                                  rows="4"
                                  multiline
                                  fullWidth
                                  error={errors.content && errors.content?.text && touched?.content?.text}
                                  success={values.content.text && values.content.text.length > 5 && !errors?.content?.text}
                                  onChange={(e) => {
                                    // console.log(e.target.value);
                                    setFieldValue("content", {text:e.target.value});
                                  }}
                                />


                              </Grid>

                              {/*<Grid item xs={12}>*/}
                              {/*  {errors && JSON.stringify(errors)}*/}
                              {/*</Grid>*/}
                              {alertMessage &&
                                <Grid item xs={12}>
                                <MDAlert color="error" dismissible>
                                  <MDTypography variant="body2" color="white">
                                    {t("AddTextScreen.AddTextError")}
                                  </MDTypography>
                                </MDAlert>
                              </Grid>
                              }



                            </Grid>
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ display: "flex" }}>
                          <Divider orientation="vertical" sx={{ mt:2, ml: 2, mr: 1 }} />
                          <MDBox ml={3}

                            aria-label={t("ContentPreview")}
                                 >
                            <Grid item xs={12}>
                              <MDTypography variant="h6" fontWeight="regular" color="text">
                                {t("Preview")}
                              </MDTypography>
                            </Grid>
                            {/*<Divider orientation="vertical" />*/}
                            <MDBox  mt={2}>
                            {values.showTitle ? (
                                <MDTypography variant="h4">
                                  {values.name}
                                </MDTypography>
                              // <h2 className="margin-top-3 margin-left-2px">{values.name}</h2>
                            ) : (
                              ""
                            )}
                            {values.content ? (
                              <MDBox >
                                {/*<MarkdownRender*/}
                                {/*  className="usa-prose textOrSummary"*/}
                                {/*  source={text}*/}
                                {/*/>*/}
                                {values.content?.text}
                              </MDBox>
                            ) : (
                              ""
                            )}
                            </MDBox>
                          </MDBox>
                        </Grid>


                      </Grid>


                      <MDBox ml="auto" justifyContent="space-between"
                             width={{ xs: "100%", sm: "auto" }}
                             display="flex"
                             alignItems={{ xs: "flex-end", sm: "center" }}
                             flexDirection={{ xs: "column", sm: "row" }}>
                        <MDButton variant="gradient" color="secondary" size="large"
                                  disabled={savingData}
                                  onClick={onEditWidgetCancel}>
                          {t("Cancel")}
                        </MDButton>
                        <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                          <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="primary" size="large"
                          >
                            {widgetData && widgetData.sk ? "Update" : "Create"}
                          </MDButton>
                        </MDBox>
                      </MDBox>


                    </MDBox>
                  </Form>
                )}
              </Formik>
            </MDBox>

          </MDBox>
        </MDBox>

    </>
  );
}

export default TextWidgetEdit;
