import { useEffect, useState, useCallback, useContext } from "react";
import { Auth } from "@aws-amplify/auth";
import { Hub } from "@aws-amplify/core";

import { User, UserPreferences, UserRoles } from "../models";
import BackendService from "../services/BackendService";
import UserService from "../services/UserService";
import { UserPreferencesContext } from "../context/UserPreferencesProvider";

type CurrentUserHook = {
  username: string;
  userFirstName: string;
  tenant: string;
  email: string;
  isAdmin: boolean;
  isEditor: boolean;
  isFederatedId: boolean;
  // isPublic: boolean;
  isLoggedIn: boolean;
  hasRole: boolean;
  userPreferences: UserPreferences;
  reloadUserPreferences: Function;
  updateUserPreference: Function;
};

export function useCurrentAuthenticatedUser(): CurrentUserHook {
  const [username, setUser] = useState<string>("");
  const [userFirstName, setUserFirstName] = useState<string>("");
  const [tenant, setTenant] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [federated, setFederated] = useState(false);
  const [hasRole, setHasRole] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isEditor, setIsEditor] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { userPreferences, reloadUserPreferences , updateUserPreference} =
    useContext(UserPreferencesContext);

  const fetchData = useCallback(async () => {
    // console.log('useCurrentAuthenticatedUser fetchData() ...');
    // const sess = await Auth.currentSession();
    // await Auth.currentSession().catch(e=>{console.log(e);return;});

    // const user = await Auth.currentAuthenticatedUser();
    const user = await Auth.currentAuthenticatedUser().catch(e=>{
      // setIsLoggedIn (false);
      console.log(e);
      return {};
    });
    setUser(user.username);
    setUserFirstName(user.attributes && user.attributes.given_name || user.username);
    setIsLoggedIn (user.username !== undefined);
    // did the user do Single Sign In, if so we'll have to do Single Sign Out
    // attributes: {identities:"[{"providerType":"SAML"}]"}
    if (user.attributes && user.attributes["identities"]) {
      const identity = JSON.parse(user.attributes.identities);
      if (Array.isArray(identity) && identity[0].providerType) {
        setFederated(true);
      }
    } else {
      setFederated(false);
    }
    if (user.attributes && user.attributes["custom:userRole"]) {
      const userRoles: string = user.attributes["custom:userRole"];
      // console.log(JSON.stringify(user.attributes))
      setIsAdmin(userRoles.includes(UserRoles.Admin));
      setIsEditor(userRoles.includes(UserRoles.Editor));
      // setIsPublic(userRoles.includes(UserRoles.Public));
    } else {
      setHasRole(false);
    }

    if (user.attributes && user.attributes["email"]) {
      setEmail(user.attributes["email"]);
    }
    if (user.attributes && user.attributes["custom:tenantId"]) {
      const userOfTenants: string = user.attributes["custom:tenantId"];
      setTenant(userOfTenants);
    }
    // console.log('useCurrentAuthenticatedUser calling reloadUserPreferences ...');
    // await reloadUserPreferences( user);

  }, []);

  /**
   * Listen for authentication events so that when users
   * signIn or their token is refreshed, we refetch the
   * TenantConfig. This covers an edge case in which we fail
   * to fetch TenantConfig the first time because the user was
   * not authenticated yet.
   */
  const listenAuthEvents = useCallback(
    (event: any) => {
      const { payload } = event;
      switch (payload.event) {
        case "signIn":
        case "tokenRefresh":
          console.log("Detected AuthEvents: " + payload.event)
          fetchData();
          break;
        default:
          break;
      }
    },
    [fetchData]
  );

  useEffect(() => {
    fetchData();
    Hub.listen("auth", listenAuthEvents);
    return () => Hub.remove("auth", listenAuthEvents);
  }, [fetchData, listenAuthEvents]);

  // useEffect(() => {
  //   fetchData();
  // }, [fetchData]);

  return {
    username,
    userFirstName,
    tenant,
    email,
    isAdmin: isAdmin,
    isFederatedId: federated,
    isEditor: isEditor,
    // isPublic: isPublic,
    isLoggedIn: isLoggedIn,
    hasRole,
    userPreferences: userPreferences as UserPreferences,
    reloadUserPreferences,
    updateUserPreference
  };
}

type UsersHook = {
  users: User[];
  loading: boolean;
  reloadUsers: Function;
  setUsers: Function;
};

export function useUsers(): UsersHook {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const data = await UserService.fetchUsers();
    //console.log(JSON.stringify(data));
    setUsers(data);
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    loading,
    users,
    reloadUsers: fetchData,
    setUsers,
  };
}
