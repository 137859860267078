import { useCallback, useEffect, useState } from "react";
import { TopicArea } from "../models";
import BackendService from "../services/BackendService";
import { usePublicTenantConfig } from "./tenant-config-hooks";

type UseTopicAreasHook = {
  loading: boolean;
  topicareas: Array<TopicArea>;
  reloadTopicAreas: Function;
};

export function useTopicAreas(): UseTopicAreasHook {
  const [loading, setLoading] = useState(false);
  const [topicareas, setTopicAreas] = useState([]);
  const { tenantConfig } = usePublicTenantConfig();
  const fetchData = useCallback(async () => {
    setLoading(true);
    // const data = await BackendService.fetchTopicAreas();
    // const data:any = [{name:"Product", id:"Product"},
    //   {name:"Services",id:"Services"},
    //   {name:"Other",id:"Other"}];
    const data = tenantConfig.topicAreas;
    setTopicAreas(data.sort((a, b) =>
      a.topicAreaName > b.topicAreaName ? 1 : -1
    ));
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    loading,
    topicareas,
    reloadTopicAreas: fetchData,
  };
}

type UseTopicAreaHook = {
  topicarea: TopicArea | undefined;
  loading: boolean;
};

export function useTopicArea(topicAreaId: string): UseTopicAreaHook {
  const [loading, setLoading] = useState(false);
  const [topicarea, setTopicArea] = useState<TopicArea | undefined>(undefined);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const data = await BackendService.fetchTopicAreaById(topicAreaId);
    setTopicArea(data);
    setLoading(false);
  }, [topicAreaId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    loading,
    topicarea,
  };
}
