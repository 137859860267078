import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Form, Formik, useFormik } from "formik";
import PropTypes from "prop-types";
import { Link, useNavigate, useParams } from "react-router-dom";


import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

import Autocomplete from "@mui/material/Autocomplete";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";


import { useTranslation } from "react-i18next";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { useSettings, useTopicAreas } from "hooks";
import Spinner from "views/custom-components/Spinner/Spinner";
import MDInput from "components/MDInput";

import { Category, TenantUser } from "models";
import { v4 as uuidv4 } from "uuid";

import {
  useDashboard,
  useFullPreview,
  useChangeBackgroundColor,
  useWindowSize
} from "hooks";

import UserService from "services/UserService";
import UtilsService from "services/UtilsService";
import RowRadioButtonsGroup from "custom-components/RowRadioButtonsGroup";
import MDEditor from "components/MDEditor";


interface FormValues {
  userName: string;
  givenName: string;
  familyName: string;
  userRole: string;
  userEmail: string;
  phoneNumber: string;
}

interface PathParams {
  tenantUserKey: string;
}

function TenantUserAddEdit({ tenantUserData, onCancel, onUpdate }) {
  const navigate = useNavigate();
  const { tenantUserKey } = useParams<PathParams>();
  const { t } = useTranslation();
  const [savingData, setSavingData] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const { topicareas, loading } = useTopicAreas();
  const sortedTopicAreas = [{label:'Admin', name:"TenantAdmin"},
    {label:'Employee / Agent', name:"Editor"},{label:'Users', name:"Public"}
    ]


  const [initValue, setInitValue] = useState({
    // shardId: tenantUserData?.shardId || "",
    // tenantUserId: tenantUserData?.tenantUserId || "",
    userName: tenantUserData?.userName || "",
    userRole: tenantUserData?.userRole || "Public",
    givenName: tenantUserData?.givenName || "",
    familyName: tenantUserData?.familyName || "",
    userEmail: tenantUserData?.userEmail || "",
    phoneNumber: tenantUserData?.phoneNumber || "",
  });
  const formik = useFormik({ initialValues: initValue });


  const form = {
    formId: "tenantUser-edit-form",
    formField: {
      userName: {
        name: "userName",
        label: t("TenantUserEdit.UserName"),
        type: "text",
        errorMsg: "userName is required.",
        invalidMsg: "userName is invalid.",
        placeholder: t("TenantUserEdit.UserNameHint")
      },

      userRole: {
        name: "userRole",
        label:t("TenantUserEdit.UserRole"),
        type: "text",
        errorMsg: "userRole is required."
      },

      givenName: {
        name: "givenName",
        label: t("TenantUserEdit.GivenName"),
        type: "text",
        errorMsg: "givenName is required.",
        placeholder: t("TenantUserEdit.GivenNameHint")
      },
      familyName: {
        name: "familyName",
        label: t("TenantUserEdit.FamilyName"),
        type: "text",
        errorMsg: "familyName is required.",
        placeholder: t("TenantUserEdit.FamilyNameHint")
      },

      userEmail: {
        name: "userEmail",
        label: t("TenantUserEdit.UserEmail"),
        type: "text",
        errorMsg: "Please specify userEmail",
        placeholder: t("TenantUserEdit.UserEmailHint"),
      },
      phoneNumber: {
        name: "phoneNumber",
        label: t("TenantUserEdit.PhoneNumber"),
        type: "text",
        errorMsg: "Please specify phoneNumber",
        placeholder: t("TenantUserEdit.PhoneNumberHint")
      }

    }
  };
  const {
    formId,
    formField: { userName,
      givenName, familyName,
      userRole, phoneNumber,
      userEmail }
  } = form;

  const validations = Yup.object().shape({
    [userName.name]: Yup.string().required(userName.errorMsg).min(6, userName.invalidMsg),
    // [description.name]: Yup.string().required(description.errorMsg).min(10, description.invalidMsg),

    [userRole.name]: Yup.string().required(userRole.errorMsg).oneOf(
      ["Public", "TenantAdmin", "Editor"],
      t("TenantUserEdit.UserRoleRequired")
    ),
    // [categoryId.name]: Yup.string().required(categoryId.errorMsg).min(3, categoryId.invalidMsg),
    // [userEmail.name]: Yup.string().required(userEmail.errorMsg).min(3, userEmail.invalidMsg),
    // [phoneNumber.name]: Yup.number().required(phoneNumber.errorMsg).min(10, phoneNumber.invalidMsg)
  });

  useEffect(() => {
    console.log("tenantUserData: " + JSON.stringify(tenantUserData));
    // setInitValue(tenantUserData);

  }, [tenantUserData]);


  useChangeBackgroundColor();

  // if (!loading) {
  //   crumbs.push({
  //     label: t("TenantUserEdit.AddTenantUser"),
  //     url: "",
  //   });
  // }
  // const mergeValues =  (values: FormValues) => {
  //   userName: tenantUserData?.userName || "",
  //     userRole: tenantUserData?.userRole || "Draft",
  //     givenName: tenantUserData?.givenName || "",
  //     price: tenantUserData?.price || 0,
  //     givenName: tenantUserData?.givenName || "Fixed",
  //     familyName: tenantUserData?.familyName || "",
  //     description: tenantUserData?.description || "",
  //     phoneNumber: tenantUserData?.phoneNumber || "Public",
  //     categoryId: tenantUserData?.categoryId || "Other",
  //     userEmail: tenantUserData?.userEmail || "Other",
  //     phoneNumber: tenantUserData?.phoneNumber || uuidv4(),
  //     content: tenantUserData?.content || { text: "" },
  //     settings: tenantUserData?.settings || { showPrice: true, showImages:false, showContent:true },
  //     workflow: tenantUserData?.workflow || { workflowId: "", workflowType:"", customApplicationId:"", customEFormId:"" },
  // }
  const onSubmit = async (values: FormValues) => {
    try {
      setSavingData(true);
      console.log("TenantUserAddEdit- onSubmit : " + JSON.stringify(values));
      if (tenantUserData && tenantUserData.shardId && tenantUserData.tenantUserId.length > 10) {
        let updValues = Object.assign(tenantUserData, values)
        console.log("updated values : " + JSON.stringify( updValues));
        await updateTenantUser(updValues);
      } else {
        await createTenantUser(values);
      }

      setSavingData(false);
      onUpdate();
    } catch (err) {
      console.log(t("AddContentFailure"), err);
      setAlertMessage(t("AddContentFailure") + ": " + err?.message);
      setSavingData(false);
    }
  };

  const createTenantUser = async (values: FormValues) => {
    console.log(JSON.stringify(values));
    await UserService.createTenantUser(values);
  };

  const updateTenantUser = async (values: FormValues) => {
    // console.log(JSON.stringify(tenantUserData));
    // console.log(JSON.stringify(values));
    await UserService.editTenantUser(tenantUserData.key, values);
  };

  return (
    <MDBox m={3}>

      {savingData ? (
        <Spinner
          label={t("TenantUserEdit.SavingData")}
        />
      ) : (
        <MDBox>
          <MDBox mb={2} >
            <MDTypography variant="h5">
              {tenantUserData && tenantUserData.shardId ? t("TenantUserEdit.EditTenantUser") : t("TenantUserEdit.AddTenantUser")}
              {tenantUserKey}
            </MDTypography>
          </MDBox>

          <MDBox>
            {/*<Media />*/}
            <MDBox>
              <Formik enableReinitialize={true}
                      initialValues={formik.initialValues}
                      validationSchema={validations}
                      onSubmit={onSubmit}
              >
                {({ values, errors, touched, isSubmitting, setFieldValue, success }) => (
                  <Form id={formId} autoComplete="off"
                  >

                        <MDBox mt={2}>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                              <FormField
                                type={userName.type}
                                label={userName.label}
                                name={userName.name}
                                value={values.userName}
                                placeholder={"Title"}
                                error={errors.userName && touched.userName}
                                success={values.userName.length > 5 && !errors.userName}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Autocomplete
                                defaultValue="Other"
                                name={userRole.name}
                                value={values.userRole}
                                isOptionEqualToValue={(option, value) =>  option.name === value }
                                options={sortedTopicAreas}
                                onChange={(e, value) => {
                                  console.log("onChange :" + JSON.stringify(value));
                                  setFieldValue("userRole",value ? value.name : "Public");
                                }}
                                getOptionLabel={(option: Object | string) => option["label"] || option}
                                renderInput={(params) =>
                                  <MDInput {...params} variant="standard"
                                           label={userRole.label}
                                           hint={`${t("SelectExistingLeading")} ${userRole.label}`}
                                           error={errors.userRole && touched.userRole}
                                           success={values.userRole.length > 0 && !errors.userRole}
                                    required
                                  />}
                              />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                              <FormField
                                type={userEmail.type}
                                label={userEmail.label}
                                name={userEmail.name}
                                value={values.userEmail}
                                placeholder={"Title"}
                                error={errors.userEmail && touched.userEmail}
                                success={UtilsService.validateEmails(values.userEmail) && !errors.userEmail}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <FormField
                                type={givenName.type}
                                label={givenName.label}
                                name={givenName.name}
                                value={values.givenName}
                                placeholder={givenName.placeholder}
                                error={errors.givenName && touched.givenName}
                                success={values.givenName.length > 5 && !errors.givenName}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormField
                                type={familyName.type}
                                label={familyName.label}
                                name={familyName.name}
                                value={values.familyName}
                                placeholder={"Title"}
                                error={errors.familyName && touched.familyName}
                                success={values.familyName.length > 5 && !errors.familyName}
                              />
                            </Grid>




                            {/*<Grid item xs={12} sm={6}>*/}
                            {/*  <FormField*/}
                            {/*    type={phoneNumber.type}*/}
                            {/*    label={phoneNumber.label}*/}
                            {/*    name={phoneNumber.name}*/}
                            {/*    value={values.phoneNumber}*/}
                            {/*    placeholder={phoneNumber.placeholder}*/}
                            {/*    error={errors.phoneNumber && touched.phoneNumber}*/}
                            {/*    success={values.phoneNumber.length > 9 && !errors.phoneNumber}*/}
                            {/*  />*/}
                            {/*</Grid>*/}


                          </Grid>

                          {/*{JSON.stringify(values)}*/}
                          {/*{JSON.stringify(errors)}*/}
                        </MDBox>




                        <MDBox mt={2}>
                          <Grid container spacing={3}>

                            <Grid item xs={12}>
                              {alertMessage && (
                                <MDAlert color="error" dismissible>
                                  <MDTypography variant="body2" color="white">
                                    {t("TenantUserEdit.SaveError")}
                                  </MDTypography>
                                </MDAlert>
                              )}

                            </Grid>
                          </Grid>

                        </MDBox>

                    <MDBox ml="auto" justifyContent="space-between"
                           width={{ xs: "100%", sm: "auto" }} display="flex"
                           alignItems={{ xs: "flex-end", sm: "center" }}
                           flexDirection={{ xs: "column", sm: "row" }}>
                      <MDButton variant="outlined" color="secondary" size="large"
                                onClick={onCancel}>
                        {t("Close")}
                      </MDButton>

                      <MDButton
                        disabled={isSubmitting}
                        type="submit"
                        variant="gradient"
                        color="primary" size="large"
                      >
                        {tenantUserData && tenantUserData.tenantUserId ? "Update" : "Create"}
                      </MDButton>
                    </MDBox>
                  </Form>
                )}
              </Formik>
            </MDBox>

          </MDBox>
        </MDBox>
      )}
    </MDBox>
  );
}

export default TenantUserAddEdit;
