/**
 * label: caption on draggable Toolbar
 *
 * @type {[{color: string, icon: string, label: string, nodeType: string, value: {name: string, key: string}, desc: string},{color: string, icon: string, label: string, nodeType: string, value: {name: string, key: string}, desc: string},{color: string, icon: string, label: string, nodeType: string, value: {name: string, key: string}, desc: string},{color: string, icon: string, label: string, nodeType: string, value: {name: string, key: string}, desc: string},{color: string, icon: string, label: string, nodeType: string, value: {name: string, key: string}, desc: string},null,null,null,null,null]}
 */
export const workflowNodeTypes = [
  {
    name: "TEXT",
    label: "Text",
    flowType: "NA",
    nodeType: "textNode",
    color: "dark",
    value: { name: "This is a Text", key: "22px" },
    icon: "title",
    desc: ""
  },
  // { label: "OFFERING", nodeType: "offeringNode", color: "primary", value: { name: "None", key: "NONE" }, icon: "image" , desc:""},
  {
    name: "T&C",
    label: "Terms & Conditions",
    flowType: "C",
    nodeType: "tncNode",
    color: "warning",
    value: { name: "None", key: "NONE" },
    icon: "note_alt",
    desc: ""
  },
  {
    name: "PRE-SCREEN",
    label: "Pre-Screening",
    flowType: "C",
    nodeType: "preScreenNode",
    color: "info",
    value: { name: "None", key: "NONE" },
    icon: "note_alt",
    desc: ""
  },
  {
    name: "ELIGIBILITY",
    label: "Eligibility",
    flowType: "C",
    nodeType: "eligibilityNode",
    color: "error",
    value: { name: "None", key: "NONE" },
    icon: "note_alt",
    desc: ""
  },
  {
    name: "APPLICATION",
    label: "Application",
    flowType: "C",
    nodeType: "applicationNode",
    color: "primary",
    value: { name: "None", key: "NONE" },
    icon: "art_track_outlined", desc: ""
  },
  {
    name: "VERIFY",
    label: "Verify",
    flowType: "C",
    nodeType: "verifyNode",
    color: "success",
    value: { name: "None", key: "NONE" },
    icon: "beenhere", desc: ""
  },
  {
    name: "DATA",
    label: "Data",
    flowType: "SYS",
    nodeType: "dataNode",
    color: "warning",
    value: { name: "None", key: "NONE" },
    icon: "data_object"
  },
  {
    name: "START",
    label: "Start",
    flowType: "SYS",
    nodeType: "startNode",
    color: "info",
    value: { name: "None", key: "NONE" },
    icon: "start", desc: ""
  },
  {
    name: "REVIEW",
    label: "Review & Submit",
    flowType: "C",
    nodeType: "reviewNode",
    color: "success",
    value: { name: "None", key: "NONE" },
    icon: "done", desc: ""
  },
  {
    name: "END",
    label: "End",
    flowType: "SYS",
    nodeType: "endNode",
    color: "success",
    value: { name: "None", key: "NONE" },
    icon: "stop", desc: ""
  }
];

