import PropTypes from "prop-types";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

function UserCell({ image, color, userData }) {
  return (
    <MDBox display="flex" alignItems="center">
      <MDBox mr={1}>
        <MDAvatar bgColor={color} src={image} alt={userData.user_name} size="xs" />
      </MDBox>
      <MDTypography variant="caption" fontWeight="medium" color="text" sx={{ lineHeight: 0 }}>
        {userData.user_name}
      </MDTypography>
      <br/>
      <MDBox mt={0}>
      {/* <MDTypography variant="caption"  color="text" sx={{ lineHeight: 0 }}> */}
      {/*   {userData.family_name} {userData.given_name} */}
      {/*   full name */}
      {/*   /!* {JSON.stringify(userData)} *!/ */}
      {/* </MDTypography> */}
      </MDBox>
    </MDBox>
  );
}

// Setting default value for the props of UserCell
UserCell.defaultProps = {
  image: "",
  color: "dark",
};

// Typechecking props for the UserCell
UserCell.propTypes = {
  image: PropTypes.string,
  userData: PropTypes.string.isRequired,
  color: PropTypes.oneOf([
    "transparent",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
};

export default UserCell;
