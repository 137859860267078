import React, { useState, useEffect, memo } from "react";

import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";
import MDInput from "components/MDInput";
import LinearLoading from "views/custom-components/LinearLoading";
import OfferingPageInfo from "../../../offering-page/components/OfferingPageInfo";
import FriendlyURLGenerator from "../../../../../services/FriendlyURLGenerator";
import { usePublicTenantConfig } from "../../../../../hooks";
import { useSubmissionContextHook } from "../../../../../hooks/submission-hooks";
import Spinner from "../../../../custom-components/Spinner/Spinner";

function SubmissionInfo() {
  const { tenantConfig  } = usePublicTenantConfig();
  const {
    loadingSubmission,
    summary,
    submissionMain,
    submissionLoaded,
    reloadSubmission
  } = useSubmissionContextHook();
  const labels = [];
  const values = [];

  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(summary).forEach((el) => {
    if (el.match(/[A-Z\s]+/)) {
      const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
      const newElement = el.replace(uppercaseLetter, ` ${uppercaseLetter.toLowerCase()}`);

      labels.push(newElement);
    } else {
      labels.push(el);
    }
  });

  // Push the object values into the values array
  Object.values(summary).forEach((el) => values.push(el));

// Render the card info items
  const renderItems = labels.map((label, key) => (
    <MDBox key={label} display="flex" py={.5} pr={2}>
      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
        {label}: &nbsp;
      </MDTypography>
      <MDTypography variant="button" fontWeight="regular" color="text">
        &nbsp;{values[key]}
      </MDTypography>
    </MDBox>
  ));


  if (loadingSubmission ) {
    return (
      <MDBox>
        <Spinner minHeight={200}/>
      </MDBox>
    );
  }
  // const offering = submissionMain.offering;
  return (
    <MDBox>
      <MDBox mb={1}>
        <MDTypography variant="h3" fontWeight="bold">
          {summary.offeringName} Application
        </MDTypography>
      </MDBox>

      <MDBox mt={3}>
        {renderItems}
      </MDBox>

      <MDBox mt={3}>
        <MDTypography variant="body2" fontWeight="regular">
        {`${FriendlyURLGenerator.getHostPathForFriendlyUrl(
          "submission",
          undefined, 
          tenantConfig.tenantName)}${submissionMain.submissionId}`}
        </MDTypography>
      </MDBox>






    </MDBox>
  );
}

export default memo(SubmissionInfo);
