import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Link } from "react-router-dom";

import Switch from "@mui/material/Switch";
// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import { useTranslation } from "react-i18next";

// Images
import bgImage from "assets/images/illustrations/illustration-reset.jpg";
import { usePublicTenantConfig } from "../../hooks";
import UtilsService from "../../services/UtilsService";
import IllustrationLayout from "../../LayoutContainers/IllustrationLayout";
import Spinner from "../custom-components/Spinner/Spinner";
import MDAlert from "../../components/MDAlert";

function ProviderLookup() {
  const navigate = useNavigate();
  const { pathname, hash, key, state } = useLocation();
  const { t } = useTranslation();
  const { tenantConfig, reloadTenantConfig, loadingTenantConfig, tenantConfigLoaded } = usePublicTenantConfig();
  const [tenantId, setTenantId] = useState(UtilsService.getSubdomain()
    || UtilsService.getLocalStorageTenant()
    || window.sessionStorage.getItem('tenantId') || "");
  const [rememberMe, setRememberMe] = useState(false);
  const [alertMessage, setAlertMessage] = useState();
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const handeSetFormValue = ({ currentTarget }) => setTenantId(currentTarget.value);

  useEffect(() => {
    if (!loadingTenantConfig && !tenantConfigLoaded) {
      const tenant = UtilsService.getSubdomain()
        || UtilsService.getLocalStorageTenant()
      || window.sessionStorage.getItem('tenantId');
      if (tenant){
        setTenantId(tenant);
      }
      // else {
      //   setTenantId("");
      // }
      // navigate("/provider-lookup");
    }
  }, []);

  useEffect(() => {
    console.log(JSON.stringify('ProviderLookup useEffect state : ... '), state);
    if (state && state.notification && state.notification==='alert'){
      setAlertMessage(state);
    }
  }, [state]);

  useEffect(() => {
    // Set the form values when the newly fetched Settings
    // come back from the backend.
    console.log(JSON.stringify('Updated tenantConfig : >>>>>>>>>>>>>>>>>> '));
    console.log(JSON.stringify(tenantConfig));
    if (tenantConfig.userPoolId) {
      navigate("/home");
      // redirect("admin/applications");
    }
  }, [tenantConfig]);

  async function lookupTenant() {
    if (tenantId) {
      window.sessionStorage.setItem('tenantId', tenantId);
      await reloadTenantConfig(tenantId, rememberMe);
    }
  }

  async function lookupTenantByName() {
    if (tenantId) {
      window.sessionStorage.setItem('tenantName', tenantId);
      await reloadTenantConfig(tenantId, rememberMe);
    }
  }

  return (
    <IllustrationLayout
      title="Provider Lookup"
      description="Enter your Tenant / Provider Id to sign in"
      illustration={bgImage}
    >
      {alertMessage && (
          <MDAlert color={alertMessage.type} dismissible>
            <MDTypography variant="body2" color="white">
              {alertMessage.message}
            </MDTypography>
          </MDAlert>
      )}
      {loadingTenantConfig && <Spinner label={t(`Loading ${tenantId} ...`)}/>}
      <MDBox component="form" role="form">
        <MDBox mb={2}>
          <MDInput type="text" label="Tenant / Provider ID" fullWidth
                   value ={tenantId}
                   onChange={handeSetFormValue}
          />
        </MDBox>
        <MDBox display="flex" alignItems="center" ml={-1}>
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
          >
            &nbsp;&nbsp;Remember Provider
          </MDTypography>
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton variant="gradient" color="info" size="large" fullWidth
                    disabled={!tenantId || loadingTenantConfig}
                    onClick={lookupTenant}>
            Lookup
          </MDButton>
        </MDBox>
        <MDBox mt={3} textAlign="center">
          <MDTypography variant="button" color="text">
            Not sure? Please contact your provider.
          </MDTypography>
        </MDBox>
        {/*<MDBox mt={3} textAlign="center">*/}
        {/*  <MDTypography variant="button" color="text">*/}
        {/*    Don&apos;t have an account?{" "}*/}
        {/*    <MDTypography*/}
        {/*      component={Link}*/}
        {/*      to="/authentication/sign-up/cover"*/}
        {/*      variant="button"*/}
        {/*      color="info"*/}
        {/*      fontWeight="medium"*/}
        {/*      textGradient*/}
        {/*    >*/}
        {/*      Sign up*/}
        {/*    </MDTypography>*/}
        {/*  </MDTypography>*/}
        {/*</MDBox>*/}
      </MDBox>


    </IllustrationLayout>
  );
}

export default ProviderLookup;
