import React, { useEffect, useState,useCallback } from "react";
import * as Yup from "yup";
import { Form, Formik, useFormik, FieldArray, ErrorMessage } from "formik";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import {Form as PrevForm} from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";


import { useTranslation } from "react-i18next";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { useChangeBackgroundColor, useDateTimeFormatter, useEForm, usePublicTenantConfig } from "hooks";
import GlobalService from "services/GlobalService";
import LinearLoading from "../../custom-components/LinearLoading";
import MDInput from "components/MDInput";
import { useMaterialUIController } from "context";
import AOTable from "views/custom-components/Table";
import { Application, ApplicationAuditLog } from "models";
import Link from "views/admin/application-setup/components/Link";
import UtilsService from "services/UtilsService";
import StatusCell from "../../custom-components/Table/StatusCell";
import DefaultCell from "layouts/ecommerce/orders/order-list/components/DefaultCell";
import IdCell from "../../custom-components/Table/IdCell";
import DashboardLayout from "LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import AdminHeader from "../../custom-components/AdminHeader";
import AppApiService from "../../../services/AppApiService";
import BackendService from "../../../services/BackendService";
import AuditTrailService from "../../../services/AuditTrailService";
import FormSelect from "../../custom-components/AOFormSelect";
import AOSelect from "../../custom-components/AOSelect";
import EFormService from "../../../services/EFormService";
import Spinner from "../../custom-components/Spinner/Spinner";
import ProviderConfigEdit from "../../custom-components/ProviderConfigEdit/ProviderConfigEdit";


interface PathParams {
  eformId: string;
}
interface FormValues {
  title: string;
  type: string;
  description: string;
  properties: {};
  required: string[];
  // topicAreaLabels: {};
}


function EFormEdit({ tenantDetails, onEditCancel, onEditUpdate }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const dateFormatter = useDateTimeFormatter();

  const { eformId } = useParams<PathParams>();
  const {eForm, loading} = useEForm(eformId);
  // const eForm = {
  //   "category": "EFORM",
  //   "config": {
  //     "boolean": {
  //       "radio": {
  //         "ui:widget": "radio"
  //       },
  //       "select": {
  //         "ui:widget": "select"
  //       }
  //     },
  //     "disabled": {
  //       "ui:disabled": true
  //     },
  //     "readonly": {
  //       "ui:readonly": true
  //     },
  //     "secret": {
  //       "ui:widget": "hidden"
  //     },
  //     "selectWidgetOptions": {
  //       "ui:options": {
  //         "backgroundColor": "purple"
  //       }
  //     },
  //     "string": {
  //       "color": {
  //         "ui:widget": "color"
  //       },
  //       "placeholder": {
  //         "ui:placeholder": "This is a placeholder"
  //       },
  //       "textarea": {
  //         "ui:options": {
  //           "rows": 5
  //         },
  //         "ui:widget": "textarea"
  //       }
  //     },
  //     "widgetOptions": {
  //       "ui:options": {
  //         "backgroundColor": "yellow"
  //       }
  //     }
  //   },
  //   "configId": "bbeced21-b5fc-482b-9923-a6e676e48aaf",
  //   "configScope": null,
  //   "content": null,
  //   "createdAt": "2024-03-20T21:19:16.483479",
  //   "createdBy": "t-admin-7xk190",
  //   "defaultVal": {
  //     "description": "Please provide the following info.",
  //     "properties": {
  //       "email": {
  //         "format": "email",
  //         "title": "E-Mail",
  //         "type": "string"
  //       },
  //       "firstName": {
  //         "title": "First name",
  //         "type": "string"
  //       },
  //       "lastName": {
  //         "title": "Last name",
  //         "type": "string"
  //       }
  //     },
  //     "required": [
  //       "firstName",
  //       "lastName"
  //     ],
  //     "title": "Client Info",
  //     "type": "object"
  //   },
  //   "description": "Copied from System",
  //   "key": "7xk190-5:bbeced21-b5fc-482b-9923-a6e676e48aaf",
  //   "label": null,
  //   "name": "Simple (Built-In) - Copy",
  //   "parent": null,
  //   "shardId": "7xk190-5",
  //   "updatedAt": "2024-03-20T21:19:16.483462",
  //   "updatedBy": "t-admin-7xk190"
  // };
  // const  loading = false;

  const [savingData, setSavingData] = useState(false);
  const [selectedTA, setSelectedTA] = useState({});
  const [alertMessage, setAlertMessage] = useState("");

  const [schema, setSchema] = useState({});
  const [uiSchema, setUISchema] = useState({});

  const [initValue, setInitValue] = useState({
      title: "EForm Title",
      description: "EForm Title",
      type: "Object",
    properties:
      [
            { field_name: "email", title: "E-Mail", type: "string", format: "email" }

      ],
  });

  // console.log(eForm);
  // console.log(initValue);

  const setupEFormData = useCallback(async () => {
      console.log("EForm loaded: >>>>>>>>>>>>> " + JSON.stringify(eForm));
      if (!eForm) return;
      const data=eForm;

      setUISchema(data.config);
      const fd = data.defaultVal;
      setSchema(fd);
      const fldArr =[];
      const fdFiels = fd.properties;

      Object.keys(fdFiels).forEach((columnName) => {
        const f = fdFiels[columnName];
        f.field_name=columnName;
        console.log(f);
        fldArr.push(f);
      });
      console.log(fldArr);


      setInitValue({
        ...fd,
        properties: fldArr
      });

    },
    [eForm]
  );

  useEffect(() => {

    // if (eForm && !loading) {
    //   console.log("EForm loaded: >>>>>>>>>>>>> " + JSON.stringify(eForm));
      setupEFormData();
      // const fetchData = async () => {
      //
      // };
      // fetchData();
    // }

  }, [eformId,eForm]);

  useEffect(() => {
    // console.log("EFormEdit  page: >>>>>>>>>>>>> " + JSON.stringify(tenantDetails));
    // console.log("loadingTenantConfig : >>>>>>>>>>>>> " + JSON.stringify(tenantConfig));
  }, []);


  const formik = useFormik({ initialValues: initValue });

  const form = {
    formId: "eForm-edit-form",
    formField: {
        title: {
          name: "title",
          label: t("EFormEdit.EFormTitle"),
          type: "text",
          errorMsg: "title is required.",
          placeholder: t("EFormEdit.EFormTitleHint")
        },
        description: {
          name: "description",
          label: t("EFormEdit.EFormDesc"),
          type: "text",
          errorMsg: "description is required.",
          placeholder: t("EFormEdit.EFormDescHint")
        },
      // type: {
      //   name: "type",
      //   label: t("EFormEdit.EFormType"),
      //   type: "text",
      //   errorMsg: "type is required.",
      //   placeholder: t("EFormEdit.EFormTypeHint")
      // }
      // ,
      properties: {
        name: "properties",
        field_name: {
          name: "field_name",
          label: t("EFormEdit.Field_Name"),
          type: "text",
          errorMsg: t("EFormEdit.Field_NameError"),
          placeholder: "Field_Name",
          invalidMsg: t("EFormEdit.Field_NameInvalid")
        },
        title: {
          name: "title",
          label: t("EFormEdit.Field_Title"),
          type: "text",
          errorMsg: t("EFormEdit.Field_TitleError"),
          placeholder: t("EFormEdit.Field_TitleHint"),
          invalidMsg: t("EFormEdit.Field_TitleInvalid")
        },
        type: {
          name: "type",
          label: t("EFormEdit.Field_Type"),
          type: "text",
          errorMsg: t("EFormEdit.Field_TypeError"),
          placeholder: t("EFormEdit.Field_TypeHint")
        },
        format: {
          name: "format",
          label: t("EFormEdit.Field_Format"),
          type: "text",
          errorMsg: t("EFormEdit.Field_FormatError"),
          placeholder: t("EFormEdit.Field_FormatHint")
        }
      }


    }
  };
  const {
    formId, formField: {
      description,
      title,
      type,
      properties,
      required
    }
  } = form;
  const validations = Yup.object().shape({
    [title.name]: Yup.string().required(title.errorMsg).min(6, title.invalidMsg),
    // [properties.title.name]: Yup.string().required(properties.title.errorMsg).min(2, properties.title.invalidMsg),
    properties: Yup.array()
      .of(Yup.object().shape({
        title: Yup.string().required(properties.title.errorMsg).min(4, properties.title.invalidMsg),
        field_name: Yup.string().required(properties.field_name.errorMsg).min(4, properties.field_name.invalidMsg),

      }))
    // [bizName.name]: Yup.string().required(bizName.errorMsg).min(6, bizName.invalidMsg)
    // [type.name]: Yup.string().required(type.errorMsg).min(6, type.invalidMsg),
    // [authRequired.name]: Yup.string().required(authRequired.errorMsg).oneOf(
    //   ["Public","Private","Protected"],
    //   t("EFormEdit.AuthenticationRequiredRequired")
    // ),
    // [tenantTier.name]: Yup.string().required(tenantTier.errorMsg).oneOf(
    //   ["Draft","Review","Published","Inactive"],
    //   t("EFormEdit.TenantTierRequired")
    // ),
    // [categoryId.name]: Yup.string().required(categoryId.errorMsg).min(3, categoryId.invalidMsg),
  });


  // if (!loading) {
  //   crumbs.push({
  //     label: t("EFormEdit.AddTenantDetails"),
  //     url: "",
  //   });
  // }

  function updateSchema(values){
    if (!values) return;
    let scmFlds = {};
    let orderedFlds = [];

    console.log('updateSchema - values ', values);

    values.properties.forEach((fld,idx)=>{
      const fldItem = structuredClone(fld);
      delete fldItem['field_name'];
      if (fldItem['format'] && fldItem['format']==='') delete fldItem['format'];
      orderedFlds.push(fld.field_name);
      scmFlds[fld.field_name]= fldItem;
      console.log(fld.field_name);
    });
    console.log(scmFlds);

    const updSchema= {
        ...values,
        properties:scmFlds
      };

 const updUISchema= {
        ...uiSchema,
        "ui:order":orderedFlds
      };

    console.log('updateSchema - updSchema ', updSchema);
    console.log('updateSchema - orderedFlds ', orderedFlds);
    console.log('updateSchema - orderedFlds ', updUISchema);

    setSchema(updSchema);
    setUISchema(updUISchema);
    console.log("fields ", JSON.stringify(scmFlds));
    return updSchema;
  }

  const onPreview = async (values: FormValues) => {
    console.log("preview", values);
    updateSchema(values);
  }
  const onSubmit = async (values: FormValues) => {
    console.log('onSubmit');
    try {
      const updSchema = updateSchema(values);
      setSavingData(true);


      if (eForm && eForm.configId && updSchema.properties) {
        const newval = {
          ...eForm,
          name: updSchema.title || '-',
          configScope: 'Public',
          defaultVal: updSchema,
          config: uiSchema
        };
        console.log("eForm Update: " + JSON.stringify(newval));
        // await GlobalService.saveTenantDetails(tenantDetails.tenantId, newval);
        await EFormService.editEForm(eForm.key, newval);
      }
      if (onEditUpdate) onEditUpdate();
      // onEditTenantDetailsUpdate();
    } catch (err) {
      console.log(t("AddEFormsFailure"), err);
      setAlertMessage(t("AddEFormsFailure") + ": " + err?.message);
      setSavingData(false);
    } finally {
      setSavingData(false);

    }
  };


  return (

    <DashboardLayout loading={loading}>
      <MDBox width="calc(100% - 48px)" position="absolute" top="1.75rem">
        <DashboardNavbar light absolute />
      </MDBox>
      <AdminHeader title= {eForm ? eForm?.name : "Edit E-Form"} icon="list"
                   description={eForm ? eForm?.description : "Add / Edit E-Form"}>
        {/*<MDButton variant="gradient" color="success" onClick={openEditModal}>*/}
        {/*  <Icon>add</Icon>&nbsp; Add New*/}
        {/*</MDButton>*/}
      </AdminHeader>
      <Grid container spacing={3}>
        {/*{eformId && eForm &&*/}
        {/*<Grid item xs={12} md={12} lg={12}>*/}
        {/*  <ProviderConfigEdit configId={eformId} tenantDetails={tenantDetails}/>*/}
        {/*</Grid>*/}
        {/*}*/}
        <Grid item xs={12} md={8} lg={6}>
    <Card id="categories" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">
          {t("EFormEdit.ScreenHeading", { tenant: tenantDetails?.tenantName })}
        </MDTypography>
      </MDBox>

      <MDBox pb={3} px={3}>
        <Formik enableReinitialize={true}
                initialValues={ initValue}
                validationSchema={validations}
                onSubmit={onSubmit}
                // onChange={onPreview}



        >
          {({ values, errors, touched, isSubmitting, setFieldValue, success, handleChange}) => (
            <Form id={formId} autoComplete="off"
            >
              <MDBox mt={0}>

                <Grid container spacing={3}>
                  {/*<Grid item xs={12}>*/}
                  {/*  <FormField*/}
                  {/*    type={description.type}*/}
                  {/*    label={description.label}*/}
                  {/*    name={description.name}*/}
                  {/*    value={values.description ? JSON.stringify( eForm.defaultVal ): ""}*/}
                  {/*    placeholder={"EFormLabelsLabel"}*/}
                  {/*    rows="20"*/}
                  {/*    multiline*/}
                  {/*    fullWidth*/}
                  {/*    error={errors.description && touched?.description}*/}
                  {/*    success={values.description && values.description.length > 5 && !errors.description}*/}
                  {/*    // onChange={(e) => {*/}
                  {/*    //   console.log(e.target.value);*/}
                  {/*    //   setFieldValue("topicAreaLabels", {*/}
                  {/*    //     ...values.topicAreaLabels,*/}
                  {/*    //     description: e.target.value*/}
                  {/*    //   });*/}
                  {/*    // }}*/}
                  {/*  />*/}
                  {/*</Grid>*/}

                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          type={title.type}
                          label={title.label}
                          name={title.name}
                          value={values.title ? values.title : ""}
                          placeholder={"Form Title"}
                          // rows="4"
                          // multiline
                          fullWidth
                          error={ errors.title && touched?.title}
                          success={ values.title && values.title.length > 5 &&  ( !errors.title)}
                          // onChange={(e) => {
                          //   console.log(e.target.value);
                          //   setFieldValue("topicAreaLabels", {
                          //     ...values.topicAreaLabels,
                          //     title: e.target.value
                          //   });
                          // }}
                        />

                      </Grid>
                      <Grid item xs={12} sm={12}>

                        <FormField
                          type={description.type}
                          label={description.label}
                          name={description.name}
                          value={values.description ? values.description : ""}
                          placeholder={"EFormLabelsLabel"}
                          rows="2"
                          multiline
                          fullWidth
                          error={errors.description && touched?.description}
                          success={values.description && values.description.length > 5 && !errors.description}
                          // onChange={(e) => {
                          //   console.log(e.target.value);
                          //   setFieldValue("topicAreaLabels", {
                          //     ...values.topicAreaLabels,
                          //     description: e.target.value
                          //   });
                          // }}
                        />

                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>

                    <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      {t("EFormEdit.Fields")}
                    </MDTypography>

                  </Grid>


                  <Grid item xs={12}>

                    <MDBox>

                      <FieldArray name="properties">
                        {({ insert, remove, push ,move }) => (
                          <MDBox mx={0}>
                            {
                              values.properties.length>0 &&
                              values.properties.map((fld, index) => (
                                <MDBox bgColor={darkMode ? "grey-900" : "grey-100"}
                                       borderRadius="lg"
                                       display="flex"
                                       justifyContent="space-between"
                                       alignItems={{ xs: "flex-start", sm: "center" }}
                                       flexDirection={{ xs: "column", sm: "row" }}
                                       my={1}
                                       py={1}
                                       pl={{ xs: 1, sm: 2 }}
                                       pr={1}>
                                  {/*{`properties.${index}.title`}*/}
                                  <MDBox width={{ xs: "100%", sm: "5%", md: "5%" }} mt={{ xs: 1, sm: 0 }}>
                                    {index}
                                  </MDBox>
                                  <MDBox width={{ xs: "100%", sm: "5%", md: "5%" }} mt={{ xs: 1, sm: 0 }} mr={2}>
                                    {index!==0 &&
                                    <MDButton iconOnly circular
                                              type="button" size="small"
                                              variant="contained"
                                      // color="error"
                                              onClick={() => move(index,index-1)}
                                    >
                                      <Icon sx={{ fontWeight: "bold" }}>keyboard_arrow_up</Icon>
                                    </MDButton>}
                                    {index!==values.properties.length-1 &&
                                    <MDButton iconOnly circular
                                              type="button" size="small"
                                              variant="contained"
                                      // color="error"
                                              onClick={() => move(index, index+1)}
                                    >
                                      <Icon sx={{ fontWeight: "bold" }}>keyboard_arrow_down</Icon>
                                    </MDButton>
                                    }
                                  </MDBox>
                                  <MDBox width={{ xs: "100%", sm: "30%", md: "30%" }} mt={{ xs: 1, sm: 0 }}>
                                    {/*<label htmlFor={`properties.${index}.title`}>Title</label>*/}
                                    <FormField
                                      name={`properties.${index}.title`}
                                      label={properties.title.label}
                                      type={properties.title.type}
                                      placeholder={properties.title.placeholder}
                                      // fullWidth
                                      // error={ errors?.properties.title && touched?.properties?.title}
                                      // type="text"
                                    />
                                    {/*<ErrorMessage*/}
                                    {/*  name={`properties.${index}.title`}*/}
                                    {/*  component="div"*/}
                                    {/*  className="field-error"*/}
                                    {/*/>*/}
                                  </MDBox>
                                  <MDBox width={{ xs: "100%", sm: "30%", md: "30%" }} mt={{ xs: 1, sm: 0 }}>
                                    {/*<label htmlFor={`properties.${index}.field_name`}>Field_Name</label>*/}
                                    <FormField
                                      name={`properties.${index}.field_name`}
                                      label={properties.field_name.label}
                                      placeholder="jane@acme.com"

                                      // type="text"
                                    />
                                    {/*<ErrorMessage*/}
                                    {/*  name={`properties.${index}.field_name`}*/}
                                    {/*  component="div"*/}
                                    {/*  className="field-error"*/}
                                    {/*/>*/}
                                  </MDBox>
                                  <MDBox width={{ xs: "100%", sm: "20%", md: "20%" }} mt={{ xs: 1, sm: 0 }}>


                                    {/*<FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} fullWidth>*/}
                                    {/*  /!*<InputLabel id="demo-simple-select-label">Age</InputLabel>*!/*/}
                                    {/*  <Select*/}
                                    {/*    name={`properties.${index}.type`}*/}
                                    {/*    // labelId="demo-simple-select-label"*/}
                                    {/*    // id="demo-simple-select"*/}
                                    {/*    value={values.properties[index].type}*/}
                                    {/*    placeholder="string"*/}
                                    {/*    label={properties.type.label}*/}
                                    {/*    onChange={handleChange}*/}
                                    {/*  >*/}
                                    {/*    <MenuItem value={'string'}>String</MenuItem>*/}
                                    {/*    <MenuItem value={'number'}>Number (Decimal)</MenuItem>*/}
                                    {/*    <MenuItem value={'integer'}>Integer (Whole)</MenuItem>*/}
                                    {/*    <MenuItem value={'boolean'}>Y/N</MenuItem>*/}
                                    {/*  </Select>*/}
                                    {/*</FormControl>*/}
                                    <FormSelect
                                      name={`properties.${index}.type`}
                                      label={properties.type.label}
                                      placeholder="string"
                                      optionList={[
                                        {name:'String',value:'string'},
                                        {name:'Number',value:'number'},
                                        {name:'Integer',value:'integer'},
                                        {name:'Yes / No',value:'boolean'},
                                      ]
                                      }

                                    />
                                  </MDBox>
                                  <MDBox width={{ xs: "100%", sm: "10%", md: "10%" }} mt={{ xs: 1, sm: 0 }}>

                                    {/*<FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} fullWidth>*/}
                                    {/*  /!*<InputLabel id="demo-simple-select-label">Age</InputLabel>*!/*/}
                                    {/*  <Select*/}
                                    {/*    name={`properties.${index}.format`}*/}
                                    {/*    // labelId="demo-simple-select-label"*/}
                                    {/*    // id="demo-simple-select"*/}
                                    {/*    value={values.properties[index].format}*/}
                                    {/*    placeholder=""*/}
                                    {/*    label={properties.format.label}*/}
                                    {/*    onChange={handleChange}*/}
                                    {/*  >*/}
                                    {/*    <MenuItem value={''}>None</MenuItem>*/}
                                    {/*    <MenuItem value={'email'}>Email</MenuItem>*/}
                                    {/*    <MenuItem value={'uri'}>URI</MenuItem>*/}
                                    {/*  </Select>*/}
                                    {/*</FormControl>*/}


                                      <FormSelect
                                        name={`properties.${index}.format`}
                                        label={properties.format.label}
                                        placeholder=""
                                        value={values.properties[index].format || ''}
                                        optionList={[
                                        {name:'None',value:''},
                                        {name:'Email',value:'email'},
                                        {name:'URI',value:'uri'},
                                          ]
                                      }

                                        />

                                  </MDBox>
                                  <MDBox width={{ xs: "100%", sm: "10%", md: "10%" }} mt={{ xs: 1, sm: 0 }} ml={2}
                                         display="flex" justifyContent="space-between"
                                         alignItems={{ xs: "flex-end", sm: "center" }}  >
                                    <MDButton iconOnly circular
                                      type="button" size="small"
                                              variant="contained"
                                              color="error"
                                      onClick={() => remove(index)}
                                    >
                                      <Icon sx={{ fontWeight: "bold" }}>close</Icon>
                                    </MDButton>
                                    {/*<MDBox ml={1}>*/}
                                      <MDButton iconOnly circular
                                                type="button" size="small"
                                                variant="contained"
                                        color="success"
                                                onClick={() => insert(index,{ title: "", field_name: "fld",type: "string", format: "" })}
                                      >
                                        <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                                      </MDButton>
                                    {/*</MDBox>*/}
                                  </MDBox>
                                </MDBox>
                              ))}

                            <MDBox pt={0} ml="auto" justifyContent="right" display="flex"
                                   alignItems={{ xs: "flex-start", sm: "center" }}
                                   flexDirection={{ xs: "column", sm: "row" }}>
                              <MDButton
                                type="button"
                                variant="outlined"
                                color="error"
                                size="small"
                                onClick={() => push({ title: "", field_name: "",type: "string", format: "" })}
                              >
                                Add Field
                              </MDButton>

                              {/*<AOSelect*/}
                              {/*  optionList={[*/}
                              {/*      {name:'None',value:''},*/}
                              {/*      {name:'Email',value:'email'},*/}
                              {/*      {name:'URI',value:'uri'},*/}
                              {/*    ]}*/}
                              {/*>*/}
                              {/*</AOSelect>*/}
                            </MDBox>
                          </MDBox>
                        )}
                      </FieldArray>


                      {/*<MDTypography variant="button" fontWeight="medium" color="text">*/}
                      {/*  Add {values.topicAreaLabels.title}*/}
                      {/*</MDTypography>*/}

                      {/*<MDBox width={{ xs: "100%", sm: "25%", md: "15%" }} mt={{ xs: 1, sm: 0 }}>*/}
                      {/*  <Tooltip title="Copy" placement="top">*/}
                      {/*    <MDInput size="small" value="1172913" />*/}
                      {/*  </Tooltip>*/}
                      {/*</MDBox>*/}
                      {/*<MDBox width={{ xs: "100%", sm: "25%", md: "15%" }} mt={{ xs: 1, sm: 0 }}>*/}
                      {/*  <Tooltip title="Copy" placement="top">*/}
                      {/*    <MDInput size="small" value="1172913" />*/}
                      {/*  </Tooltip>*/}

                      {/*</MDBox>*/}
                      {/*<MDBox width={{ xs: "100%", sm: "25%", md: "15%" }} mt={{ xs: 1, sm: 0 }}>*/}

                      {/*  <Tooltip title="Copy" placement="top">*/}
                      {/*    <MDButton size="small" value="1172913" />*/}
                      {/*  </Tooltip>*/}
                      {/*</MDBox>*/}
                    </MDBox>
                    {/*<FormField*/}
                    {/*  type={properties.topicAreaName.type}*/}
                    {/*  label={properties.topicAreaName.label}*/}
                    {/*  name={properties.name}*/}
                    {/*  value={values.properties ? values.properties?.topicAreaName : ""}*/}
                    {/*  placeholder={"EFormName"}*/}
                    {/*  // rows="4"*/}
                    {/*  // multiline*/}
                    {/*  fullWidth*/}
                    {/*  error={errors.properties && errors.properties?.topicAreaName && touched?.properties?.topicAreaName}*/}
                    {/*  success={values.properties.topicAreaName && values.properties.topicAreaName.length > 5 && !errors?.properties?.topicAreaName}*/}
                    {/*  onChange={(e) => {*/}
                    {/*    console.log(e.target.value);*/}
                    {/*    setFieldValue("properties", {*/}
                    {/*      ...values.properties,*/}
                    {/*      topicAreaName: e.target.value*/}
                    {/*    });*/}
                    {/*  }}*/}
                    {/*/>*/}
                  </Grid>


                  {/*<Grid item xs={12}>*/}
                  {/*  <AOTable table={getTableData(values.topicAreas)} entriesPerPage={false}  />*/}
                  {/*</Grid>*/}
                  <Grid item xs={12}>

                    {alertMessage && (
                      <MDAlert color="error" dismissible>
                        <MDTypography variant="body2" color="white">
                          {t("EFormEdit.SaveError")}
                        </MDTypography>
                      </MDAlert>
                    )}

                    <MDBox ml="auto" justifyContent="space-between" display="flex"
                           alignItems={{ xs: "flex-end", sm: "center" }}
                           flexDirection={{ xs: "column", sm: "row" }}>

                      <MDButton
                        disabled={isSubmitting}
                        type="submit"
                        variant="gradient"
                        color="primary" size="large"
                      >
                        {eForm && eForm.configId ? "Update" : "Create"}
                      </MDButton>
                      <MDButton
                        disabled={isSubmitting}
                        type="button"
                        variant="outlined"
                        color="success" size="large"
                        onClick={()=>onPreview(values)}
                      >
                        Refresh Preview
                      </MDButton>
                      {savingData && <LinearLoading />}
                    </MDBox>

                    {/*<MDTypography variant="caption">*/}
                    {/*  {JSON.stringify(values)}*/}
                    {/*</MDTypography>*/}
                  </Grid>
                </Grid>
                {/*{JSON.stringify(values)}*/}
              </MDBox>
            </Form>
          )}
        </Formik>


      </MDBox>

      {/*<MDTypography variant="caption" color="text">*/}
      {/*  /!*Updated: {dateFormatter(dataObj.updatedAt)}*!/*/}
      {/*  {JSON.stringify(eForm?.config,2)}*/}
      {/*</MDTypography>*/}

    </Card>
        </Grid>
        <Grid item xs={12} md={4} lg={6}>
          <MDBox m={2} p={2}>
            {eForm ?
            <PrevForm uiSchema={uiSchema}
                  schema={schema}
                  validator={validator}
            />
              :
              <Spinner/>
            }
          </MDBox>
        </Grid>
      </Grid>

      {/*<MDTypography variant="caption" color="text">*/}
      {/*  /!*Updated: {dateFormatter(dataObj.updatedAt)}*!/*/}
      {/*  {JSON.stringify(eForm)}*/}
      {/*</MDTypography>*/}
    </DashboardLayout>
  );
}

export default EFormEdit;
