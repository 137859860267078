import React, { useState } from "react";

import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import DialogContent from "@mui/material/DialogContent";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import CircularProgress from '@mui/material/CircularProgress';

import DashboardLayout from "LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import dataTableData from "layouts/ecommerce/orders/order-list/data/dataTableData";
import { useUsers } from "../../../hooks";
import { Application, LocationState, User } from "models";
import { useNavigate } from "react-router-dom";
import IdCell from "./components/IdCell";
import DefaultCell from "./components/DefaultCell";
import StatusCell from "./components/StatusCell";
import SysAdminHeader from "../../custom-components/SysAdminHeader";
import TenantDetailsEdit from "../provider-settings/global-settings/TenantDetailsEdit";
import CustomDialog from "../../custom-components/CustomDialog";
import TenantUserAddEdit from "./components/TenantUserAddEdit";
import UserCell from "./components/UserCell";


function UserList() {
  const navigate = useNavigate();

  const [menu, setMenu] = useState(null);

  const { users, reloadUsers, setUsers } = useUsers();
  const [filter, setFilter] = useState("");
  const [selected, setSelected] = useState<User>();
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);


  const getUsersTableData = () => {
    return {
      columns: [
        { Header: "Username", accessor: "user_name", Cell: ({ row }) => {
            const usr = row.original as User;
            return <UserCell image={usr.picture} color={"dark"} userData={usr} />
          }},
        // { Header: "Username", accessor: "user_name", Cell: ({ value }) => <IdCell id={value} /> },
        // { Header: "family name", accessor: "family_name", Cell: ({ value }) => <DefaultCell value={value} /> },
        // { Header: "given name", accessor: "given_name", Cell: ({ value }) => <DefaultCell value={value} /> },
        { Header: "Email", accessor: "email", Cell: ({ value }) => <DefaultCell value={value} /> },
        {
          Header: "status",
          accessor: "status",
          Cell: ({ value }) => {
            let status;
            if (value === "CONFIRMED") {
              status = <StatusCell icon="done" color="success" status="Confirmed" />;
            } else if (value === "refunded") {
              status = <StatusCell icon="replay" color="dark" status="Refunded" />;
            } else {
              status = <StatusCell icon="close" color="error" status={value} />;
            }
            return status;
          },
        },
        {
          Header: "Role",
          accessor: "user_role",
          Cell: ({ value }) => {
            let status;
            if (value === "TenantAdmin") {
              status = <StatusCell icon="done" color="success" status="Admin" />;
            } else if (value === "refunded") {
              status = <StatusCell icon="replay" color="dark" status="Refunded" />;
            } else {
              status = <StatusCell icon="close" color="error" status={value} />;
            }
            return status;
          },
        },
        {
          Header: "Created",
          accessor: "created",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        // {
        //   Header: "customer",
        //   accessor: "given_name",
        //   Cell: ({ value: [name, data] }) => (
        //     <CustomerCell image={data.image} color={data.color || "dark"} name={name} />
        //   ),
        // },
        // {
        //   Header: "product",
        //   accessor: "product",
        //   Cell: ({ value }) => {
        //     const [name, data] = value;
        //
        //     return (
        //       <DefaultCell
        //         value={typeof value === "string" ? value : name}
        //         suffix={data.suffix || false}
        //       />
        //     );
        //   },
        // },
        // { Header: "revenue", accessor: "given_name", Cell: ({ value }) => <DefaultCell value={value} /> },
      ],

      rows: users
      // rows: [
      //   {
      //     id: "#10421",
      //     date: "1 Nov, 10:20 AM",
      //     status: "paid",
      //     customer: ["Orlando Imieto", { image: team2 }],
      //     product: "Nike Sport V2",
      //     revenue: "$140,20",
      //   },
      //
      // ],
    }
  }


  // const getUserRows = () => {
  //   users.map(usr => {})
  // }
  const addUsers = () => {
    navigate("/admin/users/add");
  };

  const changeRole = () => {
    // navigate("/admin/users/changerole", {
    //   emails: selected.map((s) => s.email).join(", "),
    //   usernames: selected.map((s) => s.userId),
    // });
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={closeMenu}>Status: Paid</MenuItem>
      <MenuItem onClick={closeMenu}>Status: Refunded</MenuItem>
      <MenuItem onClick={closeMenu}>Status: Canceled</MenuItem>
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <MDTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </MDTypography>
      </MenuItem>
    </Menu>
  );
  const openEditModal = () => {
    setIsOpenEditModal(true);
  };

  const closeEditModal = async () => {
    setIsOpenEditModal(false);
    // await fetchData();
    // reloadTenantConfig();
  };
  return (
    <DashboardLayout>
      <MDBox width="calc(100% - 48px)" position="absolute" top="1.75rem">
        <DashboardNavbar light absolute />
      </MDBox>
      <SysAdminHeader title="Users" icon="people"
                      description="Edit Platform-wide Users" />
      {/*{JSON.stringify(users)}*/}

      <CustomDialog onClose={closeEditModal} open={isOpenEditModal} fullWidth={true} maxWidth={"sm"}
                    aria-labelledby="customized-dialog-title">
        <DialogContent>
          <TenantUserAddEdit tenantUserData={selected} onCancel={closeEditModal} onUpdate={closeEditModal} />

        </DialogContent>
      </CustomDialog>

      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDButton variant="gradient" color="info"
          onClick={openEditModal}>
            new user
          </MDButton>
          <MDBox display="flex">
            <MDButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
              filters&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            {renderMenu}
            <MDBox ml={1}>
              <MDButton variant="outlined" color="dark">
                <Icon>description</Icon>
                &nbsp;export csv
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>

        {/*<TenantUserAddEdit tenantUserData={selected} onCancel={closeEditModal} onUpdate={closeEditModal} />*/}

        <Card>
          {users ? <DataTable table={getUsersTableData()} entriesPerPage={false} canSearch />
            :
            <MDBox sx={{ display: 'flex' }}>
              <CircularProgress />
            </MDBox>
          }
        </Card>
      </MDBox>

    </DashboardLayout>
  );
}

export default UserList;
