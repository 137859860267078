/**
 All of the routes for the page layout of Material Dashboard 2 PRO React are added here,
 You can add a new route, customize the routes and delete the routes here.

 Once you add a new route on this file it will be visible automatically on
 the DefaultNavbar.

 For adding a new route you can follow the existing routes in the routes array.
 1. The `type` key with the `collapse` value is used for a route.
 2. The `name` key is used for the name of the route on the DefaultNavbar.
 3. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
 4. The `icon` key is used for the icon of the route on the DefaultNavbar, you have to add a node.
 5. The `collapse` key is used for making a collapsible item on the DefaultNavbar that contains other routes inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
 6. The `route` key is used to store the route location which is used for the react router.
 7. The `href` key is used to store the external links location.
 */

import Icon from "@mui/material/Icon";

const consumerRoutes = [
  {
    name: "Home",
    icon: <Icon>home</Icon>,
    route: "/home"
  },
  {
    name: "Dashboard",
    icon: <Icon>dashboard</Icon>,
    route: "/app/dashboard"
  },
  // {
  //   name: "Login",
  //   icon: <Icon>user</Icon>,
  //   route: "/app/auth/consumer-login",
  // },
  {
    name: "Messages",
    icon: <Icon>message</Icon>,
    route: "/app/messages",
  },

  // {
  //   name: "Help",
  //   icon: <Icon>help</Icon>,
  //   collapse: [
  //     {
  //       name: "getting started",
  //       href: "https://www.consen.co/learning-lab/react/quick-start/material-dashboard/",
  //       description: "All about overview, quick start, license and contents",
  //       icon: <Icon>article</Icon>
  //     },
  //     {
  //       name: "foundation",
  //       href: "https://www.consen.co/learning-lab/react/colors/material-dashboard/",
  //       description: "See our colors, icons and typography",
  //       icon: <Icon>grading</Icon>
  //     },
  //     {
  //       name: "components",
  //       href: "https://www.consen.co/learning-lab/react/alerts/material-dashboard/",
  //       description: "Explore our collection of fully designed components",
  //       icon: <Icon>apps</Icon>
  //     },
  //     {
  //       name: "plugins",
  //       href: "https://www.consen.co/learning-lab/react/datepicker/material-dashboard/",
  //       description: "Check how you can integrate our plugins",
  //       icon: <Icon>extension</Icon>
  //     }
  //   ]
  // },
  // {
  //   name: "Account",
  //   icon: <Icon>account_outlined</Icon>,
  //   collapse: [
  //     {
  //       name: "Profile",
  //       icon: <Icon>profile</Icon>,
  //       route: "/app/my-profile"
  //     },
  //     {
  //       name: "Messages",
  //       icon: <Icon>message</Icon>,
  //       route: "/app/my-messages"
  //     },
  //     {
  //       name: "Logout",
  //       icon: <Icon>logout</Icon>,
  //       route: "/app/logout"
  //     }
  //   ]
  //
  //
  // }
];

export default consumerRoutes;
