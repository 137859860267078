import { useContext, useEffect } from "react";
import { TenantConfig, PublicTenantConfig } from "../models";
import { TenantConfigContext } from "../context/TenantConfigProvider";

// type UseTenantConfigHook = {
//   tenantConfig: TenantConfig;
//   reloadTenantConfig: Function;
//   loadingTenantConfig: boolean;
// };

// export function useTenantConfig(refetch?: boolean): UseTenantConfigHook {
//
//   const { tenantConfig, reloadTenantConfig, loadingTenantConfig } =
//     useContext(TenantConfigContext);
//
//   useEffect(() => {
//     if (refetch) {
//       reloadTenantConfig();
//     }
//   }, [refetch, reloadTenantConfig]);
//
//   return {
//     tenantConfig: tenantConfig as TenantConfig,
//     reloadTenantConfig,
//     loadingTenantConfig,
//   };
// }

type UsePublicTenantConfigHook = {
  tenantConfig: PublicTenantConfig;
  reloadTenantConfig: Function;
  loadingTenantConfig: boolean;
  tenantConfigLoaded: boolean;
};

export function usePublicTenantConfig(refetch?: boolean): UsePublicTenantConfigHook {
  const { tenantConfig, reloadTenantConfig, loadingTenantConfig,tenantConfigLoaded } =
    useContext(TenantConfigContext);

  useEffect(() => {
    if (refetch) {
      reloadTenantConfig();
    }
  }, [refetch, reloadTenantConfig]);

  return {
    tenantConfig: tenantConfig as PublicTenantConfig,
    reloadTenantConfig,
    loadingTenantConfig,
    tenantConfigLoaded,
  };
}
