import React, {useEffect, useState, useCallback } from "react";
import { Handle, Position, useStore } from 'reactflow';

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DefaultInfoNodeCard from "./DefaultInfoNodeCard";
import MDInput from "../../../../components/MDInput";
import { useApplications } from "../../../../hooks/application-hooks";
import Autocomplete from "@mui/material/Autocomplete";
import useApplicationStore from "../../../../store/application-store";
import { scopeOptions } from "../../../../static-data/dropdownData";

const handleStyle = { left: 10 };

const connectionNodeIdSelector = (state) => state.connectionNodeId;
function TnCNode({ id, data, isConnectable, selected }) {

  const initApplStore = useApplicationStore.use.initStore();
  const loadingAppl = useApplicationStore.use.loading();
  const getApplOptions = useApplicationStore.use.getOptionList();

  // The node id the connection coming from while drawing and edge
  const connectionNodeId = useStore(connectionNodeIdSelector);


  const [applOptions, setApplOptions] = useState([{name: "None", key:"NONE"}]);
  // const [selectedAppl, setSelectedAppl] = useState("None");
  const [selectedApplObj, setSelectedApplObj] = useState(data.value? data.value : {name: "None", key:"NONE"});

  useEffect(() => {
    initApplStore();
    getApplOptions().then(opts=>{
      // console.log("options set : " + JSON.stringify(opts));
      setApplOptions( opts);
      setSelectedApplObj(data.value);
    });
    }, [initApplStore]);
  // useEffect(() => {
  //   console.log("options  : " + loadingAppl);
  //   // if (loadingAppl) return;
  //   getApplOptions().then(opts=>{
  //     console.log("options set : " + JSON.stringify(opts));
  //     setApplOptions( opts);
  //   })
  //
  // }, [loadingAppl]);

  // useEffect(() => {
  // console.log("data val: " + typeof data.value);
  //   if (typeof data.value == "object")     setSelectedApplObj(data.value);
  // }, []);


  // useEffect(() => {
  //   console.log("loadingAppl: " + loadingAppl);
  //   console.log("draftsApplications: " + JSON.stringify(draftsApplications));
  //   // setInitValue(offeringData);
  //   if (!loadingAppl && draftsApplications){
  //     const apOpts =[{name: "No Default", key:"NO_DEF"}];
  //     // draftsApplications.filter(app=> app.scope!==undefined)
  //     //   .map(ap=>apOpts.push({name: ap.name, key:ap.key}))
  //     const nv = draftsApplications.filter(app=> app.publishedBy!==undefined);
  //       const nv2= nv.map(ap=>apOpts.push({name: ap.name, key:ap.key}))
  //     setApplOptions(apOpts);
  //   }
  //
  // }, [loadingAppl]);

  const onChange = useCallback((evt) => {
    console.log(evt.target.value);
  }, []);

  const handleApplChange = (evt,value) => {
    console.log("handleApplChange val " + JSON.stringify( value));
    // setSelectedAppl(value);
    setSelectedApplObj(value);
    data.value = value;
    // data.description = value.name;
  };

  return (
    <MDBox
      alignItems="center"
      width="12rem"
      height="10rem"
      variant="gradient"
      bgColor={"white"}
      // color="primary"
      sx={({ palette: { light }, typography: { size }, borders: { borderWidth } }) => ({
        border: selected ? `${borderWidth[1]} solid ${light.main}` : "none",
      })}
      shadow="md"
      borderRadius="md"
      mx={2}
      // mt={-2}
      py={2}
    >
      <Handle id="c" type="target"
              position={Position.Left} isConnectable={isConnectable}  />

      <MDBox
        width="100%"
             mr={{ xs: 0, sm: 3 }} mb={{ xs: 3, sm: 0 }}
             // sx={{
             //   background: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
             //     `${linearGradient(
             //       rgba(gradients.light.main, 1.0),
             //       rgba(gradients.light.state, 1.0)
             //     )}`,
             //   backgroundSize: "cover",
             //   backgroundPosition: "50%",
             //   overflow: "hidden",
             // }}

      >
        <DefaultInfoNodeCard
          nodeData={data}
          color={data.color || "warning"}
                             icon={data.icon || "info"}
                             title={data.label}
                             description={"Default"}
          value={!selected && data.value ? data.value.name : "" }
          // value={selectedAppl.name }
        />
        {/*<MDTypography variant="h6" fontWeight="medium">*/}
        {/*{JSON.stringify(data.value)}*/}
        {/*</MDTypography>*/}

        <MDBox mx={2}>
          {selected && applOptions && applOptions.length>0 &&
<>
            <Autocomplete
              // disablePortal
              // autoHighlight
              // defaultValue="None"
              // name="app"
              value={selectedApplObj}
              options={applOptions}
              // getOptionLabel={(option) => option.name || "(empty)"}
              getOptionLabel={(option: Object | string) => option["name"] || option}

              onChange={(e, value) => {
                // setSelectedAppl(value);
                console.log("onChange == > " + JSON.stringify(value));
                setSelectedApplObj(value);
                data.value = value;
                // setFieldValue("scope",
                //   value ? value.id : "Other"
                // );
              }}

                isOptionEqualToValue={(option, value) =>  {
                  console.log("Op == > " + JSON.stringify(option));
                  console.log("VL == > " + JSON.stringify(value));
                  // console.log("name CMP == > ", option.name === value.name);
                  // console.log("key CMP == > ", option.key === value.key);
                  return option.key === value.key;
                }}
              renderInput={(params) =>
                <MDInput {...params} variant="standard"
                         label={"Default Application To Use"}
                         // hint={`${t("SelectExistingLeading")} ${scope.label}`}
                         // error={errors.scope && touched.scope}
                         // success={values.scope.length > 0 && !errors.scope}
                  // required
                />}
            />



</>
          }

        </MDBox>

      </MDBox>
      {/*<Handle*/}
      {/*  type="source"*/}
      {/*  position={Position.Left}*/}
      {/*  id="a"*/}
      {/*  style={handleStyle}*/}
      {/*  isConnectable={isConnectable}*/}
      {/*/>*/}
      <Handle type="source" position={Position.Right} id="b" isConnectable={isConnectable} />
    </MDBox>
  );
}

export default TnCNode;
