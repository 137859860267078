import React, { lazy, Suspense } from "react";
import {
  Widget,
  WidgetType,
  ChartWidget,
  TableWidget,
  ImageWidget,
  MetricsWidget, SubmissionParticipant, Application
} from "models";
import { useWidgetDataset, useImage } from "hooks";
// import ChartWidgetComponent from "./item-renderer/ChartWidget";
// import TableWidgetComponent from "./item-renderer/TableWidget";
import TextWidget from "./item-renderer/TextWidget";
import SectionWidget from "./item-renderer/SectionWidget";
import ImageWidgetComponent from "./item-renderer/ImageWidget";
// import MetricsWidgetComponent from "./item-renderer/MetricsWidget";
import Shareable from "./Shareable";
import { isPropertySignature } from "typescript";
import MDBox from "../../../../components/MDBox";
import { useSubmissionContextHook } from "../../../../hooks/submission-hooks";
// import MFileWidget from "./item-renderer/MFileWidget";
// import EFormWidget from "./item-renderer/EFormWidget";
const EFormWidget = lazy(() => import("./item-renderer/EFormWidget"));
const MFileWidget = lazy(() => import("./item-renderer/MFileWidget"));

interface Props {
  widget: Widget;
  // submissionPCP: SubmissionParticipant;
  showMobilePreview?: boolean;
  hideTitle?: boolean;
  // widgets?: Array<Widget>;
  application: Application;
  setActiveWidgetId?: Function;
  topOffset?: string;
  bottomOffset?: string;
  defaultActive?: string;
  disableShare?: boolean;
  isReviewing?: boolean;
}

function WidgetRender({
                        widget,
                        // submissionPCP,
                        showMobilePreview,
                        // widgets,
                        application,
                        hideTitle,
                        setActiveWidgetId,
                        bottomOffset,
                        topOffset,
                        defaultActive,
                        disableShare,
                        isReviewing
                      }: Props) {

  const {
    loadingSubmission,
    submissionRows,
    submissionPCP,
    summary,
    submissionMain,
    submissionLoaded,
    reloadSubmission
  } = useSubmissionContextHook();
  function getWidget() {
    switch (widget.widgetType) {
      case WidgetType.Text:
        return <TextWidget widget={widget} hideTitle={hideTitle} />;
      case WidgetType.EForm:
        return <Suspense fallback={<div>Loading... </div>}>
          <EFormWidget application={application} widget={widget} hideTitle={hideTitle}
                       // submissionPCP={submissionPCP}
          />
        </Suspense>
          ;
      case WidgetType.MFile:
        return <Suspense fallback={<div>Loading... </div>}>
          <MFileWidget application={application} widget={widget} hideTitle={hideTitle}
                       // submissionPCP={submissionPCP}
          />
        </Suspense>;
      // case WidgetType.Chart:
      //   return (
      //     <ChartWidgetComponent
      //       widget={widget as ChartWidget}
      //       showMobilePreview={showMobilePreview}
      //       hideTitle={hideTitle}
      //     />
      //   );
      // case WidgetType.Table:
      // case WidgetType.Metrics:
      //   return (
      //     <WidgetWithDataset
      //       widget={widget}
      //       showMobilePreview={showMobilePreview}
      //       hideTitle={hideTitle}
      //     />
      //   );
      case WidgetType.Image:
        return (
          <WidgetWithImage
            widget={widget as ImageWidget}
            hideTitle={hideTitle}
          />
        );
      case WidgetType.Section:
        return (
          <SectionWidget
            application={application}
            widget={widget}
            showMobilePreview={showMobilePreview}
            widgets={application.widgets}
            setActiveWidgetId={setActiveWidgetId}
            bottomOffset={bottomOffset}
            topOffset={topOffset}
            defaultActive={defaultActive}
          />
        );
      default:
        return null;
    }
  }

  if (!!disableShare) {
    return getWidget();
  }

  // return (
  //   <Shareable id={widget.id} title={widget.name}>
  //     {getWidget()}
  //   </Shareable>
  // );
  return (
    <MDBox id={widget.id}>
      {getWidget()}
    </MDBox>
  );
}

function WidgetWithImage({ widget, hideTitle }: Props) {
  const imageWidget = widget as ImageWidget;
  const content = imageWidget.content;
  const file = useImage(content.s3Key.raw);

  return (
    <ImageWidgetComponent
      title={!hideTitle && imageWidget.showTitle ? content.title : ""}
      summary={content.summary ? content.summary : ""}
      summaryBelow={content.summaryBelow}
      file={file.file}
      altText={content.imageAltText}
      scalePct={content.scalePct ? content.scalePct : "auto"}
    />
  );
}

function WidgetWithDataset({ widget, showMobilePreview, hideTitle }: Props) {
  const { json } = useWidgetDataset(widget);
  switch (widget.widgetType) {
    // case WidgetType.Table:
    //   const tableWidget = widget as TableWidget;
    //   return (
    //     <TableWidgetComponent
    //       title={
    //         !hideTitle && tableWidget.showTitle ? tableWidget.content.title : ""
    //       }
    //       summary={tableWidget.content.summary}
    //       data={json}
    //       summaryBelow={tableWidget.content.summaryBelow}
    //       columnsMetadata={tableWidget.content.columnsMetadata}
    //       sortByColumn={tableWidget.content.sortByColumn}
    //       sortByDesc={tableWidget.content.sortByDesc}
    //       significantDigitLabels={tableWidget.content.significantDigitLabels}
    //       displayWithPages={tableWidget.content.displayWithPages}
    //       showMobilePreview={showMobilePreview}
    //     />
    //   );
    // case WidgetType.Metrics:
    //   const metricsWidget = widget as MetricsWidget;
    //   return (
    //     <MetricsWidgetComponent
    //       title={
    //         !hideTitle && metricsWidget.showTitle
    //           ? metricsWidget.content.title
    //           : ""
    //       }
    //       metrics={json}
    //       metricPerRow={
    //         metricsWidget.content.oneMetricPerRow ||
    //         window.innerWidth < 640 ||
    //         showMobilePreview
    //           ? 1
    //           : 3
    //       }
    //       significantDigitLabels={metricsWidget.content.significantDigitLabels}
    //       metricsCenterAlign={metricsWidget.content.metricsCenterAlign}
    //     />
    //   );
    default:
      return null;
  }
}

export default WidgetRender;
