import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// import Link from "../components/Link";
import { useTranslation } from "react-i18next";
// import { useApplication } from "hooks";
import { Application, ApplicationState, Widget, WidgetType } from "../../../models";
import WidgetRender from "./components/WidgetRender";
// import Button from "../components/Button";
// import Alert from "../components/Alert";
// import Breadcrumbs from "../components/Breadcrumbs";
// import Modal from "../components/Modal";
// import Spinner from "../components/Spinner";
// import ApplicationHeader from "../components/ApplicationHeader";
// import PrimaryActionBar from "../components/PrimaryActionBar";
import "./ViewDashboardAdmin.css";
// import Navigation from "../components/Navigation";
import { Waypoint } from "react-waypoint";
// import Dropdown from "../components/Dropdown";
// import AlertContainer from "./AlertContainer";
// import DropdownMenu from "../components/DropdownMenu";
// import PublishApplicationModal from "../components/PublishApplicationModal";
import Spinner from "../Spinner/Spinner";
import useConsumerSingleSubmissionStore from "../../../store/consumer-single-submission-store";
import { useSubmissionContextHook } from "../../../hooks/submission-hooks";

interface PathParams {
  applicationId: string;
  renderMode?: string;
}

// interface Props {
//
// }

// const { MenuItem } = DropdownMenu;

function RenderConsumerApplication(props: PathParams) {
  const navigate = useNavigate();
  const { applicationId  } = useParams<PathParams>();
  const { renderMode  } = props;
  // const { application, loading ,reloadApplication} = useApplication(applicationId || props.applicationId);
  const isDesignMode = renderMode === 'ADMIN_DESIGN';
  //
  const [applicationKey, setApplicationKey] = useState(applicationId || props.applicationId);
  const getFullApplication = useConsumerSingleSubmissionStore.use.getFullApplication();
  const loading = useConsumerSingleSubmissionStore.use.loading();
  const [application, setApplication] = useState();

  // const { versions } = useApplicationVersions(application?.parentApplicationId);
  // const [isOpenUpdateModal, setIsOpenUpdateModal] = useState(false);
  // const [isOpenArchiveModal, setIsOpenArchiveModal] = useState(false);
  // const [isOpenRepublishModal, setIsOpenRepublishModal] = useState(false);
  // const [isOpenPublishModal, setIsOpenPublishModal] = useState(false);
  const [showVersionNotes, setShowVersionNotes] = useState(false);
  const [showMobilePreview, setShowMobilePreview] = useState(false);
  const [activeWidgetId, setActiveWidgetId] = useState("");
  const {
    submissionId,
    loadingSubmission,
    submissionRows,
    submissionPCP,
    summary,
    submissionMain,
    submissionLoaded,
    reloadSubmission,
    setEditMode
  } = useSubmissionContextHook();
  const [activeTabId, setActiveTabId] = useState("");

  // const [isOpenCopyModal, setIsOpenCopyModal] = useState(false);

  // const draftOrPublishPending = versions.find(
  //   (v) =>
  //     v.state === ApplicationState.Draft ||
  //     v.state === ApplicationState.PublishPending
  // );

  // const windowSize = useWindowSize();

  const { t } = useTranslation();

  const mobilePreviewWidth = 400;
  const maxMobileViewportWidth = 450;
  const moveNavBarWidth = 1280;
  // const isMobile = windowSize.width <= 600;
  const isMobile = false;

  // const onClosePreview = () => {
  //   navigate(UtilsService.getApplicationUrlPath(application));
  // };

  const [published, setPublished] = useState(false);
  const applicationPublished = async () => {
    setPublished(true);
  };

  useEffect(() => {
    console.log("RenderConsumerApplication .. useEffect.. getFullApplication", applicationKey);
    if (!applicationKey) {
      console.log("??????? Missing applicationKey - RenderConsumerApplication .. useEffect.. ");
      return;
    }
    getFullApplication(applicationKey,  isDesignMode ).then(app => {
      setApplication(app);
      //if (!submissionId) setEditMode(true);
    });
  }, [getFullApplication]);

  useEffect(() => {
    // console.log(JSON.stringify(application));
  }, [application]);

  //
  // const onUpdateApplication = async () => {
  //
  //   try {
  //     const draft = await BackendService.createDraft(applicationId);
  //
  //     navigate(`/admin/application/edit/${draft.id}`, {
  //       alert: {
  //         type: "success",
  //         message: t("NewDraftApplicationCreated", {
  //           applicationName: draft.name,
  //         }),
  //       },
  //       id: "top-alert",
  //     });
  //   } catch (err) {
  //     console.log("Failed to create draft", err);
  //   }
  // };
  //
  // const onArchiveApplication = async () => {
  //   setIsOpenArchiveModal(false);
  //
  //   if (!application) {
  //     return;
  //   }
  //
  //   await BackendService.archive(application.id, application.updatedAt);
  //
  //   navigate("/admin/applications?tab=archived", {
  //     alert: {
  //       type: "success",
  //       message: `${application.name} ${t("ApplicationWasArchived")}`,
  //     },
  //   });
  // };
  //
  // const onApplicationHistory = () => {
  //   if (application) {
  //     navigate(`/admin/application/${application.id}/history`);
  //   }
  // };

  // const closeCopyModal = () => {
  //   setIsOpenCopyModal(false);
  // };
  //
  // const onCopyApplication = () => {
  //   setIsOpenCopyModal(true);
  // };

  // const copyApplication = async () => {
  //   closeCopyModal();
  //
  //   if (application) {
  //     await BackendService.copyApplication(application.id);
  //
  //     navigate("/admin/applications?tab=draft", {
  //       alert: {
  //         type: "success",
  //         message: `${application.name}
  //                   ${t("RenderConsumerApplication.Application")}
  //                   ${t("RenderConsumerApplication.SuccessfullyCopied")}`,
  //       },
  //     });
  //   }
  // };
  //
  // const onArchivePublishedApplication = () => {
  //   setIsOpenArchiveModal(true);
  // };
  //
  // const onRepublishApplication = async () => {
  //   setIsOpenRepublishModal(false);
  //   if (application) {
  //     await BackendService.publishApplication(
  //       application.id,
  //       application.updatedAt,
  //       application.releaseNotes || ""
  //     );
  //     navigate(`/admin/applications?tab=published`, {
  //       alert: {
  //         type: "success",
  //         message: `${application.name} ${t("ApplicationWasRepublished")}`,
  //         to: `/${applicationId}`,
  //         linkLabel: t("ViewPublishedApplication"),
  //       },
  //     });
  //   }
  // };

  // const handleVersionChange = (event: React.FormEvent<HTMLInputElement>) => {
  //   const target = event.target as HTMLInputElement;
  //   const version = versions.find((v) => String(v.version) === target.value);
  //   if (version) {
  //     navigate(`/admin/application/${version.id}`);
  //   }
  // };

  const getSectionWithTabs = (widget: Widget, application: Application): string => {
    const section: Widget | undefined = application.widgets.find(
      (w) => w.id == widget.section
    );
    if (section) {
      return section.content.showWithTabs ? section.id : "";
    }
    return "";
  };

  const onClickHandler = (active: string) => {
    setActiveTabId(active);
    setActiveWidgetId(active);
  };

  const onBottomOfThePage = (bottom: string) => {
    const widget = application?.widgets.find((w: Widget) => w.id === bottom);
    if (widget) {
      if (widget.section) {
        const parent = application?.widgets.find(
          (w: Widget) => w.id === widget.section
        );
        if (parent) {
          setActiveWidgetId(parent.id);
        }
      } else {
        setActiveWidgetId(bottom);
      }
    }
  };

  const applicationListUrl = (application: Application) => {
    switch (application.state) {
      case ApplicationState.Published:
        return "/admin/applications?tab=published";
      case ApplicationState.Archived:
        return "/admin/applications?tab=archived";
      case ApplicationState.PublishPending:
        return "/admin/applications?tab=pending";
      default:
        return "/admin/applications";
    }
  };

  // if (loading || !application || !versions || !versions.length) {
  if (loading || !application) {

    return (
      <MDBox>
        {/*{applicationId} <br />*/}
        {/*props: {props.applicationId}*/}
        {applicationKey ?
        <Spinner minHeight={500} absoluteTop={"40%"}
        label={"Loading Application Content ..."}
        />
        :
          <MDTypography variant="body2" color="secondary">
          (No Application Attached)
          </MDTypography>
        }
      </MDBox>

    );
  }



  return (
    <>

      <div
        style={
          showMobilePreview
            ? {
              maxWidth: `${mobilePreviewWidth}px`
            }
            : {}
        }
        className={showMobilePreview ? "grid-container" : ""}
      >
        {loading ? (
          <Spinner minHeight={300} absoluteTop={"40%"}
                   label={t("LoadingSpinnerLabel")}
          />
        ) : (
          <>

            {application.widgets
              .filter((w) => !w.section)
              .map((widget, index) => {
                return (
                  <MDBox key={index}

                  >
                    {widget.widgetType === WidgetType.Section &&
                    !widget.content.showWithTabs ? (
                      <MDBox id={widget.id} my={2} py={2}>
                        <WidgetRender
                          widget={widget}
                          // submissionPCP={submissionPCP}
                          application={application}
                          showMobilePreview={showMobilePreview}
                          // widgets={application.widgets}
                          setActiveWidgetId={setActiveWidgetId}
                          topOffset="240px"
                          // bottomOffset={`${windowSize.height - 250}px`}
                          defaultActive={activeTabId}
                        />
                      </MDBox>
                    ) : (
                      <Waypoint
                        onEnter={() => {
                          setActiveWidgetId(widget.id);
                        }}
                        topOffset="240px"
                        // bottomOffset={`${windowSize.height - 250}px`}
                        fireOnRapidScroll={false}
                      >
                        <MDBox id={widget.id} my={2} >
                          <WidgetRender
                            widget={widget}
                            // submissionPCP={submissionPCP}
                            application={application}
                            showMobilePreview={showMobilePreview}
                            // widgets={application.widgets}
                            setActiveWidgetId={setActiveWidgetId}
                            topOffset="240px"
                            // bottomOffset={`${windowSize.height - 250}px`}
                            defaultActive={activeTabId}
                          />
                        </MDBox>
                      </Waypoint>
                    )}
                  </MDBox>
                );
              })}
          </>
        )}
      </div>

      {/*{applicationId}*/}
      {/*<br/>*/}
      {/*{JSON.stringify(application)}*/}

    </>
  );
}

export default RenderConsumerApplication;
