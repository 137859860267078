import React,{ useEffect, useState } from "react";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";

// Images
import burceMars from "assets/images/cn-logo-full.svg";
import GlobalService from "../../../../../services/GlobalService";


function Header({tenantDetails, tenantLoading, openEditModal}) {
  const [visible, setVisible] = useState(true);
  // const [ tenantDetails, setTenantDetails ] = useState<any>({});
  // const [tenantLoading, setTenantLoading] = useState<boolean>(false);
  const handleSetVisible = () => setVisible(!visible);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setTenantLoading(true);
  //     const data = await GlobalService.fetchTenantDetails('t4');
  //     console.log("fetchTenantDetails : ----------  " + JSON.stringify(data));
  //
  //     setTenantDetails(data.message);
  //     setTenantLoading(false);
  //   };
  //   fetchData();
  // }, []);

  return (
    <Card id="profile">
      <MDBox p={2}>

        {tenantLoading ?
          <Grid container spacing={3} alignItems="center" >
            <Grid item>
              <MDAvatar src={burceMars} alt="profile-image" size="xl" shadow="sm" />
            </Grid>
            <Grid item>
            <MDBox sx={{ display: 'flex' }} >
              <CircularProgress />
              {/*<LinearProgress />*/}
              {/*{JSON.stringify(tenantDetails)}*/}
            </MDBox>
            </Grid>
          </Grid>
          :
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar src={burceMars} alt="profile-image" size="xl" shadow="sm" />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {tenantDetails.bizName || "Business Name"}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="medium">
                {tenantDetails.tenantName} [ Identifier: {tenantDetails.tenantId} ]
              </MDTypography>
            </MDBox>
          </Grid>

          <Grid item xs={12} md={6} lg={3} sx={{ ml: "auto" }}>
            <MDBox
              display="flex"
              justifyContent={{ md: "flex-end" }}
              alignItems="center"
              lineHeight={1}
            >
              <MDButton variant="outlined" color="dark" size="small"
              onClick={()=>openEditModal()}>
                Edit
              </MDButton>
              {/*<MDTypography variant="caption" fontWeight="regular">*/}
              {/*  Switch to {visible ? "staging" : "live"}*/}
              {/*</MDTypography>*/}
              {/*<MDBox ml={1}>*/}
              {/*  <Switch checked={visible} onChange={handleSetVisible} />*/}
              {/*</MDBox>*/}
            </MDBox>
          </Grid>
          {/*<Grid item xs={12} >*/}
          {/*  <MDBox height="100%" mt={0.5} lineHeight={1}>*/}
          {/*    {tenantDetails.tenantContacts && tenantDetails.tenantContacts.address &&*/}
          {/*      <MDTypography variant="button" color="text" fontWeight="medium">*/}
          {/*        {tenantDetails.tenantContacts.address || 'Address not available'}*/}
          {/*      </MDTypography>*/}
          {/*    }*/}
          {/*    <MDTypography variant="caption" color="text" >*/}
          {/*      {JSON.stringify(tenantDetails)}*/}
          {/*    </MDTypography>*/}
          {/*  </MDBox>*/}
          {/*</Grid>*/}
        </Grid>
        }
      </MDBox>
    </Card>
  );
}

export default Header;
