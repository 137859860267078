import { create } from "zustand";
import ProviderConfigService from "../services/ProviderConfigService";
import createSelectors from "./selectors";
import WorkflowService from "../services/WorkflowService";

interface WorkflowStore {
  workflowConfigs: {};
  workflows: any[];
  loading: boolean;
  saving: boolean;
  initialized: boolean;
  initStore: () => Promise<void>;
  getOptionList: () => Promise<any[]>;
  getByKey: (key:string) => any;
  updateList: (newData: any) => void;
  save: (newData: any) => Promise<void>;
}

const useWorkflowStoreBase = create<WorkflowStore>()((set, get) => ({
  // const defaultVals = await WorkflowService.fetchWorkflows();
  workflowConfigs: {  },
  workflows: [],
  loading: false,
  saving: false,
  initialized: false,
  getOptionList: async () => {
    //console.log("..... Store : getOptionList <<<<<<<<<< workflow");
    let wfs = get().workflows;
    // //console.log("..... Store : getOptionList >> ALL applications " + apps.length);
    let options = [{name: "None", key:"NONE"}];
    set({ loading: true });
    if (!wfs || (wfs && wfs.length == 0)){
      await get().initStore();
      wfs = get().workflows;
    }
    // //console.log("..... Store : getOptionList +++++ apps ++ length" + apps.length);
    wfs.reduce((accumArr, thisObj) => {
      if (thisObj.name && thisObj.name!=="" && thisObj.state==='Published') {
        accumArr.push({ name: thisObj.name, key: thisObj.key });
      }
      // //console.log("..... Store : getOptionList +++++++ length" + accumAppArr.length);
      return accumArr;
    }, options);

    set({ loading: false });
    return options;
  },
  getByKey: (key:string) => {
    const currList = get().workflows;
    if (!currList || currList.length===0 || !key){
      //console.log("..... Store : getByKey <<<<<<<<<< None", key);
      //console.log("..... Store : currList ", currList);
      return;
    }

    return currList.find(obj => key === obj.key);
  },
  updateList: (newData, sortCol?:string) => {
    set({ loading: true });
    const currList = get().workflows;
    let wfs:any[] = [];
    if (!currList || currList.length===0){
      //console.log("..... Store : updateList <<<<<<<<<< add");
      wfs.push(newData);
    }else{
      //console.log("..... Store : updateList <<<<<<<<<< replace");
      const idx = currList.findIndex(o => {
        //console.log("..... Store : updateList <<<<<<<<<< findIndex o " + JSON.stringify(o.key));
        //console.log("..... Store : updateList <<<<<<<<<< findIndex newData " + JSON.stringify(newData.key));
        return o.key === newData.key
      });

      if (idx < 0){
        currList.push(newData);
        wfs = currList;
      }else {
        //console.log("..... Store : updateList <<<<<<<<<< replace " + idx);
        wfs = currList.map(obj => newData.key === obj.key ? newData : obj);
      }
      //console.log("..... Store : updateList <<<<<<<<<< findIndex o " + JSON.stringify(wfs));
      // wfs = [...currList.slice(0, idx), newData, ...currList.slice(idx, currList.length)];
    }
    wfs.sort((a, b) =>      a.updatedAt > b.updatedAt ? -1 : 1    );
    set({ workflows: wfs, loading: false });
    //arr1.map(obj => arr2.find(o => o.id === obj.id) || obj);
  },
  save: async (newData) => {
    set({ saving: true });
    let apiResponse;
    try {


      //console.log("..... Store : save <<<<<<<<<< workflows");
      if (newData.key) {
        //console.log("..... Store : save <<<<<<<<<< get existing workflow key" + newData.key);
        const existingData = await WorkflowService.fetchWorkflowById(newData.key);
        if (!existingData || !existingData.key) {
          console.error("..... Store : save existing data not found!");
          return;
        }
        const newval = {
          ...existingData,
          ...newData
        };
        //console.log("..... Store : save <<<<<<<<<< new val key" + newval.key);
        apiResponse = await WorkflowService.editWorkflow(existingData.key, newval);
        //console.log("..... Store : save <<<<<<<<<< response ", apiResponse);
      } else {
        apiResponse = await WorkflowService.createWorkflow(newData);
      }
    }
    catch (e) {
      //console.log(e);
    }
    finally {
      set({ saving: false });
    }

    if (apiResponse) get().updateList(apiResponse);
  },
  initStore: async () => {
    if (get().initialized && get().workflows.length > 0) return;
    //console.log("..... Store : initStore <<<<<<<<<< workflows");
    set({ loading: true });
    const wfConfigs = await ProviderConfigService.fetchProviderConfigsSelect("category", "WORKFLOW");
    let wfs = await WorkflowService.fetchWorkflows();
    // sort by recent first
     wfs.sort((a, b) =>
      a.updatedAt > b.updatedAt ? -1 : 1
    );
    set({ workflowConfigs: wfConfigs, workflows: wfs, loading: false, initialized:true });
    // if (key && key.trim()!==""){
    //   return wfs.find(obj => key === obj.key);
    // }
  }
}));

const useWorkflowStore = createSelectors(useWorkflowStoreBase);
export default useWorkflowStore;