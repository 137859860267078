import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import CircularProgress from "@mui/material/CircularProgress";
import DataTable from "examples/Tables/DataTable";

import Tooltip from "@mui/material/Tooltip";
// Data
import { useEForms } from "hooks";
import { Application, EForm } from "models";
import IdCell from "layouts/ecommerce/orders/order-list/components/IdCell";
import DefaultCell from "layouts/ecommerce/orders/order-list/components/DefaultCell";
import { Submission } from "models";


function SubmissionsTableView({submissions}) {
  const navigate = useNavigate();

  const [menu, setMenu] = useState(null);

  const [filter, setFilter] = useState("");
  const [selected, setSelected] = useState<Array<Submission>>([]);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);


  const getSubmissionsTableData = () => {
    return {
      columns: [
        {
          Header: "Name",
          accessor: "name",
          Cell: ({ row }) => {
            const submission = row.original as Submission;
            return (
              <Tooltip title={submission.submissionId} placement="top">
                <MDTypography
                  component={Link}
                  to={`/admin/submission/${submission.submissionId}/review-submission`}
                  variant="h6"
                  color="info"
                  fontWeight="bold"
                >
                  {submission.name ? submission.name : "(empty)"}
                  {/*{JSON.stringify( eForm)}*/}
                </MDTypography>
              </Tooltip>
            );
          }
        },
        // { Header: "id", accessor: "key", Cell: ({ value }) => <IdCell id={value} /> },
        { Header: "State", accessor: "state", Cell: ({ value }) => <DefaultCell value={value} /> },
        { Header: "Participant", accessor: "participant", Cell: ({ value }) => <DefaultCell value={value} /> },

        {
          Header: "Agent",
          accessor: "agent",
          Cell: ({ value }) => <DefaultCell value={value} />
        },
        {
          Header: "Updated",
          accessor: "updatedAt",
          Cell: ({ value }) => <DefaultCell value={value} />
        },
        {
          Header: "Created",
          accessor: "createdAt",
          Cell: ({ value }) => <DefaultCell value={value} />
        }

        // { Header: "revenue", accessor: "given_name", Cell: ({ value }) => <DefaultCell value={value} /> },
      ],

      rows: submissions

    };
  };


  // const getEFormRows = () => {
  //   users.map(usr => {})
  // }
  const addSubmissions = () => {
    navigate("/admin/users/add");
  };

  const changeRole = () => {
    // navigate("/admin/users/changerole", {
    //   emails: selected.map((s) => s.email).join(", "),
    //   usernames: selected.map((s) => s.userId),
    // });
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={closeMenu}>Status: Paid</MenuItem>
      <MenuItem onClick={closeMenu}>Status: Refunded</MenuItem>
      <MenuItem onClick={closeMenu}>Status: Canceled</MenuItem>
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <MDTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  return (


    <MDBox mx={4} >
      {/*<MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>*/}

      {/*  <MDBox display="flex">*/}
      {/*    <MDButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>*/}
      {/*      filters&nbsp;*/}
      {/*      <Icon>keyboard_arrow_down</Icon>*/}
      {/*    </MDButton>*/}
      {/*    {renderMenu}*/}
      {/*    <MDBox ml={1}>*/}
      {/*      <MDButton variant="outlined" color="dark">*/}
      {/*        <Icon>description</Icon>*/}
      {/*        &nbsp;export csv*/}
      {/*      </MDButton>*/}
      {/*    </MDBox>*/}
      {/*  </MDBox>*/}
      {/*</MDBox>*/}
      <MDBox>
        {submissions ? <DataTable table={getSubmissionsTableData()} entriesPerPage={false} canSearch />
          :
          <MDBox sx={{ display: "flex" }} py={4} px={4}>
            <CircularProgress />
          </MDBox>
        }
      </MDBox>
    </MDBox>


  );
}

export default SubmissionsTableView;
