import React, { useEffect } from "react";

// prop-types is library for typechecking of props

import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React base styles
// @mui icons
import { basicWFEdges, basicWFNodes, basicWFSteps } from "../../../workflow/wf-data/basic-workflow-01";
import { defaultWFEdges, defaultWFNodes, defaultWFSteps } from "../../../workflow/wf-data/default-workflow-01";
import WorkflowDisplayCard from "../WorkflowDisplayCard";
import MDButton from "components/MDButton";

function SystemWFList({ copyForUseFunc }) {

  const wfTypes = [
    { name: "Basic", steps: basicWFSteps, nodes: basicWFNodes, edges: basicWFEdges },
    { name: "Default", steps: defaultWFSteps, nodes: defaultWFNodes, edges: defaultWFEdges }
  ];


  useEffect(() => {

  }, []);

  // const renderItems = wfTypes.map((wf) => (
  //   <MDBox key={wf.name} display="flex" py={1} pr={2}>
  //     {/*{JSON.stringify(wf)}*/}
  //     {wf.steps.map((step)=>(
  //       <>
  //       <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
  //         {step.sourceType}: &nbsp;
  //       </MDTypography>
  //       <MDTypography variant="button" fontWeight="regular" color="text">
  //       &nbsp;{step.seq}
  //   </MDTypography>
  //       </>
  //     ))}
  //
  //   </MDBox>
  // ));

  const renderItems1 = wfTypes.map((wf) => (
    // <MDBox key={wf.name} display="flex" py={1} pr={2}>

    <Grid item xs={12} md={6} key={wf.name}>
      <Card sx={{ height: "100%" }}>
        <MDBox pt={3} px={3}>
          <MDTypography variant="h6" fontWeight="medium">
            {wf.name} Workflow
          </MDTypography>
          <MDBox mt={0} mb={2}>
            <MDTypography variant="button" color="text" fontWeight="regular">
              <MDTypography display="inline" variant="body2" verticalAlign="middle">
                <Icon sx={{ color: ({ palette: { success } }) => success.main }}>arrow_upward</Icon>
              </MDTypography>
              &nbsp;
              <MDTypography variant="button" color="text" fontWeight="medium">
                {wf.steps.length} Steps
              </MDTypography>{" "}

            </MDTypography>
          </MDBox>
        </MDBox>
        <WorkflowDisplayCard name="Default Workflow"
                             initialNodes={wf.nodes}
                             initialEdges={wf.edges} />
        <MDBox p={2}>
          {wf.steps.map((step) => (
            <MDTypography variant="button" color="text" fontWeight="regular" key={step.source}>
              {step.sourceType} &nbsp;
            </MDTypography>

          ))}
        </MDBox>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap" p={2}>
          <MDTypography variant="button" color="text" fontWeight="regular">
            Use This Workflow
          </MDTypography>
          {/*<MDBox component="caption" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>*/}
          {/*  Use This Workflow*/}
          {/*</MDBox>*/}
          <MDBox ml="auto">
            <MDButton variant="gradient" color="warning" size="small"
                      onClick={() => copyForUseFunc(wf.name, wf.nodes, wf.edges, wf.steps)}>
              Copy For Me
            </MDButton>
          </MDBox>
        </MDBox>
      </Card>
    </Grid>

    // </MDBox>
  ));

  return (
    <MDBox p={3}>
      <Grid container spacing={3}>
        {renderItems1}
      </Grid>


    </MDBox>
  );
}

export default SystemWFList;