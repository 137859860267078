import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Form, Formik, useFormik } from "formik";
import { useNavigate } from "react-router-dom";


import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";


import { useTranslation } from "react-i18next";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { useChangeBackgroundColor, usePublicTenantConfig } from "hooks";
import GlobalService from "../../../../services/GlobalService";
import LinearLoading from "../../../custom-components/LinearLoading";


interface FormValues {
  tenantName: string;
  bizName?: string;
  tenantTier?: string;
  contactEmail?: string;
  // topicAreaLabel?: string;
  // topicAreasLabel?: string;
  branding?: {};
  pubConfigs?: {
    slogan: string,
    homepageMessageTitle: string,
    homepageMessage: string,
    navbarTitle: string,
    homepageTitle: string,
    contactUsMessage: string
  };
  // topicAreas: {};
  frontendDomain?: string;
  cognitoDomain?: string;
  samlProvider?: string;
  enterpriseLoginLabel?: string;
  authRequired: boolean;
}


function TenantDetailsEdit({ tenantDetails, onEditCancel, onEditUpdate }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [savingData, setSavingData] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const [initValue, setInitValue] = useState({
    tenantName: tenantDetails.tenantName,
    pubConfigs: {
      slogan: "",
      dateTimeFormat: { "date": "YYYY-MM-DD", "time": "HH:mm" },
      homepageTitle: "Welcome to our company",
      homepageMessageTitle: "We are a reputed company serving thousands of clients with utmost efficiency and respect.",
      homepageMessage: "We are a reputed company serving thousands of clients with utmost efficiency and respect.",
      navbarTitle: "Consen Navigation",
      contactUsMessage: "Please, collect all the relevant information of your issue and contact us using the following email address:",
      ...tenantDetails?.pubConfigs
    },
    // topicAreas: {
    //   singular: "Offering",
    //   plural: "Offerings",
    //   ...tenantDetails?.topicAreas
    // },
    bizName: tenantDetails?.bizName || "",
    bizDomain: tenantDetails?.bizDomain || "",
    authRequired: tenantDetails?.authRequired || false,
    branding: {
      colors: { "primary": "#005EA2", "secondary": "#54278f" },
      logo: { "s3_keys": [] },
      ...tenantDetails?.branding
    }
  });

  // branding?: {};
  // pubConfigs?: {};
  // frontendDomain?: string;
  // authRequired: boolean;

  useEffect(() => {
    console.log("tenantDetails Edit page: >>>>>>>>>>>>> " + JSON.stringify(tenantDetails));
    // console.log("loadingTenantConfig : >>>>>>>>>>>>> " + JSON.stringify(tenantConfig));
  }, []);

  // useEffect(() => {
  //   // console.log("loadingTenantConfig : >>>>>>>>>>>>> " + JSON.stringify(loadingTenantConfig));
  //   console.log("tenantDetails tenantConfig : >>>>>>>>>>>>> " + JSON.stringify(tenantConfig));
  // }, [loading]);


  //  self.shardId = shardId
  //         self.providerConfigId = providerConfigId
  //         self.key = shardId + ':' + providerConfigId
  //         self.parent = parent
  //         self.name = name
  //         self.defaultVal = defaultVal
  //         self.creator = creator
  //         self.pubConfigs = pubConfigs
  //         self.config = config
  //         self.category = category
  //         self.configAuthRequired = configAuthRequired

  // const formik = useFormik({ initialValues: initValue });

  const form = {
    formId: "tenantDetails-edit-form",
    formField: {
      tenantName: {
        name: "tenantName",
        label: t("TenantDetailsEdit.TenantName"),
        type: "text",
        errorMsg: "tenantName is required.",
        invalidMsg: "tenantName is invalid.",
        placeholder: t("TenantDetailsEdit.TenantNameHint")
      },
      authRequired: {
        name: "authRequired",
        label: "authRequired",
        type: "text",
        errorMsg: "authRequired is required.",
        placeholder: t("TenantDetailsEdit.AuthenticationRequiredHint")
      },
      tenantTier: {
        name: "tenantTier",
        label: "tenantTier",
        type: "text",
        errorMsg: "tenantTier is required."
      },
      bizName: {
        label: t("TenantDetailsEdit.BizName"),
        name: "bizName",
        type: "text",
        placeholder: t("TenantDetailsEdit.BizNameHint")
      },
      branding: {
        name: "Branding"

      },
      bizDomain: {
        label: t("TenantDetailsEdit.BizDomain"),
        name: "bizDomain",
        type: "text",
        errorMsg: "bizDomain is required.",
        placeholder: t("TenantDetailsEdit.BizDomainHint")
      },

      pubConfigs: {
        name: "pubConfigs",
        homepageTitle: {
          name: "homepageTitle",
          label: t("TenantDetailsEdit.homepageTitle"),
          type: "text",
          placeholder: "Welcome to our company"
        },
        slogan: {
          name: "slogan",
          label: t("TenantDetailsEdit.Slogan"),
          type: "text",
          placeholder: "Make world a better place"
        },
        homepageMessageTitle: {
          name: "homepageMessageTitle",
          label: t("TenantDetailsEdit.homepageMessageTitle"),
          type: "text",
          placeholder: "Main message on the homepage"
        },
        homepageMessage: {
          name: "homepageMessage",
          label: t("TenantDetailsEdit.homepageMessage"),
          type: "text",
          placeholder: "Main message on the homepage"
        },
        contactUsMessage: {
          name: "contactUsMessage",
          label: t("TenantDetailsEdit.contactUsMessage"),
          type: "text",
          placeholder: "Message on the homepage to contact"
        }
      }
      // topicAreas: {
      //   name: "topicAreas",
      //   singular: {
      //     name: "singular",
      //     label: t("TenantDetailsEdit.TopicAreaSingular"),
      //     type: "text",
      //     errorMsg: "singular is required.",
      //     placeholder: t("TenantDetailsEdit.TopicAreaSingularHint")
      //   },
      //   plural: {
      //     name: "plural",
      //     label: t("TenantDetailsEdit.TopicAreaPlural"),
      //     type: "text",
      //     errorMsg: "plural is required.",
      //     placeholder: t("TenantDetailsEdit.TopicAreaPluralHint")
      //   }
      //
      // }

    }
  };
  const {
    formId, formField: {
      tenantName,
      bizName, bizDomain,
      // topicAreas, branding,
      pubConfigs
    }
  } = form;
  const validations = Yup.object().shape({
    // [tenantName.name]: Yup.string().required(tenantName.errorMsg).min(6, tenantName.invalidMsg),
    [bizName.name]: Yup.string().required(bizName.errorMsg).min(6, bizName.invalidMsg)
    // [contactUsMessage.name]: Yup.string().required(contactUsMessage.errorMsg).min(6, contactUsMessage.invalidMsg),
    // [plural.name]: Yup.string().required(plural.errorMsg).min(6, plural.invalidMsg),
    // [authRequired.name]: Yup.string().required(authRequired.errorMsg).oneOf(
    //   ["Public","Private","Protected"],
    //   t("TenantDetailsEdit.AuthenticationRequiredRequired")
    // ),
    // [tenantTier.name]: Yup.string().required(tenantTier.errorMsg).oneOf(
    //   ["Draft","Review","Published","Inactive"],
    //   t("TenantDetailsEdit.TenantTierRequired")
    // ),
    // [categoryId.name]: Yup.string().required(categoryId.errorMsg).min(3, categoryId.invalidMsg),
  });


  useChangeBackgroundColor();

  // if (!loading) {
  //   crumbs.push({
  //     label: t("TenantDetailsEdit.AddTenantDetails"),
  //     url: "",
  //   });
  // }
  const onSubmit = async (values: FormValues) => {
    try {
      setSavingData(true);
      console.log(JSON.stringify(values));
      if (tenantDetails.tenantId && tenantDetails.tenantId.length > 0) {
        const newval = {
          ...tenantDetails,
          ...values
        };
        await GlobalService.saveTenantDetails(tenantDetails.tenantId, newval);
      }
      if (onEditUpdate) onEditUpdate();
      // onEditTenantDetailsUpdate();
    } catch (err) {
      console.log(t("AddPubConfigsFailure"), err);
      setAlertMessage(t("AddPubConfigsFailure") + ": " + err?.message);
      setSavingData(false);
    } finally {
      setSavingData(false);

    }
  };


  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">
          {t("TenantDetailsEdit.TitleLabel", { tenant: tenantDetails.tenantName })}
        </MDTypography>
      </MDBox>

      <MDBox pb={3} px={3}>
        <Formik enableReinitialize={true}
                initialValues={initValue}
                validationSchema={validations}
                onSubmit={onSubmit}
        >
          {({ values, errors, touched, isSubmitting, setFieldValue, success }) => (
            <Form id={formId} autoComplete="off"
            >
              <MDBox mt={0}>
                <Grid container spacing={3}>
                  {/*<Grid item xs={12} lg={6}>*/}
                  {/*  <FormField disabled*/}
                  {/*             type={tenantName.type}*/}
                  {/*             label={tenantName.label}*/}
                  {/*             name={tenantName.name}*/}
                  {/*             value={values.tenantName}*/}
                  {/*             placeholder={"Title"}*/}
                  {/*             error={errors.tenantName && touched.tenantName}*/}
                  {/*             success={values.tenantName && values.tenantName.length > 5 && !errors.tenantName}*/}
                  {/*  />*/}
                  {/*</Grid>*/}
                  {/*<Grid item xs={12} lg={6}>*/}
                  {/*</Grid>*/}
                  <Grid item xs={12} lg={6}>
                    <FormField
                      type={bizName.type}
                      label={bizName.label}
                      name={bizName.name}
                      value={values.bizName}
                      placeholder={bizName.placeholder}
                      error={errors.bizName && touched.bizName}
                      success={values.bizName.length > 5 && !errors.bizName}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormField
                      type={bizDomain.type}
                      label={bizDomain.label}
                      name={bizDomain.name}
                      value={values.bizDomain}
                      placeholder={bizDomain.placeholder}
                      error={errors.bizDomain && touched.bizDomain}
                      success={values.bizDomain && !errors.bizDomain}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormField
                      type={pubConfigs.homepageTitle.type}
                      label={pubConfigs.homepageTitle.label}
                      name={pubConfigs.name}
                      value={values.pubConfigs ? values.pubConfigs?.homepageTitle : ""}
                      placeholder={"HomepageTitle"}
                      // rows="4"
                      // multiline
                      fullWidth
                      error={errors.pubConfigs && errors.pubConfigs?.homepageTitle && touched?.pubConfigs?.homepageTitle}
                      success={values.pubConfigs.homepageTitle && values.pubConfigs.homepageTitle.length > 5 && !errors?.pubConfigs?.homepageTitle}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setFieldValue("pubConfigs", {
                          ...values.pubConfigs,
                          homepageTitle: e.target.value
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormField
                      type={pubConfigs.slogan.type}
                      label={pubConfigs.slogan.label}
                      name={pubConfigs.name}
                      value={values.pubConfigs ? values.pubConfigs?.slogan : ""}
                      placeholder={"Slogan"}
                      // rows="4"
                      // multiline
                      fullWidth
                      error={errors.pubConfigs && errors.pubConfigs?.slogan && touched?.pubConfigs?.slogan}
                      success={values.pubConfigs.slogan && values.pubConfigs.slogan.length > 5 && !errors?.pubConfigs?.slogan}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setFieldValue("pubConfigs", {
                          ...values.pubConfigs,
                          slogan: e.target.value
                        });
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>

                    <FormField
                      type={pubConfigs.homepageMessageTitle.type}
                      label={pubConfigs.homepageMessageTitle.label}
                      name={pubConfigs.name}
                      value={values.pubConfigs ? values.pubConfigs?.homepageMessageTitle : ""}
                      placeholder={"homepageMessageTitle"}
                      // rows="4"
                      // multiline
                      fullWidth
                      error={errors.pubConfigs && errors.pubConfigs?.homepageMessageTitle && touched?.pubConfigs?.homepageMessageTitle}
                      success={values.pubConfigs.homepageMessageTitle && values.pubConfigs.homepageMessageTitle.length > 5 && !errors?.pubConfigs?.homepageMessageTitle}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setFieldValue("pubConfigs", {
                          ...values.pubConfigs,
                          homepageMessageTitle: e.target.value
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>

                    <FormField
                      type={pubConfigs.homepageMessage.type}
                      label={pubConfigs.homepageMessage.label}
                      name={pubConfigs.name}
                      value={values.pubConfigs ? values.pubConfigs?.homepageMessage : ""}
                      placeholder={"homepageMessage"}
                      // rows="4"
                      // multiline
                      fullWidth
                      error={errors.pubConfigs && errors.pubConfigs?.homepageMessage && touched?.pubConfigs?.homepageMessage}
                      success={values.pubConfigs.homepageMessage && values.pubConfigs.homepageMessage.length > 5 && !errors?.pubConfigs?.homepageMessage}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setFieldValue("pubConfigs", {
                          ...values.pubConfigs,
                          homepageMessage: e.target.value
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>

                    <FormField
                      type={pubConfigs.contactUsMessage.type}
                      label={pubConfigs.contactUsMessage.label}
                      name={pubConfigs.name}
                      value={values.pubConfigs ? values.pubConfigs?.contactUsMessage : ""}
                      placeholder={"ContactUsMessage"}
                      // rows="4"
                      // multiline
                      fullWidth
                      error={errors.pubConfigs && errors.pubConfigs?.contactUsMessage && touched?.pubConfigs?.contactUsMessage}
                      success={values.pubConfigs.contactUsMessage && values.pubConfigs.contactUsMessage.length > 5 && !errors?.pubConfigs?.contactUsMessage}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setFieldValue("pubConfigs", {
                          ...values.pubConfigs,
                          contactUsMessage: e.target.value
                        });
                      }}
                    />


                  </Grid>
                  <Grid item xs={12}>
                    {/*<MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">*/}
                    {/*  {t("TenantDetailsEdit.Configure")}*/}
                    {/*</MDTypography>*/}
                    {/*<Grid container spacing={3}>*/}


                    {/*  <Grid item xs={12} sm={6}>*/}
                    {/*    <FormField*/}
                    {/*      type={topicAreas.singular.type}*/}
                    {/*      label={topicAreas.singular.label}*/}
                    {/*      name={topicAreas.name}*/}
                    {/*      value={values.topicAreas ? values.topicAreas.singular : ""}*/}
                    {/*      placeholder={"TopicAreaLabel"}*/}
                    {/*      // rows="4"*/}
                    {/*      // multiline*/}
                    {/*      fullWidth*/}
                    {/*      error={errors.topicAreas && errors.topicAreas.singular && touched?.topicAreas.singular}*/}
                    {/*      success={values.topicAreas && values.topicAreas.singular && values.topicAreas.singular.length > 5 && (!errors.topicAreas || (errors.topicAreas && !errors.topicAreas?.singular))}*/}
                    {/*      onChange={(e) => {*/}
                    {/*        console.log(e.target.value);*/}
                    {/*        setFieldValue("topicAreas", {*/}
                    {/*          ...values.topicAreas,*/}
                    {/*          singular: e.target.value*/}
                    {/*        });*/}
                    {/*      }}*/}
                    {/*    />*/}

                    {/*  </Grid>*/}
                    {/*  <Grid item xs={12} sm={6}>*/}

                    {/*    <FormField*/}
                    {/*      type={topicAreas.plural.type}*/}
                    {/*      label={topicAreas.plural.label}*/}
                    {/*      name={topicAreas.name}*/}
                    {/*      value={values.topicAreas ? values.topicAreas.plural : ""}*/}
                    {/*      placeholder={"TopicAreasLabel"}*/}
                    {/*      // rows="4"*/}
                    {/*      // multiline*/}
                    {/*      fullWidth*/}
                    {/*      error={errors.topicAreas && errors.topicAreas.plural && touched?.topicAreas.plural}*/}
                    {/*      success={values.topicAreas && values.topicAreas.plural && values.topicAreas.plural.length > 5 && (!errors.topicAreas || (errors.topicAreas && !errors.topicAreas?.plural))}*/}
                    {/*      onChange={(e) => {*/}
                    {/*        console.log(e.target.value);*/}
                    {/*        setFieldValue("topicAreas", {*/}
                    {/*          ...values.topicAreas,*/}
                    {/*          plural: e.target.value*/}
                    {/*        });*/}
                    {/*      }}*/}
                    {/*    />*/}

                    {/*  </Grid>*/}
                    {/*</Grid>*/}
                  </Grid>

                  {/*<Grid item xs={12} alignItems="right">*/}
                  {/*<MDBox*/}
                  {/*  display="flex"*/}
                  {/*  alignItems="center"*/}
                  {/*  justifyPubConfigs="flex-end"*/}
                  {/*  width={{ xs: "100%", sm: "auto" }}*/}
                  {/*  mt={{ xs: 1, sm: 0 }}*/}
                  {/*>*/}
                  {/*  <MDBox lineHeight={0} mx={2}>*/}
                  {/*    <MDTypography variant="button" color="text">*/}
                  {/*      {t("TenantDetailsEdit.Active")} -*/}
                  {/*      {values.active ? "Active" : "Inactive"}*/}
                  {/*    </MDTypography>*/}
                  {/*  </MDBox>*/}
                  {/*  <MDBox mr={1}>*/}

                  {/*    <Switch checked={values.active}*/}
                  {/*            inputProps={{ "aria-label": "controlled" }}*/}
                  {/*            onChange={(e, value) => {*/}
                  {/*              setFieldValue("active", value);*/}
                  {/*            }} />*/}
                  {/*  </MDBox>*/}
                  {/*</MDBox>*/}
                  {/*</Grid>*/}


                  <Grid item xs={12}>

                    {alertMessage && (
                      <MDAlert color="error" dismissible>
                        <MDTypography variant="body2" color="white">
                          {t("TenantDetailsEdit.SaveError")}
                        </MDTypography>
                      </MDAlert>
                    )}

                    <MDBox ml="auto" justifyContent="space-between" display="flex"
                           alignItems={{ xs: "flex-end", sm: "center" }}
                           flexDirection={{ xs: "column", sm: "row" }}>
                      {/*<MDButton variant="gradient" color="secondary" size="large"*/}
                      {/*          onClick={onEditTenantDetailsCancel}>*/}
                      {/*  {t("Cancel")}*/}
                      {/*</MDButton>*/}
                      <MDButton
                        disabled={isSubmitting}
                        type="submit"
                        variant="gradient"
                        color="primary" size="large"
                      >
                        {tenantDetails && tenantDetails.tenantId ? "Update" : "Create"}
                      </MDButton>
                      {savingData && <LinearLoading />}
                    </MDBox>


                  </Grid>
                </Grid>

              </MDBox>
            </Form>
          )}
        </Formik>


      </MDBox>

    </Card>
  );
}

export default TenantDetailsEdit;
