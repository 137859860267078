
export const simple_eform_schema = {
  "title": "Client Info",
  "description": "Please provide the following info.",
  "type": "object",
  "required": [
    "firstName",
    "lastName"
  ],
  "properties": {
    "firstName": {
      "type": "string",
      "title": "First name",
    },
    "lastName": {
      "type": "string",
      "title": "Last name"
    },
    "email": {
      "type": "string",
      "format": "email",
      "title": "E-Mail"
    }
  }
};

export const simple_eform_schema_old = {
  "title": "A registration form",
  "description": "A simple form example.",
  "type": "object",
  "required": [
    "firstName",
    "lastName"
  ],
  "properties": {
    "firstName": {
      "type": "string",
      "title": "First name",
      "default": "Chuck"
    },
    "lastName": {
      "type": "string",
      "title": "Last name"
    },
    "age": {
      "type": "integer",
      "title": "Age"
    },
    "dob": {
      "type": "string",
      "format": "date"
    },
    "bio": {
      "type": "string",
      "title": "Bio"
    },
    "password": {
      "type": "string",
      "title": "Password",
      "minLength": 3
    },
    "telephone": {
      "type": "string",
      "title": "Telephone",
      "minLength": 10
    }
  }
};

export const simple_eform_ui_schema = {
  "boolean": {
    "radio": {
      "ui:widget": "radio"
    },
    "select": {
      "ui:widget": "select"
    }
  },
  "string": {
    "textarea": {
      "ui:widget": "textarea",
      "ui:options": {
        "rows": 5
      }
    },
    "placeholder": {
      "ui:placeholder": "This is a placeholder"
    },
    "color": {
      "ui:widget": "color"
    }
  },
  "secret": {
    "ui:widget": "hidden"
  },
  "disabled": {
    "ui:disabled": true
  },
  "readonly": {
    "ui:readonly": true
  },
  "widgetOptions": {
    "ui:options": {
      "backgroundColor": "yellow"
    }
  },
  "selectWidgetOptions": {
    "ui:options": {
      "backgroundColor": "purple"
    }
  }
};


export const eFormOptions = [
  {id:"Simple", name:"Simple (Built-In)", schema: simple_eform_schema, ui_schema:simple_eform_ui_schema},
  // {id:"BasicDemographic",name:"Basic Demographic (Built-In)"},
  // {id:"Demographic",name:"Demographic (Built-In)"},
  // {id:"ContactForm-1",name:"Contact 1 (Built-In)"},
  // {id:"ContactForm-2",name:"Contact 2 (Built-In)"},
  // {id:"ContactForm-3",name:"Contact 3 (Built-In)"}
];
