import React, { useEffect, useState, useCallback } from "react";

import Grid from "@mui/material/Grid";
import DialogContent from "@mui/material/DialogContent";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import CircularProgress from '@mui/material/CircularProgress';

// Settings page components
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import Sidenav from "./components/Sidenav";
import Header from "./components/Header";
import Accounts from "./components/Accounts";
import Notifications from "./components/Notifications";
import Sessions from "./components/Sessions";
import DeleteAccount from "./components/DeleteAccount";
import { usePublicTenantConfig, useSettings } from "../../../hooks";
import BackendService from "../../../services/BackendService";
import GlobalService from "services/GlobalService";
import DashboardLayout from "../../../LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import HomePageSettingsEdit from "./global-settings/HomePageSettingsEdit";
import AdminHeader from "../../custom-components/AdminHeader";
import SysAdminHeader from "../../custom-components/SysAdminHeader";
import TenantDetailsEdit from "./global-settings/TenantDetailsEdit";
import { useTenantDetails } from "../../../hooks/tenant-details-hooks";
import Spinner from "../../custom-components/Spinner/Spinner";
import OfferingAddEdit from "../../admin/offering-edit/OfferingAddEdit";
import CustomDialog from "views/custom-components/CustomDialog";
import { Offering } from "../../../models";
import LinearLoading from "../../custom-components/LinearLoading";
import TopicAreaEdit from "./global-settings/TopicAreaEdit";

function ProviderSettings() {
  const [loading, setLoading] = useState<boolean>(false);
  const { tenantConfig, reloadTenantConfig, loadingTenantConfig } = usePublicTenantConfig();

  const [ tenantDetails, setTenantDetails ] = useState<any>({});

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);


  const fetchData = async () => {
    setLoading(true);
    const data = await GlobalService.fetchTenantDetails(tenantConfig.tenantId);
    // console.log("fetchTenantDetails : ----------  " + JSON.stringify(data));

    setTenantDetails(data.message);
    setLoading(false);
  };

  useEffect(() => {

    if (tenantConfig.userPoolId) {
      // console.log(JSON.stringify(tenantConfig.tenantId));
      fetchData();
    }

  }, [loadingTenantConfig]);
  // @ts-ignore
  // const [ tenantDetails, setTenantDetails ] = useState<any>({});
  // const [tenantLoading, setTenantLoading] = useState<boolean>(false);

  // useEffect(() => {
  //   console.log("settings : >>>>>>>>>>>>> " + JSON.stringify(settings));
  //
  // }, [settings]);

  const openEditModal = () => {
    setIsOpenEditModal(true);
  };

  const closeEditModal = async () => {
    setIsOpenEditModal(false);
    await fetchData();
    reloadTenantConfig();
  };

  return (
    <DashboardLayout>
      <MDBox width="calc(100% - 48px)" position="absolute" top="1.75rem">
        <DashboardNavbar light absolute />
      </MDBox>
      <SysAdminHeader title="Settings" icon="settings"
                   description="Edit Platform-wide Settings" >
        Test
      </SysAdminHeader>
      <MDBox mt={4}>
        {!loadingTenantConfig ?
        <Grid container spacing={3}>
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Header tenantDetails={tenantDetails} tenantLoading={loading} openEditModal={openEditModal}/>
                </Grid>
                {tenantDetails && tenantDetails.tenantName &&
                  <>
                    <CustomDialog onClose={closeEditModal} open={isOpenEditModal} fullWidth={true} maxWidth={"lg"}
                                  aria-labelledby="customized-dialog-title">
                      <DialogContent>
                        <TenantDetailsEdit tenantDetails={tenantDetails} onEditCancel={closeEditModal} onEditUpdate={closeEditModal} />

                      </DialogContent>
                    </CustomDialog>

                  </>


                }



                <Grid item xs={12}>
                  {loading ? <Card> <MDBox minHeight="30vh"> <LinearLoading/> </MDBox> </Card>:
                  <TopicAreaEdit tenantDetails={tenantDetails} onEditUpdate={closeEditModal} />
                  }
                {/*  <HomePageSettingsEdit settingData={settings}/>*/}
                </Grid>

                <Grid item xs={12}>
                  {loading ? <Card> <MDBox minHeight="30vh"> <LinearLoading/> </MDBox> </Card>:
                    <TenantDetailsEdit tenantDetails={tenantDetails} onEditUpdate={closeEditModal} />
                  }
                  {/*  <HomePageSettingsEdit settingData={settings}/>*/}
                </Grid>

                {/*<Grid item xs={12}>*/}
                {/*  <Accounts />*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12}>*/}
                {/*  <Notifications />*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12}>*/}
                {/*  <Sessions />*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12}>*/}
                {/*  <DeleteAccount />*/}
                {/*</Grid>*/}
              </Grid>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={3}>
            {/* <MDBox sx={{ display: 'flex' }}> */}
            {/*   <CircularProgress /> */}

            {/* </MDBox> */}
            {/*<Sidenav />*/}

          </Grid>
        </Grid>
        : <MDBox minHeight="30vh" mb={1.5} mt={1.5}>
          <LinearLoading/>
          </MDBox>}
      </MDBox>
    </DashboardLayout>
  );
}

export default ProviderSettings;
