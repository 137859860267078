import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Form, Formik, useFormik, useFormikContext } from "formik";
import PropTypes from "prop-types";
import { Link, useNavigate, useParams } from "react-router-dom";


import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Select, { SelectChangeEvent } from '@mui/material/Select';

import Autocomplete from "@mui/material/Autocomplete";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";


import { useTranslation } from "react-i18next";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { useSettings, useTopicAreas, useWorkflows } from "hooks";
import Spinner from "views/custom-components/Spinner/Spinner";
import MDInput from "components/MDInput";

import { Category, Offering } from "models";
import { v4 as uuidv4 } from "uuid";

import {
  useDashboard,
  useFullPreview,
  useChangeBackgroundColor,
  useWindowSize
} from "hooks";

import OfferingService from "services/OfferingService";
import UtilsService from "services/UtilsService";
import RowRadioButtonsGroup from "../../custom-components/RowRadioButtonsGroup";
import { priceTypeOptions, scopeOptions, stateOptions } from "../../../static-data/dropdownData";
import Media from "../../../layouts/ecommerce/products/new-product/components/Media";
import MDEditor from "../../../components/MDEditor";
import FriendlyURLGenerator from "../../../services/FriendlyURLGenerator";
import { eFormOptions } from "../../../static-data/eform-data-static";
import useOfferingStore from "../../../store/offering-store";
import useApplicationStore from "../../../store/application-store";
import useWorkflowStore from "../../../store/workflow-store";
// import { workflowStaticOptions } from "../workflow/wf-data/workflow-data-static";


interface FormValues {
  // shardId: string;
  // offeringId: string;
  sku: string;
  name: string;
  description: string;
  price: number;
  priceType: string;
  priceLabel: string;
  state: string;
  content?: string;
  settings?: string;
  workflow?: string;
  scope: string;
  friendlyURL: string;
  categoryId: string;
  categoryName: string;
  images?: {};
  tags?: [];
}

interface PathParams {
  offeringKey: string;
}

function OfferingAddEdit({ offeringData, onEditOfferingCancel, onEditOfferingUpdate }) {
  const navigate = useNavigate();
  const { offeringKey } = useParams<PathParams>();
  const { t } = useTranslation();
  // const [savingData, setSavingData] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  // const {workflows,reloadWorkflows, loading:loadingWF} = useWorkflows();
  const [wfOptions, setWFOptions] = useState([{name: "None", key:"NONE"}]);

  //
  const [working, setWorking] = useState();


  const initOffsStore = useOfferingStore.use.initStore();
  const savingData = useOfferingStore.use.saving();
  const save = useOfferingStore.use.save();
  //
  const initWFStore = useWorkflowStore.use.initStore();
  const loadingWF = useWorkflowStore.use.loading();
  const getWFOptions = useWorkflowStore.use.getOptionList();

  const { topicareas:sortedTopicAreas, loading } = useTopicAreas();
  // const sortedTopicAreas = topicareas.sort((a, b) =>
  //   a.name > b.name ? 1 : -1
  // );

  useEffect(() => {    initOffsStore();  }, [initOffsStore]);
  useEffect(() => {    initWFStore();
    getWFOptions().then(opts=>{
      // console.log("options set : " + JSON.stringify(opts));
      setWFOptions( opts);
    });
    }, [initWFStore]);

  const [initValue, setInitValue] = useState({
    // shardId: offeringData?.shardId || "",
    // offeringId: offeringData?.offeringId || "",
    name:  "",
    state:  "Draft",
    sku:  "",
    price:  0,
    priceType:  "Fixed",
    priceLabel: "",
    description:  "",
    scope:"Public",
    categoryId: "Other",
    categoryName: "Other",
    friendlyURL:  uuidv4(),
    content:  { text: "" },
    settings:  { showPrice: true, showImages:false, showContent:true },
    workflow:  { workflowKey: "", workflowName:"", customApplicationId:"", customEFormId:"" },
    ...offeringData
    // tags: offeringData?.tags || []
  });
  // const [initValue, setInitValue] = useState({
  //   // shardId: offeringData?.shardId || "",
  //   // offeringId: offeringData?.offeringId || "",
  //   name: offeringData?.name || "",
  //   state: offeringData?.state || "Draft",
  //   sku: offeringData?.sku || "",
  //   price: offeringData?.price || 0,
  //   priceType: offeringData?.priceType || "Fixed",
  //   priceLabel: offeringData?.priceLabel || "",
  //   description: offeringData?.description || "",
  //   scope: offeringData?.scope || "Public",
  //   categoryId: offeringData?.categoryId || "Other",
  //   categoryName: offeringData?.categoryName || "Other",
  //   friendlyURL: offeringData?.friendlyURL || uuidv4(),
  //   content: offeringData?.content || { text: "" },
  //   settings: offeringData?.settings || { showPrice: true, showImages:false, showContent:true },
  //   workflow: offeringData?.workflow || { workflowKey: "", workflowName:"", customApplicationId:"", customEFormId:"" },
  //   // tags: offeringData?.tags || []
  // });
  // const formik = useFormik({ initialValues: initValue });
  // const {
  //   values,
  //   touched,
  //   setFieldValue,
  // } = useFormikContext();

  const form = {
    formId: "offering-edit-form",
    formField: {
      name: {
        name: "name",
        label: t("OfferingEdit.OfferingTitle"),
        type: "text",
        errorMsg: "Title is required.",
        invalidMsg: "Title is invalid.",
        placeholder: t("OfferingEdit.OfferingTitleHint")
      },
      scope: {
        name: "scope",
        label: "scope",
        type: "text",
        errorMsg: "scope is required.",
        placeholder: t("OfferingEdit.OfferingTitleHint")
      },
      state: {
        name: "state",
        label: "state",
        type: "text",
        errorMsg: "state is required."
      },
      sku: {
        name: "sku",
        label: "CODE / SKU",
        type: "text",
        placeholder: t("OfferingEdit.OfferingTitleHint")
      },
      price: {
        name: "price",
        label: "Price",
        type: "text",
        errorMsg: "Price is required.",
        placeholder: t("OfferingEdit.OfferingTitleHint")
      },
      priceType: {
        name: "priceType",
        label: "priceType",
        type: "text",
        errorMsg: "priceType is required.",
        placeholder: t("OfferingEdit.OfferingTitleHint")
      },
      priceLabel: {
        name: "priceLabel",
        label: "priceLabel",
        type: "text",
        errorMsg: "priceLabel is required.",
        placeholder: t("OfferingEdit.OfferingTitleHint")
      },
      description: {
        name: "description",
        label: "description",
        type: "text",
        errorMsg: "description is required.",
        placeholder: t("OfferingEdit.OfferingTitleHint")
      },
      categoryId: {
        name: "categoryId",
        label: "Category Type",
        type: "text",
        errorMsg: "Please specify category",
        placeholder: "category"
      },
      categoryName: {
        name: "categoryName",
        label: "CategoryName",
        type: "text",
        errorMsg: "Please specify categoryName",
        placeholder: "categoryName"
      },
      friendlyURL: {
        name: "friendlyURL",
        label: "friendlyURL",
        type: "text",
        errorMsg: "Please specify friendlyURL",
        placeholder: "friendlyURL"
      },
      content: {
        name: "content",
        label: "Offering Content",
        type: "text",
      },
      settings: {
        name: "settings",
        label: "Offering Settings",
        type: "text",
      },
      workflow: {
        name: "workflow",
        label: "Offering workflow",
        type: "text",
      }
    }
  };
  const {
    formId,
    formField: { name, sku, description,
      price, priceType, priceLabel,
      state, scope,friendlyURL,
      categoryId,categoryName,
      content,workflow,settings }
  } = form;

  const validations = Yup.object().shape({
    [name.name]: Yup.string().required(name.errorMsg).min(6, name.invalidMsg),
    [description.name]: Yup.string().required(description.errorMsg).min(10, description.invalidMsg),
    [scope.name]: Yup.string().required(scope.errorMsg).oneOf(
      ["Public", "Private", "Protected"],
      t("OfferingEdit.ScopeRequired")
    ),
    [state.name]: Yup.string().required(state.errorMsg).oneOf(
      ["Draft", "Review", "Published", "Inactive"],
      t("OfferingEdit.StateRequired")
    ),
    [categoryId.name]: Yup.string().required(categoryId.errorMsg).min(3, categoryId.invalidMsg),
    // [categoryName.name]: Yup.string().required(categoryName.errorMsg).min(3, categoryName.invalidMsg),
    [friendlyURL.name]: Yup.string().required(friendlyURL.errorMsg).min(10, friendlyURL.invalidMsg)
  });

  useEffect(() => {
    // console.log("offeringData: " + JSON.stringify(offeringData));
    // setInitValue(offeringData);

  }, [offeringData]);


  // useChangeBackgroundColor();

  // if (!loading) {
  //   crumbs.push({
  //     label: t("OfferingEdit.AddOffering"),
  //     url: "",
  //   });
  // }
  // const mergeValues =  (values: FormValues) => {
  //   name: offeringData?.name || "",
  //     state: offeringData?.state || "Draft",
  //     sku: offeringData?.sku || "",
  //     price: offeringData?.price || 0,
  //     priceType: offeringData?.priceType || "Fixed",
  //     priceLabel: offeringData?.priceLabel || "",
  //     description: offeringData?.description || "",
  //     scope: offeringData?.scope || "Public",
  //     categoryId: offeringData?.categoryId || "Other",
  //     categoryName: offeringData?.categoryName || "Other",
  //     friendlyURL: offeringData?.friendlyURL || uuidv4(),
  //     content: offeringData?.content || { text: "" },
  //     settings: offeringData?.settings || { showPrice: true, showImages:false, showContent:true },
  //     workflow: offeringData?.workflow || { workflowKey: "", workflowType:"", customApplicationId:"", customEFormId:"" },
  // }
  const onSubmit = async (values: FormValues) => {
    try {
      // setSavingData(true);
      // console.log("OfferingAddEdit- onSubmit : " + JSON.stringify(values));
      if (offeringData && offeringData.shardId && offeringData.offeringId.length > 10) {
        let updValues = Object.assign(offeringData, values)
        // console.log("updated values : " + JSON.stringify( updValues));
        await save(updValues);
      } else {
        await save(values);
      }

      // setSavingData(false);
      onEditOfferingUpdate();
    } catch (err) {
      console.log(t("AddContentFailure"), err);
      setAlertMessage(t("AddContentFailure") + ": " + err?.message);
      // setSavingData(false);
    }
  };

  const createOffering = async (values: FormValues) => {
    console.log(JSON.stringify(values));
    await OfferingService.createOffering(values);
  };

  const generateFriendlyUrl = async (name) => {
  //   // console.log(JSON.stringify(form));
    setWorking("GenerateURL");
  //   console.log(JSON.stringify(values));
    const url= FriendlyURLGenerator.generateFromName(name);
  //   await formik.setFieldValue("friendlyURL",url);
  //   // formik.setFieldTouched("friendlyURL");
  //   formik.validateForm();
    setWorking(undefined);
    return url;
  //   // console.log(JSON.stringify(formik.values));
  //   console.log(JSON.stringify(formik.values.friendlyURL));
  }
  const updateOffering = async (values: FormValues) => {
    // console.log(JSON.stringify(offeringData));
    // console.log(JSON.stringify(values));
    await OfferingService.editOffering(offeringData.key, values);
  };

  return (
    <MDBox m={3}>

      {savingData ? (
        <Spinner
          label={t("OfferingEdit.SavingData")}
        />
      ) : (
        <MDBox>
          <MDBox mb={2} >
            <MDTypography variant="h5">
              {offeringData && offeringData.shardId ? t("OfferingEdit.EditOffering") : t("OfferingEdit.AddOffering")}
              {offeringKey}
            </MDTypography>
          </MDBox>

          <MDBox>
            {/*<Media />*/}
            <MDBox>
              <Formik enableReinitialize={true}
                      initialValues={initValue}
                      validationSchema={validations}
                      onSubmit={onSubmit}
              >
                {({ values, errors, touched, isSubmitting, setFieldValue, success }) => (
                  <Form id={formId} autoComplete="off"
                  >
                    {/*<input type="hidden" name="shardId" value={values.shardId} />*/}
                    {/*<input type="hidden" name="offeringId" value={values.offeringId} />*/}
                    {/*{JSON.stringify(values)}*/}
                    {/*{JSON.stringify(errors)}*/}

                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <MDBox mt={2}>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                              <FormField
                                type={sku.type}
                                label={sku.label}
                                name={sku.name}
                                value={values.sku}
                                placeholder={sku.placeholder}
                                error={errors.sku && touched.sku}
                                success={values.sku.length > 5 && !errors.sku}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Autocomplete
                                defaultValue="Other"
                                name={categoryName.name}
                                value={values.categoryName}
                                isOptionEqualToValue={(option, value) =>  option.topicAreaName === value }
                                options={sortedTopicAreas}
                                onChange={(e, value) => {
                                  console.log("onChange :" + JSON.stringify(value));
                                  setFieldValue("categoryId",value ? value.topicAreaId : "Other");
                                  setFieldValue("categoryName",value ? value.topicAreaName : "Other");
                                }}
                                getOptionLabel={(option: Object | string) => option["topicAreaName"] || option}
                                renderInput={(params) =>
                                  <MDInput {...params} variant="standard"
                                           label={categoryName.label}
                                           hint={`${t("SelectExistingLeading")} ${categoryName.label}`}
                                           error={errors.categoryName && touched.categoryName}
                                           success={values.categoryName.length > 0 && !errors.categoryName}
                                    // required
                                  />}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <FormField
                                type={name.type}
                                label={name.label}
                                name={name.name}
                                value={values.name}
                                placeholder={"Title"}
                                error={errors.name && touched.name}
                                success={values.name.length > 5 && !errors.name}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FormField
                                type={description.type}
                                label={description.label}
                                name={description.name}
                                value={values.description}
                                placeholder={"Title"}
                                error={errors.description && touched.description}
                                success={values.description.length > 5 && !errors.description}
                              />
                            </Grid>

                            <Grid item xs={12}>

                              <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                  <FormField
                                    type={price.type}
                                    label={price.label}
                                    name={price.name}
                                    value={values.price}
                                    placeholder={price.placeholder}
                                    error={errors.price && touched.price}
                                    success={values.price && !errors.price}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>

                                  <Autocomplete
                                    defaultValue="Fixed"
                                    name="priceType"
                                    value={values.priceType}
                                    isOptionEqualToValue={(option, value) =>  option.id === value }
                                    options={priceTypeOptions}
                                    onChange={(e, value) => {
                                      setFieldValue("priceType",
                                        value ? value.id : "Fixed"
                                      );
                                    }}
                                    getOptionLabel={(option: Object | string) => option["name"] || option}
                                    renderInput={(params) =>
                                      <MDInput {...params} variant="standard"
                                               label={priceType.label}
                                               hint={`${t("SelectExistingLeading")} ${priceType.label}`}
                                               error={errors.priceType && touched.priceType}
                                               success={values.priceType.length > 0 && !errors.priceType}
                                        // required
                                      />}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <FormField
                                    type={priceLabel.type}
                                    label={priceLabel.label}
                                    name={priceLabel.name}
                                    value={values.priceLabel}
                                    placeholder={priceLabel.placeholder}
                                    error={errors.priceLabel && touched.priceLabel}
                                    success={values.priceLabel.length > 5 && !errors.priceLabel}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  priceLabel Help
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12} sm={12}>
                              <MDEditor
                                label={content.label}
                                name={content.name}
                                value={values.content && values.content.text ? values.content.text : ""}
                                placeholder={"Text Content"}
                                fullWidth
                                rows="4"
                                multiline
                                // readOnly
                                // theme={"bubble"}
                                error={errors.content && errors.content?.text && touched?.content?.text}
                                success={values.content.text && values.content.text.length > 5 && !errors?.content?.text}
                                onChange={(e) => {
                                  if (e) {
                                    console.log( e);
                                    setFieldValue("content", { text: e });
                                  }
                                }}
                              />
                              {/*<MDEditor*/}
                              {/*  type={content.type}*/}
                              {/*  label={content.label}*/}
                              {/*  name={content.name}*/}
                              {/*  value={values.content ? values.content?.text : ""}*/}
                              {/*  placeholder={"Text"}*/}
                              {/*  rows="4"*/}
                              {/*  multiline*/}
                              {/*  fullWidth*/}
                              {/*  error={errors.content && errors.content?.text && touched?.content?.text}*/}
                              {/*  success={values.content.text && values.content.text.length > 5 && !errors?.content?.text}*/}
                              {/*  onChange={(e) => {*/}
                              {/*    console.log(e.target.value);*/}
                              {/*    setFieldValue("content", { text: e.target.value });*/}
                              {/*  }}*/}
                              {/*/>*/}
                            </Grid>


                          </Grid>
                        </MDBox>
                      </Grid>
                    </Grid>


                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <MDBox mt={2}>
                          <Grid container spacing={3}>

                            {/*<Grid item xs={12}>*/}
                            {/*  <MDBox className="fallback" bgColor="transparent">*/}
                            {/*  <MDBox component="input" name="file" type="file" />*/}
                            {/*  </MDBox>*/}
                            {/*</Grid>*/}

                            {/*<Grid item xs={12} sm={12}>*/}
                            {/*  <FormControl fullWidth>*/}
                            {/*    <InputLabel id="demo-simple-select-label">Age</InputLabel>*/}
                            {/*    <Select*/}
                            {/*      labelId="demo-simple-select-label"*/}
                            {/*      id="demo-simple-select"*/}
                            {/*      value={age}*/}
                            {/*      label="Age"*/}
                            {/*      onChange={handleChange}*/}
                            {/*    >*/}
                            {/*      <MenuItem value={10}>Ten</MenuItem>*/}
                            {/*      <MenuItem value={20}>Twenty</MenuItem>*/}
                            {/*      <MenuItem value={30}>Thirty</MenuItem>*/}
                            {/*    </Select>*/}
                            {/*  </FormControl>*/}
                            {/*</Grid>*/}
                            <Grid item xs={12} sm={6}>
                              <Autocomplete
                                defaultValue="Public"
                                name="scope"
                                value={values.scope}
                                options={scopeOptions}
                                onChange={(e, value) => {
                                  // console.log(JSON.stringify(value));
                                  setFieldValue("scope",
                                    value ? value.id : "Private"
                                  );
                                }}
                                // onInputChange={(e, value) => {
                                //   console.log(JSON.stringify(value));
                                //   // setFieldValue("scope",
                                //   //   value ? value.id : "Other"
                                //   // );
                                // }}
                                isOptionEqualToValue={(option, value) =>  option.id === value }
                                getOptionLabel={(option: Object | string) => option["name"] || option}
                                renderInput={(params) =>
                                  <MDInput {...params} variant="standard"
                                           label={scope.label}
                                           hint={`${t("SelectExistingLeading")} ${scope.label}`}
                                           error={errors.scope && touched.scope}
                                           success={values.scope.length > 0 && !errors.scope}
                                    // required
                                  />}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>

                              {/*{!loadingWF && workflows && JSON.stringify(workflows[0].name) }*/}

                              {!loadingWF && wfOptions && <>
                                <Autocomplete
                                  // defaultValue={wfOptions[0].name5}
                                  name={workflow.name}
                                  value={values.workflow ? values.workflow?.workflowName : wfOptions[0].name}
                                  isOptionEqualToValue={(option, value) =>  {
                                    // console.log("O == > " + JSON.stringify(option));
                                    // console.log("V == > " + JSON.stringify(value));
                                    return option.name === value}}
                                  options={wfOptions}
                                  getOptionLabel={(option: Object | string) => option["name"] || option}
                                  onChange={(e, value) => {
                                    // console.log("onChange V:" + JSON.stringify(value));
                                    // console.log("onChange current wf v:" + JSON.stringify(values.workflow));
                                    setFieldValue("workflow", {
                                      ...values.workflow,
                                      workflowKey: value? value.key : "",
                                      workflowName: value? value.name : ""
                                    })
                                  }}
                                  renderInput={(params) =>
                                    <MDInput {...params} variant="standard"
                                             label={workflow.label}
                                             hint={`${t("SelectExistingLeading")} ${workflow.label}`}
                                             error={errors.workflow && errors.workflow?.workflowKey && touched?.workflow?.workflowKey}
                                             success={values.workflow?.workflowKey && !errors?.workflow?.workflowKey}
                                      // required
                                    />}
                                />

                              </>
                            }

                              {/*<Autocomplete*/}
                              {/*  defaultValue= {workflows[0].name}*/}
                              {/*  name={workflow.name}*/}
                              {/*  // value={values.workflow ? values.workflow : {workflowName:workflows[0].name, workflowKey: workflows[0].key}}*/}
                              {/*  value={values.workflow ? values.workflow : {}}*/}
                              {/*  isOptionEqualToValue={(option, value) =>  {*/}
                              {/*    console.log("o==> "+JSON.stringify(option));*/}
                              {/*    console.log("v==> "+JSON.stringify(value));*/}
                              {/*    return option.key === value*/}
                              {/*  }}*/}
                              {/*  options={workflows}*/}
                              {/*  getOptionLabel={(option: Object | string) => option["name"] || option}*/}
                              {/*  onChange={(e, value) => {*/}
                              {/*    console.log("onChange current value:" + JSON.stringify(value));*/}
                              {/*    setFieldValue("workflow", {*/}
                              {/*      ...values.workflow,*/}
                              {/*      workflowName: value? value.name : "",*/}
                              {/*      workflowKey: value? value.key : "",*/}
                              {/*      workflowType: value? value.categoryId : ""*/}
                              {/*    })*/}
                              {/*  }}*/}
                              {/*  renderInput={(params) =>*/}
                              {/*    <MDInput {...params} variant="standard"*/}
                              {/*             label={workflow.label}*/}
                              {/*             hint={`${t("SelectExistingLeading")} ${workflow.label}`}*/}
                              {/*             error={errors.workflow && errors.workflow?.workflowKey && touched?.workflow?.workflowKey}*/}
                              {/*             success={values.workflow.workflowKey && !errors?.workflow?.workflowKey}*/}
                              {/*      // required*/}
                              {/*    />}*/}
                              {/*/>*/}
                            </Grid>

                            <Grid item xs={12} sm={12}>
                              <MDBox ml="auto" lineHeight={0}
                                     width={{ xs: "100%", sm: "auto" }} display="flex"
                                     alignItems={{ xs: "flex-end", sm: "center" }}
                                     flexDirection={{ xs: "column", sm: "row" }}>
                                {FriendlyURLGenerator.getHostPathForFriendlyUrl("offering")}
                                <FormField
                                type={friendlyURL.type}
                                label={friendlyURL.label}
                                name={friendlyURL.name}
                                value={values.friendlyURL}
                                placeholder={friendlyURL.placeholder}
                                error={errors.friendlyURL && touched.friendlyURL}
                                success={values.friendlyURL && values.friendlyURL.length > 9 && !errors.friendlyURL}
                              />
                              <MDButton variant="outlined" color="secondary" size="small"
                                        disabled={working && working==='GenerateURL'}
                                        onClick={()=>generateFriendlyUrl(values.name).then(v=>{setFieldValue("friendlyURL",v);})}>
                                {t("Generate")}
                              </MDButton>
                              </MDBox>
                            </Grid>


                            <Grid item xs={12}>
                              {alertMessage && (
                                <MDAlert color="error" dismissible>
                                  <MDTypography variant="body2" color="white">
                                    {t("OfferingEdit.AddOfferingError")}
                                  </MDTypography>
                                </MDAlert>
                              )}

                            </Grid>
                          </Grid>

                        </MDBox>
                      </Grid>

                    </Grid>
                    <MDBox ml="auto" justifyContent="space-between"
                           width={{ xs: "100%", sm: "auto" }} display="flex"
                           alignItems={{ xs: "flex-end", sm: "center" }}
                           flexDirection={{ xs: "column", sm: "row" }}>
                      <MDButton variant="outlined" color="secondary" size="large"
                                onClick={onEditOfferingCancel}>
                        {t("Close")}
                      </MDButton>

                      <MDButton
                        disabled={isSubmitting}
                        type="submit"
                        variant="gradient"
                        color="primary" size="large"
                      >
                        {offeringData && offeringData.offeringId ? "Update" : "Create"}
                      </MDButton>
                    </MDBox>

                    {/*{JSON.stringify(errors)}*/}
                    {/*<br/>...*/}
                    {/*{JSON.stringify(values)}*/}
                  </Form>
                )}
              </Formik>
            </MDBox>

          </MDBox>
          {/*{JSON.stringify(offeringData)}*/}
        </MDBox>
      )}
    </MDBox>
  );
}

export default OfferingAddEdit;
