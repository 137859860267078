import { forwardRef } from "react";

import PropTypes from "prop-types";
import MenuItem from '@mui/material/MenuItem';
// Custom styles for MDInput
import AOSelectRoot from "./AOSelectRoot";

const AOSelect = forwardRef(({ error, success, disabled, register,
                              validate, required, optionList, ...rest }, ref) => (

  <AOSelectRoot {...rest} ref={register &&
    (validate
      ? register({
        required: required,
        validate: validate,
      })
      : register({ required: required }))}
                ownerState={{ error, success, disabled }} >

    {optionList.map((o)=> (
      <MenuItem value={o.value}>{o.name}</MenuItem>
    ))}

  </AOSelectRoot>
));

// Setting default values for the props of AOSelect
AOSelect.defaultProps = {
  error: false,
  success: false,
  disabled: false,
};

// Typechecking props for the AOSelect
AOSelect.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
  register: Function,
};

export default AOSelect;
