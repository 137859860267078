import React, { useEffect } from "react";

// prop-types is library for typechecking of props
import validator from "@rjsf/validator-ajv8";
import Form from "@rjsf/mui";

import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React base styles
// @mui icons
// import EFormDisplayCard from "../EFormDisplayCard";
import MDButton from "components/MDButton";
import { eFormOptions } from "static-data/eform-data-static";
import EFormFieldsView from "../EFormFieldsView";

function SystemEFormList({ copyForUseFunc }) {

  const sysEForms = eFormOptions;

  useEffect(() => {
    console.log("System EForms : " + sysEForms.length);
  }, []);

  // const renderItems = efTypes.map((ef) => (
  //   <MDBox key={ef.name} display="flex" py={1} pr={2}>
  //     {/*{JSON.stringify(ef)}*/}
  //     {ef.steps.map((step)=>(
  //       <>
  //       <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
  //         {step.sourceType}: &nbsp;
  //       </MDTypography>
  //       <MDTypography variant="button" fontWeight="regular" color="text">
  //       &nbsp;{step.seq}
  //   </MDTypography>
  //       </>
  //     ))}
  //
  //   </MDBox>
  // ));

  const renderItems1 = sysEForms.map((ef) => (
    // <MDBox key={ef.name} display="flex" py={1} pr={2}>

    <Grid item xs={12} md={12} key={ef.id}>
      <Card sx={{ height: "100%", overflow: "visible" }}>
        {/*<MDBox pt={3} px={3}>*/}
        {/*  <MDTypography variant="h5" color="text" fontWeight="regular">*/}
        {/*    {ef.name}*/}
        {/*  </MDTypography>*/}
        {/*</MDBox>*/}
        <Grid container spacing={1}>

          <Grid item xs={12} md={6} xl={6}>
            <MDBox m={2} p={2}>
              <Form uiSchema={ef.ui_schema}
                    schema={ef.schema}
                    validator={validator}
              />
            </MDBox>
            {/*<EFormRenderer schema={ef.schema} uiSchema={ef.ui_schema} />*/}
          </Grid>
          <Grid item xs={12} md={6} xl={6} sx={{ display: "flex" }}>
            <Divider orientation="vertical" sx={{ ml: 0, mr: 1 }} />
            <MDBox m={2} p={2}>
              <MDBox mt={4} pt={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12} >
                    <MDTypography variant="h6" color="text" fontWeight="regular">
                      {ef.name} EForm
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} >
                    <EFormFieldsView  schemaProperties={ef.schema.properties} />
                    {/*{JSON.stringify(ef.schema.properties)}*/}
                  {/*  {Object.keys(ef.schema.properties).map((columnName) => (*/}
                  {/*    <Grid container>*/}
                  {/*      <Grid item xs={4}>*/}
                  {/*        <MDTypography variant="caption" color="text" fontWeight="regular">*/}
                  {/*          {ef.schema.properties[columnName].title}*/}
                  {/*        </MDTypography>*/}
                  {/*      </Grid>*/}
                  {/*      <Grid item xs={4}>*/}
                  {/*        <MDTypography variant="caption" color="text" fontWeight="regular">*/}
                  {/*          {ef.schema.properties[columnName].type}*/}
                  {/*        </MDTypography>*/}
                  {/*      </Grid>*/}
                  {/*      <Grid item xs={4}>*/}
                  {/*        <MDTypography variant="caption" color="text" fontWeight="regular">*/}
                  {/*          {ef.schema.properties[columnName].format}*/}
                  {/*        </MDTypography>*/}
                  {/*      </Grid>*/}
                  {/*    </Grid>*/}
                  {/*  ))}*/}
                  </Grid>
                  <Grid item xs={12} >
                    <MDBox ml="auto" justifyContent="space-between" display="flex"
                           alignItems={{ xs: "flex-end", sm: "center" }}
                           flexDirection={{ xs: "column", sm: "row" }}>

                      <MDTypography variant="button" color="text" fontWeight="regular">
                        Use This EForm
                      </MDTypography>
                      <MDBox ml="auto">
                        <MDButton variant="gradient" color="success" size="small"
                                  onClick={() => copyForUseFunc(ef.name, ef.schema, ef.ui_schema)}>
                          Copy For Me
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </Grid>
                </Grid>

              </MDBox>
            </MDBox>
          </Grid>
        </Grid>

      </Card>
    </Grid>

    // </MDBox>
  ));

  return (
    <MDBox p={3}>
      <Grid container spacing={3}>
        {renderItems1}
      </Grid>


    </MDBox>
  );
}

export default SystemEFormList;