import { useContext, useEffect,useState } from "react";
import { Submission, SubmissionParticipant, WidgetType } from "../models";
import { SubmissionContext } from "../context/SubmissionProvider";
import SubmissionService from "../services/SubmissionService";


type UseSubmissionHook = {
  submissionId:  string;
  submissionMain:  Submission;
  submissionPCP: SubmissionParticipant;
  submissionRows: Submission[];
  summary: {};
  reloadSubmission: Function;
  refreshRows: Function;
  saveRow: Function;
  createNewRowDataFromWidget: Function;
  editMode: boolean;
  setEditMode: Function;
  savingData: boolean;
  loadingSubmission: boolean;
  submissionLoaded: boolean;
};

export function useSubmissionContextHook(submissionIdToLoad?: string, initEditMode?:boolean): UseSubmissionHook {
  const [submissionData, dispatch] =   useContext(SubmissionContext);
  // const context =   contextArr;
  // const sContext =   useContext(SubmissionContext);
  // const [submissionData:context, dispatch] =   useContext(SubmissionContext);
  const { submissionId, submissionMain, submissionPCP, submissionRows, editMode,
    reloadSubmission, loadingSubmission,submissionLoaded,summary,refreshRows } =
      submissionData;
  const [savingData, setSavingData] = useState(false);

  useEffect(() => {
    console.log("useSubmissionContextHook:", submissionIdToLoad);
    if (submissionIdToLoad && submissionData.reloadSubmission) {
      console.log(" Y-> useSubmissionContextHook:", submissionIdToLoad);
      submissionData.reloadSubmission(submissionIdToLoad);
      if (editMode!==initEditMode) setEditMode(initEditMode);
    }
    // else{
    //   console.log(" X -> useSubmissionContextHook didn't call reload",submissionIdToLoad, reloadSubmission);
    // }
  }, [submissionIdToLoad]);

  async function saveSubmissionRow (newData) {
    setSavingData(true);
    let apiResponse;
    try {
      if (newData.key) {
        apiResponse = await SubmissionService.editSubmission(newData.key, newData);
      } else {
        apiResponse = await SubmissionService.createSubmission(newData);
      }
      // if (apiResponse && apiResponse.participantType === "PCP") {
      //   set({ submissionPCP: apiResponse });
      // } else if (apiResponse && apiResponse.participantType === "META") {
      //   set({ submissionMain: apiResponse });
      // }
      console.log(" R -> useSubmissionContextHook saveSubmissionRow output",apiResponse);
      // await refreshRows(); //@Todo: replace with granular update // XXX creates loop
      // await refreshRows(apiResponse); //@Todo: replace with granular update // XXX creates loop
      await refreshRowList(apiResponse); //@Todo: replace with granular update // XXX creates loop
    } catch (e) {
      console.log(e);
    } finally {
      setSavingData(false);
    }

    // if (apiResponse) get().updateList(apiResponse); // @Todo: update/reload
  }

  const refreshRowList = async (newData) => {
    console.log(" IN -> Hook refreshRowList ",newData);
    const currList = submissionRows;
    // setSubmissionData({
    //   ...submissionData,
    //   loadingSubmission: true,
    // });
    let rows: any[] = [];
    if (!currList || currList.length === 0) {
      rows.push(newData);
    } else {
      const idx = currList.findIndex(o => {
        return o.key === newData.key;
      });

      if (idx < 0) {
        currList.push(newData);
        rows = currList;
      } else {
        rows = currList.map(obj => newData.key === obj.key ? newData : obj);
      }
    }
    // set({ submissionRows: rows, loading: false });
    // setSubmissionData({
    //   ...submissionData,
    //   reloadSubmission: loadSubmission,
    //   refreshRows,
    //   submissionRows: rows,
    // });

    dispatch({ type: "ROWS", submissionRows:rows });
  }

  const setEditMode = async (isEditMode) => {
    console.log(" IN -> Hook setEditMode ",isEditMode);
    // if (typeof dispatch !== "function") {
    //   console.log(" IN -> Hook setEditMode ... submissionData",submissionData);
    // }
      dispatch({ type: "EDITMODE", value: isEditMode });

  }


  function createNewRowDataFromWidget (application, widget, rowContent) {
    let mfRow = {
      // ...submissionPCP,
      sk: undefined, // To indicate new row
      key: submissionPCP.key, // To indicate parent
      name: widget.name,
      rowType: widget.widgetType.toUpperCase(), //Submission RowType
      refId: widget.sk, // @TODO: replace with widget.key
      refType: widget.widgetType,
      refData: widget.content,
      workflow: {
        application: { shardId: application.shardId, sk: application.sk },
        widget: { shardId: widget.shardId, sk: widget.sk }
      },
      content: rowContent

    };
    delete mfRow.shardId;

    return mfRow;
  }

  return {
    ...submissionData,
    savingData,
    setEditMode,
    saveRow: saveSubmissionRow,
    createNewRowDataFromWidget
  }

  // return {
  //   submissionId: context.submissionId,
  //   submissionMain: context.submissionMain,
  //   submissionPCP: context.submissionPCP,
  //   submissionRows: context.submissionRows,
  //   summary: context.summary,
  //   saveRow: saveSubmissionRow,
  //   savingData: savingData,
  //   createNewRowDataFromWidget,
  //   reloadSubmission:context.reloadSubmission,
  //   refreshRows:context.refreshRows,
  //   loadingSubmission:context.loadingSubmission,
  //   submissionLoaded:context.submissionLoaded,
  // };
  // return {
  //   submissionId: submissionId,
  //   submissionMain: submissionMain,
  //   submissionPCP: submissionPCP,
  //   submissionRows: submissionRows,
  //   summary: summary,
  //   saveRow: saveSubmissionRow,
  //   savingData: savingData,
  //   createNewRowDataFromWidget,
  //   reloadSubmission,
  //   refreshRows,
  //   loadingSubmission,
  //   submissionLoaded,
  // };
}
