import { create } from "zustand";
import ProviderConfigService from "../services/ProviderConfigService";
import createSelectors from "./selectors";
import EFormService from "../services/EFormService";
import Auth from "@aws-amplify/auth";
import { UserRoles } from "../models";

interface EFormStore {
  eFormConfigs: {};
  eForms: any[];
  loading: boolean;
  saving: boolean;
  initStore: () => Promise<void>;
  getOptionList: () => Promise<any[]>;
  getById: (eFormId:string) => Promise<any>;
  // getByKey: (key:string) => any;
  updateList: (newData: any) => void;
  save: (newData: any) => Promise<void>;
}

const useEFormStoreBase = create<EFormStore>()((set, get) => ({
  // const defaultVals = await EFormService.fetchEForms();
  eFormConfigs: {  },
  eForms: [],
  loading: false,
  saving: false,
  getOptionList: async () => {
    //console.log("..... Store : getOptionList <<<<<<<<<< eForm");
    let wfs = get().eForms;
    // //console.log("..... Store : getOptionList >> ALL applications " + apps.length);
    let options = [{name: "None", key:"NONE"}];
    set({ loading: true });
    if (!wfs || (wfs && wfs.length == 0)){
      await get().initStore();
      wfs = get().eForms;
    }
    // //console.log("..... Store : getOptionList +++++ apps ++ length" + apps.length);
    wfs.reduce((accumArr, thisObj) => {
      if (thisObj.name && thisObj.name!=="" && thisObj.state==='Published') {
        accumArr.push({ name: thisObj.name, key: thisObj.key });
      }
      // //console.log("..... Store : getOptionList +++++++ length" + accumAppArr.length);
      return accumArr;
    }, options);

    set({ loading: false });
    return options;
  },
  getById: async (eFormId:string) => {
    console.log("..... Store : getById eFormId <<<<<<<<<< ", eFormId);

    if (!eFormId || eFormId.trim()==="")      return;

    const eForms = get().eForms;
    console.log("eForms", eForms);
    // let foundObj = eForms.find(obj => eFormId === obj.configId);
    let foundObj = eForms.find(obj => eFormId === obj.key);
    if (foundObj) return foundObj;
    set({ loading: true });
    console.log("..... Store : getById <<<<<<<<<< need fetching", eFormId);
    try {
      const data = await EFormService.fetchEFormById(eFormId);
      if (data && data.length===1) {
        foundObj = data[0];
        get().updateList(foundObj);
      }
    }finally {
      set({ loading: false });
    }
    return foundObj;
  },
  // getByKey: (key:string) => {
  //   const currList = get().eForms;
  //   if (!currList || currList.length===0 || !key){
  //     //console.log("..... Store : getByKey <<<<<<<<<< None", key);
  //     //console.log("..... Store : currList ", currList);
  //     return;
  //   }
  //
  //   return currList.find(obj => key === obj.key);
  // },
  updateList: (newData, sortCol?:string) => {
    set({ loading: true });
    const currList = get().eForms;
    let eFormList:any[] = [];
    if (!currList || currList.length===0){
      //console.log("..... Store : updateList <<<<<<<<<< add");
      eFormList.push(newData);
    }else{
      //console.log("..... Store : updateList <<<<<<<<<< replace");
      const idx = currList.findIndex(o => {
        //console.log("..... Store : updateList <<<<<<<<<< findIndex o " + JSON.stringify(o.key));
        //console.log("..... Store : updateList <<<<<<<<<< findIndex newData " + JSON.stringify(newData.key));
        return o.key === newData.key
      });

      if (idx < 0){
        currList.push(newData);
        eFormList = currList;
      }else {
        //console.log("..... Store : updateList <<<<<<<<<< replace " + idx);
        eFormList = currList.map(obj => newData.key === obj.key ? newData : obj);
      }
      //console.log("..... Store : updateList <<<<<<<<<< findIndex o " + JSON.stringify(eFormList));
      // wfs = [...currList.slice(0, idx), newData, ...currList.slice(idx, currList.length)];
    }
    eFormList.sort((a, b) =>      a.updatedAt > b.updatedAt ? -1 : 1    );
    set({ eForms: eFormList, loading: false });
    //arr1.map(obj => arr2.find(o => o.id === obj.id) || obj);
  },
  save: async (newData) => {
    set({ saving: true });
    let apiResponse;
    try {
      //console.log("..... Store : save <<<<<<<<<< eForms");
      if (newData.key) {
        //console.log("..... Store : save <<<<<<<<<< get existing eForm key" + newData.key);
        const existingData = await EFormService.fetchEFormById(newData.key);
        if (!existingData || !existingData.key) {
          console.error("..... Store : save existing data not found!");
          return;
        }
        const newval = {
          ...existingData,
          ...newData
        };
        //console.log("..... Store : save <<<<<<<<<< new val key" + newval.key);
        apiResponse = await EFormService.editEForm(existingData.key, newval);
        //console.log("..... Store : save <<<<<<<<<< response ", apiResponse);
      } else {
        apiResponse = await EFormService.createEForm(newData);
      }
    }
    catch (e) {
      //console.log(e);
    }
    finally {
      set({ saving: false });
    }

    if (apiResponse) get().updateList(apiResponse);
  },
  initStore: async () => {
    const user = await Auth.currentAuthenticatedUser().catch(e=>{
      //console.log(e);
      return {};
    });
    let isEFormAdmin = false;
    if (user.attributes && user.attributes["custom:userRole"]) {
      const userRoles: string = user.attributes["custom:userRole"];
      isEFormAdmin = userRoles.includes(UserRoles.Admin) ||    userRoles.includes(UserRoles.Editor);
    }
    if (!isEFormAdmin) {
      //console.log("..... Store : initStore << eForms << Admin only. Use getByKey() otherwise.");
    }

    if (get().eForms.length > 0) return;

    //console.log("..... Store : initStore <<<<<<<<<< eForms");
    set({ loading: true });
    let wfs = await EFormService.fetchEForms();
    set({  eForms: wfs, loading: false });
  }
}));

const useEFormStore = createSelectors(useEFormStoreBase);
export default useEFormStore;