import Dialog from '@mui/material/Dialog';
import { styled } from "@mui/material/styles";

export default styled(Dialog)(({ theme, ownerState }) => {
  const { palette, typography, borders, functions } = theme;
  const { color } = ownerState;

  const { white, gradients } = palette;
  const { fontSizeRegular, fontWeightMedium } = typography;
  const { borderRadius } = borders;
  const { pxToRem, linearGradient } = functions;

  // backgroundImage value
  const backgroundImageValue = gradients[color]
    ? linearGradient(gradients[color].main, gradients[color].state)
    : linearGradient(gradients.info.main, gradients.info.state);

  return {
    // display: "flex",
    overflow:"hidden",
    // justifyContent: "space-between",
    // alignItems: "center",
    minHeight: pxToRem(60),
    // backgroundImage: backgroundImageValue,
    // color: white.main,
    // position: "relative",
    // padding: pxToRem(16),
    marginBottom: pxToRem(16),
    borderRadius: borderRadius.md,
    // fontSize: fontSizeRegular,
    // fontWeight: fontWeightMedium,
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  };
});
