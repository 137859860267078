import React from "react";
import MDBox from "components/MDBox";
import CircularProgress from '@mui/material/CircularProgress';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import LinearProgress from '@mui/material/LinearProgress';

import MDProgress from "components/MDProgress";
import Box from '@mui/material/Box';

interface SpinnerProps {
  label?: string;
  display?: string;
  color?: string;
  style?: any;
  minHeight?: string;
}

function LinearLoading(props: SpinnerProps) {
  return (
    <MDBox sx={{ minHeight: props.minHeight?? '100px' }}>
    <Grid container alignItems="center" justifyContent="center">
    <MDBox sx={{ width: '30%' }}>

         {/*<MDProgress value={90} color="warning"/>*/}
        <LinearProgress  sx={{
          color: props.color || 'info',
        }}/>
    </MDBox>
    </Grid>
    </MDBox>
  );
}

export default LinearLoading;
