import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import { Hub } from "@aws-amplify/core";
// import Auth from "@aws-amplify/auth";
// import { Hub} from "@aws-amplify/core";
import { Auth } from "@aws-amplify/auth";
// import { Amplify } from 'aws-amplify';
import { Submission, SubmissionParticipant } from "../models";
import { useNavigate } from "react-router-dom";
import SubmissionService from "../services/SubmissionService";

/**
 * Default submission to start with while we fetch the actual
 * Submission from the Tenant.
 */
// const defaultSubmission: Submission = {
//   // authRequired: true
//   submissionId:undefined
// };


interface SubmissionContextProps {
  submissionId: string;
  submissionMain: Submission;
  submissionPCP: SubmissionParticipant;
  submissionRows: Submission[];
  summary: {};
  reloadSubmission: Function;
  refreshRows: Function;
  // savingData: boolean;
  loadingSubmission: boolean;
  submissionLoaded: boolean;
}

// export const SubmissionContext = React.createContext<SubmissionContextProps>({
//   submissionId: undefined,
//   reloadSubmission: (submissionId: string) => {
//   },
//   loadingSubmission: false,
//   submissionLoaded: false,
//   saving: false
// });

// const submissionReducer = (
//   backendSubmission: Submission
// ): Submission => {
//   return {
//     ...backendSubmission, // add all values to start with
//     // Check if we need to fallback to default values
//     // enterpriseLoginLabel: "Enterprise Sign-In"
//   };
// };

export const SubmissionContext = React.createContext([{},]);


function reducer(state, action) {
  switch (action.type) {
    case "LOADING": {
      return { ...state, loadingSubmission: action.value };
    }
    case "LOADED": {
      return { ...state, submissionLoaded: action.value };
    }
    case "MAIN": {
      return { ...state, submissionMain: action.submissionMain };
    }
    case "PCP": {
      return { ...state, submissionPCP: action.submissionPCP };
    }
    case "ROWS": {
      return { ...state, submissionRows: action.submissionRows };
    }
    case "ID": {
      return { ...state, submissionId: action.submissionId };
    }
    case "SUMMARY": {
      return { ...state, summary: action.summary };
    }
    case "DATA": {
      return { ...state, ...action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "EDITMODE": {
      return { ...state, editMode: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

/**
 * This provider wraps the root of our component's tree in <App />
 * to provide Submission to all the children components in the tree. It
 * uses React Context so that submission are kept in a global state instead
 * of fetching them over and over on every screen.
 */
function SubmissionProvider(props: { children: React.ReactNode }) {
  const navigate = useNavigate();

  // const [savingData, setSavingData] = React.useState(false);


  const fetchSubmission = useCallback(async (submissionId: string) => {
    // if (!submissionId) {
    //   submissionId =    window.sessionStorage.getItem('submissionId');
    //   console.log("got submissionId from session " , submissionId);
    // }

    if (!submissionId) {
      console.log("not in a submission context");
      return;
    }
    return await SubmissionService.fetchSubmissionById(submissionId);
  }, []);


  const loadSubmission = useCallback(async (submissionIdToLoad: string) => {
    console.log("loadSubmission passed :" + submissionIdToLoad);
    let submissionId = submissionIdToLoad;
    if (!submissionIdToLoad && submissionData.submissionId) {
      submissionId = submissionData.submissionId;
    }
    if (!submissionId) {
      submissionId = window.sessionStorage.getItem("submissionId");
    }
    if (!submissionId) {
      // setSubmissionData({
      //   reloadSubmission: loadSubmission,
      //   refreshRows: refreshRows,
      //   loadingSubmission: false,
      //   submissionLoaded: false
      // });
      dispatch({
        type: "DATA",
        value: {
          loadingSubmission: false,
          submissionLoaded: false
        }
      });
      return;
    }
    // dispatch({ type: "ID", submissionId });

    try {
      /**
       * useCallback is used because it is important that the
       * reloadSubmission function does not change, because it
       * causes an infinite loop due to the submission-hook
       * having a useEffect on it.
       */
      // setSubmissionData({
      //   submissionId,
      //   reloadSubmission: loadSubmission,
      //   refreshRows: refreshRows,
      //   submissionMain: undefined,
      //   loadingSubmission: true,
      //   submissionLoaded: false
      // });
      dispatch({
        type: "DATA",
        value: {
          submissionId,
          loadingSubmission: true,
          submissionLoaded: false
        }
      });

      const data: any = await fetchSubmission(submissionId);
      // console.log("Tenant config : " + JSON.stringify(data.data));

      if (!data || data.length<1) {
        // setSubmissionData({
        //   // reloadSubmission: loadSubmission,
        //   // refreshRows: refreshRows,
        //   ...submissionData,
        //   loadingSubmission: false,
        //   submissionLoaded: false
        // });
        // dispatch({ type: "LOADING", value:false });
        // dispatch({ type: "LOADED", value:false });
        dispatch({
          type: "DATA",
          value: {
            submissionId: undefined,
            loadingSubmission: false,
            submissionLoaded: false
          }
        });
        return;
      }

      const sRows = data;
      const user = await Auth.currentAuthenticatedUser();
      const submissionRows = (sRows.filter((s) => s.rowType !== "MAIN" && s.rowType !== "PCP"));


      const submissionMain = (sRows.filter((s) => s.rowType === "MAIN")[0]);
      const submissionPCP = (sRows.filter((s) => s.rowType === "PCP"
        && s.participant
        && s.participant === user.username)[0]);
      const primaryPCP = (sRows.filter((s) => s.rowType === "PCP"
        && s.participantType
        && s.participantType === "PRIMARY")[0]);

      let summary = {
        noOfParticipants: submissionMain.participant,
        primaryApplicant: primaryPCP.name + ` ( ${primaryPCP.participant} )`,
        offeringName: submissionMain.refData.name

      };

      // set({ submissionId, submissionRows, submissionMain, submissionPCP, summary });
      // setSubmissionData({
      //   ...submissionData,
      //   // submissionId: submissionMain.submissionId,
      //   submissionPCP: submissionPCP,
      //   submissionMain: submissionMain,
      //   submissionRows: submissionRows,
      //   summary: summary,
      //   // reloadSubmission: loadSubmission,
      //   refreshRows: refreshRows,
      //   loadingSubmission: false,
      //   submissionLoaded: true
      // });
      // dispatch({ type: "MAIN", submissionMain });
      // dispatch({ type: "PCP", submissionPCP });
      // dispatch({ type: "ROWS", submissionRows });
      // dispatch({ type: "SUMMARY", summary });
      // dispatch({ type: "LOADING", value:false });
      // dispatch({ type: "LOADED", value:true });

      dispatch({
        type: "DATA",
        value: {
          submissionMain,
          submissionPCP,
          submissionRows,
          summary,
          loadingSubmission: false,
          submissionLoaded: true
        }
      });

      console.log("Submission loaded");

    } catch (err) {
      console.log("Failed to load submission from backend", err);
      dispatch({
        type: "DATA",
        value: {
          loadingSubmission: false,
          submissionLoaded: false
        }
      });
    }
  }, [fetchSubmission]);


  // const [submissionData, setSubmissionData] = useState<SubmissionContextProps>({
  //   reloadSubmission: loadSubmission,
  //   submissionMain: undefined,
  //   loadingSubmission: false,
  //   submissionLoaded: false
  // });

  const refreshRowsC = useCallback(async () => {
    console.log("Submission Provider refreshRows ... ");
    if (!submissionData.submissionId) {
      console.log("Submission Provider refreshRows submissionId missing. ");
      return;
    }

    try {

      const data: any = await fetchSubmission(submissionData.submissionId);

      if (!data) {
        setSubmissionData({
          ...submissionData,
          submissionPCP: undefined,
          submissionMain: undefined,
          submissionRows: undefined,
          loadingSubmission: false,
          submissionLoaded: false
        });

        return;
      }

      const sRows = data;
      const user = await Auth.currentAuthenticatedUser();
      const submissionRows = sRows;


      const submissionMain = (sRows.filter((s) => s.rowType === "MAIN")[0]);
      const submissionPCP = (sRows.filter((s) => s.rowType === "PCP" && s.participant === user.username)[0]);
      const primaryPCP = (sRows.filter((s) => s.rowType === "PCP" && s.participantType === "PRIMARY")[0]);

      let summary = {

        noOfParticipants: submissionMain.participant,
        primaryApplicant: primaryPCP.name + ` ( ${primaryPCP.participant} )`,
        offeringName: submissionMain.refData.name

      };

      // set({ submissionId, submissionRows, submissionMain, submissionPCP, summary });
      setSubmissionData({
        ...submissionData,
        submissionPCP: submissionPCP,
        submissionMain: submissionMain,
        submissionRows: submissionRows,
        summary: summary,
        // reloadSubmission: loadSubmission,
        loadingSubmission: false,
        submissionLoaded: true
      });

      console.log("Submission refreshed");

    } catch (err) {
      console.log("Failed to refreshRows from backend", err);
    }
  }, [fetchSubmission]);

  // const refreshRows = useCallback(async (newData) => {
  const refreshRows = async (newData) => {
    // console.log(" IN -> Provider refreshRows ",newData);
    // const currList = submissionData.submissionRows;
    // // setSubmissionData({
    // //   ...submissionData,
    // //   loadingSubmission: true,
    // // });
    // let rows: any[] = [];
    // if (!currList || currList.length === 0) {
    //   rows.push(newData);
    // } else {
    //   const idx = currList.findIndex(o => {
    //     return o.key === newData.key;
    //   });
    //
    //   if (idx < 0) {
    //     currList.push(newData);
    //     rows = currList;
    //   } else {
    //     rows = currList.map(obj => newData.key === obj.key ? newData : obj);
    //   }
    // }
    // // set({ submissionRows: rows, loading: false });
    // // setSubmissionData({
    // //   ...submissionData,
    // //   reloadSubmission: loadSubmission,
    // //   refreshRows,
    // //   submissionRows: rows,
    // // });
    //
    // dispatch({ type: "ROWS", submissionRows:rows });
  };
  // }, []);
  const initialState = {
    reloadSubmission: loadSubmission,
    refreshRows: refreshRows,
    submissionMain: undefined,
    loadingSubmission: true,
    submissionLoaded: false,
    editMode: false,
    saving: false
  };
  const [submissionData, dispatch] = useReducer(reducer, initialState);
  const value = useMemo(() => [submissionData, dispatch], [submissionData, dispatch]);

  /**
   * Listen for authentication events so that when users
   * signIn or their token is refreshed, we refetch the
   * Submission. This covers an edge case in which we fail
   * to fetch Submission the first time because the user was
   * not authenticated yet.
   */
  const listenAuthEvents = useCallback(
    (event: any) => {
      const { payload } = event;
      switch (payload.event) {
        case "signIn":
        case "tokenRefresh":
          console.log("Detected AuthEvents: " + payload.event);
          if (payload.event === "signIn") {
            // const submissionId = Auth.
            // console.log("Full AuthEvents after signIn: " + JSON.stringify(payload.data));
            if (payload.data.attributes && payload.data.attributes["custom:submissionId"]) {
              const authSubmissionId = payload.data.attributes["custom:submissionId"];
              console.log("Found SubmissionId from Auth SignIn...");
              window.sessionStorage.setItem("submissionId", authSubmissionId);
              window.sessionStorage.removeItem("tenantName");
              // return authSubmissionId;
            }
          }
          loadSubmission();
          break;
        default:
          break;
      }
    },
    [loadSubmission]
  );

  useEffect(() => {
    console.log("SubmissionProvider ... useEffect:");
    loadSubmission();
    Hub.listen("auth", listenAuthEvents);
    return () => Hub.remove("auth", listenAuthEvents);
  }, [loadSubmission, listenAuthEvents]);


  return (
    <SubmissionContext.Provider value={value}>
      {props.children}
      {/*{JSON.stringify(value.submissionMain)}*/}
    </SubmissionContext.Provider>
  );
}

// function useSubmissionContext(submissionIdToLoad?: string) {
//   const context = useContext(SubmissionContext);
//
//   if (!context) {
//     throw new Error(
//       "useSubmissionContextController should be used inside the SubmissionContextDataProvider."
//     );
//   }
//
//   return context;
// }

export default SubmissionProvider;
