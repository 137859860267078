import { useEffect, useCallback, useState } from "react";
import { Hub } from "@aws-amplify/core";

import { useLocation, useNavigate } from "react-router-dom";

import PropTypes from "prop-types";
// import { withAuthenticator } from "@aws-amplify/ui-react";
import {
  Authenticator,
  Button,
  CheckboxField,
  Heading,
  Image,
  Text,
  useAuthenticator,
  ThemeProvider,
  useTheme,
  View
} from "@aws-amplify/ui-react";

import MDBox from "components/MDBox";

import Link from "@mui/material/Link";
import Fade from "@mui/material/Fade";
import LinearProgress from '@mui/material/LinearProgress';

import { setLayout, setMiniSidenav, useMaterialUIController } from "context";
// import withSAMLAuthenticator from "../SAMLAuthenticator";
// import "../cognito-style.css";
import brandWhite from "logo.svg";
// import "../cognito-style.css";
import { useCurrentAuthenticatedUser, usePublicTenantConfig } from "hooks";
import SettingsProvider from "../../context/SettingsProvider";
import { Auth } from "@aws-amplify/auth";
import { UserRoles } from "../../models";
import Footer from "../../examples/Footer";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import AdminHeader from "../../views/custom-components/AdminHeader";
import LoadingBar from "../../views/custom-components/LoadingBar/LoadingBar";
import Sidenav from "../../examples/Sidenav";
import brandDark from "../../assets/images/app-brand/consen-logo-col.svg";
import routes from "../../routes";
import Configurator from "../../examples/Configurator";
import '@aws-amplify/ui-react/styles.css';
import '../cognito-style.css';
import UserPreferencesProvider from "../../context/UserPreferencesProvider";


const authComponents = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image maxWidth={"180px"} paddingTop={"100px"}
               alt="meteoar logo"
               src={brandWhite}
        />
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={2}
        >
          Welcome to Consen
        </Heading>
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={`${tokens.colors.neutral["80"]}`} fontSize={"0.7em"}>
          <Link href={"https://aceoar.io"} target="_blank">
            Ace|Oar Technologies {" "}
          </Link>
          &copy; All Rights Reserved
        </Text>
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        // <MDBox pb={3}>
        //   <Grid container alignItems="center">
        //     <Grid item xs={12} md={7}>
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Sign in to your account
        </Heading>
        //     </Grid>
        //   </Grid>
        // </MDBox>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            size="small"
            variation="link"
          >
            Reset Password
          </Button>
        </View>
      );
    }
  },

  SignUp: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Create a new account
        </Heading>
      );
    },
    FormFields() {
      const { validationErrors } = useAuthenticator();

      return (
        <>
          {/* Re-use default `Authenticator.SignUp.FormFields` */}
          <Authenticator.SignUp.FormFields />

          {/* Append & require Terms & Conditions field to sign up  */}
          <CheckboxField
            errorMessage={validationErrors.acknowledgement}
            hasError={!!validationErrors.acknowledgement}
            name="acknowledgement"
            value="yes"
            label="I agree with the Terms & Conditions"
          />
        </>
      );
    },
    Footer() {
      const { toSignIn } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toSignIn}
            size="small"
            variation="link"
          >
            Back to Sign In
          </Button>
        </View>
      );
    }
  },
  ConfirmSignUp: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    }
  },
  SetupTOTP: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    }
  },
  ConfirmSignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    }
  },
  ResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    }
  },
  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    }
  }
};

const authServices = {
  async validateCustomSignUp(formData) {
    if (!formData.acknowledgement) {
      return {
        acknowledgement: "You must agree to the Terms & Conditions"
      };
    }
  }
};

const formFields = {
  signIn: {
    username: {
      placeholder: 'Enter your email',
    },
  },
  signUp: {
    password: {
      label: 'Password:',
      placeholder: 'Enter your Password:',
      isRequired: false,
      order: 2,
    },
    confirm_password: {
      label: 'Confirm Password:',
      order: 1,
    },
  },
  forceNewPassword: {
    password: {
      placeholder: 'Enter your Password:',
    },
  },
  resetPassword: {
    username: {
      placeholder: 'Enter your email:',
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      placeholder: 'Enter your Confirmation Code:',
      label: 'New Label',
      isRequired: false,
    },
    confirm_password: {
      placeholder: 'Enter your Password Please:',
    },
  },
  setupTOTP: {
    QR: {
      totpIssuer: 'test issuer',
      totpUsername: 'amplify_qr_test_user',
    },
    confirmation_code: {
      label: 'New Label',
      placeholder: 'Enter your Confirmation Code:',
      isRequired: false,
    },
  },
  confirmSignIn: {
    confirmation_code: {
      label: 'New Label',
      placeholder: 'Enter your Confirmation Code:',
      isRequired: false,
    },
  },
};

function DashboardLayout({ children, loading }) {
  const [controller, dispatch] = useMaterialUIController();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { tenantConfig, reloadTenantConfig, loadingTenantConfig } = usePublicTenantConfig();
  // const { username, isAdmin, isFederatedId, isEditor, isPublic, hasRole, loading : userLoading } =
  //   useCurrentAuthenticatedUser();
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  const {
    miniSidenav,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  // const {userPoolId} = tenantConfig;
  // const [user, setUser] = useState("");
  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // const handleSignOut = async (event) => {
  //   event.preventDefault();
  //   setTimeout(async () => {
  //     try {
  //       await Auth.signOut();
  //       if (!isFederatedId) {
  //         window.location.href = "/landing";
  //       }
  //     } catch (error) {
  //       console.log("error signing out: ", error);
  //       event.preventDefault();
  //     }
  //   });
  // };
  // const loadSettings = useCallback(async () => {
  //   try {
  //     await Auth.currentSession();
  //     setIsUserAuthenticated( true);
  //   } catch (err) {
  //     setIsUserAuthenticated( false);
  //   }
  // }, [tenantConfig]);

  // const refreshAuth = useCallback(async () => {
  //   const user = await Auth.currentAuthenticatedUser();
  //   // setUser(user.username);
  // }, []);

  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname]);

  // useEffect(() => {
  //
  //   console.log("DB Layout username: " + username + " isAdmin: " + isAdmin);
  // }, []);

  useEffect(() => {
    // if (!tenantConfig.userPoolId && !loadingTenantConfig && !userLoading) {
    if (!tenantConfig.userPoolId && !loadingTenantConfig ) {
      navigate("/provider-lookup");
    }
  }, [loadingTenantConfig]);

  // useEffect(() => {
  //   console.log(JSON.stringify('Dash tenantConfig : >>>---------->>>> ' + username));
  //   console.log(JSON.stringify(tenantConfig));
  // }, [tenantConfig]);

// const configsButton = (
  //   <MDBox
  //     display="flex"
  //     justifyContent="center"
  //     alignItems="center"
  //     width="3.25rem"
  //     height="3.25rem"
  //     bgColor="white"
  //     shadow="sm"
  //     borderRadius="50%"
  //     position="fixed"
  //     right="2rem"
  //     bottom="2rem"
  //     zIndex={99}
  //     color="dark"
  //     sx={{ cursor: "pointer" }}
  //     onClick={handleConfiguratorOpen}
  //   >
  //     <Icon fontSize="small" color="inherit">
  //       settings
  //     </Icon>
  //   </MDBox>
  // );

  return (
    <>

      {!loadingTenantConfig && tenantConfig.userPoolId &&
        // <SettingsProvider>
        // <ThemeProvider>
        <Authenticator loginMechanisms={["username"]} hideSignUp={true}
                         formFields={formFields}
                         components={authComponents}
                         services={authServices}

          >
            {({ signOut, user }) => (
              <>
                  <Sidenav
                    color={sidenavColor}
                    brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                    // brandName="Consen"
                    routes={routes.filter((rt) => rt.scope !== "none")}
                    onMouseEnter={handleOnMouseEnter}
                    onMouseLeave={handleOnMouseLeave}
                  />
                  <Configurator />
                  {/* {configsButton */}

                {/* <a href="/landing" onClick={handleSignOut}> */}
                {/*   Sign Out */}
                {/* </a> */}
                {/* <LoadingBar/> */}
                {/*   <Fade in={true} timeout={300}> */}
                <MDBox sx={{ height: 6, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  {loading &&
                      <MDBox sx={{ width: '35%'  }}>
                    <Fade
                        in={loading}
                        style={{
                          transitionDelay: loading ? '800ms' : '0ms',
                        }}
                        unmountOnExit
                    >
                      <LinearProgress color="primary" />
                    </Fade>
                  </MDBox>
                  }
                </MDBox>
                <MDBox
                  // minHeight="80vh"
                  sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
                    px: 3, py:2,
                    position: "relative",

                    [breakpoints.up("xl")]: {
                      marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
                      transition: transitions.create(["margin-left", "margin-right"], {
                        easing: transitions.easing.easeInOut,
                        duration: transitions.duration.standard
                      })
                    }
                  })}
                >
                  <MDBox                      minHeight="80vh">
                  {children}
                  </MDBox>
                  {/* {JSON.stringify(SettingsProvider.settings)} */}
                  <Footer />
                </MDBox>
                  {/* </Fade> */}
              </>
            )}
          </Authenticator>
        // </ThemeProvider>
        // </SettingsProvider>
      }
    </>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default DashboardLayout;
