import React from "react";

import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import { workflowNodeTypes } from "static-data/wf-node-types";

function WorkflowStepsDisplay({ wfSteps }) {
  const getNodeDetails = (nodeType) => workflowNodeTypes.find(nt => nt.nodeType === nodeType);
  return (

    <MDBox p={3} mb={2} lineHeight={1}>

      <Grid container spacing={2} alignItems="center">
        {wfSteps && wfSteps.length>0 && wfSteps.map((s) => {
            const nodeType = getNodeDetails(s.sourceType) ;
            return <Grid item xs={12} key={s.seq}>
              <MDBox  >
                <MDTypography variant="h6" fontWeight="regular" color={ "text"}>
                  <MDBadge variant="contained" color={"secondary"} badgeContent={"" + s.seq} />
                  &nbsp;
                  <MDTypography display="inline" component="small" variant="h6" color={ nodeType? nodeType.color : "text"}>
                    {"|"}
                  </MDTypography>
                  &nbsp;
                  {nodeType? nodeType.label : s.sourceType}
                </MDTypography>
                {/*<MDBox*/}
                {/*  display="grid"*/}
                {/*  justifyContent="center"*/}
                {/*  alignItems="center"*/}
                {/*  bgColor={nodeType? nodeType.color : "secondary"}*/}
                {/*  color="white"*/}
                {/*  width="2rem"*/}
                {/*  height="2rem"*/}
                {/*  shadow="sm"*/}
                {/*  borderRadius="md"*/}
                {/*  variant="gradient"*/}
                {/*>*/}
                {/*  <Icon fontSize="small">*/}
                {/*    {nodeType? nodeType.icon : "question"}*/}
                {/*  </Icon>*/}
                {/*</MDBox>*/}
                {/*<MDTypography variant="h6" fontWeight="regular" color={nodeType? nodeType.color : "text"}>*/}
                {/* */}
                {/*</MDTypography>*/}
                <MDBox px={2} ml={2.5} lineHeight={0}>
                  {s.sourceData && s.sourceData.name !=='None' &&
                    <Tooltip title={s.sourceData.key} placement="bottom">
                      <MDTypography variant="caption" color="text" fontWeight="regular">
                        {s.sourceData.name || "Unspecified"}
                      </MDTypography>

                    </Tooltip>}
                </MDBox>
              </MDBox>
            </Grid>
          }
        )}

      </Grid>
      {/*{JSON.stringify(wfSteps)}*/}
    </MDBox>
  );
}

export default WorkflowStepsDisplay;