import React, { useState } from "react";

import Container from "@mui/material/Container";
import { Link } from "react-router-dom";

import PageLayout from "examples/LayoutContainers/PageLayout";

// Pricing page components
import Header from "./components/Header";
import Footer from "./components/Footer";
import TenancyCards from "./components/TenancyCards";
import TrustedBrands from "./components/TrustedBrands";
import Faq from "./components/Faq";
import MDButton from "../../components/MDButton";
import ConsumerCard from "./components/ConsumerCard";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "../../components/MDTypography";
import MDBox from "../../components/MDBox";
import ActionCard from "../custom-components/ActionCard";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import WorkflowDisplayCard from "../admin/workflow-list/component/WorkflowDisplayCard";
import { defaultWFEdges, defaultWFNodes } from "../admin/workflow/wf-data/default-workflow-01";
import { advancedWFEdges, advancedWFNodes } from "../admin/workflow/wf-data/advance-workflow-01";
import csSiteImg from "assets/images/cs-logo/1.svg";
import csOverviewGif from "assets/images/cs-gifs/CS-overview-2.gif";
import MDAvatar from "../../components/MDAvatar";
import csIconGray from "assets/images/card_sym/9.png";
import csCardSymBusiness from "assets/images/card_sym/12.png";
import { useCurrentAuthenticatedUser, usePublicTenantConfig } from "../../hooks";
import bgImage from "../../assets/images/shapes/waves-white.svg";


function LandingPage() {
  const { tenantConfig, reloadTenantConfig, loadingTenantConfig,tenantConfigLoaded } = usePublicTenantConfig();
  const { username, isAdmin, isEditor, hasRole, isLoggedIn } =
    useCurrentAuthenticatedUser();



  return (
    <PageLayout>

      <Header />
      {/*<Container>*/}

          <Card
            sx={{ mt: -16 }}
            //     sx={{ mt: -16,
            //       backgroundImage: ({ functions: { linearGradient, rgba }, palette: { white, info } }) =>
            //         `${linearGradient(rgba(info.main, 0.55), rgba(white.main, 0.35))}, url(${bgImage})`,
            //       backgroundSize: "cover",
            //       backgroundPosition: "top"
            //     }}
          >
            <MDBox my={6} alignItems="center">

                <Grid container spacing={1} justifyContent="center" alignItems="center" sx={{ px: 4, my: 8 }}>
                  <Grid item xs={12} md={10} align="center" >
                    <MDTypography variant="h2" fontWeight="bold" gutterBottom>
                      What are you getting done today?
                    </MDTypography>
                  </Grid>
                  {/*<Grid item xs={12} md={10} align="center" >*/}
                  {/*  /!*<MDBox component="img" src={csSiteImg} alt="CONSEN" width="600px" mt={1} />*!/*/}
                  {/*</Grid>*/}
                  {/*<Grid item xs={12} sm={8} md={6} xl={4} align="center">*/}
                  {/*  <MDBox py={4} px={2}>*/}

                  {/*    <ActionCard*/}
                  {/*      image={csCardSymBusiness}*/}
                  {/*      title="Business Signup"*/}
                  {/*      badge={{ color: "warning", label: "New Tenant" }}*/}
                  {/*      content="Contact us to discuss your need. We will assign a dedicated team to help you get onboard.*/}
                  {/*" >*/}
                  {/*      /!* <MDButton variant="outlined" color="secondary"> *!/*/}
                  {/*      /!*   Login *!/*/}
                  {/*      /!* </MDButton> *!/*/}
                  {/*      <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>*/}
                  {/*        {!isLoggedIn &&*/}
                  {/*          <MDButton variant="text" color="info" sx={{ height: "100%" }}*/}
                  {/*                    component={Link} to={"mailto:info@aceoar.com"}>*/}
                  {/*            Request A Demo*/}
                  {/*          </MDButton>*/}
                  {/*        }*/}
                  {/*         <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>*/}
                  {/*          {isLoggedIn && isAdmin ?*/}
                  {/*            <MDButton variant="gradient" color="warning" sx={{ height: "100%" }}*/}
                  {/*                      component={Link} to={"/admin/dashboard"}>*/}
                  {/*              Visit Admin Dashboard*/}
                  {/*            </MDButton>*/}
                  {/*            :*/}
                  {/*            <MDButton variant="gradient" color="primary" sx={{ height: "100%" }}*/}
                  {/*                      component={Link} to={"https://consen.co/contact"}>*/}
                  {/*              Contact to Signup*/}
                  {/*            </MDButton>*/}
                  {/*          }*/}
                  {/*        </MDBox>*/}
                  {/*      </MDBox>*/}
                  {/*    </ActionCard>*/}
                  {/*  </MDBox>*/}
                  {/*</Grid>*/}
                  <Grid item xs={12} sm={8} md={6} xl={4} align="center">
                    <MDBox py={4} px={2}>

                      <ActionCard
                        image={csIconGray}
                        title="Continue Submission"
                        badge={{ color: "success", label: "Existing Applicant" }}
                        content="Continue your application if you already have started one
                  with a Vendor.
                  Login to view your Dashboard." >
                        {/* <MDButton variant="outlined" color="secondary"> */}
                        {/*   Login */}
                        {/* </MDButton> */}
                        <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
                          {tenantConfigLoaded ?
                            <MDButton variant="text" color="info" sx={{ height: "100%" }}
                                      component={Link} to={`/${tenantConfig.tenantName}/home`}>
                              Visit Last Viewed Provider: {tenantConfig.bizName}
                            </MDButton>
                            :
                            <MDButton variant="gradient" color="error" sx={{ height: "100%" }}
                                      component={Link} to={"/provider-lookup"}>
                              Lookup Provider
                            </MDButton>
                          }
                          {tenantConfigLoaded && <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                            {isLoggedIn ?
                              <MDButton variant="gradient" color="success" sx={{ height: "100%" }}
                                        component={Link} to={"/app/dashboard"}>
                                View My Dashboard
                              </MDButton>
                              :
                              <MDButton variant="gradient" color="info" sx={{ height: "100%" }}
                                        component={Link} to={`/app/auth/consumer-login`}>
                                Login to Continue
                              </MDButton>
                            }
                          </MDBox>}
                        </MDBox>
                      </ActionCard>
                    </MDBox>
                  </Grid>

                  <Grid item xs={12}  align="center" >
                    <MDBox my={6} py={6}
                          // sx={{
                          //   backgroundImage: ({ functions: { linearGradient, rgba }, palette: { white, info } }) =>
                          //     // `${linearGradient(rgba(info.main, 0.55), rgba(white.main, 0.35))}, url(${bgImage})`,
                          //     `url(${bgImage})`,
                          //   backgroundSize: "repeat",
                          //   // backgroundPosition: "top"
                          // }}
                    >
                      <MDTypography variant="h2" fontWeight="bold" gutterBottom>
                        One platform to Automate Your Entire Intake Process.
                      </MDTypography>
                      <MDBox component="img" src={csOverviewGif} alt="CONSEN" width="800px" mt={1} py={2}/>

                      <MDBox py={6}>
                        <MDTypography variant="h2" fontWeight="bold" gutterBottom>
                          You Design Your Own Workflow
                        </MDTypography>
                        <MDTypography variant="body1" fontWeight="regular" gutterBottom>
                          No one knows your process better than you.<br/> So Consen gives the power to design your own workflow, as it best works for your business.
                        </MDTypography>
                        <MDBox py={2}>
                          <WorkflowDisplayCard name="Sample workflow"
                                               initialNodes={advancedWFNodes}
                                               initialEdges={advancedWFEdges}/>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Grid>

                  <Grid item xs={12} md={10} align="center" >
                    <MDBox my={6} py={6}
                           sx={{
                             backgroundImage: ({ functions: { linearGradient, rgba }, palette: { white, info } }) =>
                               // `${linearGradient(rgba(info.main, 0.55), rgba(white.main, 0.35))}, url(${bgImage})`,
                               `url(${bgImage})`,
                             backgroundSize: "repeat",
                             // backgroundPosition: "top"
                           }}
                    >
                      <MDBox mb={2}>
                        <MDTypography variant="h2" fontWeight="bold" gutterBottom color="text">
                          Contact us to Register or for a Demo
                        </MDTypography>
                      </MDBox>
                      <MDButton variant="gradient" color="primary" size="large" component={Link} to={"https://consen.co/contact"}>
                        Contact Us
                      </MDButton>
                    </MDBox>
                  </Grid>
                  {/*<Grid item xs={12} md={10} align="center" >*/}
                  {/*  <MDBox>*/}

                  {/*  </MDBox>*/}
                  {/*</Grid>*/}

                  {/*<Grid item xs={12} sm={8} md={6} xl={4} align="center">*/}
                  {/*  <MDBox py={4} px={2}>*/}
                  {/*    /!*<MDTypography variant="h4" fontWeight="bold" gutterBottom>*!/*/}
                  {/*    /!*  Agent / Employee*!/*/}
                  {/*    /!*</MDTypography>*!/*/}
                  {/*  <ActionCard*/}
                  {/*    image={csIconGray}*/}
                  {/*    title="Employee Login"*/}
                  {/*    badge={{ color: "error", label: "Agent / Employee" }}*/}
                  {/*    content="Please continue your application if you already have started one.*/}
                  {/*    Or simply login to your Account." >*/}
                  {/*    <MDButton variant="gradient" color="error" component={Link} to={"/admin/dashboard"}>*/}
                  {/*      Dashboard Login*/}
                  {/*    </MDButton>*/}
                  {/*    /!* <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}> *!/*/}
                  {/*    /!*   <MDButton variant="gradient" color="white" sx={{ height: "100%" }}> *!/*/}
                  {/*    /!*     Login *!/*/}
                  {/*    /!*   </MDButton> *!/*/}
                  {/*    /!* </MDBox> *!/*/}
                  {/*  </ActionCard>*/}
                  {/*  </MDBox>*/}
                  {/*</Grid>*/}
                </Grid>



            </MDBox>






            {/* <MDBox component="img" src={productImage} width="60%"  borderRadius="sm" mb={1} /> */}


            {/*{tenantConfigLoaded ?*/}
            {/*  <Grid container spacing={1} justifyContent="center" alignItems="center" sx={{ px: 4, my: 8 }}>*/}
            {/*    <Grid item xs={12} md={10} align="center" >*/}
            {/*      <MDTypography variant="h5"  gutterBottom>*/}
            {/*        Or,*/}
            {/*      </MDTypography>*/}
            {/*      /!*{tenantConfig.branding &&*!/*/}
            {/*      /!* <MDBox component="img" src={tenantConfig.branding} width="60%"  borderRadius="sm" mb={1} />*!/*/}
            {/*      /!*}*!/*/}
            {/*      <MDBox py={4} mb={2}>*/}
            {/*        <MDTypography variant="h3" fontWeight="bold" color="dark">*/}
            {/*          Continue to {tenantConfig.bizName}*/}

            {/*        </MDTypography>*/}
            {/*      </MDBox>*/}
            {/*      <MDButton variant="outlined" color="primary" size="large" component={Link}*/}
            {/*                to={`/${tenantConfig.tenantName}/home`}>*/}
            {/*        View HomePage*/}
            {/*      </MDButton>*/}


            {/*    </Grid>*/}
            {/*  </Grid>*/}
            {/*  :*/}
            {/*<Grid container spacing={1} justifyContent="center" alignItems="center" sx={{ px: 4, my: 8 }}>*/}

            {/*  <Grid item xs={12} md={12} align="center" >*/}

            {/*  </Grid>*/}
            {/*  </Grid>*/}
            {/*}*/}

             {/*<Faq />*/}
          </Card>

      {/*</Container>*/}
      <Footer />
    </PageLayout>
  );
}

export default LandingPage;
