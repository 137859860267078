import React, {useEffect, useState} from 'react';
import validator from '@rjsf/validator-ajv8';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import { Widget } from "models";
import { useEForm } from "hooks";
import EFormService from "../../../../../services/EFormService";
import LinearLoading from "../../../LinearLoading";
import Spinner from "../../../Spinner/Spinner";
import useEFormStore from "../../../../../store/eForms-store";


interface Props {
  widget: Widget;
  hideTitle?: boolean;
}
function EFormWidget(props: Props) {

  const { content, showTitle, name } = props.widget;
  // const { eForm, loading : loadingEForm } = useEForm(content.eFormId);
  const initStore = useEFormStore.use.initStore();
  const loadingEForm = useEFormStore.use.loading();
  const getById = useEFormStore.use.getById();
  const [eForm, setEForm] = useState(undefined);
  const [schema, setSchema] = useState(undefined);
  const [uiSchema, setUISchema] = useState(undefined);

  // const fetchData = useCallback(async () => {
  //   setLoading(true);
  //   const data = isProtected? await EFormService.fetchProtectedEForms() :
  //     await EFormService.fetchEForms();
  //   console.log("useEForms eForms   >> " + JSON.stringify(data));
  //   setEForms(data);
  //   setLoading(false);
  // }, []);

  useEffect(() => {
    console.log("useEffect EFormWidget  getById  >> " + JSON.stringify(content?.eFormId));
    if (!content.eFormId) return;
    initStore().then(()=>
    getById(content.eFormId).then((configEForm) => {
      setEForm(configEForm); // from provider config
      // console.log("configEForm useEffect EFormWidget   >> " + JSON.stringify(configEForm));
      if (configEForm ) {
        // console.log("configEForm: " + JSON.stringify(configEForm));
        setSchema(configEForm.defaultVal);
        setUISchema(configEForm.config);
      }else{
        console.log("EFormWidget - "+ loadingEForm +", eForm not found for: " + JSON.stringify(content));
      }
    }))

  }, [getById,content]);

  useEffect(() => {
    console.log("useEffect EFormWidget  loadingEForm  >> " + JSON.stringify(content?.eFormId));
    if (eForm ) {
      console.log("EFormWidget: " + JSON.stringify(eForm));
      setSchema(eForm.defaultVal);
      setUISchema(eForm.config);
      console.log("EFormWidget SET <<<<<<<<<<<<<<<< : ");
    }else{
      console.log("EFormWidget - "+ loadingEForm +", eForm not found for: " + JSON.stringify(content));
    }
  }, [loadingEForm]);

  // const schema: RJSFSchema = eForm.schema
  // const uiSchema = eForm.uiSchema;
  const log = (type) => console.log.bind(console, type);

  return (
    <MDBox m={2} p={2}>

      {uiSchema && schema ?

      <Form uiSchema={uiSchema}
        schema={schema}
        validator={validator}
        onChange={log('changed')}
        onSubmit={log('submitted')}
        onError={log('errors')}
      />
        :
        <MDBox>
        <Spinner label={"Loading Eform ..."} />

      </MDBox>}

      {/*...........*/}
      {/*{JSON.stringify(eForm)}*/}
      {/*-------------------*/}
      {/*{JSON.stringify(schema)}*/}
    </MDBox>
  );
}

export default EFormWidget;
