import React from "react";
import MDBox from "components/MDBox";
import CircularProgress from '@mui/material/CircularProgress';
import Grid from "@mui/material/Grid";
import Fade from '@mui/material/Fade';
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";

interface Props {
    message?: string;
    hint?: string;
  color?: string;
  bgTransparent?: boolean;
  minHeight?: number;
  height?: number;
  action?:Function
}

function QuickMessageBox(props: Props) {
  return (
              <Fade
                in={true}
                style={{
                    transitionDelay: '800ms',
                }}
                unmountOnExit
            >
      <MDBox mt={props.action?0:4}
             sx={({
                      borders: { borderRadius },
                      functions: { pxToRem },
                      palette: { light },
                      transitions,
                  }) => ({
                 display: "flex",
                 alignItems: "center",
                 flexDirection: 'column',
                 height:props.height,
                 minHeight:props.action?180:140,
                 borderRadius: borderRadius.md,
                 padding: `${pxToRem(40)} ${pxToRem(16)}`,
                 transition: transitions.create("background-color", {
                     easing: transitions.easing.easeInOut,
                     duration: transitions.duration.shorter,
                 }),
                 backgroundColor: props.bgTransparent? "transparent" : light.main ,
             })
             }
      >
          <MDTypography variant="body2" fontWeight="medium">
              {props.message || "No Content Found"}
          </MDTypography>
          {props.hint && <MDTypography variant="button" color="text">
              {props.hint}
          </MDTypography>}
          {props.action && <MDBox mt={2}>
              <MDButton variant="contained" color={props.color || "primary"} size="medium"
                        onClick={props.action}>
                  Add Content
              </MDButton>
          </MDBox>}
      </MDBox>
              </Fade>
  );
}

export default QuickMessageBox;
