import React, { memo } from "react";
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Images
import { useMaterialUIController } from "context";
import { useSubmissionContextHook } from "../../../../../hooks/submission-hooks";
import FullBodyCard from "../../../../custom-components/FullBodyCard";
import MiniInfoCard from "../../../../custom-components/InfoCards/MiniInfoCard";

function SubmissionHeader() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const {
    submissionId,
    loadingSubmission,
    submissionRows,
    submissionPCP,
    summary,
    submissionMain,
    submissionLoaded,
    reloadSubmission
  } = useSubmissionContextHook();

  if (loadingSubmission) {
    return (
      <MDBox mt={4} mb={2} minHeight="60px">
        <MDBox mb={1}>
          <MDTypography variant="h3" fontWeight="bold">
            Retrieving submission details
          </MDTypography>
        </MDBox>
        <MDTypography variant="h5" fontWeight="regular" color="secondary">
          Please wait ...
        </MDTypography>

      </MDBox>
    );
  }

  const displayHeadline = (title, subLine) => {
    return <>
      <MDBox mb={1}>
        <MDTypography variant="h3" fontWeight="bold">
          {title}

        </MDTypography>
      </MDBox>
      <MDTypography variant="h5" fontWeight="regular" color="secondary">
        {subLine}
      </MDTypography>
    </>;
  };

  return (
    <MDBox mt={6} mb={2} minHeight="60px">
      <Grid container spacing={3}>
        <Grid item xs={12} lg={9}>
          <MDBox>
            {submissionLoaded ?
              <>
                {submissionMain && submissionPCP ?
                  displayHeadline(
                    `Review ${submissionMain.name} Submission`,
                    `Last Updated : ${submissionPCP.updatedAt} by ${submissionPCP.updatedBy}`
                  )
                  :
                  displayHeadline(
                    `Loading submission data`,
                    `Please wait ...`
                  )
                }
              </>
              :
              <>
                {!submissionId ?
                  displayHeadline(
                    `Submission Not Found`,
                    `Please check the Submission Number `
                  )
                  :
                  displayHeadline(
                    `Loading submission details`,
                    `Please wait ...`
                  )}
              </>
            }
          </MDBox>
        </Grid>

        <Grid item xs={12} lg={3}>

        </Grid>

        {/* <Grid item xs={12} lg={3}> */}
        {/*   <DefaultInfoCard */}
        {/*     icon="account" */}
        {/*     title={offering.name} */}
        {/*     description={offering.categoryName} */}
        {/*     value={offering.name} */}
        {/*   /> */}
        {/* </Grid> */}


        {/* <Grid item xs={12} lg={6}> */}
        {/*   {JSON.stringify(submissionMain)} */}
        {/* </Grid> */}
        {/* <Grid item xs={12} lg={3}> */}
        {/* </Grid> */}

      </Grid>

      <MDBox mt={4} mb={2} minHeight="40px">
        <Fade in={!loadingSubmission} timeout={1300}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            {submissionPCP &&
              <FullBodyCard title={submissionPCP.participant} description={""} badge={submissionPCP.participantType} />
            }
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            {/* <IDVerificationCard */}
            {/*   state={lights} */}
            {/*   icon={ */}
            {/*     <Icon className={lights ? "text-white" : "text-dark"} fontSize="large"> */}
            {/*       lightbulb */}
            {/*     </Icon> */}
            {/*   } */}
            {/*   title="Lights" */}
            {/*   onChange={handleSetLights} */}
            {/* /> */}
            {/* {submissionPCP && */}
            {/* <MiniInfoCard */}
            {/*   color={"success"} */}
            {/*   icon="gpp_maybe" */}
            {/*   title={ */}
            {/*     <> */}
            {/*       ID Check &nbsp; */}
            {/*       <MDTypography variant="button" color="secondary" fontWeight="medium"> */}
            {/*         3rd Party */}
            {/*       </MDTypography> */}
            {/*     </> */}
            {/*   } */}
            {/*   description="Verification Completed" */}
            {/* />} */}
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            {/* <MiniInfoCard */}
            {/*   color={"success"} */}
            {/*   icon="gpp_maybe" */}
            {/*   title={ */}
            {/*     <> */}
            {/*       ID Check &nbsp; */}
            {/*       <MDTypography variant="button" color="secondary" fontWeight="medium"> */}
            {/*         3rd Party */}
            {/*       </MDTypography> */}
            {/*     </> */}
            {/*   } */}
            {/*   description="Verification required" */}
            {/* /> */}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            {submissionMain && submissionMain.refData &&
              <FullBodyCard title={submissionMain.refData.name} description={submissionMain.refData.sku}
                            badge={submissionMain.refData.categoryName} />

            }
          </Grid>
        </Grid>
        </Fade>
      </MDBox>
    </MDBox>
  );
}

export default memo(SubmissionHeader);
