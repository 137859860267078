import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import DialogContent from "@mui/material/DialogContent";
import Switch from "@mui/material/Switch";

import Collapse from '@mui/material/Collapse';
import { TransitionGroup } from 'react-transition-group';

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";


import DashboardLayout from "LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { Workflow } from "models";
import { useDateTimeFormatter } from "hooks";
import { useTranslation } from "react-i18next";
import AdminHeader from "views/custom-components/AdminHeader";
import CustomDialog from "views/custom-components/CustomDialog";
import CardSkeleton from "../../custom-components/CardSkeleton";
import WorkflowAdminCard from "./component/WorkflowAdminCard";
import WorkflowAddEdit from "./component/WorkflowDetailsEdit/WorkflowAddEdit";
import SystemWFList from "./component/SystemWFList";
import WorkflowService from "../../../services/WorkflowService";
import { useWorkflowConfigs } from "../../../hooks/workflow-hooks";
import MDTypography from "../../../components/MDTypography";
import { UserPreferencesContext } from "../../../context/UserPreferencesProvider";
import useWorkflowStore from "../../../store/workflow-store";
import BasicContainerCard from "../../custom-components/BasicContainerCard";

// import { useProviderConfigsStore } from "store/provider-configs-store";


interface Props {
  workflows: Array<Workflow>;
  reloadWorkflows: Function;
}


function AdminWorkflows(props: Props) {
  const dateFormatter = useDateTimeFormatter();
  const { t } = useTranslation();

  const { userPreferences, updateUserPreference } = useContext(UserPreferencesContext);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);

  const [filter, setFilter] = useState("");
  const [selected, setSelected] = useState<Workflow | undefined>(undefined);

  // const { workflows, reloadWorkflows, loading } = useWorkflows();
  // const { loading } = useWorkflows();
  const initStore = useWorkflowStore.use.initStore();
  const updateList = useWorkflowStore.use.updateList();
  const workflows = useWorkflowStore.use.workflows();
  const loading = useWorkflowStore.use.loading();
  const savingData= useWorkflowStore.use.saving();
  const saveWorkflow= useWorkflowStore.use.save();

  const { workflowConfigs } = useWorkflowConfigs();
  const [showSystemSamples, setShowSystemSamples] = useState(true);

  useEffect(() => {
    initStore();
  }, [initStore]);

  useEffect(() => {
    // console.log("dataObj: " + JSON.stringify(dataObj));
    // setWfNodes(advancedWFNodes);
    // setWfEdges(advancedWFEdges);
    // initStore();
    setShowSystemSamples(userPreferences.showSamples.workflow || false);

  }, []);

  const handleSetShowSystemSamples = () => {
    // updateUserPreference('showSamples', 'workflow', !showSystemSamples );
    setShowSystemSamples(!showSystemSamples);

  };

  const loadData = () => {

  };
  const openEditModal = () => {
    setIsOpenEditModal(true);
  };

  const closeEditModal = async () => {
    setIsOpenEditModal(false);
    setSelected(undefined);
    // await reloadWorkflows();
  };
  const closeDeleteModal = () => {
    setIsOpenDeleteModal(false);
  };

  const copyForUse = async (name, nodes, edges, steps) => {
    console.log("copy pressed for " + name);
    // const res = await ProviderConfigService.createProviderConfig(name,"WORKFLOW",
    //   "Copied from System",steps,nodes,edges);
    await saveWorkflow({
      name, description: "Copied from System at " + new Date(),
      categoryId: "System", categoryName: "System",
      steps: [ ...steps ], content: { nodes, edges }
    });
    // const res = await WorkflowService.createWorkflow(
    //   {
    //     name, description: "Copied from System",
    //     categoryId: "System", categoryName: "System",
    //     settings: { steps }, content: { nodes, edges }
    //   });
    // console.log("copy pressed for " + JSON.stringify(res));
    // updateList(res);
  };



  return (
    <DashboardLayout>

      <MDBox width="calc(100% - 48px)" position="absolute" top="1.75rem">
        <DashboardNavbar light absolute />
      </MDBox>
      <AdminHeader title={`Workflows (${workflows.length})`}  icon="airline_stops_outlined"
                   description="Add / modify workflows" loading={savingData || loading} >
        <MDBox
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          width={{ xs: "100%", sm: "auto" }}
          mt={{ xs: 1, sm: 0 }}
        >
          <MDBox lineHeight={0} mx={1}>
            <MDTypography variant="button" color="text">
              Copy system samples
            </MDTypography>
          </MDBox>
          <MDBox mr={0}>
            <Switch checked={showSystemSamples} onChange={handleSetShowSystemSamples} />
          </MDBox>
        </MDBox>
        <MDButton variant="gradient" color="success" onClick={openEditModal}>
          <Icon>add</Icon>&nbsp; Add New
        </MDButton>
      </AdminHeader>

      <CustomDialog onClose={closeEditModal} open={isOpenEditModal} fullWidth={true} maxWidth={"sm"}
                    aria-labelledby="customized-dialog-title">
        <DialogContent>
          <WorkflowAddEdit workflowData={selected}
                           onEditWorkflowCancel={closeEditModal}
                           onEditWorkflowUpdate={closeEditModal} />
        </DialogContent>
      </CustomDialog>

      <MDBox pb={3}>
        {/*{JSON.stringify(workflows1)}*/}

        <MDBox mt={5}>
          {showSystemSamples &&            <SystemWFList copyForUseFunc={copyForUse} />          }

          {/*<Grid container spacing={3}>*/}
            {loading &&
              <Grid item xs={12}>
                <CardSkeleton />
              </Grid>
            }
            <TransitionGroup>
            {!loading && workflows.map((workflow) => (
              // <Grid item xs={12} key={workflow.workflowId}>
              <Collapse key={workflow.workflowId}>
                <MDBox mb={1.5} mt={1.5}>
                  {/*<BasicContainerCard dataObj={workflow} >*/}

                  {/*</BasicContainerCard>*/}

                  <WorkflowAdminCard dataObj={workflow}
                                     title={workflow.name}
                                     badge={{ color: "error", label: workflow.categoryName }}
                                     content={JSON.stringify(workflow)}>


                    <MDBox px={0} display="flex" alignItems="center" color="text">
                      <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
                        {(workflow.state === "Draft" || workflow.state === "Review") &&
                        <MDButton variant="outlined" color="secondary"
                                  onClick={() => {
                                    setSelected(workflow);
                                    openEditModal();
                                  }}>
                          Edit Info
                        </MDButton>}
                        {workflow.state === "Published" &&
                          <MDButton variant="gradient" color="warning" sx={{ height: "100%" }}
                                    disabled={!workflow.settings || !workflow.steps}>
                            Archive
                          </MDButton>}
                        <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                          {workflow.state === "Draft" &&
                            <MDButton variant="outlined" color="error" sx={{ height: "100%" }}
                                      component={Link} to={`/admin/workflow/edit/${workflow.key}/edit-flow`}>
                              Edit Flow
                            </MDButton>}

                        </MDBox>
                      </MDBox>

                    </MDBox>
                    <MDBox p={3}>
                      <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
                        {/*{workflow.state === "Draft" &&*/}
                        {/*  <MDButton variant="outlined" color="error" sx={{ height: "100%" }}*/}
                        {/*            component={Link} to={`/admin/workflow/edit/${workflow.key}/edit-flow`}>*/}
                        {/*    Edit Flow*/}
                        {/*  </MDButton>}*/}
                        <MDButton variant="gradient" color="primary" sx={{ height: "100%" }}
                                  component={Link} to={`/admin/workflow/edit/${workflow.key}`}>
                          View Details
                        </MDButton>
                        <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>



                          {/*{workflow.state === "Published" &&*/}
                          {/*  <MDButton variant="gradient" color="warning" sx={{ height: "100%" }}*/}
                          {/*            disabled={!workflow.settings || !workflow.steps}>*/}
                          {/*    Archive*/}
                          {/*  </MDButton>}*/}
                        </MDBox>
                      </MDBox>
                    </MDBox>


                  </WorkflowAdminCard>

                </MDBox>
              </Collapse>
              // </Grid>
            ))}
            </TransitionGroup>

          {/*</Grid>*/}
          {/*providerConfigs-user-pref ...*/}
          {/*{JSON.stringify(userPreferences)}*/}
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default AdminWorkflows;
