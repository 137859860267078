import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import CardMedia from "@mui/material/CardMedia";
import Card from "@mui/material/Card";
import DialogContent from "@mui/material/DialogContent";
import Fade from "@mui/material/Fade";
// @mui icons
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import { useTranslation } from "react-i18next";

import DashboardLayout from "LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Overview page components
import Header from "./components/Header";

// Data
// Images
import { useDateTimeFormatter, usePublicTenantConfig } from "../../../hooks";
import Spinner from "../../custom-components/Spinner/Spinner";
import { useOffering } from "../../../hooks/offering-hooks";
import OfferingAddEdit from "../offering-edit/OfferingAddEdit";
import CustomDialog from "../../custom-components/CustomDialog";
import OfferingInfoCard from "../offering/components/OfferingInfoCard";
import WorkflowDisplayCard from "../workflow-list/component/WorkflowDisplayCard";
// import { advancedWFEdges, advancedWFNodes } from "../workflow/wf-data/advance-workflow-01";
import MDEditor from "../../../components/MDEditor";
import FileInput from "../../custom-components/FileInput/FileInput";
import FileService from "../../../services/FileService";
import OfferingService from "../../../services/OfferingService";
import WorkflowService from "../../../services/WorkflowService";
import MDBadge from "../../../components/MDBadge";
import WorkflowStepsDisplay from "../workflow/component/WorkflowStepsDisplay";
import ConsumerWorkflowStepsDisplay
  from "../../custom-components/ConsumerWorkflowStepsDisplay/ConsumerWorkflowStepsDisplay";
import AdminHeader from "../../custom-components/AdminHeader";
import FriendlyURLGenerator from "../../../services/FriendlyURLGenerator";
import UtilsService from "../../../services/UtilsService";
import useOfferingStore from "../../../store/offering-store";

interface PathParams {
  offeringId: string;
}

function OfferingDetails() {
  // const { settings, loadingSettings } = useSettings();
  const { tenantConfig, reloadTenantConfig, loadingTenantConfig } = usePublicTenantConfig();
  const { topicAreaLabels, topicAreas } = tenantConfig;
  // const [loading, setLoading] = useState<boolean>(false);
  const [notifyItems, setNotifyItems] = useState([]);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const { offeringId } = useParams<PathParams>();
  const { offering, loading, reloadOffering, editable } = useOffering(offeringId);
  const updateList = useOfferingStore.use.updateList();
  // const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [openModalMode, setOpenModalMode] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const dateFormatter = useDateTimeFormatter();
  const [newImageFile, setNewImageFile] = useState<File | undefined>(undefined);

  const [workflow, setWorkflow] = useState(null);
  const [wfNodes, setWfNodes] = useState(null);
  const [wfEdges, setWfEdges] = useState(null);

  const [tabValue, setTabValue] = useState("info");
  // const [imageUrls, setImageUrls] = useState([]);
  const [isLoadingImages, setIsLoadingImages] = useState(false);

  const handleSetTabValue = (event, newValue) => {
    // console.log("handleSetTabValue " + tabValue);
    setTabValue(newValue);
  };

  // const fetchImages = async () => {
  //   if (!offering || !offering.images) return;
  //   setIsLoadingImages(true);
  //   try {
  //     let loaded_s3_keys = offering.images.s3_keys.slice();
  //     console.log("fetchImages images   >> " + JSON.stringify(loaded_s3_keys));
  //     if (loaded_s3_keys.length>0) {
  //       const data = await FileService.getPublicFileUrl(loaded_s3_keys[0].s3_url);
  //       loaded_s3_keys[0].presigned_url = data;
  //       //console.log("OfferingDetails images   >> " + JSON.stringify(data));
  //       setImageUrls(loaded_s3_keys);
  //     }
  //   } catch (e) {
  //     console.log("Error in OfferingDetails images   >> ", e);
  //   } finally {
  //     setIsLoadingImages(false);
  //   }
  //
  // };

  const fetchWorkflow = useCallback(async (workflowId) => {
    const effectiveWF = await WorkflowService.fetchWorkflowById(workflowId);
    // console.log("OfferingDetails effectiveWF   >> " + JSON.stringify(effectiveWF));
    setWorkflow(effectiveWF);
    if (effectiveWF && effectiveWF.content && effectiveWF.content.nodes && effectiveWF.content.edges) {
      setWfNodes(effectiveWF.content.nodes);
      setWfEdges(effectiveWF.content.edges);
    }
  }, []);

  useEffect(() => {
    // console.log(JSON.stringify(tenantConfig));
    // console.log(JSON.stringify(settings));
    if (offering) {
      // if (offering.images && offering.images.s3_keys) {
      //   // setImageUrls([]);
      //   // fetchImages();
      // }

      const workflowKey = offering.workflow && offering.workflow.workflowKey ?
        offering.workflow.workflowKey : undefined;
      //console.log"useEffect workflowId " + workflowKey);
      if (workflowKey) {
        fetchWorkflow(workflowKey);
        // loadWFEdges(workflowId);
        // loadWFNodes(workflowId);
      }

    }
    return () => updateList(offering);
  }, [offering]);


  // const openEditModal = () => {
  //   setIsOpenEditModal(true);
  // };

  // const closeEditModal = async () => {
  //   reloadOffering();
  //   setIsOpenEditModal(false);
  //   // await reloadOfferings();
  // };

  const notify = async () => {
    //console.log"Notify Clicked" + JSON.stringify(notifyItems));
    // @ts-ignore
    notifyItems.push({ title: "Done", content: "Done Message" });
  };

  const onImageDelete = async (filepath) => {
    // images.s3_keys[0].s3_url
    if (!offering || !offering.images || !offering.images.s3_keys) {
      return;
    }

    setIsLoadingImages(true);
    try {
      const new_keys = UtilsService.removeObjFromJsonObjArray(offering.images.s3_keys,
        "s3_url", filepath);
      offering.images = {
        ...offering.images,
        s3_keys: new_keys
      };
      //console.log"update offering images to: " + JSON.stringify(offering.images));
      await OfferingService.editOffering(offering.key, offering).then(async (res) => {
        const del_res = await FileService.deletePublicFile(filepath);
        //console.logdel_res);
        reloadOffering();
      });
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoadingImages(false);
    }

  };
  const onFileProcessed = async (evt: CustomEvent) => {
    console.log("onFileProcessed evt... ", evt);
    //console.log("onFileProcessed detail... ", evt.detail);
    console.log("onFileProcessed detail. value.. ", evt.detail.value);

    if (!evt.detail || !offering) return;
    const data = evt.detail.value; //file

    if (!data) return;
    const file = data;
    setIsLoadingImages(true);
    try {
      setNewImageFile(data);
      const urlData = await FileService.uploadTenantPublicImage(file,"offering",offering.offeringId);
      console.log("Saving File result : " ,urlData);

      if (urlData) {
        let s3_keys = [];
        if (offering.images && offering.images.s3_keys) {
          s3_keys = offering.images.s3_keys;
        }
        s3_keys.push({ "s3_url": urlData.fields.key });
        offering.images = {
          ...offering.images,
          s3_keys: s3_keys
        };
        console.log("update offering to: " + JSON.stringify(offering));
        await OfferingService.editOffering(offering.key, offering);
        reloadOffering();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoadingImages(false);
    }
    // await StorageService.uploadImage(data);
    // await StorageService.uploadImage(data);

  };

  // const OImageBox = memo( function OImageBox({ images }) {
  //   // const fetchData = async (s3_keys) => {
  //   //   console.log("OImageBox was rendered at", new Date().toLocaleTimeString());
  //     return FileService.getImageUrl( images.s3_keys[0].s3_url)
  //       .then(imgUrl=>{
  //         console.log("CardMedia was rendered at", new Date().toLocaleTimeString(), imgUrl);
  //         return <CardMedia
  //           src={imgUrl}
  //           component="img"
  //           title={images.s3_keys[0].s3_url}
  //           sx={{
  //             maxWidth: "100%",
  //             margin: 0,
  //             boxShadow: ({ boxShadows: { md } }) => md,
  //             objectFit: "cover",
  //             objectPosition: "center"
  //           }}
  //         />;
  //     });
  //   // };
  //   // return fetchData(images.s3_keys);
  //   // console.log("OImageBox was rendered at", new Date().toLocaleTimeString());
  //   //
  //   // const imgUrl = fetchData().then();
  //   // console.log("imgUrl", imgUrl);
  //   // return
  // });
  const openEditModal = () => {
    setOpenModalMode("edit");
  };

  const closeModal = () => {
    setOpenModalMode("");
  };
  const openPublishModal = () => {
    setOpenModalMode("publish");
  };

  const handlePublish = async () => {
    if (!offering || !offering.userFlow || !offering.userFlow.steps) {
      console.log("Steps missing. Please revisit / fix the Flow.");
      return;
    }

    const ufs = offering.userFlow.steps;

    if (!ufs || ufs.length == 0) {
      console.log("Steps are missing / failed to generate");
      return;
    }

    try {
      const offeringData = {
        ...offering,
        state: "Published"
      };
      console.log("offeringData to update : ", JSON.stringify(offeringData));
      // console.log(JSON.stringify(values));
      await OfferingService.editOffering(offering.key, offeringData);
      reloadOffering();
      closeModal();
    } catch (e) {
      console.log(t("AddContentFailure"), e);
      setAlertMessage(t("AddContentFailure") + ": " + e?.message);
    } finally {
      // setSavingFlow(false);
    }

    // await WorkflowService.editWorkflow(workflowId, workflowData);
  };

  return (
    <DashboardLayout>

      <MDBox width="calc(100% - 48px)" position="absolute" top="1.75rem">
        <DashboardNavbar light absolute />
      </MDBox>
      <MDBox mb={2} mt={1} />


      {/*<SnackBarNotify notifyItems={notifyItems}/>*/}

      {offering ? <>
          <AdminHeader title={topicAreaLabels && `${topicAreaLabels.singular}: ${offering.name}`}
                       icon="shopping_basket_outlined"
                       description={`Last Updated ${dateFormatter(offering.updatedAt)} by ${offering.updatedBy}`}
          >
            {editable &&
              <MDButton variant="gradient" color="error" sx={{ height: "100%" }}
                        disabled={!offering.userFlow || !offering.userFlow.steps}
                        onClick={openPublishModal}>
                Publish
              </MDButton>}
          </AdminHeader>
          {/*<Header dataObj={offering} tabValue={tabValue} handleSetTabValue={handleSetTabValue}>*/}

          {/*  /!*{tabValue === "info" &&*!/*/}

          {/*  /!*}*!/*/}

          {/*  /!*{tabValue === "workflow" &&*!/*/}
          {/*  /!*  <MDBox>Workflow</MDBox>*!/*/}
          {/*  /!*}*!/*/}
          {/*</Header>*/}

          <MDBox
            // mt={4}
            // display="flex"
            alignItems="center"
            // position="relative"
            minHeight="18.75rem"
            borderRadius="md">

            <Card sx={{ overflow: "visible" }}>
              <MDBox m={2} mb={1}>
                {/*<MDBox display="flex" justifyContent="space-between" alignItems="center" mx={1} p={1}>*/}
                {/*  <MDTypography variant="h5">{offering.name} Details</MDTypography>*/}
                {/*  <MDBadge variant="contained" color="secondary" badgeContent={offering.state} />*/}
                {/*  /!*{offering && offering.state === "Draft" &&*!/*/}
                {/*  /!*  <MDButton variant="gradient" color="error" sx={{ height: "100%" }}*!/*/}
                {/*  /!*            disabled={!offering.userFlow || !offering.userFlow.steps}*!/*/}
                {/*  /!*            onClick={openPublishModal}>*!/*/}
                {/*  /!*    Publish*!/*/}
                {/*  /!*  </MDButton>}*!/*/}
                {/*</MDBox>*/}
                <MDBox px={4} display="flex" justifyContent="space-between" alignItems="center">
                  <MDTypography variant="h6" fontWeight="medium">
                    {offering.name} {" "}
                    <MDBadge variant="contained" color="secondary" badgeContent={offering.state} />
                  </MDTypography>

                  <MDBox mb={1}>
                    <MDTypography variant="caption" color="text">
                      {offering.scope}
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox px={4} mt={1}>
                  <MDTypography variant="button" color="text">
                    {offering.description}
                  </MDTypography>
                </MDBox>

                <MDBox mb={3} pt={1}>
                  <Grid container spacing={3}>

                    <Grid item xs={12} md={4} xl={4}>
                      {/*{JSON.stringify(offering.images)}*/}
                      {/*{JSON.stringify(imageUrls)}*/}
                      {offering.images && offering.images.s3_keys && offering.images.s3_keys.length > 0 ? (
                          <MDBox mt={editable ? 2 : 4} ml={6} width="70%" minHeight="25rem">
                            {/*<OImageBox images={offering.images} />*/}
                            {/*{isLoadingImages ? <MDBox mr={1} minHeight="8.75rem"> Loading image... </MDBox> :*/}

                            <MDBox>
                              <Fade
                                in={!isLoadingImages}
                                style={{
                                  transitionDelay: isLoadingImages ? "800ms" : "0ms"
                                }}
                                // unmountOnExit
                              >
                                <CardMedia
                                  // src={imageUrls[0].presigned_url}
                                  src={UtilsService.getS3PublicFilePath(offering.images.s3_keys[0].s3_url)}
                                  component="img"
                                  // title={imageUrls[0].s3_url}
                                  title={offering.images.s3_keys[0].s3_url}
                                  sx={{
                                    maxWidth: "100%",
                                    margin: 0,
                                    boxShadow: ({ boxShadows: { md } }) => md,
                                    objectFit: "cover",
                                    objectPosition: "center"
                                  }}
                                />
                              </Fade>
                              {/*<OfferingImage/>*/}
                              {editable &&
                                <MDBox
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  mt={4}
                                  position="relative"
                                  zIndex={1}
                                >
                                  <MDBox mr={1}>
                                    <MDButton variant="gradient" color="info" size="small"
                                      // onClick={notify}
                                    >
                                      edit
                                    </MDButton>
                                  </MDBox>
                                  <MDButton variant="outlined" color="error" size="small"
                                            onClick={() => onImageDelete(offering.images.s3_keys[0].s3_url)}>
                                    remove Image
                                  </MDButton>
                                </MDBox>
                              }
                            </MDBox>

                            {/*}*/}
                          </MDBox>
                        )
                        : <MDBox mt={8} ml={6} position="relative" width="70%">
                          <FileInput hint={"Choose Image"} loading={loading || isLoadingImages}
                                     accept=".png,.jpg,.jpeg,.svg"
                            // onFileProcessed={onFileProcessed}
                                     onChange={onFileProcessed}
                                     options={{ addRemoveLinks: true }}>

                          </FileInput>
                        </MDBox>
                      }
                    </Grid>
                    <Grid item xs={12} md={4} xl={4} sx={{ display: "flex" }}>
                      <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
                      <MDBox>
                        <OfferingInfoCard
                          title={offering.name}
                          description={offering.description || ""}
                          info={{
                            // state: `${offering.state}`,
                            category: `${offering.categoryName} (${offering.categoryId})`,
                            price: `${offering.price} - ${offering.priceLabel} (${offering.priceType})`,
                            scope: `${offering.scope}`,
                            sku: `${offering.sku}`
                            // updated: `${dateFormatter(offering.updatedAt)} by ${offering.updatedBy} `,
                            // friendlyURL: `${offering.friendlyURL}`
                          }}

                          action={{ onEdit: editable ? openEditModal : undefined, tooltip: "Edit Offering" }}
                          shadow={false}
                        />

                      </MDBox>
                    </Grid>


                    <Grid item xs={12} md={4} xl={4} sx={{ display: "flex" }}>
                      <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
                      <MDBox>
                        {offering.content && offering.content.text &&
                          <MDBox px={2} mb={2} pt={2}
                                 minHeight="15rem">
                            <MDTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
                              Content
                            </MDTypography>
                            {/*   {dataObj.content.text} */}
                            <MDEditor
                              // label={content.label}
                              name="content"
                              value={offering.content.text}
                              fullWidth
                              readOnly
                              theme={"bubble"}
                            />
                          </MDBox>}
                        <MDTypography variant="caption" color="text" textTransform="uppercase">
                          Public URL:
                        </MDTypography>
                        {/*<MDTypography variant="caption"  color="text" >*/}
                        {/*  {`${FriendlyURLGenerator.getHostPathForFriendlyUrl("offering",*/}
                        {/*    undefined, tenantConfig.tenantName)}/${offering.friendlyURL}`}*/}
                        {/*</MDTypography>*/}

                        <Link href={`${FriendlyURLGenerator.getHostPathForFriendlyUrl("offering",
                          undefined, tenantConfig.tenantName)}/${offering.friendlyURL}`} target="_blank">
                          <MDTypography variant="caption" color="text">
                            {`${FriendlyURLGenerator.getHostPathForFriendlyUrl("offering",
                              undefined, tenantConfig.tenantName)}/${offering.friendlyURL}`}
                          </MDTypography>
                        </Link>
                      </MDBox>
                    </Grid>
                    {/*<Grid item xs={12} md={12} xl={12} sx={{ display: "flex" }} textAlign={"right"}>*/}
                    {/*  */}

                    {/*</Grid>*/}
                  </Grid>

                </MDBox>


                <Grid container spacing={1}>


                  <Grid item xs={12} xl={4} sx={{ display: "flex" }}>
                    <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
                    {/*  <AppWorkflow/>*/}
                    {/*<OfferingSettings dataObj={offering} />*/}

                  </Grid>

                </Grid>
                {/*<Grid item xs={12} md={5} sx={{ textAlign: "right" }}>*/}
                {/*  <MDButton variant="gradient" color="info" onClick={openEditModal}>*/}
                {/*    <Icon>edit</Icon>&nbsp; Edit*/}
                {/*  </MDButton>*/}
                {/*</Grid>*/}
              </MDBox>
            </Card>
          </MDBox>

          <MDBox py={2} mt={2}>
            <Card sx={{ overflow: "visible" }}>
              <MDBox p={2} m={2}>
                <MDTypography variant="h6" fontWeight="medium">
                  Workflow {workflow && ` : ${workflow.name}`}
                </MDTypography>
                {/*<MDBox mb={1}>*/}
                {/*  <MDTypography variant="button" color="text">*/}
                {/*    Current Active Workflow*/}
                {/*  </MDTypography>*/}
                {/*</MDBox>*/}
                {wfNodes && wfEdges ?
                  <WorkflowDisplayCard name=""
                                       initialNodes={wfNodes}
                                       initialEdges={wfEdges}
                  />
                  :
                  <MDBox py={2} mt={2}>
                    No Workflow Set yet
                  </MDBox>
                }

                {workflow &&
                  <MDBox mx={4} my={2}>

                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} md={6}>
                        <MDTypography variant="button" color="text">
                          Workflow
                        </MDTypography>
                        <WorkflowStepsDisplay wfSteps={workflow.steps} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MDTypography variant="button" color="text">
                          Consumer / User flow
                        </MDTypography>
                        <ConsumerWorkflowStepsDisplay wfSteps={offering.userFlow?.steps} />
                      </Grid>
                    </Grid>
                    {/*<MDTypography variant="caption" color="text">*/}
                    {/*  {JSON.stringify(offering.userFlow)}*/}
                    {/*</MDTypography>*/}
                  </MDBox>
                }
              </MDBox>
            </Card>
          </MDBox>

          {editable &&
            <CustomDialog onClose={closeModal} open={openModalMode === "edit"} fullWidth={true} maxWidth={"lg"}
                          aria-labelledby="customized-dialog-title" color={undefined} dismissible={undefined}
            >
              <DialogContent>
                <OfferingAddEdit offeringData={offering}
                                 onEditOfferingCancel={closeModal}
                                 onEditOfferingUpdate={closeModal} />
              </DialogContent>
            </CustomDialog>
          }

          {editable &&
            <CustomDialog onClose={closeModal} open={openModalMode === "publish"} fullWidth={true} maxWidth={"sm"}
                          aria-labelledby="customized-dialog-title">
              <DialogContent>
                <MDBox height={200}>

                  <MDTypography variant="button" color="text" >
                    Confirm publish?
                  </MDTypography>
                </MDBox>

                <MDBox ml="auto" justifyContent="space-between"
                       width={{ xs: "100%", sm: "auto" }}
                       display="flex"
                       alignItems={{ xs: "flex-end", sm: "center" }}
                       flexDirection={{ xs: "column", sm: "row" }}>
                  <MDButton variant="outlined" color="secondary"
                            onClick={closeModal}>
                    {t("Cancel")}
                  </MDButton>
                  <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                    <MDButton onClick={handlePublish}
                              disabled={loading}
                              type="submit"
                              variant="gradient"
                              color="error"
                    >
                      Confirm & Publish
                    </MDButton>
                  </MDBox>
                </MDBox>
              </DialogContent>
            </CustomDialog>
          }
          {/*<MDTypography variant="caption">*/}
          {/*  {JSON.stringify(offering)}*/}
          {/*</MDTypography>*/}
        </>


        :
        <MDBox mt={4}
               display="flex"
               alignItems="center"
               position="relative"
               minHeight="18.75rem"
               borderRadius="xl">
          <Spinner label={"Loading..."} />
        </MDBox>
      }
    </DashboardLayout>
  );
}

export default OfferingDetails;
