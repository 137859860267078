import { useCallback, useEffect, useState } from "react";
import { Workflow } from "../models";
import WorkflowService from "../services/WorkflowService";
import { Auth } from "@aws-amplify/auth";

type UseWorkflowsHook = {
  loading: boolean;
  workflows: Array<Workflow>;
  reloadWorkflows: Function;
};

export function useWorkflows(isProtected?:boolean): UseWorkflowsHook {
  const [loading, setLoading] = useState(false);
  const [workflows, setWorkflows] = useState([]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const data = isProtected? await WorkflowService.fetchProtectedWorkflows() :
      await WorkflowService.fetchWorkflows();
    // console.log("useWorkflows workflows >> " + JSON.stringify(data));
    setWorkflows(data);
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    loading,
    workflows,
    reloadWorkflows: fetchData,
  };
}

type UseWorkflowHook = {
  workflow: Workflow | undefined;
  loading: boolean;
};

export function useWorkflow(workflowId: string): UseWorkflowHook {
  const [loading, setLoading] = useState(false);
  const [workflow, setWorkflow] = useState<Workflow | undefined>(undefined);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const data = await WorkflowService.fetchWorkflowById(workflowId);
    setWorkflow(data);
    setLoading(false);
  }, [workflowId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    loading,
    workflow,
  };
}


type UseConsumerWorkflowHook = {
  workflow: Workflow | undefined;
  loadingWF: boolean;
};

export function useConsumerWorkflow(workflowId: string): UseConsumerWorkflowHook {
  const [loadingWF, setLoadingWF] = useState(false);
  const [workflow, setWorkflow] = useState<Workflow | undefined>(undefined);

  const fetchData = useCallback(async () => {
    setLoadingWF(true);
    const data = await WorkflowService.fetchWorkflowById(workflowId);
    setWorkflow(data);
    setLoadingWF(false);
  }, [workflowId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    loadingWF,
    workflow,
  };
}


type UseWorkflowConfigsHook = {
  loadingWFConfigs: boolean;
  workflowConfigs: Array<any>;
  reloadWorkflowConfigs: Function;
};

export function useWorkflowConfigs(): UseWorkflowConfigsHook {
  const [loadingWFConfigs, setLoadingWFConfigs] = useState(false);
  const [samplesConfig, setShowSamples] = useState({});
  const [workflowConfigs, setWorkflowConfigs] = useState([]);

  // const getUserOrSystemConfig(samples:[], username){
  //   if (samples) {
  //     let effectivArr= samples.filter((f)=>f.configScope === username);
  //     let effectivValue= effectivArr.length ===1 && effectivArr[0] ;
  //     if (!effectivValue) {
  //       effectivArr= samples.filter((f)=>f.configScope === 'System');
  //       return effectivArr.length ===1 && effectivArr[0] ;
  //     }else {
  //       return effectivValue;
  //     }
  //   }
  // }

  const fetchData = useCallback(async () => {
    setLoadingWFConfigs(true);
    const data =  await WorkflowService.fetchWorkflowConfigs() ;
    const user = await Auth.currentAuthenticatedUser().catch(e=>{
      // setIsLoggedIn (false);
      console.log(e);
      return {};
    });
    const username =user.username;
    // console.log("useWorkflows workflows >> " + JSON.stringify(data));
    setWorkflowConfigs(data);
    if (data) {
      const samples = data.filter((d) => (
        d.name === 'showSamples'
        && (d.configScope==='System' || d.configScope===username)));

    }
    setLoadingWFConfigs(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    loadingWFConfigs,
    workflowConfigs,
    reloadWorkflowConfigs: fetchData,
  };
}