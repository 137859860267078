import { create } from "zustand";
import createSelectors from "./selectors";
import SubmissionService from "../services/SubmissionService";
import { Submission, SubmissionParticipant } from "../models";
import Auth from "@aws-amplify/auth";
import ApplicationService from "../services/AppApiService";

interface ConsumerSingleSubmissionStore {
  submissionId: string | undefined;
  submissionConfigs: {};
  submissionMain: Submission;
  applications: [];
  // wfSteps: [];
  offering: {} | undefined;
  summary: {} | undefined;
  submissionPCP: SubmissionParticipant;
  submissionRows: Submission[];
  loading: boolean;
  saving: boolean;
  getFullApplication: (submissionId: string, forceRefresh?:boolean) => Promise<any>;
  initStore: (submissionId: string) => void;
  updateList: (newData: any) => void;
  // getOptionList: () => Promise<any[]>;
  save: (newData: any) => Promise<void>;
}

const useConsumerSingleSubmissionStoreBase = create<ConsumerSingleSubmissionStore>()((set, get) => ({
  submissionId: undefined,
  submissionConfigs: {},
  submissionMain: {},
  submissionPCP: {},
  applications: [],
  // wfSteps: [],
  offering: {},
  summary: {},
  submissionRows: [],
  loading: false,
  saving: false,
  getFullApplication: async (appId:string, forceRefresh?:boolean) => {
    let applications = get().applications;
    const app = applications.find(ap => ap.appId === appId);
    if (app && !forceRefresh) return app;
    // console.log("..... Store : loading getFullApplication <<< consumerSingleSubmission");
    set({ loading: true });
    try {
      //UtilsService.getHashReplacedURI(currStep.sourceData.key)
      // const encodedAppKey = applicationKey;
      const data = await ApplicationService.fetchApplicationById(appId);
      if (data) {
        data.widgets.sort((a, b) => a.order - b.order);
        applications.push(data);
        set({ applications });
        return data;
      }
    } catch (e) {
      console.error("Error loading application ", e);
    } finally {
      set({ loading: false });
    }

  },
  getOptionList: async () => {
    let apps = get().submissionRows;
    let appOptions = [{ name: "None", key: "NONE" }];
    set({ loading: true });
    if (!apps || (apps && apps.length == 0)) {
      await get().initStore();
      apps = get().submissionRows;
    }
    apps.reduce((accumAppArr, thisApp) => {
      if (thisApp.name && thisApp.name !== "") {
        accumAppArr.push({ name: thisApp.name, key: thisApp.key });
      }
      return accumAppArr;
    }, appOptions);

    set({ loading: false });
    return appOptions;
  },
  updateList: (newData, sortCol?: string) => {
    set({ loading: true });
    const currList = get().submissionRows;
    let rows: any[] = [];
    if (!currList || currList.length === 0) {
      rows.push(newData);
    } else {
      const idx = currList.findIndex(o => {
        return o.key === newData.key;
      });

      if (idx < 0) {
        currList.push(newData);
        rows = currList;
      } else {
        rows = currList.map(obj => newData.key === obj.key ? newData : obj);
      }
    }
    set({ submissionRows: rows, loading: false });
  },
  save: async (newData) => {
    set({ saving: true });
    let apiResponse;
    try {
      if (newData.key) {
        apiResponse = await SubmissionService.editSubmission(newData.key, newData);
      } else {
        apiResponse = await SubmissionService.createSubmission(newData);
      }
      if (apiResponse && apiResponse.participantType === "PCP") {
        set({ submissionPCP: apiResponse });
      } else if (apiResponse && apiResponse.participantType === "META") {
        set({ submissionMain: apiResponse });
      }
    } catch (e) {
      console.log(e);
    } finally {
      set({ saving: false });
    }

    if (apiResponse) get().updateList(apiResponse);
  },
  initStore: async (submissionId) => {
    if (get().submissionId === submissionId) return;
    set({ loading: true });
    try {
      const data = await SubmissionService.fetchSubmissionById(submissionId);
      if (data) {
        const sRows = data;
        const user = await Auth.currentAuthenticatedUser();
        const submissionRows = sRows;


        const submissionMain = (sRows.filter((s) => s.rowType === "MAIN")[0]);
        const submissionPCP = (sRows.filter((s) => s.rowType === "PCP" && s.participant === user.username)[0]);
        const primaryPCP = (sRows.filter((s) => s.rowType === "PCP" && s.participantType === "PRIMARY")[0]);

        let summary = {

          noOfParticipants: submissionMain.participant,
          primaryApplicant: primaryPCP.name + ` ( ${primaryPCP.participant} )`,
          offeringName: submissionMain.refData.name

        };

        set({ submissionId, submissionRows, submissionMain, submissionPCP, summary });
      }
    } catch (e) {
      console.error("Error useConsumerSingleSubmissionStoreBase : initStore ", e);
    }
    set({ loading: false });
  }
}));

const useConsumerSingleSubmissionStore = createSelectors(useConsumerSingleSubmissionStoreBase);

export default useConsumerSingleSubmissionStore;