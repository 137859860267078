import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Form, Formik, useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";


import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";

import Autocomplete from "@mui/material/Autocomplete";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";


import { useTranslation } from "react-i18next";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { useFullPreview } from "hooks";
import Spinner from "../../../custom-components/Spinner/Spinner";
import MDInput from "components/MDInput";

import AppApiService from "services/AppApiService";
import UtilsService from "services/UtilsService";
import EFormWidget from "../../../custom-components/RenderApplication/components/item-renderer/EFormWidget";


interface FormValues {
  sk: string;
  name: string;
  showTitle: boolean;
  widgetType: string;
  content: { eFormId: string, title: string };
  section: string;
  updatedAt: string;
}

interface PathParams {
  applicationId: string;
}

// const EFormPreview = () => {
//   // Grab values and submitForm from context
//   const { values } = useFormikContext();
//   React.useEffect(() => {
//     // Submit the form imperatively as an effect as soon as form values.token are 6 digits long
//     if (values.content && values.content.eFormId ) {
//
//     }
//   }, [values]);
//   return null;
// };

function EFormWidgetEdit({ widgetData, onEditWidgetCancel, onEditWidgetUpdate, eForms }) {
  const navigate = useNavigate();
  const { applicationId } = useParams<PathParams>();
  const { t } = useTranslation();
  const [creatingWidget, setCreatingWidget] = useState(false);
  const { fullPreview, fullPreviewButton } = useFullPreview();
  const [alertMessage, setAlertMessage] = useState("");
  const [previewValues, setPreviewValues] = useState(undefined);
  const [initValue, setInitValue] = useState({
    name: "",
    showTitle: true,
    widgetType: "EForm",
    content: { title: eForms[0].name, eFormId: eForms[0].key},
    section: undefined,
    ...widgetData
  });
  const formik = useFormik({ initialValues: initValue });
  const ref = useRef(null);

  // const formValues = fm => {
  //   const error = getIn(fm.formik.errors, fm.name);
  //   const touch = getIn(fm.formik.touched, fm.name);
  //   // const values = getIn(fm.formik.values, fm);
  //   return touch && error ? error : fm.formik.values;
  // };
  // connect(formValues);

  const form = {
    formId: "widget-edit-form",
    formField: {
      name: {
        name: "name",
        label: t("AddEditEFormScreen.Title"),
        type: "text",
        errorMsg: "Title is required.",
        invalidMsg: "Title is invalid.",
        placeholder: t("AddEditEFormScreen.EFormTitleHint")
      },
      showTitle: {
        name: "showTitle",
        label: "show Title",
        type: "text",
        errorMsg: "showTitle is required."
      },
      widgetType: {
        name: "widgetType",
        label: "widget Type",
        type: "eForm",
        errorMsg: "widgetType is required.",
        placeholder: "widgetType"
      },
      content: {
        name: "content",
        label: "EForm Content",
        type: "text",
        placeholder: "Some description"
      },
      eFormId: {
        name: "EForm",
        label: "Client Form",
        type: "text",
        placeholder: "Select form"
      }
    }
  };
  const {
    formId, formField: {
      name, showTitle,
      widgetType, content, eFormId
    }
  } = form;
  const validations = Yup.object().shape({
    [name.name]: Yup.string().required(name.errorMsg).min(6, name.invalidMsg),
    [showTitle.name]: Yup.boolean().required(showTitle.errorMsg)
  });

  useEffect(() => {
    console.log("widgetData: " + JSON.stringify(widgetData));
    setInitValue(widgetData);

  }, [widgetData]);


  // useChangeBackgroundColor();

  // if (!loading) {
  //   crumbs.push({
  //     label: t("AddEditEFormScreen.AddEForm"),
  //     url: "",
  //   });
  // }
  const onSubmit = async (values: FormValues) => {
    try {
      setCreatingWidget(true);
      console.log(JSON.stringify(values));
      if (values.sk && values.sk.length > 10) {
        await updateWidget(values);
      } else {
        await createWidget(values);
      }

      setCreatingWidget(false);
      onEditWidgetUpdate();
    } catch (err) {
      console.log(t("AddContentFailure"), err);
      setAlertMessage(t("AddContentFailure") + ": " + err?.message);
      setCreatingWidget(false);
    }
  };

  const createWidget = async (values: FormValues) => {
    console.log(JSON.stringify(values));
    await AppApiService.createWidget(applicationId,
      values.name,
      values.widgetType,
      values.showTitle,
      values.content
    );
  };

  const updateWidget = async (values: FormValues) => {
    console.log(JSON.stringify(values));
    await AppApiService.editWidget(applicationId,
      UtilsService.generateEncodedHashReplacedKey(values.shardId, values.sk),
      values.name,
      values.showTitle,
      values.content,
      values.updatedAt
    );
  };

  const previewEForm = () => {

    if (!previewValues) return;
    const values = previewValues;
    console.log("previewEForm values", values);
    return <MDBox ml={3} aria-label={t("ContentPreview")}>

      <Grid item xs={12}>
        <MDTypography variant="h6" fontWeight="regular" color="text">
         {t("Preview")}
        </MDTypography>
      </Grid>
      <MDBox mt={2}>
        {values.showTitle ? (
          <MDTypography variant="h4">
            {values.name}
          </MDTypography>
          // <h2 className="margin-top-3 margin-left-2px">{values.name}</h2>
        ) : (
          ""
        )}
        {values.content && values.content.eFormId ? (
          <MDBox>
            <EFormWidget widget={values} />
          </MDBox>
        ) : (
          <MDBox minHeight="400">No Form Selected</MDBox>
        )}
      </MDBox>

    </MDBox>;


  };

  return (
    <>

      {creatingWidget ? (
        <Spinner
          // className="text-center margin-top-6"
          label={t("AddEditEFormScreen.Creating")}
        />
      ) : (
        <MDBox>
          <MDBox mb={1} mt={1}>
            <MDTypography variant="h5">
              {t("AddEditEFormScreen.Add")}
            </MDTypography>
          </MDBox>
          <MDBox>

            <MDBox hidden={fullPreview}>

              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <MDBox mt={2}>
                    <Formik enableReinitialize={true}
                            initialValues={formik.initialValues}
                            validationSchema={validations}
                            onSubmit={onSubmit}
                            innerRef={ref}
                    >
                      {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                        <Form id={formId} autoComplete="off"
                        >
                          {setPreviewValues(values)}
                          <MDBox mt={2}>
                            <Grid container spacing={3}>
                              <Grid item xs={12}>
                                <MDTypography variant="h6" fontWeight="regular" color="text">
                                  {t("AddEditEFormScreen.Configure")}
                                </MDTypography>
                              </Grid>
                              <Grid item xs={12}>
                                <FormField
                                  type={name.type}
                                  label={name.label}
                                  name={name.name}
                                  value={values.name}
                                  placeholder={"Title"}
                                  error={errors.name && touched.name}
                                  success={values.name.length > 5 && !errors.name}
                                />
                              </Grid>
                              <Grid item xs={12} alignItems="right">
                                <MDBox
                                  display="flex"
                                  alignItems="center"
                                  // justifyContent="flex-end"
                                  width={{ xs: "100%", sm: "auto" }}
                                  mt={{ xs: 1, sm: 0 }}
                                >
                                  <MDBox lineHeight={0} mx={0}>
                                    <MDTypography variant="button" color="text">
                                      {t("AddEditEFormScreen.ShowTitle")}
                                      {/*-                                      {values.showTitle ? "Show" : "Hide"}*/}
                                    </MDTypography>
                                  </MDBox>
                                  <MDBox mr={1}>
                                    <Switch checked={values.showTitle}
                                            inputProps={{ "aria-label": "controlled" }}
                                            onChange={(e, value) => {
                                              setFieldValue("showTitle", value);
                                            }} />
                                  </MDBox>
                                </MDBox>
                              </Grid>
                              <Grid item xs={12}>

                                {/*<FormField*/}
                                {/*  type={content.type}*/}
                                {/*  label={content.label}*/}
                                {/*  name={content.name}*/}
                                {/*  value={values.content ? values.content?.title : ""}*/}
                                {/*  placeholder={"EForm"}*/}
                                {/*  rows="4"*/}
                                {/*  multiline*/}
                                {/*  fullWidth*/}
                                {/*  error={errors.content && errors.content?.title && touched?.content?.title}*/}
                                {/*  success={values.content.title && values.content.title.length > 5 && !errors?.content?.title}*/}
                                {/*  onChange={(e) => {*/}
                                {/*    console.log(e.target.value);*/}
                                {/*    setFieldValue("content", {...values.content, title:e.target.value});*/}
                                {/*  }}*/}
                                {/*/>*/}

                                <Autocomplete
                                  defaultValue={eForms[0].name}
                                  name={eFormId.name}
                                  value={values.content ? values.content?.title : eForms[0].name}
                                  isOptionEqualToValue={(option, value) => option.name === value}
                                  options={eForms}
                                  getOptionLabel={(option: Object | string) => option["name"] || option}
                                  onChange={(e, value) => {
                                    console.log("onChange :" + JSON.stringify(value));
                                    setFieldValue("content", {
                                      ...values.content,
                                      eFormId: value ? value.key: "",
                                      title: value ? value.name : ""
                                    });
                                  }}
                                  renderInput={(params) =>
                                    <MDInput {...params} variant="standard"
                                             label={eFormId.label}
                                             hint={`${t("SelectExistingLeading")} ${eFormId.label}`}
                                             error={errors.content && errors.content?.eFormId && touched?.content?.eFormId}
                                             success={values.content.eFormId && !errors?.content?.eFormId}
                                      // required
                                    />}
                                />

                                {/*<FormField*/}
                                {/*  type={content.type}*/}
                                {/*  label={content.label}*/}
                                {/*  name={content.name}*/}
                                {/*  value={values.content ? values.content?.eFormId : ""}*/}
                                {/*  placeholder={"EForm"}*/}
                                {/*  rows="4"*/}
                                {/*  multiline*/}
                                {/*  fullWidth*/}
                                {/*  error={errors.content && errors.content?.eFormId && touched?.content?.eFormId}*/}
                                {/*  success={values.content.eFormId && values.content.eFormId.length > 5 && !errors?.content?.eFormId}*/}
                                {/*  onChange={(e) => {*/}
                                {/*    console.log(e.target.value);*/}
                                {/*    setFieldValue("content", {*/}
                                {/*      ...values.content,*/}
                                {/*      eFormId:e.target.value*/}
                                {/*    });*/}
                                {/*  }}*/}
                                {/*/>*/}

                              </Grid>

                              {alertMessage &&
                                <Grid item xs={12}>
                                  <MDAlert color="error" dismissible>
                                    <MDTypography variant="body2" color="white">
                                      {t("AddEditEFormScreen.AddError")}
                                    </MDTypography>
                                  </MDAlert>
                                </Grid>
                              }


                            </Grid>

                            <MDBox mt={2} ml="auto" justifyContent="space-between" display="flex"
                                   width={{ xs: "100%", sm: "auto" }}
                                   alignItems={{ xs: "flex-end", sm: "center" }}
                                   flexDirection={{ xs: "column", sm: "row" }}>
                              <MDButton variant="gradient" color="secondary" size="large"
                                        onClick={onEditWidgetCancel}>
                                {t("Cancel")}
                              </MDButton>
                              <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                <MDButton
                                  disabled={isSubmitting}
                                  type="submit"
                                  variant="gradient"
                                  color="primary" size="large"
                                >
                                  {widgetData && widgetData.sk ? "Update" : "Create"}
                                </MDButton>
                              </MDBox>
                            </MDBox>
                          </MDBox>
                        </Form>
                      )}
                    </Formik>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: "flex" }}>
                  <Divider orientation="vertical" sx={{ mt: 2, ml: 2, mr: 1 }} />
                  {previewEForm()}
                </Grid>

              </Grid>

            </MDBox>

          </MDBox>
        </MDBox>
      )}
    </>
  );
}

export default EFormWidgetEdit;
