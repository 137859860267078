import { API } from "aws-amplify";
import Auth from "@aws-amplify/auth";
import SettingsService from "./SettingsService";
import ProviderConfigService from "./ProviderConfigService";
import authHeaders, { apiName, execTenantAPIget, execTenantAPIpost } from "./services-base/api-base";



async function fetchEForms() {
  // await SettingsService.fetchSettings();
  return await ProviderConfigService.fetchProviderConfigsSelect('category', 'EFORM');
  // return await ProviderConfigService.fetchProviderConfigs().then(res=>{
  //   return res.filter(pc=> pc.category==="EFORM");
  // });
}

async function fetchProtectedEForms() {
  // const headers = await authHeaders();
  return await API.get(apiName, "protected/eForms", {  });
}

async function fetchEFormById(eFormId: string) {
  return await ProviderConfigService.fetchProviderConfig(eFormId);
  // @TODO: Temp solution. Should use the call with Id
  // return await ProviderConfigService.fetchProviderConfigsSelect('category', 'EFORM').then(res=>{
  //   return res.filter(pc=> pc.configId===eFormId);
  // });
}

async function createEForm(eFormInfo) {
  // const headers = await authHeaders();
  console.log(JSON.stringify(eFormInfo))
  return await execTenantAPIpost( "provider/config", eFormInfo);
}

async function editEForm( eFormKey: string,  eFormInfo) {
  // const headers = await authHeaders();
  return await ProviderConfigService.updateProviderConfig(eFormKey,eFormInfo);
}
async function deleteEForm(eFormId: string) {
  const headers = await authHeaders();
  return await API.del(apiName, `eForm/${eFormId}`, {
    headers,
  });
}

const EFormService = {
  fetchEForms,
  fetchProtectedEForms,
  fetchEFormById,
  editEForm,
  createEForm,
  deleteEForm
}

export default EFormService;
