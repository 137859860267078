import { API } from "aws-amplify";
import Auth from "@aws-amplify/auth";

import UtilsService from "./UtilsService";
import { Application, Dataset, Widget } from "../models";
import authHeaders, { apiName, execTenantAPIpost } from "./services-base/api-base";

async function fetchProviderConfigs() {
  const headers = await authHeaders();
  return API.get(apiName, "provider/configs", { headers });
}

async function fetchProviderConfig(configId:string) {
  const headers = await authHeaders();
  return API.get(apiName, `provider/config/${configId}`, { headers });
}

async function fetchProviderConfigsSelect(selectBy:string, selectValue:string) {
  return execTenantAPIpost( "provider/configs/select",
    {
      selectBy: selectBy,
      selectValue:selectValue
    });
  // return execTenantAPIpost( "provider/configs",
  //   {
  //     selectBy: selectBy,
  //     selectValue:selectValue
  //   });
}

async function fetchPublicProviderConfigs() {
  let headers = {};
  if (window.EnvironmentConfig?.authRequired) {
    headers = await authHeaders();
  }
  return API.get(apiName, `provider/pub-configs`, { headers });
}

async function editProviderConfigs(publishingGuidance: string, updatedAt: Date) {
  const headers = await authHeaders();
  return await API.put(apiName, "providerConfigs", {
    headers,
    body: {
      publishingGuidance,
      updatedAt,
    },
  });
}

async function addProviderConfigs(publishingGuidance: string, updatedAt: Date) {
  const headers = await authHeaders();
  return await API.put(apiName, "provider/config", {
    headers,
    body: {
      publishingGuidance,
      updatedAt,
    },
  });
}

async function createProviderConfig(name: string, category:str, description: string,defaultVal:{},
                                    content:{}, config:{}, parent?:string) {
  const headers = await authHeaders();
  return await API.post(apiName, "provider/config", {
    headers,
    body: {
      name,
      category,
      description,
      defaultVal,
      content,
      config,
      parent
    },
  });
}

async function updateProviderConfig(
  providerConfigKey: string,
  providerConfigValue: any
) {
  const headers = await authHeaders();
  return await API.put(apiName, `provider/config/${providerConfigKey}`, {
    headers,
    body: providerConfigValue,
    // body: {
    //   [providerConfigKey]: providerConfigValue,
    //   updatedAt,
    // },
  });
}


const ProviderConfigService = {
  fetchProviderConfigs,
  fetchProviderConfig,
  fetchProviderConfigsSelect,
  fetchPublicProviderConfigs,
  addProviderConfigs,
  editProviderConfigs,
  updateProviderConfig,
  createProviderConfig
};

export default ProviderConfigService;
