import { useEffect } from "react";

import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";


import DashboardLayout from "LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import BookingCard from "examples/Cards/BookingCard";

// Anaytics dashboard components
import SalesByCountry from "layouts/dashboards/analytics/components/SalesByCountry";

// Data
import reportsBarChartData from "layouts/dashboards/analytics/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";

// Images
import MDButton from "../../../components/MDButton";
import ActionCard from "../../custom-components/ActionCard";
import { useCurrentAuthenticatedUser } from "../../../hooks";
import { setLayout } from "../../../context";
import AdminHeader from "../../custom-components/AdminHeader";

function AdminHome() {
  const { sales, tasks } = reportsLineChartData;
  const { username, userFirstName, isAdmin, isFederatedId, isEditor, hasRole, loading:userLoading } =
    useCurrentAuthenticatedUser();
  // Action buttons for the BookingCard
  useEffect(() => {
    // console.log("username: " + username + " isAdmin: " + isAdmin);
  }, []);

  const actionButtons = (
    <>
      <Tooltip title="Refresh" placement="bottom">
        <MDTypography
          variant="body1"
          color="primary"
          lineHeight={1}
          sx={{ cursor: "pointer", mx: 3 }}
        >
          <Icon color="inherit">refresh</Icon>
        </MDTypography>
      </Tooltip>
      <Tooltip title="Edit" placement="bottom">
        <MDTypography variant="body1" color="info" lineHeight={1} sx={{ cursor: "pointer", mx: 3 }}>
          <Icon color="inherit">edit</Icon>
        </MDTypography>
      </Tooltip>
    </>
  );

  return (
    <DashboardLayout>
      <MDBox width="calc(100% - 48px)" position="absolute" top="1.75rem">
        <DashboardNavbar light absolute />
      </MDBox>
      <AdminHeader title="Home" icon="home"
                   description="Quick launch-pad to work on the next thing" />
      <MDBox py={3} minHeight="40vh">
        <Grid container>
          <MDTypography variant="h3" fontWeight="bold">
            Welcome back, {userFirstName}
          </MDTypography>
        </Grid>

        <MDBox mt={6}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="weekend"
                  title="Submissions"
                  count={381}
                  percentage={{
                    color: "success",
                    amount: "+55%",
                    label: "than last week",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="leaderboard"
                  title="Today's Users"
                  count="2,300"
                  percentage={{
                    color: "success",
                    amount: "+3%",
                    label: "than last month",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="store"
                  title="Offerings"
                  count="3P"
                  percentage={{
                    color: "success",
                    amount: "+1%",
                    label: "than yesterday",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="person_add"
                  title="New Applicants"
                  count="+91"
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "Just updated",
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={4}>
          <MDBox mb={3} mx={1}>
            <MDTypography variant="body1" fontWeight="bold">
              View Application submissions, Tasks and others
            </MDTypography>
          </MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ActionCard
                  title="Submissions & Intake"
                  content="View application submissions from your clients." >
                  <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                    <Link to={"/admin/submissions"}>
                      <MDButton variant="contained" color={"success"}>Submissions</MDButton>
                    </Link>
                  </MDBox>
                </ActionCard>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ActionCard
                  title="Tasks"
                  content="Work on the priority things in the process." >
                  <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                    <Link to={"/admin/products"}>
                      <MDButton variant="contained" color={"success"}>Tasks</MDButton>
                    </Link>
                  </MDBox>
                </ActionCard>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ActionCard
                  title="Invite & Communications "
                  content="Invite new clients or communicate with existing ones." >
                  <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                    <Link to={"admin/workflow"}>
                      <MDButton variant="contained" color={"success"}>Communications</MDButton>
                    </Link>
                  </MDBox>
                </ActionCard>
              </MDBox>
            </Grid>

          </Grid>
        </MDBox>
        {isAdmin && <>
        <Divider />
        <MDBox mt={3} >
          <MDBox mb={3} mx={1}>
            <MDTypography variant="body1" color="info" fontWeight="bold">
              Administration
            </MDTypography>
          </MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={3}>
              <MDBox mb={3}>
                <ActionCard
                  title="Offerings"
                  content="Add or update product and service offerings for your clients." >
                  <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                    <Link to={"/admin/offerings"}>
                      <MDButton variant="outlined" color={"info"}>Offerings</MDButton>
                    </Link>
                  </MDBox>
                </ActionCard>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <MDBox mb={3}>
                <ActionCard
                  title="Applications"
                  content="Setup the applications to collect information from your clients." >
                  <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                    <Link to={"/admin/applications"}>
                      <MDButton variant="outlined" color={"info"}>Applications</MDButton>
                    </Link>
                  </MDBox>
                </ActionCard>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <MDBox mb={3}>
                <ActionCard
                  title="Workflow Orchestration"
                  content="Define your application submission process and desired outcome." >
                  <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                    <Link to={"/admin/workflows"}>
                      <MDButton variant="outlined" color={"info"}>Workflow</MDButton>
                    </Link>
                  </MDBox>
                </ActionCard>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <MDBox mb={3}>
                <ActionCard
                  title="Manage Users"
                  content="Add, modify users and their roles in your organization." >
                  <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                    <Link to={"/admin/users"}>
                      <MDButton variant="outlined" color={"info"}>Users</MDButton>
                    </Link>
                  </MDBox>
                </ActionCard>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        </>}

      </MDBox>

    </DashboardLayout>
  );
}

export default AdminHome;
