import React, { useState } from "react";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";
import DialogContent from "@mui/material/DialogContent";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";


import DashboardLayout from "LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Project page components
// import Header from "./components/Header";

// Images
import team4 from "assets/images/team-4.jpg";

import { LocationState, Offering } from "models";
import { useDateTimeFormatter, useOfferings } from "hooks";
import { useTranslation } from "react-i18next";
import AdminHeader from "views/custom-components/AdminHeader";
import CustomDialog from "views/custom-components/CustomDialog";
import OfferingAddEdit from "../offering-edit/OfferingAddEdit";
import UtilsService from "../../../services/UtilsService";
import Spinner from "../../custom-components/Spinner/Spinner";
import OfferingAdminCard from "../../custom-components/OfferingAdminCard";
import OfferingInfoCard from "../offering-details/components/OfferingInfoCard";
import consumerRoutes from "../../../consumer.routes";
import DefaultNavbar from "../../../examples/Navbars/DefaultNavbar";
import routes from "../../../routes";

interface Props {
  offerings: Array<Offering>;
  reloadOfferings: Function;
}

function AgentTasks(props: Props) {
return (
  <DashboardLayout>

    <MDBox width="calc(100% - 48px)" position="absolute" top="1.75rem">
      {/*<DefaultNavbar filterScope="agent"*/}
      {/*  routes={routes}*/}
      {/*  action={{*/}
      {/*    type: "external",*/}
      {/*    route: "https://signup.consen.co/registration/",*/}
      {/*    label: "SignUp now",*/}
      {/*    color: "error",*/}
      {/*  }}*/}
      {/*  transparent*/}
      {/*  light*/}
      {/*/>*/}
      <DashboardNavbar light absolute />
    </MDBox>
    <AdminHeader title="Tasks" icon="checklist"
                 description="Manage your tasks" />
  </DashboardLayout>
);
}

export default AgentTasks;
