import React, { lazy, Suspense, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import MenuItem from "@mui/material/MenuItem";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import BookingCard from "examples/Cards/BookingCard";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
// Anaytics dashboard components
// Data

// Images
import ConsumerLayout, { withConsumerLayout } from "LayoutContainers/ConsumerLayout";
import { useConsumerSubmissions } from "../../../hooks/submission-consumer-hooks";
import Spinner from "../../custom-components/Spinner/Spinner";
import SubmissionViewCard from "../consumer-components/SubmissionViewCard";
import LinearLoading from "../../custom-components/LinearLoading";
import { useCurrentAuthenticatedUser, usePublicTenantConfig } from "../../../hooks";
import OfferingConsumerCard from "../../custom-components/OfferingConsumerCard";
import MDButton from "../../../components/MDButton";
import useConsumerOfferingsStore from "../../../store/consumer-offering-store";
import CardSkeleton from "../../custom-components/CardSkeleton";
import SubmissionService from "../../../services/SubmissionService";
import NoContentBox from "../../custom-components/QuickMessageBox";
import QuickMessageBox from "../../custom-components/QuickMessageBox";

// const SubmissionViewCard = lazy(() => import("../consumer-components/SubmissionViewCard"));
const BookingCard = lazy(() => import("examples/Cards/BookingCard"));

// import BookingCard from "examples/Cards/BookingCard";

function ConsumerDashboard() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { tenantConfig, tenantConfigLoaded, loadingTenantConfig, reloadTenantConfig } = usePublicTenantConfig();
  const { username, userFirstName,tenant, isAdmin, isLoggedIn } =
    useCurrentAuthenticatedUser();

  const [slackBotMenu, setSlackBotMenu] = useState(null);
  const openSlackBotMenu = (event) => setSlackBotMenu(event.currentTarget);
  const closeSlackBotMenu = () => setSlackBotMenu(null);
  const {
    draftsSubmissions,
    submittedSubmissions,
    reviewSubmissions,
    archivedSubmissions,
    cancelledSubmissions,
    submissions,
    reloadSubmissions,
    loading
  } = useConsumerSubmissions();

  //Submission - @Todo: Categorize by state
  // const initStore = useConsumerSubmissionStore.use.initStore();
  // const updateList = useConsumerSubmissionStore.use.updateList();
  // const submissions = useConsumerSubmissionStore.use.submissions();
  // const loadingSubStore = useConsumerSubmissionStore.use.loading();
  // const savingData= useConsumerSubmissionStore.use.saving();
  // const save= useConsumerSubmissionStore.use.save();

  // const {offerings,reloadOfferings, loading:loadingOfferings} = useOfferings();
  const initOffsStore = useConsumerOfferingsStore.use.initStore();
  const offerings = useConsumerOfferingsStore.use.offerings();
  const loadingOfferings = useConsumerOfferingsStore.use.loading();
  const applyInProgress = useConsumerOfferingsStore.use.working();
  const applyForOffering = useConsumerOfferingsStore.use.applyForOffering();


  useEffect(() => {
    if (!loadingTenantConfig) {
      console.log(">> ConsumerDashboard loadingTenantConfig " + loadingTenantConfig);
      if (tenantConfigLoaded) {
        initOffsStore(tenantConfig.authRequired, tenantConfig.tenantId, isAdmin);
      } else {
        console.log(">>> ConsumerDashboard loadingTenantConfig " + loadingTenantConfig);
        navigate("/provider-lookup");
      }

    }
  }, [loadingTenantConfig]);


  // Action buttons for the BookingCard
  const actionButtons = (
    <>
      <Tooltip title="Refresh" placement="bottom">
        <MDTypography
          variant="body1"
          color="primary"
          lineHeight={1}
          sx={{ cursor: "pointer", mx: 3 }}
        >
          <Icon color="inherit">refresh</Icon>
        </MDTypography>
      </Tooltip>
      <Tooltip title="Edit" placement="bottom">
        <MDTypography variant="body1" color="info" lineHeight={1} sx={{ cursor: "pointer", mx: 3 }}>
          <Icon color="inherit">edit</Icon>
        </MDTypography>
      </Tooltip>
    </>
  );

  const renderMenu = (state, data, close) => (
    <Menu
      anchorEl={state}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
    >
      <MenuItem onClick={() => handleClickContinueSubmission(data)}>Continue</MenuItem>
      <MenuItem onClick={()=>handleCancelSubmission(data)}>Cancel</MenuItem>
    </Menu>
  );

  const handleClickContinueSubmission = (submissionData) => {
    console.log("Continue -" + JSON.stringify(submissionData));
    navigate(`/app/submission/${submissionData.submissionId}`, {
      state: {
        notification: "alert",
        type: "success",
        message: t("AddSectionScreen.AddSectionSuccess", {
          title: submissionData.name
        })
      }
    });
  };

  const handleCancelSubmission = async (submissionData) => {
    closeSlackBotMenu();
      await SubmissionService.deleteSubmission(submissionData.submissionId)
        .then(res=>{
          console.log("Cancelled Submission -" + JSON.stringify(res));
          reloadSubmissions();
        })

        .catch ((e)=> {
      console.error(e);
    })
  };

  const handleApply = async (offeringKey) => {
    try {
      const submission = await applyForOffering(offeringKey);
      navigate(`/app/submission/${submission.metadata.submissionId}`);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <MDBox>

      {/*<ConsumerHeader title={"Dashboard"}*/}
      {/*                subTitle={""} />*/}
      <MDBox pt={4}>
        <MDBox container>
          <MDTypography variant="h3" fontWeight="bold">
          {userFirstName}, Welcome back to your Dashboard
          </MDTypography>
          <MDTypography variant="body2" fontWeight="regular">
            Should we continue where we left off last time?
          </MDTypography>
        </MDBox>


        <MDBox mt={4} mb={6} minHeight="18rem">
          <MDBox mb={2} >

            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={4}>
                <MDTypography variant="h4" fontWeight="bold">
                Continue Submissions
                </MDTypography>
                {/*{JSON.stringify(slackBotMenu)}*/}
              </Grid>
               <Grid item xs={12} md={6} lg={8}>
                {/* {loading && <MDBox*/}

                {/*  sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({*/}
                {/*    px: 3,*/}
                {/*    position: "relative",*/}
                {/*    [breakpoints.up("xl")]: {*/}
                {/*      marginLeft: pxToRem(120),*/}
                {/*      transition: transitions.create(["margin-left", "margin-right"], {*/}
                {/*        easing: transitions.easing.easeInOut,*/}
                {/*        duration: transitions.duration.standard*/}
                {/*      })*/}
                {/*    }*/}
                {/*  })}*/}
                {/*>*/}
                {/*  <LinearLoading />*/}
                {/*</MDBox>}*/}

              </Grid>
            </Grid>
          </MDBox>
          <Grid container spacing={3}>
            {submissions && submissions.length > 0 ? <>
              {submissions.filter(f => f.rowType === "PCP").map(s =>
                <Grid item key={s.sk} xs={12} md={6} lg={4}>
                  <Fade in={!loading} timeout={800}>
                  <MDBox mb={3}>
                    {/*<Suspense fallback={<div> ... </div>}>*/}
                      {/*{JSON.stringify(s)}*/}
                      <SubmissionViewCard
                        submissionData={s}
                        // image={logoCS}
                        // title={s.name || "No Name"}
                        // description={s.submissionId}
                        // dateTime={s.updatedAt}
                        // members={[team1, team2, team3, team4, team5]}
                        clickAction={handleClickContinueSubmission}
                        dropdown={{
                          action: openSlackBotMenu,
                          menu: renderMenu(slackBotMenu, s, closeSlackBotMenu)
                        }} />
                    {/*</Suspense>*/}
                  </MDBox>
                  </Fade>
                </Grid>
              )}
            </>:
              <Grid item xs={12} >

                {loading ? <Spinner minHeight={200}/>
                  :
                <QuickMessageBox message={"No Submissions Found"} bgTransparent
                            minHeight={300}
                            hint={"When you apply, your Submissions will show up here"}
              />
                }

              </Grid>
            }

          </Grid>

          {!loading && cancelledSubmissions && cancelledSubmissions.length > 0 &&
            <MDTypography variant="caption" fontWeight="regular">
              * Excludes {cancelledSubmissions.length} Cancelled Submissions
            </MDTypography>
          }
        </MDBox>


        <MDBox mt={2} mb={4}>
          <MDBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} >
                <MDTypography variant="h4" fontWeight="regular">
                Available Offerings
                </MDTypography>
              </Grid>

              {loadingOfferings && <>
                  <Grid item xs={12} md={4} >
                      <CardSkeleton/>
                  </Grid>
                <Grid item xs={12} md={4} >
                  <CardSkeleton/>
                </Grid>
                <Grid item xs={12} md={4} >
                  <CardSkeleton/>
                </Grid>
              </>
              }

              {/*{loadingOfferings && <Grid item xs={12} md={6} lg={8}>*/}
              {/*  <MDBox*/}
              {/*    minHeight="10vh"*/}
              {/*    sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({*/}
              {/*      p: 3,*/}
              {/*      position: "relative",*/}
              {/*      [breakpoints.up("xl")]: {*/}
              {/*        marginLeft: pxToRem(120),*/}
              {/*        transition: transitions.create(["margin-left", "margin-right"], {*/}
              {/*          easing: transitions.easing.easeInOut,*/}
              {/*          duration: transitions.duration.standard*/}
              {/*        })*/}
              {/*      }*/}
              {/*    })}*/}
              {/*  >*/}
              {/*    <Spinner />*/}
              {/*  </MDBox>*/}
              {/*</Grid>}*/}
            </Grid>
          </MDBox>
          <Grid container spacing={3}>

            {!loadingOfferings && offerings && offerings.map((offering) => (
              <Grid item xs={12} md={6} lg={4} key={offering.offeringId}>
                <MDBox mb={1.5} mt={1.5}>
                  <Suspense fallback={<div> ... </div>}>
                    <OfferingConsumerCard dataObj={offering} title={offering.name}
                      // badge={{ color: "info", label: offering.categoryName }}
                                          content={JSON.stringify(offering)}>
                      {/*<MDButton variant="outlined" color="secondary"*/}
                      {/*          component={Link}*/}
                      {/*          to={`/app/offering/details/${offering.shardId}:${offering.offeringId}`}>*/}
                      {/*  More...*/}
                      {/*</MDButton>*/}
                      <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                        <MDButton variant="contained" color="info" sx={{ height: "100%" }}
                                  disabled={applyInProgress}
                                  onClick={() => handleApply(offering.key)}
                        >
                          Apply
                        </MDButton>
                      </MDBox>
                    </OfferingConsumerCard>
                    {/*<BookingCard*/}
                    {/*  image={booking1}*/}
                    {/*  title={offering.name}*/}
                    {/*  description={offering.description}*/}
                    {/*  price={`${(offering.price)} / ${offering.priceLabel} (${(offering.priceType)})`}*/}
                    {/*  // location="Barcelona, Spain"*/}
                    {/*  // action={actionButtons}*/}
                    {/*/>*/}

                  </Suspense>

                </MDBox>
              </Grid>
            ))
            }


            {/*<Grid item xs={12} md={6} lg={4}>*/}
            {/*  <MDBox mt={3}>*/}
            {/*    <BookingCard*/}
            {/*      image={booking2}*/}
            {/*      title="Office Studio"*/}
            {/*      description='The place is close to Metro Station and bus stop just 2 min by walk and near to "Naviglio" where you can enjoy the night life in London, UK.'*/}
            {/*      price="$1.119/night"*/}
            {/*      location="London, UK"*/}
            {/*      action={actionButtons}*/}
            {/*    />*/}
            {/*  </MDBox>*/}
            {/*</Grid>*/}

          </Grid>
        </MDBox>
      </MDBox>

    </MDBox>
  );
}

export default withConsumerLayout(ConsumerDashboard);
