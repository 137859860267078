import { API } from "aws-amplify";
import Auth from "@aws-amplify/auth";
import authHeaders, { apiName } from "./services-base/api-base";


async function fetchOfferings() {
  console.log('offerings');
  const headers = await authHeaders();
  return await API.get(apiName, "offerings", { headers });
}

async function fetchPublicOfferings(tenantId:string) {
  // const headers = await authHeaders();
  console.log('public/offerings for ' + tenantId);
  return await API.get(apiName, "public/offerings/"+ tenantId,{});
}

async function fetchOfferingById(offeringId: string) {
  const headers = await authHeaders();
  return await API.get(apiName, `offering/${offeringId}`, { headers });
}

async function createOffering(offeringInfo) {
  const headers = await authHeaders();
  return await API.post(apiName, "offering", {
    headers,
    body: offeringInfo,
  });
}

async function editOffering( offeringKey: string,  offeringInfo) {
  const headers = await authHeaders();
  return await API.put(apiName, `offering/${offeringKey}`, {
    headers,
    body: offeringInfo,
  });
  //   .catch(e=>{
  //   console.log("e : " + JSON.stringify(e.message));
  // });
}
async function deleteOffering(offeringId: string) {
  const headers = await authHeaders();
  return await API.del(apiName, `offering/${offeringId}`, {
    headers,
  });
}

const OfferingService = {
  fetchOfferings,
  fetchPublicOfferings,
  fetchOfferingById,
  editOffering,
  createOffering,
  deleteOffering
}

export default OfferingService;
