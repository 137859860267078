// prop-types is library for typechecking of props
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function DefaultInfoNodeCard({nodeData, color, icon, title, description, value }) {
  return (
    <MDBox>
      <MDBox pb={1} px={1} display="flex" justifyContent="center">
        <MDBox
          display="grid"
          justifyContent="center"
          alignItems="center"
          bgColor={color}
          color="white"
          width="2rem"
          height="2rem"
          shadow="md"
          borderRadius="lg"
          variant="gradient"
        >
          <Icon fontSize="small">{icon}</Icon>
        </MDBox>
      </MDBox>
      <MDBox p={0} px={0} textAlign="center" >
        <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
        <br/>
        {description && (
          <MDTypography variant="overline" color="text" fontWeight="regular">
            {description}
          </MDTypography>
        )}
        {/* {description && value ? null : <Divider />} */}
        {value && (
          <MDTypography variant="body2" fontWeight="regular">
            {value}
          </MDTypography>
        )}
        {/* -d {JSON.stringify(nodeData)} d- */}
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of DefaultInfoNodeCard
DefaultInfoNodeCard.defaultProps = {
  color: "info",
  value: "",
  description: "",
};

// Typechecking props for the DefaultInfoNodeCard
DefaultInfoNodeCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DefaultInfoNodeCard;
