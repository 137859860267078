import { User } from "../models";
import authHeaders, { apiName, regApiGatewayUrl } from "./services-base/api-base";
import { API } from "aws-amplify";

async function fetchUsers(): Promise<User[]> {
  const headers = await authHeaders();
  return await API.get(regApiGatewayUrl, "users", { headers });
}

async function createTenantUser(userInfo) {
  const headers = await authHeaders();
  return await API.post(regApiGatewayUrl, "user", {
    headers,
    body: userInfo,
  });
}

async function removeUsers(usernames: Array<string>) {
  const headers = await authHeaders();
  return await API.del(apiName, "user", {
    headers,
    body: {
      usernames,
    },
  });
}


async function resendInvite(emails: Array<string>) {
  const headers = await authHeaders();
  return await API.post(apiName, "user/invite", {
    headers,
    body: {
      emails: emails.join(","),
    },
  });
}

async function changeRole(role: string, usernames: Array<string>) {
  const headers = await authHeaders();
  return await API.put(apiName, "user/role", {
    headers,
    body: {
      role,
      usernames: usernames,
    },
  });
}


const UserService = {
  fetchUsers,
  createTenantUser,
  removeUsers,
  resendInvite,
  changeRole,
}

export default UserService;