import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Form, Formik, useFormik } from "formik";
import PropTypes from "prop-types";
import { Link, useNavigate, useParams } from "react-router-dom";


import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

import Autocomplete from "@mui/material/Autocomplete";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";


import { useTranslation } from "react-i18next";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { useSettings, useTopicAreas } from "hooks";
import Spinner from "../../../custom-components/Spinner/Spinner";
import MDInput from "components/MDInput";

import { WidgetType } from "models";
import {
  useDashboard,
  useFullPreview,
  useChangeBackgroundColor,
  useWindowSize
} from "hooks";

import AppApiService from "services/AppApiService";
import UtilsService from "../../../../services/UtilsService";


interface FormValues {
  sk: string;
  name: string;
  showTitle: boolean;
  widgetType: string;
  content: {};
  section: string;
  updatedAt: string;
}

interface PathParams {
  applicationId: string;
}

function ImageWidgetEdit( { widgetData, onEditWidgetCancel, onEditWidgetUpdate }) {
  const navigate = useNavigate();
  const { applicationId } = useParams<PathParams>();
  const { t } = useTranslation();
  const [creatingWidget, setCreatingWidget] = useState(false);
  const { fullPreview, fullPreviewButton } = useFullPreview();
  const [alertMessage, setAlertMessage] = useState("");
  const [initValue, setInitValue] = useState(widgetData || {
    name: widgetData?.name || "",
    showTitle: widgetData?.showTitle || true,
    widgetType: widgetData?.widgetType || "Image",
    content: widgetData?.content || {
      "title": "title",
      "imageAltText": "imageAltText",
      "summary": "summary",
      "summaryBelow": "summaryBelow",
      "s3Key": "s3Key",
      "fileName": "fileName",
      "scalePct": "scalePct",
    },
    section: widgetData?.section
  });
  const formik = useFormik({initialValues: initValue});

  const form = {
    formId: "widget-edit-form",
    formField: {
      name: {
        name: "name",
        label: t("AddTextScreen.TextTitle"),
        type: "text",
        errorMsg: "Title is required.",
        invalidMsg: "Title is invalid.",
        placeholder: t("AddTextScreen.TextTitleHint")
      },
      showTitle: {
        name: "showTitle",
        label: "show Title",
        type: "text",
        errorMsg: "showTitle is required."
      },
      widgetType: {
        name: "widgetType",
        label: "widget Type",
        type: "text",
        errorMsg: "widgetType is required.",
        placeholder: "widgetType"
      },
      content: {
        name: "content",
        label: "Text Content",
        type: "text",
        placeholder: "Some description"
      },
      title: {
        name: "title",
        label: "title",
        type: "text",
        placeholder: "Some title"
      },
      imageAltText: {
        name: "imageAltText",
        label: "imageAltText",
        type: "text",
        placeholder: "Some imageAltText"
      },
      summary: {
        name: "summary",
        label: "Text summary",
        type: "text",
        placeholder: "Some summary"
      },
      scalePct: {
        name: "scalePct",
        label: "Text scalePct",
        type: "text",
        placeholder: "Some scalePct"
      }
    }
  };
  const { formId, formField: { name, showTitle, widgetType, content,
    title, imageAltText, summary, scalePct} } = form;
  const validations = Yup.object().shape({
    [name.name]: Yup.string().required(name.errorMsg).min(6, name.invalidMsg),
    [showTitle.name]: Yup.boolean().required(showTitle.errorMsg)
  });

  useEffect(() => {
    console.log("widgetData: " + JSON.stringify(widgetData));
    setInitValue(widgetData);

  }, [widgetData]);



  useChangeBackgroundColor();

  // if (!loading) {
  //   crumbs.push({
  //     label: t("AddTextScreen.AddText"),
  //     url: "",
  //   });
  // }
  const onSubmit = async (values: FormValues) => {
    try {
      setCreatingWidget(true);
      console.log(JSON.stringify(values));
      if (values.sk && values.sk.length>10){
        await updateWidget(values);
      }else{
        await createWidget(values);
      }

      setCreatingWidget(false);
      onEditWidgetUpdate();
    } catch (err) {
      console.log(t("AddContentFailure"), err);
      setAlertMessage(t("AddContentFailure") + ": " + err?.message);
      setCreatingWidget(false);
    }
  };

  const createWidget = async (values: FormValues) => {
      console.log(JSON.stringify(values));
      await AppApiService.createWidget(applicationId,
        values.name,
        values.widgetType,
        values.showTitle,
        values.content
      );
  };

  const updateWidget = async (values: FormValues) => {
      console.log(JSON.stringify(values));
      await AppApiService.editWidget(applicationId,
        UtilsService.generateEncodedHashReplacedKey(values.shardId, values.sk),
        values.name,
        values.showTitle,
        values.content,
        values.updatedAt
      );
  };

  return (
    <>

      {creatingWidget ? (
        <Spinner
          // className="text-center margin-top-6"
          label={t("AddTextScreen.CreatingText")}
        />
      ) : (
        <MDBox>
          <MDBox mb={1} mt={1}>
            <MDTypography variant="h5">
              {t("AddTextScreen.AddText")}
            </MDTypography>
          </MDBox>
          <MDBox>

            <MDBox hidden={fullPreview}>

              <Formik enableReinitialize={true}
                initialValues={formik.initialValues}
                validationSchema={validations}
                onSubmit={onSubmit}
              >
                {({ values, errors, touched, isSubmitting, setFieldValue, success }) => (
                  <Form id={formId} autoComplete="off"
                        >
                    <MDBox mt={2}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <MDBox mt={2}>
                            <Grid container spacing={3}>
                              <Grid item xs={12}>
                                <MDTypography>
                                  {t("AddTextScreen.Configure")}
                                </MDTypography>
                              </Grid>
                              <Grid item xs={12}>
                                <FormField
                                  type={name.type}
                                  label={name.label}
                                  name={name.name}
                                  value={values.name}
                                  placeholder={"Title"}
                                  error={errors.name && touched.name}
                                  success={values.name.length > 5 && !errors.name}
                                />
                              </Grid>
                              <Grid item xs={12} alignItems="right">
                                <MDBox
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="flex-end"
                                  width={{ xs: "100%", sm: "auto" }}
                                  mt={{ xs: 1, sm: 0 }}
                                >
                                  <MDBox lineHeight={0} mx={2}>
                                    <MDTypography variant="button" color="text">
                                      {t("AddTextScreen.ShowTitle")} -
                                      {values.showTitle ? "Show" : "Hide"}
                                    </MDTypography>
                                  </MDBox>
                                  <MDBox mr={1}>

                                    <Switch checked={values.showTitle}
                                            inputProps={{ "aria-label": "controlled" }}
                                            onChange={(e, value) => {
                                              setFieldValue("showTitle", value);
                                            }} />
                                  </MDBox>
                                </MDBox>
                              </Grid>
                              <Grid item xs={12}>

                                <FormField
                                  type={summary.type}
                                  label={summary.label}
                                  name={summary.name}
                                  value={values.content ? values.content?.title : ""}
                                  placeholder={"title"}
                                  rows="4"
                                  multiline
                                  fullWidth
                                  error={errors.content && errors.content?.title && touched?.content?.title}
                                  success={values.content.title && values.content.title.length > 5 && !errors?.content?.title}
                                  onChange={(e) => {
                                    console.log(e.target.value);
                                    setFieldValue("content", {
                                      ...values.content,
                                      title:e.target.value});
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12}>

                                <FormField
                                  type={imageAltText.type}
                                  label={imageAltText.label}
                                  name={imageAltText.name}
                                  value={values.content ? values.content?.imageAltText : ""}
                                  placeholder={"imageAltText"}
                                  fullWidth
                                  error={errors.content && errors.content?.imageAltText && touched?.content?.imageAltText}
                                  success={values.content.imageAltText && values.content.imageAltText.length > 5 && !errors?.content?.imageAltText}
                                  onChange={(e) => {
                                    console.log(e.target.value);
                                    setFieldValue("content", {
                                      ...values.content,
                                      imageAltText:e.target.value
                                    });
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12}>

                                <FormField
                                  type={summary.type}
                                  label={summary.label}
                                  name={summary.name}
                                  value={values.content ? values.content?.summary : ""}
                                  placeholder={"summary"}
                                  rows="4"
                                  multiline
                                  fullWidth
                                  error={errors.content && errors.content?.summary && touched?.content?.summary}
                                  success={values.content.summary && values.content.summary.length > 5 && !errors?.content?.summary}
                                  onChange={(e) => {
                                    console.log(e.target.value);
                                    setFieldValue("content", {
                                      ...values.content,
                                      summary:e.target.value});
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12}>

                                <FormField
                                  type={scalePct.type}
                                  label={scalePct.label}
                                  name={scalePct.name}
                                  value={values.content ? values.content?.scalePct : ""}
                                  placeholder={"scalePct"}
                                  rows="4"
                                  multiline
                                  fullWidth
                                  error={errors.content && errors.content?.scalePct && touched?.content?.scalePct}
                                  success={values.content.scalePct && values.content.scalePct.length > 5 && !errors?.content?.scalePct}
                                  onChange={(e) => {
                                    console.log(e.target.value);
                                    setFieldValue("content", {
                                      ...values.content,
                                      scalePct:e.target.value});
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                {alertMessage && (
                                  <MDAlert color="error" dismissible>
                                    <MDTypography variant="body2" color="white">
                                      {t("AddTextScreen.AddTextError")}
                                    </MDTypography>
                                  </MDAlert>
                                ) }

                                <MDBox ml="auto" justifyContent="space-between" display="flex"
                                       alignItems={{ xs: "flex-end", sm: "center" }}
                                       flexDirection={{ xs: "column", sm: "row" }}>
                                  <MDButton variant="gradient" color="secondary" size="large"
                                            onClick={onEditWidgetCancel}>
                                    {t("Cancel")}
                                  </MDButton>
                                  <MDButton
                                    disabled={isSubmitting}
                                    type="submit"
                                    variant="gradient"
                                    color="primary" size="large"
                                  >
                                    {widgetData && widgetData.sk ? "Update" : "Create"}
                                  </MDButton>

                                </MDBox>


                                {/*{JSON.stringify(values)}*/}
                                {JSON.stringify(errors)}
                              </Grid>
                            </Grid>
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <section
                            className={
                              fullPreview ? "tablet:grid-col-12" : "tablet:grid-col-6"
                            }
                            aria-label={t("ContentPreview")}
                          >

                            {showTitle ? (
                              <h2 className="margin-top-3 margin-left-2px">{values.name}</h2>
                            ) : (
                              ""
                            )}
                            {values.content ? (
                              <MDBox className="padding-left-05">
                                {/*<MarkdownRender*/}
                                {/*  className="usa-prose textOrSummary"*/}
                                {/*  source={text}*/}
                                {/*/>*/}
                                {values.content?.text}
                              </MDBox>
                            ) : (
                              ""
                            )}
                          </section>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </Form>
                )}
              </Formik>
            </MDBox>

          </MDBox>
        </MDBox>
      )}
    </>
  );
}

export default ImageWidgetEdit;
