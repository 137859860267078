import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBadge from "../../../../../components/MDBadge";
import { useDateTimeFormatter } from "../../../../../hooks";

function Header({name, desc, state,updatedAt,updatedBy}) {
    const dateFormatter = useDateTimeFormatter();
  return (
      <MDBox mb={1}>
    <MDBox display="flex" justifyContent="space-between" alignItems="center">
      <MDBox>
        <MDBox mb={0}>
          <MDTypography variant="h5" fontWeight="medium">
            {name}
            &nbsp;
            {state && <MDBadge variant="contained" color={state === "Draft" ? "secondary" : "info"}
                               badgeContent={state} />}
          </MDTypography>
        </MDBox>

          <MDTypography variant="caption" color="text">
              Updated {dateFormatter(updatedAt)} by {updatedBy}
          </MDTypography>
      </MDBox>
        {/*{state && <MDBadge variant="contained" color={state === "Draft" ? "secondary" : "info"}*/}
        {/*                   badgeContent={state} />}*/}
      {/*<MDButton variant="gradient" color="secondary" size="small">*/}
      {/*  Details*/}
      {/*</MDButton>*/}
    </MDBox>
          <MDBox mt={2}>
          <MDTypography variant="body2" color="text">
             {desc}
          </MDTypography>
    </MDBox>
    </MDBox>
  );
}

export default Header;
