import { API } from "aws-amplify";
import axios from "axios";
import Auth from "@aws-amplify/auth";
import { environment } from "../environments/environment";
import authHeaders, { apiName, regApiGatewayUrl } from "./services-base/api-base";

// const regApiGatewayUrl = "regApiGatewayUrl";
const publicPath = window.EnvironmentConfig?.authRequired
  ? "protected"
  : "public";


async function fetchPublicTenantConfig(tenantId: string) {
  const url = `${environment.regApiGatewayUrl}tenant/lookup/` + tenantId;
  console.log("calling tenant config : " + url);
  return axios.get(url);
}

async function fetchPublicTenantConfigByName(tenantName: string) {
  const url = `${environment.regApiGatewayUrl}tenant/init/` + tenantName;
  console.log("calling tenant config : " + url);
  return axios.get(url);
}

async function fetchTenantDetails(tenantId:string) {
  const headers = await authHeaders();
  return API.get(regApiGatewayUrl, `tenant/${tenantId}`, { headers });
}

async function saveTenantDetails( tenantId: string,  details) {
  const headers = await authHeaders();
  console.log(JSON.stringify(details));
  return await API.put(regApiGatewayUrl, `tenant/${tenantId}`, {
    headers,
    body: details,
  });
}

async function sendEmail(emailData) {
  const headers = await authHeaders();
  return await API.post(apiName, "email/send", {
    headers,
    body: emailData,
  });
}

const GlobalService = {
  fetchPublicTenantConfig,
  fetchPublicTenantConfigByName,
  fetchTenantDetails,
  saveTenantDetails,
  sendEmail
};

export default GlobalService;
