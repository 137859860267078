import { API } from "aws-amplify";
import Auth from "@aws-amplify/auth";

import UtilsService from "./UtilsService";
import { Application, Dataset, Widget } from "../models";
import authHeaders, { apiName } from "./services-base/api-base";


async function createWidget(  applicationKey: string,  name: string,  widgetType: string,
  showTitle: boolean,  content: object,  section?: string) {
  const headers = await authHeaders();
  return await API.post(apiName, `application/${applicationKey}/widget`, {
    headers,
    body: {
      name,
      widgetType,
      showTitle,
      content,
      section
    },
  });
}

async function editWidget(  applicationId: string,  widgetKey: string,  name: string,
  showTitle: boolean,  content: object,  updatedAt: Date) {
  const headers = await authHeaders();
  return await API.put(apiName, `application/widget/${widgetKey}`, {
    headers,
    body: {
      name,
      showTitle,
      content,
      updatedAt,
    },
  });
}

async function duplicateWidget(  applicationId: string,  widgetKey: string) {
  const headers = await authHeaders();
  //application/{id}/widget/{widgetKey}/duplicate
  return await API.post(apiName, `application/${applicationId}/widget/${widgetKey}/duplicate`, {
    headers,
    body: {

    },
  });
}

async function deleteWidget(   widgetKey: string) {
  const headers = await authHeaders();
  console.log("deleteWidget : " + widgetKey)
  return await API.del(apiName, `application/widget/${widgetKey}`, {
    headers,
  });
}

// async function duplicateWidget(
//   applicationKey: string,
//   widgetId: string,
//   updatedAt: Date,
//   copyLabel: string
// ) {
//   const headers = await authHeaders();
//   return await API.post(    apiName,    `application/${applicationKey}/widget/${widgetId}`,
//     {
//       headers,
//       body: {
//         updatedAt,
//         copyLabel,
//       },
//     }
//   );
// }
async function fetchWidgetById(  dashboardId: string,  widgetId: string): Promise<Widget> {
  const headers = await authHeaders();
  return await API.get(apiName, `application/widget/${widgetId}`, {
    headers,
  });
}

async function setWidgetOrder(  applicationKey: string,  widgets: Array<Widget>
): Promise<Dataset> {
  const headers = await authHeaders();
  // console.log(JSON.stringify("applicationKey " + applicationKey));
  console.log(JSON.stringify(widgets));
  const payload = widgets.map((widget) => ({
    shardId: widget.shardId,
    sk: widget.sk,
    updatedAt: widget.updatedAt,
    order: widget.order,
    section: widget.section,
    content: widget.content,
  }));
  return await API.put(apiName, `application/widgets/${applicationKey}/setorder`, {
    headers,
    body: {
      widgets:payload
    },
  });
}



async function fetchApplicationWidgets(applicationKey: string) {
  const headers = await authHeaders();
  return await API.get(apiName, `application/widgets/${applicationKey}`, {
    headers,
  });
}

async function fetchApplications(): Promise<Array<Application>> {
  const headers = await authHeaders();
  // try {
  // const getapp=await API.get(apiName, "application/id234", { headers });
  // console.log(JSON.stringify(getapp));
  // } catch (e){
  //   console.log(JSON.stringify(e));
  // }
  // await API.get(apiName, "applications", { headers });
  return await API.get(apiName, "applications", { headers });
}
async function fetchApplicationById(appId: string): Promise<Application> {
  const headers = await authHeaders();
  console.log("fetchApplicationById :" + appId);
  return await API.get(apiName, `application/${appId}`, { headers });
}

async function createApplication(appData) {
  const headers = await authHeaders();
  return await API.post(apiName, "application", {
    headers,
    body: {
      name:appData.name,
      topicAreaId:undefined,
      topicAreaName:undefined,
      scope:appData.scope,
      description:appData.description
    },
  });
}


async function editApplication( applicationKey:string, data:Application) {
  const headers = await authHeaders();
  return await API.put(apiName, `application/${applicationKey}`, {
    headers,
    body: {
      name: data.name,
      topicAreaId: data.topicAreaId,
      displayTableOfContents: data.displayTableOfContents,
      tableOfContents: data.tableOfContents,
      state: data.state,
      description: data.description,
      updatedAt: data.updatedAt,
    },
  });
}


const AppApiService = {
  createApplication,
  editApplication,
  createWidget,
  editWidget,
  deleteWidget,
  fetchWidgetById,
  setWidgetOrder,
  duplicateWidget,
  fetchApplicationWidgets,
  fetchApplications,
  fetchApplicationById
};

export default AppApiService;
