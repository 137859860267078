import React, { useCallback } from "react";
import { Handle, Position } from "reactflow";

import MDBox from "components/MDBox";
import DefaultInfoNodeCard from "./DefaultInfoNodeCard";
import MDInput from "../../../../components/MDInput";

const handleStyle = { left: 10 };

function TextNode({ data, isConnectable }) {
  const onChange = useCallback((evt) => {
    //console.log(evt.target.value);
    data.value.name = evt.target.value;
  }, []);

  return (
    <MDBox>
      {/*<Handle id="c" type="target"*/}
      {/*        position={Position.Left} isConnectable={isConnectable} />*/}

      <MDBox width="100%" mr={{ xs: 0, sm: 3 }} mb={{ xs: 3, sm: 0 }}>
        {/*<DefaultInfoNodeCard color={data.color || "warning"}*/}
        {/*                     icon={data.icon || "info"}*/}
        {/*                     title={data.label}*/}
        {/*                     description={data.description}*/}
        {/*  // value={data.value }*/}
        {/*/>*/}
        <MDInput id="text" name="text" onChange={onChange} className="nodrag" />
        {JSON.stringify(data)}
      </MDBox>
      {/*<Handle*/}
      {/*  type="source"*/}
      {/*  position={Position.Left}*/}
      {/*  id="a"*/}
      {/*  style={handleStyle}*/}
      {/*  isConnectable={isConnectable}*/}
      {/*/>*/}
      {/*<Handle type="source" position={Position.Right} id="b" isConnectable={isConnectable} />*/}
    </MDBox>
  );
}

export default TextNode;
