import React, { useState } from "react";

import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";


import DashboardLayout from "LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import BookingCard from "examples/Cards/BookingCard";
import AdminHeader from "views/custom-components/AdminHeader";

import DataTable from "examples/Tables/DataTable";

// Data
import dataTableData from "layouts/applications/data-tables/data/dataTableData";
// Anaytics dashboard components
import SalesByCountry from "layouts/dashboards/analytics/components/SalesByCountry";

// Data
import reportsBarChartData from "layouts/dashboards/analytics/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";

// Images
import booking1 from "assets/images/products/product-1-min.jpg";
import booking2 from "assets/images/products/product-2-min.jpg";
import booking3 from "assets/images/products/product-3-min.jpg";
import { useConsumerSubmissions } from "../../../hooks/submission-consumer-hooks";
import { useAdminSubmissions } from "../../../hooks/submission-admin-hooks";
import Spinner from "../../custom-components/Spinner/Spinner";
import SubmissionsTableView from "./components/SubmissionsTableView";

function AdminSubmissions() {
  const { sales, tasks } = reportsLineChartData;



  const {
    draftsSubmissions,
    submittedSubmissions,
    reviewSubmissions,
    archivedSubmissions,
    submissions,
    reloadSubmissions,

    loading
  } = useAdminSubmissions();

  // const tableHeaders = [
  //     { Header: "user", accessor: "participant", width: "20%" },
  //     { Header: "name", accessor: "name", width: "25%" },
  //     { Header: "Offering", accessor: "offering.name" },
  //     { Header: "state", accessor: "state", width: "7%" },
  //     { Header: "Updated", accessor: "updatedAt" },
  //     { Header: "salary", accessor: "salary" },
  //   ];

  // Action buttons for the BookingCard
  const actionButtons = (
    <>
      <Tooltip title="Refresh" placement="bottom">
        <MDTypography
          variant="body1"
          color="primary"
          lineHeight={1}
          sx={{ cursor: "pointer", mx: 3 }}
        >
          <Icon color="inherit">refresh</Icon>
        </MDTypography>
      </Tooltip>
      <Tooltip title="Edit" placement="bottom">
        <MDTypography variant="body1" color="info" lineHeight={1} sx={{ cursor: "pointer", mx: 3 }}>
          <Icon color="inherit">edit</Icon>
        </MDTypography>
      </Tooltip>
    </>
  );

  return (
    <DashboardLayout>
      <MDBox width="calc(100% - 48px)" position="absolute" top="1.75rem">
        <DashboardNavbar light absolute />
      </MDBox>
      <AdminHeader title="Submissions" icon="list_alt"
                   description="View / Manage Submissions"
                   loading={loading}/>
      {/*<MDBox py={3}>*/}
      {/*  <Grid container>*/}
      {/*    /!*Submissions ({!loading && submissions ? submissions.length:0}) <br/>*!/*/}
      {/*    {loading && <Spinner/>}*/}
      {/*    /!*{!loading && submissions &&*!/*/}
      {/*    /!*  <MDBox>*!/*/}
      {/*    /!*    <MDTypography variant="h5" fontWeight="medium">*!/*/}
      {/*    /!*    Total: {submissions.length} {" "}*!/*/}
      {/*    /!*    </MDTypography>*!/*/}
      {/*    /!*    </MDBox>*!/*/}
      {/*    /!*}*!/*/}
      {/*  </Grid>*/}
      {/*</MDBox>*/}

      <MDBox px={2}>
        <MDTypography variant="h5" fontWeight="medium">
          Submissions ({!loading && submissions ? submissions.length:0})
        </MDTypography>
      </MDBox>
      <MDBox pt={4} pb={3}>

        <Card>
          {/*<MDBox  pt={3} px={3} lineHeight={1}>*/}
          {/*  <MDTypography variant="h5" fontWeight="medium">*/}
          {/*    Submissions ({!loading && submissions ? submissions.length:0})*/}
          {/*  </MDTypography>*/}
          {/*  /!*<MDTypography variant="button" color="text">*!/*/}
          {/*  /!*  A lightweight, extendable, dependency-free javascript HTML table plugin.*!/*/}
          {/*  /!*</MDTypography>*!/*/}
          {/*</MDBox>*/}
          {/*<DataTable table={{columns:tableHeaders, rows:submissions}} canSearch />*/}

          <SubmissionsTableView submissions={submissions}/>
        </Card>
      </MDBox>


    </DashboardLayout>
  );
}

export default AdminSubmissions;
