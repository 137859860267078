import React, { useEffect, useState, useCallback } from "react";
import {Amplify, Hub} from "@aws-amplify/core";
// import Auth from "@aws-amplify/auth";
// import { Hub} from "@aws-amplify/core";
import { Auth} from "@aws-amplify/auth";
import { Storage} from "@aws-amplify/storage";
// import { Amplify } from 'aws-amplify';
import { UserPreferences, PublicUserPreferences } from "../models";
import EnvConfig from '../services/EnvConfig';
import UtilsService from "../services/UtilsService";
import GlobalService from "../services/GlobalService";
import { useNavigate } from "react-router-dom";
import ProviderConfigService from "../services/ProviderConfigService";

/**
 * Default userPreferences to start with while we fetch the actual
 * UserPreferences from the Tenant.
 */
const defaultUserPreferences: UserPreferences = {
  showSamples : {config:{
    workflow : false,
    application : false,
    eForm : false,
    offering : false,
  }}
};

const defAmplifyConfig = {

};

interface UserPreferencesContextProps {
  userPreferences: UserPreferences ;
  reloadUserPreferences: Function;
  updateUserPreference: Function;
  loadingUserPreferences: boolean;
}

export const UserPreferencesContext = React.createContext<UserPreferencesContextProps>({
  reloadUserPreferences: (coguser?:any) => {},
  updateUserPreference: (configId:any) => {},
  userPreferences: defaultUserPreferences,
  loadingUserPreferences: true,
});

/**
 * The userPreferences reducer takes the userPreferences object coming from the
 * backend and returns a UserPreferences object containing those values
 * and fallbacks to default values for any missing field.
 *
 * This protects the frontend from crashing in the event where the
 * backend returns a UserPreferences object that is missing certain fields.
 */
// const userPreferencesReducer = (backendUserPreferences: UserPreferences): UserPreferences => {
//   return {
//     ...backendUserPreferences, // add all values to start with
//     // Check if we need to fallback to default values
//     enterpriseLoginLabel: "Enterprise Sign-In",
//   };
// };

const userPreferencesReducer = (backendUserPreferences: []): UserPreferences => {
  let showSamplesVal = defaultUserPreferences.showSamples;
  const showSamplesData = backendUserPreferences.filter((d)=>d.name ==='showSamples');
  if (showSamplesData.length===1){
    showSamplesVal =  showSamplesData[0];
  }

  return {
    showSamples : showSamplesVal,
  };
};


/**
 * This provider wraps the root of our component's tree in <App />
 * to provide UserPreferences to all the children components in the tree. It
 * uses React Context so that userPreferences are kept in a global state instead
 * of fetching them over and over on every screen.
 */
function UserPreferencesProvider(props: { children: React.ReactNode }) {
  const navigate = useNavigate();
  const [userPreferences, setUserPreferences]
    = useState<UserPreferencesContextProps>({
    reloadUserPreferences: (coguser?:any) => {},
    updateUserPreference: (configId:any) => {},
    userPreferences: defaultUserPreferences,
    loadingUserPreferences: true,
  });

  const fetchUserPreferences = useCallback(async (usrname?:string) => {
    return await ProviderConfigService.fetchProviderConfigsSelect('category',usrname + '#PREF');
  }, []);

  const updateUserPreference = useCallback(async (name, module, value) => {
    if (name==='showSamples'){

    }
    //await ProviderConfigService.updateProviderConfig('category',usrname + '#PREF');
  }, []);

  const loadUserPreferences = useCallback(async () => {
    console.log('loadUserPreferences ...');
    const coguser = await Auth.currentAuthenticatedUser().catch(e=>{
      // setIsLoggedIn (false);
      console.log(e);
      return;
    });
    if (!coguser || !coguser.username) return;

    const username=coguser.username;

    try {
      /**
       * useCallback is used because it is important that the
       * reloadUserPreferences function does not change, because it
       * causes an infinite loop due to the userPreferences-hook
       * having a useEffect on it.
       */
      setUserPreferences({
        reloadUserPreferences: loadUserPreferences,
        updateUserPreference: (configId:any) => {},
        userPreferences: defaultUserPreferences,
        loadingUserPreferences: true,
      });

      const data:any = await fetchUserPreferences(username);
      // console.log("User Preferences : " + JSON.stringify(data));

      if (!data) {
        setUserPreferences({
          reloadUserPreferences: loadUserPreferences,
          updateUserPreference:updateUserPreference,
          userPreferences: defaultUserPreferences,
          loadingUserPreferences: false,
        });
        return;
      }

      // await visibilityConfig(data);

      setUserPreferences({
        userPreferences: userPreferencesReducer( data),
        reloadUserPreferences: loadUserPreferences,
        updateUserPreference:updateUserPreference,
        loadingUserPreferences: false,
      });


      console.log("UserPreferences loaded");



    } catch (err) {
      console.log("Failed to load userPreferences from backend");
      setUserPreferences({
        reloadUserPreferences: loadUserPreferences,
        updateUserPreference: (configId:any) => {},
        userPreferences: defaultUserPreferences,
        loadingUserPreferences: false,
      });
    }



    // const sess = await Auth.currentSession();
    // console.log("Session : " + JSON.stringify(sess));
  }, [fetchUserPreferences, updateUserPreference]);

  async function visibilityConfig(res:any) {
    let configuration: { [k: string]: any } = defAmplifyConfig;

    // console.log(JSON.stringify(res.data));
    const cfg=res.data;

    if (!cfg.userPoolId || !cfg.appClientId) {
      return;
    }
    const region = cfg.userPoolId?.split('_')[0];

    const beApi = configuration.API.endpoints.filter(ep => ep.name === "BackendApi");
    if (beApi.length===0) {
      configuration.API.endpoints.push({
        name: "BackendApi",
        endpoint: cfg.apiGatewayUrl,
      });
    }

    configuration.Auth = {
      region: region,
      userPoolId: cfg.userPoolId,
      userPoolWebClientId: cfg.appClientId,
      // (required) only for Federated Authentication - Amazon Cognito Identity Pool ID
      // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
    };

    configuration.Storage.AWSS3.region = region;

    // console.log("Loading Amplify Config with : " + JSON.stringify(configuration));

    if (EnvConfig.samlProvider) {
      configuration.oauth = oauthConfig;
    }
    // await Amplify.configure(configuration);
    // await Auth.configure(configuration);
    // await Storage.configure(configuration);
    // console.log ("Amplify Configured with: " + JSON.stringify(configuration));
  }

  const oauthConfig = {
    domain: EnvConfig.cognitoDomain,
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: EnvConfig.frontendDomain,
    redirectSignOut: EnvConfig.frontendDomain,
    responseType: "code", // or token
    // optional, for Cognito hosted ui specified options
    options: {
      // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
      AdvancedSecurityDataCollectionFlag: true,
    },
  };
  /**
   * Listen for authentication events so that when users
   * signIn or their token is refreshed, we refetch the
   * UserPreferences. This covers an edge case in which we fail
   * to fetch UserPreferences the first time because the user was
   * not authenticated yet.
   */
  const listenAuthEvents = useCallback(
    (event: any) => {
      const { payload } = event;
      switch (payload.event) {
        case "signIn":
        case "tokenRefresh":
          console.log("UserPreferencesProvider Detected AuthEvents: " + payload.event)
          // if (payload.event==='signIn'){
          //   // const usrname = Auth.
          //   console.log("Full AuthEvents after signIn: " + JSON.stringify(payload.data));
          //   if (payload.data.attributes && payload.data.attributes["custom:usrname"]){
          //     const authUsrname = payload.data.attributes["custom:usrname"];
          //     console.log("Found Usrname from Auth SignIn...");
          //     window.sessionStorage.setItem('usrname', authUsrname);
          //     window.sessionStorage.removeItem('tenantName');
          //     // return authUsrname;
          //   }
          // }
          loadUserPreferences();
          break;
        default:
          break;
      }
    },
    [loadUserPreferences]
  );

  useEffect(() => {
    loadUserPreferences();
    Hub.listen("auth", listenAuthEvents);
    return () => Hub.remove("auth", listenAuthEvents);
  }, [loadUserPreferences, listenAuthEvents]);

  return (
    <UserPreferencesContext.Provider value={userPreferences}>
      {props.children}
    </UserPreferencesContext.Provider>
  );
}

export default UserPreferencesProvider;
