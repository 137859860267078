import React, { useEffect, useState, lazy, Suspense } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import DialogContent from "@mui/material/DialogContent";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { useTranslation } from "react-i18next";

// import { useDashboard, useChangeBackgroundColor } from "hooks";
// import Breadcrumbs from "../components/Breadcrumbs";
// import Alert from "../components/Alert";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "LayoutContainers/DashboardLayout";
// import AppWidgetTree from "./components/AppWidgetTree";
import { Widget, WidgetType } from "../../../models";
// import BackendService from "../../../services/BackendService";
import AppApiService from "../../../services/AppApiService";
import { useApplication } from "../../../hooks/application-hooks";
import Spinner from "../../custom-components/Spinner/Spinner";
import EditWidgetItem from "./components/EditWidgetItem";
import WidgetToolbar from "./components/WidgetToolbar/WidgetToolbar";
import ViewDashboardAdmin from "../../custom-components/RenderApplication/ViewDashboardAdmin";
import CustomDialog from "../../custom-components/CustomDialog";
import AdminHeader from "../../custom-components/AdminHeader";
import MDButton from "components/MDButton";
import UtilsService from "../../../services/UtilsService";
import TextWidgetEdit from "./widget-edit/TextWidgetEdit";
import ImageWidgetEdit from "./widget-edit/ImageWidgetEdit";
import EFormWidgetEdit from "./widget-edit/EFormWidgetEdit";
import RenderApplication from "../../custom-components/RenderApplication";
import { useEForms } from "hooks";
import useSingleApplicationStore from "../../../store/single-application-store";
import RenderConsumerApplication from "../../custom-components/RenderConsumerApplication";
import SectionAddEdit from "./widget-edit/SectionAddEdit";
import MFileWidgetEdit from "./widget-edit/MFileWidgetEdit";

const AppWidgetTree = lazy(() => import("./components/AppWidgetTree"));
interface FormValues {
  widgetType: string;
}

interface PathParams {
  applicationId: string;
}

function WidgetsEdit() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const { applicationId } = useParams<PathParams>();
  // const { applicationId:applicationKey } = useParams<PathParams>();
  const { applicationId } = useParams<PathParams>();
  // const { application, loading, reloadApplication, setApplication } = useApplication(applicationKey);

  const initStore = useSingleApplicationStore.use.initStore();
  const application = useSingleApplicationStore.use.applicationMain();
  const widgets = useSingleApplicationStore.use.widgets();
  const fetchWidgets = useSingleApplicationStore.use.fetchWidgets();
  // const fetchById = useSingleApplicationStore.use.fetchById();
  const loading = useSingleApplicationStore.use.loading();
  const loadingWidgets = useSingleApplicationStore.use.loadingWidgets();
  const applicationConfigs = useSingleApplicationStore.use.applicationConfigs();
  const saveApplication = useSingleApplicationStore.use.save();
  const savingData = useSingleApplicationStore.use.saving();

  const { eForms, loading: loadingEForms } = useEForms();


  // const { register, handleSubmit, watch } = useForm<FormValues>();
  const [error, setError] = useState("");
  const [tabValue, setTabValue] = useState("preview");

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [reordering, setReordering] = useState(false);
  const [widgetToDelete, setWidgetToDelete] = useState<Widget | undefined>(
    undefined
  );
  const [editingWidget, setEditingWidget] = useState<Widget | undefined>(undefined);

  const [creatingWidget, setCreatingWidget] = useState<WidgetType | undefined>(undefined);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    // console.log("Widget edit applicationId: " + applicationId + " application is : " + application ? application?.name : "");
  }, []);

  useEffect(() => {
    initStore(applicationId, true).then(() => {
      console.log("getByKey " + applicationId);
      // fetchData();

    }).then(() => fetchWidgets(applicationId, true));
  }, [initStore]);

  // const widgetType = watch("widgetType");
  // const formik = useFormik({
  //   initialValues: {
  //     widgetType: ""
  //   }
  // });
  // const onSelectWidgetLibrary = async (widgetType) => {
  //   console.log("Selected Widget " + widgetType);
  //   try {
  //     // setCreatingWidget(true);
  //     let wcontent = {};
  //     if (widgetType === "Text") {
  //       wcontent = {
  //         text: "Text Block Content"
  //       };
  //     } else if (widgetType === "EForm") {
  //       wcontent = {
  //         title: "title Val",
  //         eFormId: "eFormId Val",
  //         allowMultiple: false,
  //         allowSkip: false,
  //         disableValidation: false
  //       };
  //     } else if (widgetType === "Section") {
  //       wcontent = {
  //         title: "title Val",
  //         widgetIds: [],
  //         summary: undefined,
  //         layout: undefined,
  //         orientation: undefined,
  //         description: undefined
  //       };
  //     } else if (widgetType === "Image") {
  //       wcontent = {
  //         title: "title Val",
  //         imageAltText: "imageAltText Val",
  //         summary: undefined,
  //         summaryBelow: undefined,
  //         s3Key: "s3Key Val",
  //         fileName: "fileName",
  //         scalePct: undefined
  //       };
  //     }
  //
  //     console.log(JSON.stringify(wcontent));
  //
  //     // await AppApiService.createWidget(applicationId,
  //     //   widgetType + " Widget Title 11",
  //     //   widgetType, true, wcontent
  //     // );
  //
  //     // setCreatingWidget(false);
  //     // await reloadApplication();
  //     await fetchWidgets(applicationId);
  //
  //   } catch (err) {
  //     console.log(t("AddContentFailure"), err);
  //     // setCreatingWidget(false);
  //   }
  // };

  const renderEditScreenByType = (widgetType) => {
    if (widgetType === WidgetType.Text) {
      return <TextWidgetEdit widgetData={editingWidget}
                             onEditWidgetUpdate={onEditWidgetUpdate}
                             onEditWidgetCancel={onEditWidgetCancel} />;
    } else if (widgetType === WidgetType.Image) {
      return <ImageWidgetEdit widgetData={editingWidget}
                              onEditWidgetUpdate={onEditWidgetUpdate}
                              onEditWidgetCancel={onEditWidgetCancel} />;
    } else if (widgetType === WidgetType.EForm) {
      return <EFormWidgetEdit widgetData={editingWidget} eForms={eForms}
                              onEditWidgetUpdate={onEditWidgetUpdate}
                              onEditWidgetCancel={onEditWidgetCancel} />;
    }
    else if (widgetType === WidgetType.MFile) {
      return <MFileWidgetEdit widgetData={editingWidget} mFiles={eForms}
                              onEditWidgetUpdate={onEditWidgetUpdate}
                              onEditWidgetCancel={onEditWidgetCancel} />;
    }
    else if (widgetType === WidgetType.Section) {
      return <SectionAddEdit widgetData={editingWidget}
                              onEditWidgetUpdate={onEditWidgetUpdate}
                              onEditWidgetCancel={onEditWidgetCancel} />;
    }
  };

  const onWidgetAdd = async (widgetType) => {
    console.log("Selected Widget " + widgetType);
    // const wd:Widget={widgetType:widgetType};
    setEditingWidget(undefined);
    setCreatingWidget(widgetType);
    // setOpen(true);
  };

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleCloseEditWidgetModal = () => {
    onEditWidgetCancel();
    // setOpen(false);
    setCreatingWidget(undefined);
  };
  const onEditWidget = (widget: Widget) => {
    // console.log("Edit Widget " + JSON.stringify(widget));
    setEditingWidget(widget);
    // setIsOpenDeleteModal(true);
  };
  const onEditWidgetUpdate = async () => {
    console.log("Edit Widget onEditWidgetUpdate");
    setEditingWidget(undefined);
    // await reloadApplication();
    // await initStore(applicationId,true);
    await fetchWidgets(applicationId, true);
  };
  const onEditWidgetCancel = () => {
    console.log("Edit Widget onEditWidgetCancel");
    setEditingWidget(undefined);
    // setIsOpenDeleteModal(true);
  };

  const onAddContent = async () => {
    navigate(`/admin/application/${applicationId}/edit-content`);
  };
  const handleGoBack = async () => {
    navigate(`/admin/application/edit/${applicationId}`);
  };
  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
  };
  const onDeleteWidget = async (widget: Widget) => {
    console.log("onDeleteWidget");
    setWidgetToDelete(widget);
    await AppApiService.deleteWidget(widget.key).then(()=>fetchWidgets(applicationId, true));
    setIsOpenDeleteModal(true);
  };

  const onDuplicateWidget = async (widget: Widget) => {
    if (applicationId && widget) {
      await AppApiService.duplicateWidget(
        applicationId,
        widget.key
      );

      // navigate(`/admin/application/edit/${applicationId}`, {
      //   state: {
      //     notification: "alert",
      //     type: "success",
      //     message: `${t(
      //       widget.widgetType === WidgetType.Chart
      //         ? widget.content.chartType
      //         : widget.widgetType
      //     )} '${widget.name}' ${t("DashboardWasCopied")}`
      //   }
      // });

      // await reloadApplication();
      await fetchWidgets(applicationId, true);
      // await initStore(applicationId,true);
    }
  };

  const onDrag = async (widgets: Widget[]) => {
    if (applicationId && !reordering) {
      setReordering(true);
      // console.log(JSON.stringify("applicationId " + applicationId));

      try {
        // setApplication({ ...application, widgets });
        await AppApiService.setWidgetOrder(application.key, widgets);
      } finally {
        // await reloadApplication(false);
        await fetchWidgets(applicationId, true);
        // await initStore(applicationId,true);
        setReordering(false);
      }
    }
  };

  return (
    <DashboardLayout loading={loading}>
      <MDBox width="calc(100% - 48px)" position="absolute" top="1.75rem">
        <DashboardNavbar light absolute />
      </MDBox>
      <AdminHeader title={`Application Contents: ${application?.name}`} icon="apps"
                   description="Design core contents to collect information">
        <MDBox mr={2}>
          <MDButton variant="outlined" color="info"
                    onClick={handleGoBack}>
            <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>arrow_backward</Icon>
            Back to Details
          </MDButton>
        </MDBox>

      </AdminHeader>
      <CustomDialog onClose={handleCloseEditWidgetModal} open={creatingWidget !== undefined} fullWidth={true}
                    maxWidth={"lg"}
                    aria-labelledby="customized-dialog-title">
        <DialogContent>
          {creatingWidget && renderEditScreenByType(creatingWidget)}
          {/*<EditWidgetItem widgetData={editingWidget}*/}
          {/*                onEditWidgetUpdate={onEditWidgetUpdate}*/}
          {/*                onEditWidgetCancel={onEditWidgetCancel} />*/}
        </DialogContent>
      </CustomDialog>
      <Grid container justifyContent="center" sx={{ my: 0 }} spacing={3}>
        {/*<Grid item xs={12} lg={12}>*/}
        {/*  <MDBox mt={0} mb={2}>*/}
        {/*    <MDBox mb={1}>*/}
        {/*      <MDTypography variant="h5" fontWeight="medium">*/}
        {/*        {t("ContentItems")}*/}
        {/*      </MDTypography>*/}
        {/*    </MDBox>*/}
        {/*    /!*<MDTypography variant="body2" fontWeight="regular" color="text">*!/*/}
        {/*    /!*  Setup the information your clients needs to provide*!/*/}
        {/*    /!*</MDTypography>*!/*/}
        {/*  </MDBox>*/}
        {/*</Grid>*/}
        <Grid item xs={12} lg={6}>

          <MDBox px={2}>

            <Card sx={{ overflow: "visible", boxShadow: "none", backgroundColor: "transparent" }}>
              {/*{(!loading && applicationId) ?*/}
                  <Suspense fallback={<div>Loading... </div>}>
                <AppWidgetTree
                  widgets={widgets ? widgets : []}
                  onClick={onAddContent}
                  onEdit={onEditWidget}
                  onDelete={onDeleteWidget}
                  // onWidgetAdd = {onWidgetAdd}
                  onDuplicate={onDuplicateWidget}
                  onDrag={onDrag}
                /></Suspense>
              {/*  : <MDBox py={12}> <Spinner /> </MDBox>*/}
              {/*}*/}
            </Card>

            <MDBox py={2}>
              <Divider sx={{ margin: 0 }} light={false} />

            </MDBox>
            <WidgetToolbar onSelect={onWidgetAdd} />
          </MDBox>


        </Grid>
        <Grid item xs={12} lg={6}>

          <Card>
          <MDBox minWidth={{ xs: "22rem", md: "25rem" }}>
            <AppBar position="static">
              <Tabs value={tabValue} onChange={handleSetTabValue} centered>
                <Tab label={"Details"} value={"details"} />
                <Tab label={"Preview"} value={"preview"} />
              </Tabs>
            </AppBar>
          </MDBox>

          <MDBox pt={2} pb={3}>
            {editingWidget && renderEditScreenByType(editingWidget.widgetType)}


            {/*{!editingWidget && tabValue === "preview" &&*/}
            {/*  <Card>*/}

            {/*    <RenderApplication applicationId={applicationId}*/}
            {/*                       appData={application} />*/}

            {/*  </Card>*/}
            {/*}*/}

            {!editingWidget && tabValue === "preview" &&

                <MDBox mx={2}>
                <RenderConsumerApplication applicationId={applicationId}
                                           renderMode={'ADMIN_DESIGN'}
                                   appData={application} />
                </MDBox>

            }


            {/*<WidgetLibrary onSelect={onSelectWidgetLibrary} />*/}
          </MDBox>
          </Card>
          <MDBox pt={2} pb={3}>
            <Card>
              {!loading && tabValue === "details" &&
                <MDBox p={3} lineHeight={1}>
                  <MDTypography variant="caption" color="text" fontWeight="regular">
                    {JSON.stringify(widgets)}
                  </MDTypography>

                </MDBox>
              }

            </Card>
          </MDBox>

        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default WidgetsEdit;
