import React, { useEffect, useMemo, useState } from "react";
import ReactFlow, { Background, useEdgesState, useNodesState } from "reactflow";


import PropTypes from "prop-types";

import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import "reactflow/dist/style.css";
import { workflowNodeComponentTypes } from "../../../workflow/wf-data/wf-node-component-types";


function WorkflowDisplayCard({ name, initialNodes, initialEdges, noGutter }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const initBgColor = "#1A192B";
  const [bgColor, setBgColor] = useState(initBgColor);
  const connectionLineStyle = { stroke: "#78f" };
  const snapGrid = [20, 20];
  const nodeTypes = useMemo(() => (workflowNodeComponentTypes), []);

  const defaultViewport = { x: 0, y: 0, zoom: 1.25 };
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);

  useEffect(() => {
    setNodes(initialNodes);
    setEdges(initialEdges);
  }, []);


  return (

    <MDBox p={3} mb={2} lineHeight={1} className="layoutflow">

      {/* {name} */}

      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        elementsSelectable={false}
        connectionLineStyle={connectionLineStyle}
        snapToGrid={true}
        snapGrid={snapGrid}
        defaultViewport={defaultViewport}
        fitView
        attributionPosition="bottom-right"
        aria-readonly
      >
        <MDBox py={6} px={3} lineHeight={1} minHeight="22vh">

          <Background />
        </MDBox>
      </ReactFlow>
    </MDBox>

  );
}

// Setting default values for the props of Bill
WorkflowDisplayCard.defaultProps = {
  noGutter: false
};

// Typechecking props for the Bill
WorkflowDisplayCard.propTypes = {
  name: PropTypes.string.isRequired,
  // topicArea: PropTypes.string.isRequired,
  // description: PropTypes.string,
  // state: PropTypes.string.isRequired,
  // dataObj: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.node, PropTypes.object])).isRequired,
  noGutter: PropTypes.bool
};

export default WorkflowDisplayCard;
