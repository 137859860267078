import React from "react";
// import MarkdownRender from "./MarkdownRender";
import { Application, Widget } from "../../../../../models";
import WidgetRender from "../WidgetRender";
// import Tabs from "./Tabs";
// import TabsVertical from "./TabsVertical";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useWindowSize, useColors } from "../../../../../hooks";
import { Waypoint } from "react-waypoint";
import { useTranslation } from "react-i18next";
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";

interface Props {
  application: Application;
  widget: Widget;
  showMobilePreview?: boolean;
  widgets?: Array<Widget>;
  setActiveWidgetId?: Function;
  topOffset?: string;
  bottomOffset?: string;
  defaultActive?: string;
}

function SectionWidget(props: Props) {
  const { t } = useTranslation();
  const { application, widget } = props;
  const { name, content, showTitle } = widget;
  const windowSize = useWindowSize();
  const primaryColor = useColors(1)[0];

  let activeTabId = "0";
  if (props.widgets && props.defaultActive) {
    const widget = props.widgets?.find(
      (w: Widget) => w.id === props.defaultActive
    );
    if (widget && widget.section) {
      const parent = props.widgets?.find(
        (w: Widget) => w.id === widget.section
      );
      if (parent && parent.id === props.widget.id) {
        const index = parent.content.widgetIds.findIndex(
          (w: string) => w === props.defaultActive
        );
        if (index >= 0) {
          activeTabId = index.toString();
        }
      }
    }
  }

  return (
    <MDBox aria-label={name}
           sx={({
                  borders: { borderRadius, borderWidth, borderColor },
                  functions: { pxToRem },
                  palette: { light },
                  transitions,
                }) => ({

             borderRadius: borderRadius.md,
             border:`${borderWidth[1]} solid ${borderColor}`,
             padding: `${pxToRem(16)} ${pxToRem(30)}`,
             transition: transitions.create("background-color", {
               easing: transitions.easing.easeInOut,
               duration: transitions.duration.shorter,
             }),
             backgroundColor: light.main,

           })}
    >
      {!content.showWithTabs ? (
        <Waypoint
          onEnter={() => {
            if (props.setActiveWidgetId) {
              props.setActiveWidgetId(props.widget.id);
            }
          }}
          topOffset={props.topOffset}
          bottomOffset={props.bottomOffset}
          fireOnRapidScroll={false}
        >
          <MDBox>
            {showTitle &&
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="medium">
                  {name}
                </MDTypography>
              </MDBox>
            }
            {/*Content: {JSON.stringify({content})}*/}
            {content.summary ? (
              <MDBox pl={4}>

                {JSON.stringify(content.summary)}
                {/*<MarkdownRender*/}
                {/*  className="usa-prose textOrSummary"*/}
                {/*  source={content.summary}*/}
                {/*/>*/}
              </MDBox>
            ) : (
              ""
            )}
          </MDBox>
        </Waypoint>
      ) : (
        <>
          {showTitle && <h2>{content.title}</h2>}
          {content.summary ? (
            <div className="padding-left-05">
              {/*<MarkdownRender*/}
              {/*  className="usa-prose textOrSummary"*/}
              {/*  source={content.summary}*/}
              {/*/>*/}
              {JSON.stringify(content)}
            </div>
          ) : (
            ""
          )}
        </>
      )}
      {!content.showWithTabs &&
        content.widgetIds &&
        content.widgetIds.map((id: string, index: number) => {
          const widget = props.widgets?.find((w) => w.sk === id);
          if (widget) {
            return (
              <MDBox key={index}
              >
                <Waypoint
                  onEnter={() => {
                    if (props.setActiveWidgetId) {
                      props.setActiveWidgetId(widget.sk);
                    }
                  }}
                  topOffset={props.topOffset}
                  bottomOffset={props.bottomOffset}
                  fireOnRapidScroll={false}
                >
                  <MDBox id={id}>
                    <WidgetRender
                      application={application}
                      widget={widget}
                      showMobilePreview={props.showMobilePreview}
                      bottomOffset={props.bottomOffset}
                      topOffset={props.topOffset}
                      disableShare={true}
                    />
                  </MDBox>
                </Waypoint>
              </MDBox>
            );
          }
          return false;
        })}
      {content.showWithTabs &&
        (content.horizontally ||
          props.showMobilePreview ||
          windowSize.width <= 600) &&
        content.widgetIds?.length > 0 && (
          <Tabs
            defaultActive={activeTabId}
            showArrows
            activeColor={`${primaryColor}`}
            container={t("Section")}
          >
            {content.widgetIds.map((id: string, index: number) => {
              const widget = props.widgets?.find((w) => w.id === id);
              if (widget) {
                return (
                  <div key={index} id={`${index}`} label={widget.name}>
                    <WidgetRender
                      widget={widget}
                      showMobilePreview={props.showMobilePreview}
                      hideTitle={true}
                      disableShare={true}
                    />
                  </div>
                );
              }
              return false;
            })}
          </Tabs>
        )}
      {content.showWithTabs &&
        !content.horizontally &&
        !props.showMobilePreview &&
        windowSize.width > 600 &&
        content.widgetIds && (<>vertical tabs</>
          // <TabsVertical
          //   defaultActive={activeTabId}
          //   activeColor={`${primaryColor}`}
          // >
          //   {content.widgetIds.map((id: string, index: number) => {
          //     const widget = props.widgets?.find((w) => w.id === id);
          //     if (widget) {
          //       return (
          //         <div key={index} id={`${index}`} label={widget.name}>
          //           <WidgetRender
          //             widget={widget}
          //             showMobilePreview={props.showMobilePreview}
          //             hideTitle={true}
          //             disableShare={true}
          //           />
          //         </div>
          //       );
          //     }
          //     return false;
          //   })}
          // </TabsVertical>
        )}
    </MDBox>
  );
}

export default SectionWidget;
