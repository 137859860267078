import React from "react";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from 'react-dom/client';
import App from "./App";
import i18n from "./i18n";
// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "./context";
import TenantConfigProvider from "./context/TenantConfigProvider";

const root = createRoot(document.getElementById("root") as HTMLElement);
i18n();
root.render(
  <BrowserRouter>
    <TenantConfigProvider>
      <MaterialUIControllerProvider>
        {/*<Authenticator.Provider>*/}
          <App />
        {/*</Authenticator.Provider>*/}
      </MaterialUIControllerProvider>
    </TenantConfigProvider>
  </BrowserRouter>
);
