import { API } from "aws-amplify";
import authHeaders, { apiName } from "./services-base/api-base";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const baseBucketUrl = "https://cs-sls-tenant-files-prod-pooled.s3.amazonaws.com/";

type ValidFileTypes = {
  [key: string]: string;
};
const imageFileTypes: ValidFileTypes = {
  "image/png": ".png",
  "image/jpeg": ".jpg",
  "image/svg": ".svg",
  "image/svg+xml": ".svg"
};

const docFileTypes: ValidFileTypes = {
  "application/msword": ".doc",
  "application/pdf": ".pdf",
};

const dataFileTypes: ValidFileTypes = {
  "text/csv": ".csv",
  "application/vnd.ms-excel": ".csv",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": ".xlsx"
};

const uploadSuccessStatuses = [200, 201, 202, 203, 204];

async function createSignedUrl(file: File, module: string) {
  const headers = await authHeaders();
  // IN USE Dec-19
  return await API.post(apiName, "/file/createsignedurlpost", {
    headers,
    body: { fileName: file.name, fileType: file.type, module, method: "post", mode: "" }
  });
}

async function getImageUrl(s3Key: string) {
  // return this.http.post<any>(url, {fileName:'article/marble_sm.jpg.jpeg',objmode:'get'} );
  const headers = await authHeaders();
  const imgUrl = await API.post(apiName, "file/signedurltoload", {
    headers,
    body: { fileName: s3Key, objmode: "get" }
  });
  // console.log(JSON.stringify(imgUrl));
  return imgUrl;
  // await axios.post(urlData.url, fd);
  // return baseBucketUrl + s3Key;
}

async function deleteFile(s3Key: string) {
  // return this.http.post<any>(url, {fileName:'article/marble_sm.jpg.jpeg',objmode:'get'} );
  const headers = await authHeaders();
  const res = await API.post(apiName, "file/deletefile", {
    headers,
    body: { filepath: s3Key }
  });
  console.log(JSON.stringify(res));
  return res;
  // await axios.post(urlData.url, fd);
  // return baseBucketUrl + s3Key;
}

// async function convertFile(file: File): Promise<string> {
//   // const result = new ReplaySubject<string>(1);
//   const reader = new FileReader();
//   reader.readAsBinaryString(file);
//   reader.onload = (event) => result.next(btoa(event.target.result.toString()));
//   return result;
// }
async function s3Upload(fd: FormData, urlData) {
  const headers = await authHeaders();
  return await axios.post(urlData.url, fd);
}


// async function saveFile(fd:FormData) {
//   const headers = await authHeaders();
//   return await API.post(apiName, "file/save", {
//     headers,
//     body:fd
//   });
// }

async function createPublicSignedUrlPost(file: File, module: string) {
  const headers = await authHeaders();
  // IN USE Dec-19
  return await API.post(apiName, "/pubfile/createsignedurlpost", {
    headers,
    body: { fileName: file.name, fileType: file.type, module, method: "post", mode: "" }
  });
}


async function createPublicImageSignedUrlPost(rawFile: File, module: string) {
  const headers = await authHeaders();
  // IN USE Dec-19
  const mimeType = rawFile.type;
  const extension = imageFileTypes[mimeType as keyof ValidFileTypes];
  if (!extension) {
    throw new Error("File type is not supported");
  }
  const s3Key = uuidv4().concat(extension);
  return await API.post(apiName, "/pubfile/createsignedurlpost", {
    headers,
    body: { fileName: s3Key, fileType: mimeType, module, method: "post", mode: "" }
  });
}


async function createPublicSignedUrlPostKey(s3Key: string, mimeType: string, module: string, moduleId?: string) {
  const headers = await authHeaders();
  return await API.post(apiName, "/pubfile/createsignedurlpost", {
    headers,
    body: { fileName: s3Key, fileType: mimeType, module, method: moduleId ? "post-ext" : "post", moduleId: moduleId }
  });
}

async function uploadFile(file: File, urlData) {
  const fd = new FormData();
  Object.keys(urlData.fields).forEach(key => fd.append(key, urlData.fields[key]));
  fd.append("file", file);

  return await axios.post(urlData.url, fd);
}

async function uploadTenantPublicImage(rawFile: File, module: string, moduleId?: string) {
  const headers = await authHeaders();
  // IN USE Dec-19
  const mimeType = rawFile.type;
  const extension = imageFileTypes[mimeType as keyof ValidFileTypes];
  if (!extension) {
    throw new Error("File type is not supported");
  }

  const s3Key = uuidv4().concat(extension);
  return await createPublicSignedUrlPostKey(s3Key, mimeType, module, moduleId)
    .then(async (urlData) => {
      if (!urlData) throw new Error("No urlData");
      const result = await uploadFile(rawFile, urlData);
      if (!result || !uploadSuccessStatuses.includes(result.status)) {
        throw new Error("Unexpected return status");
      }
      return urlData;
    }).catch(e => console.log(e));
}

async function uploadTenantFile(rawFile: File, module: string, moduleId?: string) {
  const headers = await authHeaders();
  // IN USE Dec-19
  const mimeType = rawFile.type;
  const extension = docFileTypes[mimeType as keyof ValidFileTypes];
  if (!extension) {
    throw new Error("File type is not supported");
  }

  const s3Key = uuidv4().concat(extension);
  return await createPublicSignedUrlPostKey(s3Key, mimeType, module, moduleId)
    .then(async (urlData) => {
      if (!urlData) throw new Error("No urlData");
      const result = await uploadFile(rawFile, urlData);
      if (!result || !uploadSuccessStatuses.includes(result.status)) {
        throw new Error("Unexpected return status");
      }
      return urlData;
    }).catch(e => console.log(e));
}


async function uploadTenantPublicImage_bak(rawFile: File, module: string) {
  const headers = await authHeaders();
  const mimeType = rawFile.type;
  const extension = imageFileTypes[mimeType as keyof ValidFileTypes];
  if (!extension) {
    throw new Error("File type is not supported");
  }
  const s3Key = uuidv4().concat(extension);
  return await API.post(apiName, "/pubfile/createsignedurlpost", {
    headers,
    body: { fileName: s3Key, fileType: mimeType, module, method: "post-ext", moduleId: "345345" }
  }).then(async (urlData) => {
    if (!urlData) throw new Error("No urlData");
    console.log("uploadTenantPublicImage urlData : " + JSON.stringify(urlData));

    const fd = new FormData();
    Object.keys(urlData.fields).forEach(key => fd.append(key, urlData.fields[key]));
    fd.append("file", rawFile);

    // const result = await s3Upload(fd, urlData);
    const result = await axios.post(urlData.url, fd);
    console.log("uploadTenantPublicImage result : " + JSON.stringify(result));
    if (!result || ![200, 201, 202, 203, 204].includes(result.status)) {
      throw new Error("Unexpected return status");
    }
    return urlData;
  }).catch(e => console.log(e))
    ;


}

async function deletePublicFile(s3Key: string) {
  // return this.http.post<any>(url, {fileName:'article/marble_sm.jpg.jpeg',objmode:'get'} );
  const headers = await authHeaders();
  const res = await API.post(apiName, "pubfile/deletefile", {
    headers,
    body: { filepath: s3Key }
  });
  console.log(JSON.stringify(res));
  return res;
  // await axios.post(urlData.url, fd);
  // return baseBucketUrl + s3Key;
}

async function getPublicFileUrl(s3Key: string) {
  // return this.http.post<any>(url, {fileName:'article/marble_sm.jpg.jpeg',objmode:'get'} );
  const headers = await authHeaders();
  const imgUrl = await API.post(apiName, "pubfile/signedurltoload", {
    headers,
    body: { fileName: s3Key, objmode: "get" }
  });
  return imgUrl;
}

const FileService = {
  createSignedUrl,
  s3Upload,
  deleteFile,
  getImageUrl,
  createPublicSignedUrlPost,
  createPublicImageSignedUrlPost,
  uploadTenantPublicImage,
  getPublicFileUrl,
  deletePublicFile,
  uploadTenantFile
};

export default FileService;
