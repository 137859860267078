import { useCallback, useEffect, useState } from "react";
import { Offering, OfferingState } from "../models";
import OfferingService from "../services/OfferingService";
import { usePublicTenantConfig } from "./tenant-config-hooks";
import { Auth } from "aws-amplify";

type UseOfferingsHook = {
  loading: boolean;
  offerings: Array<Offering>;
  reloadOfferings: Function;
};

export function useOfferings(isPublic?:boolean): UseOfferingsHook {
  const [loading, setLoading] = useState(false);
  const [offerings, setOfferings] = useState([]);
  const { tenantConfig, tenantConfigLoaded } = usePublicTenantConfig();

  const fetchData = useCallback(async () => {
    // console.log(JSON.stringify(tenantConfig));
    // if (!tenantConfigLoaded) return;
    // const user = await Auth.currentAuthenticatedUser();
    const user = await Auth.currentUserInfo().catch(e=> null);
    // console.log(tenantConfig);

    try {
      setLoading(true);
      let data = [];
      if (!user){
        if (!tenantConfig.authRequired){
          //console.log('useOfferings (public) for ' + tenantConfig.tenantId);
          data = await OfferingService.fetchPublicOfferings(tenantConfig.tenantId);
        }
      }else{
        //console.log('useOfferings (user) for ' + tenantConfig.tenantId);
        data = await OfferingService.fetchOfferings();
      }

      if (data && data.length>1){
        data.sort((a, b) => a.updatedAt > b.updatedAt ? 1 : -1);
      }
      // const data = !user && !tenantConfig.authRequired ? await OfferingService.fetchPublicOfferings(tenantConfig.tenantId) :
      //   await OfferingService.fetchOfferings();
      // const data = user &&
      //   await OfferingService.fetchOfferings();
      // console.log("useOfferings offerings >> " + JSON.stringify(data));
      setOfferings(data);
    }catch (e) {
      console.log(e);
    }finally {
      setLoading(false);
    }


  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    loading,
    offerings,
    reloadOfferings: fetchData,
  };
}

type UseOfferingHook = {
  offering: Offering | undefined;
  reloadOffering: Function;
  loading: boolean;
  editable: boolean;
};

export function useOffering(offeringId: string): UseOfferingHook {
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [offering, setOffering] = useState<Offering | undefined>(undefined);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const data = await OfferingService.fetchOfferingById(offeringId);
    setOffering(data);
    if (data.state===OfferingState.Draft) setEditable(true);
    setLoading(false);
  }, [offeringId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    loading,
    reloadOffering: fetchData,
    offering,
    editable
  };
}
