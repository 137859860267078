import React, { ChangeEvent, ForwardedRef, useCallback, useMemo, useRef } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import Icon from "@mui/material/Icon";
// import "./FileInput.css";
import { useTranslation } from "react-i18next";
import Spinner from "../Spinner/Spinner";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import { useMaterialUIController } from "../../../context";
import MDDropzoneRoot from "../../../components/MDDropzone/MDDropzoneRoot";
import { fireChangeEvent, getBaseButtonText } from "../FileUpload/internal";

interface Props {
  id: string;
  name: string;
  // label: string;
  register?: Function;
  required?: boolean;
  disabled?: boolean;
  fileName?: string;
  staticFileName?: string | undefined;
  hint?: string | React.ReactNode;
  icon?: string;
  accept?: string;
  errors?: any;
  value?: any;
  loading?: boolean;
  multiple: false;
  // onFileProcessed?: Function;
  onChange?: Function;
}

function FileInputComponent(props: Props, ref: ForwardedRef<ButtonProps.Ref>) {
  const { t } = useTranslation();
  const [controller] = useMaterialUIController();
  const {
    hint, value, multiple, onChange,
    icon, loading
  } = props;
  const { darkMode } = controller;

  const fileInput = useRef<HTMLInputElement>(null);
  const handleButtonClick = () => fileInput.current && fileInput.current.click();
  let content = (
    <MDBox className="usa-file-input__instructions" aria-hidden="true">
      <MDTypography variant="caption">
        {`${t("DragFileHere")} `}
      </MDTypography>
      <MDTypography variant="caption">
        {t("ChooseFromFolder")}
      </MDTypography>
      {/*<span className="usa-file-input__drag-text">{`${t("DragFileHere")} `}</span>*/}
      {/*<span className="usa-file-input__choose">{t("ChooseFromFolder")}</span>*/}
    </MDBox>
  );

  if (props.loading) {
    content = (
      <Spinner label={t("UploadingFile")} />
    );
  } else if (props.fileName || props.staticFileName) {
    content = (
      <MDBox>
        <MDBox>
          <MDTypography variant="body2">
            {t("SelectedFile")}{" "}
          </MDTypography>
          <MDTypography variant="caption">
            {t("ChangeFile")}
          </MDTypography>
        </MDBox>
        <MDBox className="usa-file-input__preview">
          <MDBox className="usa-file-input__preview-image">
            <MDBox className="fileIcon" id="fileIconMDBox">
              <FontAwesomeIcon icon={faFile} size="lg" />
            </MDBox>
          </MDBox>
          <MDBox>
            <MDTypography variant="caption">
              {props.fileName || props.staticFileName}
            </MDTypography>
          </MDBox>

        </MDBox>
        <MDBox className="usa-file-input__box"></MDBox>
      </MDBox>
    );
  }

  const baseButtonText = useMemo((): React.ReactNode => {
    return getBaseButtonText(multiple, hint);
  }, [multiple, hint]);

  const handleInputChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      //console.log("handleInputChange 0");
      let newValue = null;
      if (target.files && target.files[0]) {
        if (multiple) {
          newValue = value instanceof Array ? [...value, target.files[0]] : [target.files[0]];
        } else {
          newValue = target.files[0];
        }
        // setDocumentTypeList(value instanceof Array ? value.length : 0, '');
      }
      if (onChange) {
        //console.log("handleInputChange onChange");
        fireChangeEvent(onChange, { value: newValue });
      }
    },
    [value, multiple, onChange]
  );


  return (
    <MDBox>
      <label htmlFor={props.id}>
        {props.label}
        {props.label && props.required && <span>&#42;</span>}
      </label>
      {/*<MDTypography variant="caption">*/}
      {/*  {props.hint}*/}
      {/*</MDTypography>*/}
      {props.errors && (
        <span

          id="file-input-error-alert"
          role="alert"
        >
          {props.errors}
        </span>
      )}

      <MDDropzoneRoot
        // component="form"
        className="form-control dropzone"
        ownerState={{ darkMode }}
        justifyContent="center"
        alignItems="center"
        shadow="md"
        borderRadius="md"
        component="a"
        ref={ref}
        onClick={handleButtonClick}
        sx={({
               borders: { borderRadius, borderWidth, borderColor },
               functions: { pxToRem },
               palette: { light },
               transitions
             }) => ({
          // display: "flex",
          // flexDirection: "column",
          alignItems: "center",
          minWidth: "5rem",
          minHeight: "3rem",
          borderRadius: borderRadius.md,
          border: `${borderWidth[1]} solid ${borderColor}`,
          padding: `${pxToRem(16)} ${pxToRem(30)}`,
          transition: transitions.create("background-color", {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.shorter
          }),
          "&:hover": {
            backgroundColor: light.main
          },
          cursor: "pointer"
        })}
        // sx={{
        //   border: ({ borders: { borderWidth }, palette: { light, error } }) =>
        //     props.errors && props.errors.length > 0 ?
        //       `${borderWidth[1]} solid ${error.main}` : `${borderWidth[1]} solid ${light.main}`
        // }}
        // className={`usa-file-input${
        //   props.disabled ? " usa-file-input--disabled" : ""
        // }`}
      >
        {/*<MDBox*/}

        {/*>*/}
        {/*  {content}*/}
        {/*  <MDBox className="fallback" bgColor="transparent">*/}
        {/*  <MDBox component="input"*/}
        {/*    id={props.id}*/}
        {/*    // className="usa-file-input__input"*/}
        {/*    type="file"*/}
        {/*    name={props.name}*/}
        {/*    accept={props.accept}*/}
        {/*    disabled={props.disabled}*/}
        {/*    ref={props.register && props.register({ required: props.required })}*/}
        {/*    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {*/}
        {/*      if (props.onFileProcessed) {*/}
        {/*        props.onFileProcessed(*/}
        {/*          event.target.files?.length && event.target.files[0]*/}
        {/*        );*/}
        {/*      }*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</MDBox>*/}
        <MDBox className="fallback dz-button dz-default"
        pt={2}
               bgColor="transparent"
               display="flex"
               flexDirection="column"
               alignItems="center"
               position="relative"
               minHeight="4.75rem"
               borderRadius="xl"

          // sx={{ cursor: "pointer", userSelect: "none" }}
          // sx={{
          //   backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
          //     `${linearGradient(
          //       rgba(gradients.info.main, 0.6),
          //       rgba(gradients.info.state, 0.6)
          //     )}, url(${backgroundImage})`,
          //   backgroundSize: "cover",
          //   backgroundPosition: "50%",
          //   overflow: "hidden",
          // }}
        >

          {loading ?
            <Spinner />
            :
            <Icon fontSize="large" color={"error"}>{icon ?? "image"}</Icon>
          }


          {/*<MDButton ref={ref}*/}
          {/*          variant="text" color={"secondary"}*/}
          {/*  // iconName="upload" formAction="none"*/}
          {/*  // disabled={disabled}*/}
          {/*          onClick={handleButtonClick}>*/}
          {/*  {baseButtonText}*/}
          {/*</MDButton>*/}
          <MDTypography
            variant="button"
            fontWeight="regular"
            textTransform="capitalize">
            {baseButtonText}
          </MDTypography>
          <MDBox component="input"
                 hidden
                 id={props.id}
                 className="dz-button dz-filename"

                 type="file"
                 name={props.name}
                 accept={props.accept}
                 disabled={props.disabled}
            // ref={props.register && props.register({ required: props.required })}
                 ref={fileInput}
            // onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            //   if (props.onFileProcessed) {
            //     // console.log("onFileProcessed");
            //     props.onFileProcessed(
            //       event.target.files?.length && event.target.files[0]
            //     );
            //   }
            // }}
                 onChange={handleInputChange}
          />
        </MDBox>
        {/*</MDBox>*/}
      </MDDropzoneRoot>
    </MDBox>
  );
}

const FileInput = React.forwardRef(FileInputComponent);
export default FileInput;
