/**
  All of the routes for the page layout of Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the DefaultNavbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `name` key is used for the name of the route on the DefaultNavbar.
  3. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  4. The `icon` key is used for the icon of the route on the DefaultNavbar, you have to add a node.
  5. The `collapse` key is used for making a collapsible item on the DefaultNavbar that contains other routes inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  6. The `route` key is used to store the route location which is used for the react router.
  7. The `href` key is used to store the external links location.
*/

import Icon from "@mui/material/Icon";

const pageRoutes = [
  {
    name: "My Work",
    columns: 2,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "Start",
        icon: <Icon>home</Icon>,
        collapse: [
          {
            name: "Home",
            route: "/admin",
          },

        ],
      },
      {
        name: "Dashboard",
        icon: <Icon>apps</Icon>,
        collapse: [
          {
            name: "Dashboard",
            route: "/admin/dashboard",
          },

        ],
      },
      {
        name: "Submission",
        icon: <Icon>grading</Icon>,
        collapse: [
          {
            name: "Application Submissions",
            route: "/admin/submissions",
          },

        ],
      },
      {
        name: "Task",
        icon: <Icon>memory</Icon>,
        collapse: [
          {
            name: "Task List",
            route: "/admin/tasks",
          },

        ],
      },
    ],
  },
  {
    name: "Administration",
    columns: 3,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "Offering",
        icon: <Icon>shopping_cart</Icon>,
        collapse: [
          {
            name: "Offerings",
            route: "/admin/offerings",
          },
          {
            name: "Eligibility Rules",
            route: "/admin/eligibility-rules",
          },
        ],
      },
      {
        name: "Application",
        icon: <Icon>apps</Icon>,
        collapse: [
          {
            name: "Applications",
            route: "/admin/applications",
          },
          {
            name: "Pre-Screening",
            route: "/admin/pre-screening",
          },
          {
            name: "Widgets",
            route: "/admin/widgets",
          },

        ],
      },
      {
        name: "Workflow",
        icon: <Icon>memory</Icon>,
        collapse: [
          {
            name: "All Workflow",
            route: "/admin/workflows",
          },
        ],
      },
      {
        name: "user",
        icon: <Icon>people</Icon>,
        collapse: [
          {
            name: "Users",
            route: "/admin/users",
          },
        ],
      },
      {
        name: "Settings",
        icon: <Icon>extension</Icon>,
        collapse: [
          {
            name: "Brand",
            route: "/admin/settings/brand",
          },
          {
            name: "System",
            route: "/admin/settings/system",
          },

        ],
      },
    ],
  },
  {
    name: "Integration",
    columns: 2,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "Integration",
        icon: <Icon>extension</Icon>,
        collapse: [
          {
            name: "Integrations",
            route: "/admin/integrations",
          },
        ],
      },
      {
        name: "Partner",
        icon: <Icon>extension</Icon>,
        collapse: [
          {
            name: "Connections",
            route: "/admin/integration/connections",
          },
        ],
      },
    ],
  },
  {
    name: "docs",
    collapse: [
      {
        name: "getting started",
        href: "https://www.consen.co/learning-lab/react/quick-start/material-dashboard/",
        description: "All about overview, quick start, license and contents",
        icon: <Icon>article</Icon>,
      },
      {
        name: "foundation",
        href: "https://www.consen.co/learning-lab/react/colors/material-dashboard/",
        description: "See our colors, icons and typography",
        icon: <Icon>grading</Icon>,
      },
      {
        name: "components",
        href: "https://www.consen.co/learning-lab/react/alerts/material-dashboard/",
        description: "Explore our collection of fully designed components",
        icon: <Icon>apps</Icon>,
      },
      {
        name: "plugins",
        href: "https://www.consen.co/learning-lab/react/datepicker/material-dashboard/",
        description: "Check how you can integrate our plugins",
        icon: <Icon>extension</Icon>,
      },
    ],
  },
];

export default pageRoutes;
