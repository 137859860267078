import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

export default memo(({ data, isConnectable }) => {
  return (
    <MDBox>
      <Handle
        type="target"
        position={Position.Left}
        style={{ background: '#555' }}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <MDTypography variant="caption">
        Custom Color Picker Node: <strong>{data.color}</strong>
      </MDTypography>
<br/>
      <input className="nodrag" type="color" onChange={data.onChange} defaultValue={data.color}
      />
      <MDTypography variant="caption">
        {JSON.stringify(data)}
      </MDTypography>
      <Handle
        type="source"
        position={Position.Right}
        id="a"
        style={{ top: 10, background: '#555' }}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Right}
        id="b"
        style={{ bottom: 10, top: 'auto', background: '#555' }}
        isConnectable={isConnectable}
      />
    </MDBox>
  );
});
