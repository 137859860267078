import { create } from "zustand";
import ProviderConfigService from "../services/ProviderConfigService";
import createSelectors from "./selectors";
import { Application, Widget } from "../models";
import Auth from "@aws-amplify/auth";
import ApplicationService from "../services/AppApiService";
import OfferingService from "../services/OfferingService";
import AppApiService from "../services/AppApiService";
import UtilsService from "../services/UtilsService";
import FriendlyURLGenerator from "../services/FriendlyURLGenerator";
import { createStoreContext } from "./store-context";
import EFormService from "../services/EFormService";
import widgets from "../layouts/pages/widgets";

interface SingleApplicationStore {
  appId: string | undefined;
  applicationConfigs: {};
  applicationMain: Application;
  eForms: Widget[];
  summary: {} | undefined;
  widgets: Widget[];
  loading: boolean;
  loadingWidgets: boolean;
  saving: boolean;
  initStore: (appId: string,refreshData?:boolean) => Promise<void>;
  fetchWidgets: (appId: string,refreshData?:boolean) => Promise<void>;
  fetchWidgetsContentData: (appId: string) => Promise<void>;
  updateList: (newData: any) => void; //Widgets
  save: (newData: any) => Promise<void>;
}

const createSingleApplicationStoreBase = create<SingleApplicationStore>()((set, get) => ({
  // const defaultVals = await ApplicationService.fetchApplications();

  appId: undefined,
  applicationConfigs: {},
  applicationMain: undefined,
  eForms: [],
  summary: {},
  widgets: [],
  loading: false,
  loadingWidgets: false,
  saving: false,

  initStore: async (appId, refreshData?:boolean) => {
    //console.log("..... Store : initStore get().appId ", get().appId);
    //console.log("..... Store : initStore appId ", appId);
    if (get().appId === appId && !refreshData) return;
    //console.log("..... Store : initStore <<<<<<<<<< singleApplication");
    set({ loading: true });
    const appConfigs = await ProviderConfigService.fetchProviderConfigsSelect("category", "APPLICATION");
    try {
      const data = await ApplicationService.fetchApplicationById(appId);
      if (data) {
        // const user = await Auth.currentAuthenticatedUser();
        const widgets = data.widgets; // @Todo: call widgets api
        let summary ={
          // noOfParticipants : applicationMain.participant,
          // primaryApplicant : primaryPCP.name + ` ( ${primaryPCP.participant} )`,
          // offeringName : applicationMain.refData.name,
        }
        set({ appId, applicationMain:data,  summary });
      }
    } catch (e) {
      console.error("Error useSingleApplicationStoreBase : initStore ", e);
    }
    set({ applicationConfigs: appConfigs, loading: false });
  },
  fetchWidgets: async (appId, refreshData?:boolean) => {
    //console.log("..... Store : fetchWidgets get().appId ", get().appId);

    // if (!refreshData || (get().appId === appId && get().widgets.length>0)) return;
    if ( get().appId !== appId || !refreshData) throw new Error("Not Same App!");
    //console.log("..... Store : fetchWidgets <<<<<<<<<< singleApplication");
    set({ loadingWidgets: true });
    try {
      let widgets = await ApplicationService.fetchApplicationWidgets(appId);
      //console.log("..... Store : fetchWidgets done for appId ", appId);
      if (widgets) {
        widgets.sort((a, b) => a.order - b.order);
      } else {
        widgets = [];
      }
      set({ widgets });

    } catch (e) {
      console.error("Error useSingleApplicationStoreBase : initStore ", e);
    }
    finally {
      set({ loadingWidgets:false });
    }
    // set({ applicationConfigs: appConfigs, loading: false });
  },
  fetchWidgetsContentData: async (appId) => {
    //console.log("..... Store : fetchWidgetsContentData get().appId ", get().appId);
    //console.log("..... Store : fetchWidgetsContentData appId ", appId);

    if (get().appId !== appId || !get().widgets || get().widgets.length<1) return;
    //console.log("..... Store : fetchWidgetsContentData <<<<<<<<<< singleApplication");
    set({ loading: true });
    try {
        const widgets = get().widgets;
        let eForms = get().eForms || [];
        // const applicationMain = (sRows.filter((s) => s.rowType === "APP")[0]);



        widgets.forEach((el) => {
          if (el.widgetType==='EForm') {

            const idx = eForms.findIndex(o => {
              return o.configId === el.content.eFormId;
            });

            if (idx < 0) {
              const eFormConfig = EFormService.fetchEFormById(el.content.eFormId);
              if (eFormConfig)               eForms.push(eFormConfig);
            }

          }
        });
        set({ appId, widgets });
    } catch (e) {
      console.error("Error useSingleApplicationStoreBase : initStore ", e);
    }
    set({  loading: false });
  },
  updateList: (newData, sortCol?: string) => {
    set({ loading: true });
    const currList = get().widgets;
    let rows: any[] = [];
    if (!currList || currList.length === 0) {
      //console.log("..... Store : updateList <<<<<<<<<< add");
      rows.push(newData);
    } else {
      //console.log("..... Store : updateList <<<<<<<<<< replace");
      const idx = currList.findIndex(o => {
        //console.log("..... Store : updateList <<<<<<<<<< findIndex o " + JSON.stringify(o.key));
        //console.log("..... Store : updateList <<<<<<<<<< findIndex newData " + JSON.stringify(newData.key));
        return o.key === newData.key;
      });

      if (idx < 0) {
        currList.push(newData);
        rows = currList;
      } else {
        //console.log("..... Store : updateList <<<<<<<<<< replace " + idx);
        rows = currList.map(obj => newData.key === obj.key ? newData : obj);
      }
      //console.log("..... Store : updateList <<<<<<<<<< findIndex o " + JSON.stringify(rows));
      // apps = [...currList.slice(0, idx), newData, ...currList.slice(idx, currList.length)];
    }
    // rows.sort((a, b) => a.updatedAt > b.updatedAt ? -1 : 1);
    set({ widgets: rows, loading: false });
    //arr1.map(obj => arr2.find(o => o.id === obj.id) || obj);
  },
  save: async (newData) => {
    set({ saving: true });
    let apiResponse;
    try {
      //console.log("..... Store : save <<<<<<<<<< singleApplication");
      if (newData.key ) {
        //console.log("..... Store : save <<<<<<<<<< existing application ", newData);
        // const existingData = await ApplicationService.fetchApplicationById(newData.appId);
        // if (!existingData){
        //   //console.log("..... Store : save << ?????? < application not found with key" + newData.key);
        //   return;
        // }
        // //console.log("..... Store : save <<  application found /w key " + existingData.key);
        // const newval = {
        //   ...existingData,
        //   ...newData
        // };
        //console.log("..... Store : save <<<<<<<<<< newData ", newData);
        apiResponse = await ApplicationService.editApplication(newData.key, newData);
        //console.log("..... Store : save <<<<<<<<<< response key" + apiResponse.key);
      } else {
        //console.log("..... Store : save <<<<<<<<<< NEW application ", newData);
        apiResponse = await ApplicationService.createApplication(newData);
      }
      if (apiResponse && apiResponse.participantType==='PCP') {
        set({ applicationPCP: apiResponse });
      }else if (apiResponse && apiResponse.participantType==='META') {
        set({ applicationMain: apiResponse });
      }
    } catch (e) {
      //console.log(e);
    } finally {
      set({ saving: false });
    }

    if (apiResponse) get().updateList(apiResponse);
    // if (apiResponse) get().updateList(apiResponse);
  },
}));

const useSingleApplicationStore = createSelectors(createSingleApplicationStoreBase);
// const [StoreProvider, useStore] = createStoreContext(createSingleApplicationStoreBase);
export default useSingleApplicationStore;