import React, { lazy, Suspense, useEffect, useState, memo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Fade from "@mui/material/Fade";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import ConsumerLayout from "../../../LayoutContainers/ConsumerLayout";
import { useCurrentAuthenticatedUser } from "../../../hooks";
import PreviewHeader from "./components/PreviewHeader";
import { useTranslation } from "react-i18next";
import SubmissionInfo from "./components/SubmissionInfo";
import { useMaterialUIController } from "../../../context";
import { useSubmissionContextHook } from "../../../hooks/submission-hooks";
import Spinner from "../../custom-components/Spinner/Spinner";
import { workflowNodeTypes } from "../../../static-data/wf-node-types";

const RenderConsumerApplication = lazy(() => import("../../custom-components/RenderConsumerApplication"));


function SubmissionPreview({oSteps, submissionPCP, loading, submissionLoaded}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  // const { tenantConfig, reloadTenantConfig, loadingTenantConfig } = usePublicTenantConfig();
  const { username, tenant, isAdmin, isEditor } =
    useCurrentAuthenticatedUser();



  const {
   editMode,
    setEditMode,

  } = useSubmissionContextHook();

  const prevEditMode = editMode || false;



  const getNodeDetails = (nodeType) => workflowNodeTypes.find(nt => nt.nodeType === nodeType);

  useEffect(() => {
    console.log("....... SubmissionPreview editMode is ", editMode);
    setEditMode(false);
    return () => {
      //reset submission editMode to previous
      console.log("Unset ....... SubmissionPreview editMode to ", prevEditMode);
      setEditMode(prevEditMode);
    };
    // setActiveStep(2);
  }, []);

  const getSelectedStepHeader = (stepIndex) => {
    if (!oSteps) return;
    const currStep = oSteps.find(s => s.seq === stepIndex);
    if (!currStep) {
      //console.log"Current step not found with index {}", stepIndex, wfSteps);
      return "None";
    }
    const nodeType = getNodeDetails(currStep.sourceType);
    return <>
      <MDBox mb={1}>
        <MDTypography variant="h6" fontWeight="medium" color={"text"}>
          {nodeType ? nodeType.label : currStep.sourceType}
        </MDTypography>
      </MDBox>
      {currStep.sourceData && currStep.sourceData.name !== "None" &&
        <MDTypography variant="body2" color="text" fontWeight="regular">
          {currStep.sourceData.name}
        </MDTypography>

      }

    </>;
  };
  const getSelectedStepContent = (stepIndex) => {
    if (!oSteps) return;
    // const stepIndex = 0;
    const currStep = oSteps.find(s => s.seq === stepIndex);
    if (!currStep) {
      //console.log"Current step not found with index {}", stepIndex, wfSteps);
      return;
    }
    console.log("  - Rendering step Preview - ", stepIndex, currStep);
    // preloadStepContent(currStep);
    switch (currStep.sourceType) {
      case "startNode":
        return <SubmissionInfo />;
      // case "dataNode":
      //   return <Pricing />;
      case "applicationNode":
        const appKey = currStep.sourceData.key.split(/[?:;|$]+/)[1];
        //return <RenderApplication applicationId={"t4-9|APPHASHSYMbf9d7137-a01f-4f5d-8c92-b5278995a604:APPHASHSYMbf9d7137-a01f-4f5d-8c92-b5278995a604"}/>;
        return <Suspense fallback={<div>Rendering your application... </div>}>
          <RenderConsumerApplication applicationId={appKey}
            // submissionPCP={submissionPCP}
          />
        </Suspense>;
      // case "reviewNode":
      //   return <MDBox> TBD: Review Screen </MDBox>;
      case "tncNode":
        return <MDBox> TBD: TnC Screen </MDBox>;
      default:
        return <MDBox> Default Screen {JSON.stringify(currStep)}       </MDBox>;
    }
  };



  if (loading  && !submissionPCP) {
    return (
        <MDBox mt={2} minHeight="20vh">
          <Spinner minHeight={400} />
        </MDBox>
    );
  }

  return (

  <MDBox mt={2} minHeight="40vh">
    <MDBox mb={3}>


            <MDBox m={4} minHeight="20vh">
              {submissionLoaded ?
                <MDBox>
                  {oSteps && oSteps.length > 0 && oSteps.map(s => (
                      <MDBox mb={4} key={s.seq}>
                        {s.seq !== 0 && <Divider />}
                        {getSelectedStepHeader(s.seq)}

                        <Fade in={submissionLoaded} timeout={1300}>
                          <MDBox ml={4}>
                            {getSelectedStepContent(s.seq)}
                          </MDBox>
                        </Fade>
                      </MDBox>
                    )
                  )}
                </MDBox>
                :
                <MDBox mt={2}>
                  <Spinner minHeight={300} />
                </MDBox>
              }
            </MDBox>




    </MDBox>

  </MDBox>

)

}

export default SubmissionPreview;
