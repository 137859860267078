import React, { useState } from "react";

import PropTypes from "prop-types";

import Icon from "@mui/material/Icon";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import { useDateTimeFormatter } from "hooks";
import WorkflowDisplayCard from "../WorkflowDisplayCard";
import { useMaterialUIController } from "../../../../../context";
import WorkflowStepsDisplay from "../../../workflow/component/WorkflowStepsDisplay";
import MDButton from "../../../../../components/MDButton";

// Custom styles for the Card

function WorkflowAdminCard({
                             dataObj, image, title, subtitle, badge, content,
                             progress, attachedFiles, children
                           }) {
  const dateFormatter = useDateTimeFormatter();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [tabValue, setTabValue] = useState("details");
  // const [workflow, setWorkflow] = useState(dataObj);

  // const steps = dataObj && dataObj.settings && dataObj.settings.steps ?
  //   dataObj.settings.steps.filter(s => s.sourceType !== "start" && s.sourceType !== "end") : [];

  const steps =  dataObj.steps || [] ;//? dataObj.steps : [];

  // const renderMembers = members.map((member, key) => {
  //   const imageAlt = `image-${key}`;
  //
  //   return (
  //     <MDAvatar
  //       key={imageAlt}
  //       src={member}
  //       alt={imageAlt}
  //       size="xs"
  //       sx={{
  //         border: ({ borders: { borderWidth }, palette: { white } }) =>
  //           `${borderWidth[2]} solid ${white.main}`,
  //         cursor: "pointer",
  //         position: "relative",
  //         ml: -1,
  //         mr: 0,
  //
  //         "&:hover, &:focus": {
  //           zIndex: "10",
  //         },
  //       }}
  //     />
  //   );
  // });
  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <Card>

      <MDBox mt={4} px={4} mb={1}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDBox>
            <MDBox mb={0}>
              <MDTypography variant="h5" fontWeight="medium">
                {dataObj.name} &nbsp;
                {badge && <MDBadge variant="contained" color={dataObj.state === "Draft" ? "secondary" : "info"}
                                   badgeContent={dataObj.state} />}
              </MDTypography>
            </MDBox>
            <MDTypography variant="body2">
              {dataObj.description}

            </MDTypography>

            {/* <MDTypography variant="caption"> */}
            {/*   Updated {dateFormatter(dataObj.updatedAt)} */}
            {/* </MDTypography> */}
            {/* <MDTypography component="p" variant="button" fontWeight="regular" color="text"> */}
            {/*   Code: <b>KF332</b> */}
            {/* </MDTypography> */}
          </MDBox>
          <MDBox minWidth={{ xs: "10rem", md: "12rem" }}>
            <AppBar position="static">
              <Tabs value={tabValue} onChange={handleSetTabValue}>
                <Tab label={"Details"} value={"details"} />
                <Tab label={"Preview"} value={"preview"} />
              </Tabs>
            </AppBar>
          </MDBox>
        </MDBox>
        <MDBox
          // bgColor={darkMode ? "grey-900" : "grey-100"}
          // borderRadius="lg"
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          my={1}
          py={1}
          pl={{ xs: 1, sm: 2 }}
          pr={1}
        >

          <MDTypography variant="caption" color="text">
            Steps: {dataObj.steps && dataObj.steps.length}
          </MDTypography>
          <MDTypography variant="caption" color="text">
            Scope: {dataObj.scope }
          </MDTypography>
          <MDTypography variant="caption" color="text">
            Updated {dateFormatter(dataObj.updatedAt)} by {dataObj.updatedBy}
          </MDTypography>
        </MDBox>
        <Grid container spacing={3} alignItems="center">
          {/* <Grid item xs={12} md={6} lg={6} sx={{ ml: "auto" }}> */}
          {/*   <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} mb={2} px={3}> */}
          {/*     <MDBox pt={1} lineHeight={1}> */}
          {/*       <MDTypography variant="h6" fontWeight="medium"> */}
          {/*         {title} */}
          {/*       </MDTypography> */}
          {/*       {subtitle && <MDTypography variant="button" color="text" fontWeight="regular"> */}
          {/*         {subtitle} */}
          {/*       </MDTypography>} */}
          {/*     </MDBox> */}
          {/*      */}
          {/*   </MDBox> */}
          {/* </Grid> */}

          {/* <Grid item xs={12} md={8} lg={6} sx={{ ml: "auto" }}> */}
          {/*    */}
          {/* </Grid> */}


          <Grid item xs={12} md={12}>
            {tabValue === "details" &&
              <MDBox mt={1} px={3} mb={2}>
                <Grid container spacing={1} alignItems="center">

                  <Grid item xs={12} md={6}>
                    <MDBox pt={2} lineHeight={0}>
                      <MDTypography variant="body2" fontWeight="medium" textTransform="capitalize">
                        Steps
                      </MDTypography>
                      {steps.length < 1 &&
                        <MDTypography variant="caption" color="error">
                          Workflow cannot work without any {" "}
                          <MDTypography component="a" href="#" variant="caption" fontWeight="medium" color="error">
                            Step
                          </MDTypography>
                          . <br />Please Edit and fix errors.
                        </MDTypography>
                      }
                    </MDBox>

                    <WorkflowStepsDisplay wfSteps={steps}/>

                    {/* <Grid container spacing={1} alignItems="center"> */}
                    {/*   {steps.map((s) => ( */}
                    {/*     <Grid item xs={12} key={s.source}> */}
                    {/*       <MDTypography variant="body2"> */}
                    {/*         <MDBadge variant="contained" color={"secondary"} badgeContent={s.seq} /> */}
                    {/*         {" "} */}
                    {/*         {s.sourceType} */}
                    {/*       </MDTypography> */}
                    {/*       <MDBox px={2} ml={2.5} lineHeight={0}> */}
                    {/*         <MDTypography variant="button" color="text" fontWeight="regular"> */}
                    {/*           {s.sourceData || ""} */}
                    {/*         </MDTypography> */}
                    {/*       </MDBox> */}
                    {/*     </Grid> */}
                    {/*   ))} */}

                    {/* </Grid> */}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDBox
                        bgColor={darkMode ? "grey-900" : "grey-100"}
                        borderRadius="lg"
                        display="flex"
                        justifyContent="space-between"
                        // alignItems={{ xs: "flex-start", md: "center" }}
                        flexDirection={"column"}
                        my={1}
                        py={1}
                        pl={{ xs: 1, sm: 2 }}
                        pr={1}
                    >
                      <MDTypography variant="caption" color="text">
                        settings: {JSON.stringify(dataObj.settings)} <br/>____
                      </MDTypography>
                      <MDTypography variant="caption" color="text">

                        rulez: {JSON.stringify(dataObj.rulez)} <br/>____
                      </MDTypography>
                      <MDTypography variant="caption" color="text">
                        integrations: {JSON.stringify(dataObj.integrations)} <br/>____
                      </MDTypography>
                    </MDBox>
                    <MDBox>
                      {/* <MDBox p={3}> */}
                      {/*   <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }}> */}

                      {/*     <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}> */}
                      {/*       {dataObj.state === "Draft" && */}
                      {/*         <MDButton variant="gradient" color="primary" sx={{ height: "100%" }} */}
                      {/*                   disabled={!dataObj.settings || !dataObj.settings.steps}> */}
                      {/*           Publish */}
                      {/*         </MDButton>} */}
                      {/*       {dataObj.state === "Published" && */}
                      {/*         <MDButton variant="gradient" color="warning" sx={{ height: "100%" }} */}
                      {/*                   disabled={!dataObj.settings || !dataObj.settings.steps}> */}
                      {/*           Archive */}
                      {/*         </MDButton>} */}
                      {/*     </MDBox> */}
                      {/*   </MDBox> */}
                      {/* </MDBox> */}
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            }
            {tabValue === "preview" && <>
              {dataObj.content.nodes && dataObj.content.edges ?
                <MDBox mt={1} px={1}>
                  <WorkflowDisplayCard name="Default Workflow"
                                       initialNodes={dataObj.content.nodes}
                                       initialEdges={dataObj.content.edges}
                  />
                </MDBox>
                :
                <MDBox mt={1} px={3} mb={2}>None</MDBox>
              }</>}
          </Grid>

        </Grid>
        <MDBox display="flex" justifyContent="space-between" alignItems="center">

              {children}

          {/* <MDBox display="flex">{renderMembers}</MDBox> */}
        </MDBox>
      </MDBox>


    </Card>
  );
}

// Setting default props for the Card
WorkflowAdminCard.defaultProps = {
  image: "",
  progress: 0,
  attachedFiles: ""
};

// Typechecking props for the Card
WorkflowAdminCard.propTypes = {
  image: PropTypes.string,
  badge: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light"
    ]),
    label: PropTypes.string
  }),
  content: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  progress: PropTypes.number,
  attachedFiles: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node
};

export default WorkflowAdminCard;
