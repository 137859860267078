import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import ViewAgendaOutlinedIcon from '@mui/icons-material/ViewAgendaOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import { useMaterialUIController } from "context";
import UtilsService from "../../../../services/UtilsService";
import logoCS from "assets/images/cs-gray-sym.svg";
import logoCSName from "assets/images/consen-gr-n.svg";
import MDBadge from "../../../../components/MDBadge";
import { useDateTimeFormatter } from "hooks";
import LoadingBar from "../../../custom-components/LoadingBar/LoadingBar";

function AdminApplicationBox({ dataObj, noGutter, onSelectPreview }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { widgets } = dataObj;
  const navigate = useNavigate();
  const dateFormatter = useDateTimeFormatter();

  const [asana2FA, setAsana2FA] = useState(false);


  const handleSetAsana2FA = () => setAsana2FA(!asana2FA);

  useEffect(() => {
    // console.log("dataObj: " + JSON.stringify(dataObj));
  }, []);

  return (

    <Card id={`application-${dataObj.sk}`}>

      <MDBox p={3} lineHeight={1}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
            >

              <MDBox display="flex" alignItems="center">
                <MDAvatar src={logoCS} alt="Consen logo" variant="rounded" />
                {/* <Icon fontSize="medium" color="inherit" > */}
                {/*   art_track_outlined */}
                {/* </Icon> */}
                <MDBox ml={2}>
                  {/* <MDTypography variant="h5" fontWeight="medium"> */}
                  {/*   {dataObj.name} */}
                  {/* </MDTypography> */}
                  <MDTypography
                    component={Link}
                    to={`/admin/application/edit/${dataObj.appId}`}
                    variant="h5"
                    color="info"
                    fontWeight="bold"
                  >
                    {dataObj.name} &nbsp;
                    <MDBadge
                      variant="contained"
                      size="xs"
                      badgeContent={dataObj.state}
                      color={dataObj.state==="Draft"? "secondary" : "info"}
                      container
                    />
                    {/* &nbsp; */}
                    {/* <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>arrow_forward</Icon> */}
                  </MDTypography>
                  <MDBox display="flex" alignItems="flex-end">
                    <MDTypography variant="caption" color="text">
                      Updated: {dateFormatter(dataObj.updatedAt)}
                    </MDTypography>
                    {/* <MDTypography variant="button" color="text" sx={{ lineHeight: 0 }}> */}
                    {/*   <Icon fontSize="small">expand_less</Icon>?? */}
                    {/* </MDTypography> */}
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
                <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }} mr={2}>
                  {/* <MDButton variant="text" color={darkMode ? "white" : "dark"} */}
                  {/*           component={Link} */}
                  {/*           to={`/admin/application/edit/${UtilsService.getEncodedHashReplacedURI(dataObj.key)}`}> */}
                  {/*   <Icon>view</Icon>&nbsp;Details */}
                  {/* </MDButton> */}
                  <MDBadge
                    variant="contained"
                    // size="xs"
                    badgeContent={dataObj.scope}
                    color={dataObj.scope==="Public"? "primary" : "error"}
                    container
                  />
                  {/* <MDTypography variant="button" color="text"> */}
                  {/*   {dataObj.state} */}
                  {/* </MDTypography> */}
                </MDBox>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBox ml={4} pl={4}>
              <MDTypography variant="button" color="text">
                {dataObj.description}
                {/* <br /> */}
                {/* {JSON.stringify(`${encodeURI(dataObj.key)}`)} */}
              </MDTypography>

              <MDBox mt={2} pt={2} pb={3}>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                >
                  <MDBox display="flex" alignItems="center">
                    <ViewAgendaOutlinedIcon color="text"/>
                    <MDBox ml={2}>
                      <MDTypography variant="h6" fontWeight="medium">
                        Content
                        {/* ({dataObj.widgets ? dataObj.widgets.length : 0}) */}
                      </MDTypography>
                      <MDBox display="flex" alignItems="flex-end">
                        <MDTypography variant="button" color="text">
                          Show less
                        </MDTypography>
                        <MDTypography variant="button" color="text" sx={{ lineHeight: 0 }}>
                          <Icon fontSize="small">expand_less</Icon>
                        </MDTypography>
                      </MDBox>
                      {/*{widgets && widgets.length>0 && widgets.map(w=>(*/}
                      {/*    <MDBox>*/}
                      {/*      {w.name}*/}
                      {/*    </MDBox>*/}
                      {/*))*/}
                      {/*}*/}
                    </MDBox>
                  </MDBox>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    width={{ xs: "100%", sm: "auto" }}
                    mt={{ xs: 1, sm: 0 }}
                  >
                    {/* <MDBox lineHeight={0} mx={2}> */}
                    {/*   <MDTypography variant="button" color="text"> */}
                    {/*     {slack2FA ? "Enabled" : "Disabled"} */}
                    {/*   </MDTypography> */}
                    {/* </MDBox> */}
                    <MDBox mr={1}>
                      <MDButton variant="outlined" color={darkMode ? "white" : "primary"} size="small"
                                component={Link}
                                to={`/admin/application/${dataObj.appId}/widgets-edit`}>
                        <Icon>edit</Icon>&nbsp;Edit
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
                <Divider />

                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                >
                  <MDBox display="flex" alignItems="center">
                    <AccountTreeOutlinedIcon color="text"/>
                    <MDBox ml={2} lineHeight={0}>
                      <MDTypography variant="h6" fontWeight="medium">
                        Workflow
                      </MDTypography>
                      <MDTypography variant="button" color="text">
                        Organize your process 2
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    width={{ xs: "100%", sm: "auto" }}
                    mt={{ xs: 1, sm: 0 }}
                  >
                    <MDBox lineHeight={0} mx={2}>
                      <MDTypography variant="button" color="text">
                        {asana2FA ? "Enabled" : "Disabled"}
                      </MDTypography>
                    </MDBox>
                    <MDBox mr={1}>
                      <Switch checked={asana2FA} onChange={handleSetAsana2FA} />
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDBox>

          </Grid>
          <Grid item xs={12} md={6} align="center" sx={{ display: "flex" }}>
            <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
            >
              <MDBox display="flex" alignItems="center">
                <MDBox ml={2} lineHeight={0}>
                  <MDTypography variant="h5" fontWeight="medium" color="text">
                    Workflow
                  </MDTypography>
                  <MDTypography variant="caption" >
                    {JSON.stringify(dataObj)}
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                width={{ xs: "100%", sm: "auto" }}
                mt={{ xs: 1, sm: 0 }}
              >
                {/* <MDBox lineHeight={0} mx={2}> */}
                {/*   <MDTypography variant="button" color="text"> */}
                {/*     {asana2FA ? "Enabled" : "Disabled"} */}
                {/*   </MDTypography> */}
                {/* </MDBox> */}
                <MDBox mr={1}>
                  <Switch checked={asana2FA} onChange={handleSetAsana2FA} />
                </MDBox>

              </MDBox>

            </MDBox>

          </Grid>
        </Grid>
        {/* @TODO : add tabs to preview? */}
        {/*<ViewDashboardAdmin applicationId={applicationId}*/}
        {/*                    appData={application} />*/}

      </MDBox>


    </Card>


  );
}

// Setting default values for the props of Bill
AdminApplicationBox.defaultProps = {
  noGutter: false
};

// Typechecking props for the Bill
AdminApplicationBox.propTypes = {
  // name: PropTypes.string.isRequired,
  // topicArea: PropTypes.string.isRequired,
  // description: PropTypes.string,
  // state: PropTypes.string.isRequired,
  dataObj: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.node, PropTypes.object])).isRequired,
  noGutter: PropTypes.bool
};

export default AdminApplicationBox;
