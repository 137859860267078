import React, {useCallback, memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";

import Icon from "@mui/material/Icon";
import Fade from "@mui/material/Fade";
import CardMedia from "@mui/material/CardMedia";

import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDProgress from "components/MDProgress";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

import MDButton from "components/MDButton";
import { useDateTimeFormatter } from "hooks";
import csIconGray from "assets/images/consen-gr-n.svg";
import MDEditor from "components/MDEditor";
// Custom styles for the Card
import "react-quill/dist/quill.bubble.css";
import FileService from "../../../services/FileService";
import LinearLoading from "../LinearLoading";
import Spinner from "../Spinner/Spinner";
import UtilsService from "../../../services/UtilsService";

function OfferingConsumerCard({
                                dataObj, badge, content,
                                progress, attachedFiles, children
                              }) {
  const dateFormatter = useDateTimeFormatter();
  // const [isLoadingImages, setIsLoadingImages] = useState(true);
  const [hasImages, setHasImages] = useState(true);

  // const [imageUrls, setImageUrls] = useState([]);
  //
  // const fetchImages = useCallback(async (images) => {
  //   try {
  //     const data = await FileService.getImageUrl(images.s3_keys[0].s3_url);
  //     setImageUrls([data]);
  //   }finally {
  //     setIsLoadingImages(false);
  //   }
  // }, []);

  useEffect(() => {
    // if (dataObj.images && dataObj.images.s3_keys) {
    //   setImageUrls([]);
    //   setHasImages(true);
    //   // setTimeout(() => {
    //     fetchImages(dataObj.images);
    //   // }, 3500);
    //
    // }else{
    //   setIsLoadingImages(false);
    // }
  }, [dataObj]);
  // const renderMembers = members.map((member, key) => {
  //   const imageAlt = `image-${key}`;
  //
  //   return (
  //     <MDAvatar
  //       key={imageAlt}
  //       src={member}
  //       alt={imageAlt}
  //       size="xs"
  //       sx={{
  //         border: ({ borders: { borderWidth }, palette: { white } }) =>
  //           `${borderWidth[2]} solid ${white.main}`,
  //         cursor: "pointer",
  //         position: "relative",
  //         ml: -1,
  //         mr: 0,
  //
  //         "&:hover, &:focus": {
  //           zIndex: "10",
  //         },
  //       }}
  //     />
  //   );
  // });

  return (
    <Card    >

      <MDBox m={0} minHeight={hasImages?"30rem":"16rem"}
             position="relative"
             borderRadius="lg"
             mt={-1}
             mx={2}
             className="card-header"
             sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)" }}
      >
        {hasImages && <>
        {dataObj.images && dataObj.images.s3_keys && dataObj.images.s3_keys.length>0  ?
          <Fade in={true} timeout={800}>
            <MDBox
              position="relative"
              borderRadius="lg"
              mt={-1}
              mx={2}
              className="card-header"
              sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)" }}
            >
              <MDBox
                component="img"
                src={UtilsService.getS3PublicFilePath(dataObj.images.s3_keys[0].s3_url)}
                alt={dataObj.name}
                borderRadius="lg"
                shadow="md"
                width="100%"
                height="100%"
                position="relative"
                zIndex={1}
              />
              <MDBox
                borderRadius="lg"
                shadow="md"
                width="100%"
                height="100%"
                position="absolute"
                left={0}
                top="0"
                sx={{
                  backgroundImage: `url(${UtilsService.getS3PublicFilePath(dataObj.images.s3_keys[0].s3_url)})`,
                  transform: "scale(0.94)",
                  filter: "blur(12px)",
                  backgroundSize: "cover",
                }}
              />
            </MDBox>
            {/* <CardMedia */}
            {/*   src={imageUrls[0]} */}
            {/*   component="img" */}
            {/*   title={imageUrls[0]} */}
            {/*   sx={{ */}
            {/*     maxWidth: "100%", */}
            {/*     margin: 0, */}
            {/*     boxShadow: ({ boxShadows: { md } }) => md, */}
            {/*     objectFit: "cover", */}
            {/*     objectPosition: "center" */}
            {/*   }} */}
            {/* /> */}
            {/* <MDBox display="flex" justifyContent="center" alignItems="center" > */}
            {/*     <MDBox component="img" src={imageUrls[0]} width="40%" borderRadius="sm" mb={1} /> */}
            {/* </MDBox> */}
          </Fade>
          :
          <MDBox
            position="relative"

            mt={3}
            mx={2}

            minHeight="20rem"

          >
            <Spinner color="error" />
          </MDBox>
        }
        </>
        }


      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} mb={2} px={2}>
        <MDBox pt={1} lineHeight={1} align="left">
          <Link
                    to={`/app/offering/details/${dataObj.key}`}>
            <MDTypography variant="h5" fontWeight="medium">
              {dataObj.name}
            </MDTypography>
          </Link>

          {/* {dataObj.categoryId && */}
          {/*   <MDTypography variant="button" color="text" fontWeight="regular"> */}
          {/*   {dataObj.categoryId} */}
          {/* </MDTypography> */}
          {/* } */}
        </MDBox>
        {dataObj.categoryId && <MDBadge variant="contained"
                                        color={"info"}
                                        badgeContent={dataObj.categoryId} container />}
      </MDBox>

      <MDBox m={2} px={0} minHeight="4rem">
        <MDTypography variant="body2">
          {dataObj.description}
        </MDTypography>
        {progress > 0 && (
          <MDBox mt={0.25}>
            <MDProgress variant="gradient" value={progress} color={badge.color} />
          </MDBox>
        )}
      </MDBox>
      {/* {dataObj.content && dataObj.content.text && */}
      {/*   <MDBox px={2} mb={2} */}
      {/*          minHeight="15rem"> */}
      {/*     <Divider sx={{ margin: "1.0rem 0" }} /> */}
      {/*     /!*   {dataObj.content.text} *!/ */}
      {/*     <MDEditor */}
      {/*       // label={content.label} */}
      {/*       name="content" */}
      {/*       value={dataObj.content.text} */}
      {/*       fullWidth */}
      {/*       readOnly */}
      {/*       theme={"bubble"} */}
      {/*     /> */}
      {/*   </MDBox>} */}
      {/* <Divider sx={{ margin: "0.5rem 0" }} /> */}
      <MDBox display="flex" justifyContent="space-between" alignItems="center">

        <MDBox px={2} display="flex" alignItems="center" color="text">
          {attachedFiles && (
            <>
              <MDTypography variant="body2" color="text" sx={{ lineHeight: 0 }}>
                <Icon sx={{ fontWeight: "bold" }}>attach_file</Icon>
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text">
                &nbsp;{attachedFiles}
              </MDTypography>
            </>
          )}
          <MDTypography variant="caption">
            ${(dataObj.price)} / {(dataObj.priceLabel)} ({(dataObj.priceType)})
          </MDTypography>
        </MDBox>
        <MDBox p={3}>
          <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
            {children}
          </MDBox>
        </MDBox>
        {/* <MDBox display="flex">{renderMembers}</MDBox> */}
      </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props for the Card
OfferingConsumerCard.defaultProps = {
  image: "",
  progress: 0,
  attachedFiles: ""
};

// Typechecking props for the Card
OfferingConsumerCard.propTypes = {
  image: PropTypes.string,
  badge: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light"
    ]),
    label: PropTypes.string
  }),
  content: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  progress: PropTypes.number,
  attachedFiles: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node
};

export default memo(OfferingConsumerCard);
