import React, { useState } from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import DialogContent from "@mui/material/DialogContent";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";


import DashboardLayout from "LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { simple_eform_schema, simple_eform_ui_schema } from "static-data/eform-data-static";
// Project page components
// import Header from "./components/Header";
// Images

import { EForm, Offering } from "models";
import { useDateTimeFormatter, useEForms } from "hooks";
import { useTranslation } from "react-i18next";
import AdminHeader from "views/custom-components/AdminHeader";
import CustomDialog from "views/custom-components/CustomDialog";
import EFormRenderer from "../../custom-components/RJSF-Forms/EFormRenderer";
import EFormsTableView from "./EFormsTableView";
import SystemEFormsListView from "./SystemEFormsListView";
import EFormService from "services/EFormService";
import CardSkeleton from "../../custom-components/CardSkeleton";
import EFormAddEdit from "./components/EFormAddEdit/EFormAddEdit";
import SystemEFormList from "./components/SystemEFormList";
import EFormAdminCard from "./components/EFormAdminCard";
import EFormDisplayCard from "./components/EFormDisplayCard";

interface Props {
  // offerings: Array<Offering>;
  // reloadOfferings: Function;
}

function AdminEForms() {
  const { eForms, reloadEForms, loading } = useEForms();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [selected, setSelected] = useState<EForm | undefined>(undefined);

  const dateFormatter = useDateTimeFormatter();
  const { t } = useTranslation();
  const openEditModal = () => {
    setIsOpenEditModal(true);
  };

  const closeEditModal = async () => {
    setIsOpenEditModal(false);
    await reloadEForms();
  };
  const closeDeleteModal = () => {
    setIsOpenDeleteModal(false);
  };

  const copyForUse = async (name, schema, ui_schema) => {
    // console.log("copy pressed for " + name);
    // const res = await ProviderConfigService.createProviderConfig(name,"EFORM",
    //   "Copied from System",steps,nodes,edges);
    const res = await EFormService.createEForm(
      {
        name:name + ' - Copy',
        category:"EFORM",
        // parent=p_dict.get("parent", None),
        config:ui_schema,
        // content=,
        defaultVal:schema,
        configScope:"Public",
        description:"Copied from System",
      });
    //console.log("copy pressed for " + JSON.stringify(res));
  };

  return (
    <DashboardLayout loading={loading}>
      <MDBox width="calc(100% - 48px)" position="absolute" top="1.75rem">
        <DashboardNavbar light absolute />
      </MDBox>
      <AdminHeader title="E-Forms" icon="list"
                   description="Add / modify E-Forms">
        <MDButton variant="gradient" color="success" onClick={openEditModal}>
          <Icon>add</Icon>&nbsp; Add New
        </MDButton>
      </AdminHeader>

      {/*<CustomDialog onClose={closeEditModal} open={isOpenEditModal} fullWidth={true} maxWidth={"sm"}*/}
      {/*              aria-labelledby="customized-dialog-title">*/}
      {/*  <DialogContent>*/}
      {/*    <EFormAddEdit eFormData={selected}*/}
      {/*                  onEditEFormCancel={closeEditModal}*/}
      {/*                  onEditEFormUpdate={closeEditModal} />*/}
      {/*  </DialogContent>*/}
      {/*</CustomDialog>*/}

      {/*<MDBox pb={3}>*/}
      {/*  <MDBox mt={5}>*/}


          <SystemEFormList copyForUseFunc={copyForUse} />


      {/*    <Grid container spacing={3}>*/}
      {/*      {loading &&*/}
      {/*        <Grid item xs={12}>*/}
      {/*          <CardSkeleton />*/}
      {/*        </Grid>*/}
      {/*      }*/}
      {/*      {!loading && eForms.map((eForm) => (*/}
      {/*        <Grid item xs={12} key={eForm.eFormId}>*/}
      {/*          <MDBox mb={1.5} mt={1.5}>*/}

      {/*            <EFormAdminCard dataObj={eForm}*/}
      {/*                            title={eForm.name}*/}
      {/*                            badge={{ color: "info", label: eForm.categoryName }}*/}
      {/*                            content={JSON.stringify(eForm)}>*/}
      {/*              /!* <MDButton variant="outlined" color="secondary"> *!/*/}
      {/*              /!*   Login *!/*/}
      {/*              /!* </MDButton> *!/*/}
      {/*              <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>*/}
      {/*                <MDButton variant="contained" color="info" sx={{ height: "100%" }}*/}
      {/*                          component={Link} to={`/admin/eForm/edit/${eForm.key}`}>*/}
      {/*                  View Details*/}
      {/*                </MDButton>*/}
      {/*              </MDBox>*/}
      {/*            </EFormAdminCard>*/}
      {/*            <EFormDisplayCard name="Default EForm"*/}
      {/*                              eformSchema={simple_eform_schema}*/}
      {/*                              eformUISchema={simple_eform_ui_schema}*/}
      {/*            />*/}
      {/*          </MDBox>*/}
      {/*        </Grid>*/}
      {/*      ))}*/}

      {/*      /!*<Grid item xs={12} md={6} lg={4}>*!/*/}
      {/*      /!*  <MDBox mb={1.5} mt={1.5}>*!/*/}
      {/*      /!*    <ComplexProjectCard*!/*/}
      {/*      /!*      image={logoAtlassian}*!/*/}
      {/*      /!*      title="EForm Development"*!/*/}
      {/*      /!*      description="We strive to embrace and drive change in our industry. We are happy to work at such a project."*!/*/}
      {/*      /!*      dateTime="16.01.22"*!/*/}
      {/*      /!*      members={[team1, team2, team3, team4]}*!/*/}
      {/*      /!*      dropdown={{*!/*/}
      {/*      /!*        action: openDeveloperFirstMenu,*!/*/}
      {/*      /!*        menu: renderMenu(developerFirstMenu, closeDeveloperFirstMenu),*!/*/}
      {/*      /!*      }}*!/*/}
      {/*      /!*    />*!/*/}
      {/*      /!*  </MDBox>*!/*/}
      {/*      /!*</Grid>*!/*/}
      {/*    </Grid>*/}
      {/*  </MDBox>*/}
      {/*</MDBox>*/}

      {/*{eForms && <SystemEFormsListView eForms={eForms} />}*/}
      {eForms && <EFormsTableView eForms={eForms} />}
      {/*<EFormRenderer schema={simple_eform_schema} uiSchema={simple_eform_ui_schema} />*/}
      {/*{eForms && JSON.stringify(eForms[0]) }*/}
    </DashboardLayout>
  );
}

export default AdminEForms;
