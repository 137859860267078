import React, { useCallback } from 'react';
import { Handle, Position } from 'reactflow';

import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Tooltip from "@mui/material/Tooltip";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import media from "assets/images/cs-gray-sym.svg";
import DefaultInfoCard from "views/custom-components/InfoCards/DefaultInfoCard";
import DefaultInfoNodeCard from "./DefaultInfoNodeCard";

const handleStyle = { left: 10 };

function CardNode({ data, isConnectable }) {
  const onChange = useCallback((evt) => {
    //console.log(evt.target.value);
  }, []);

  return (
    <MDBox>
      <Handle id="c" type="target"
              position={Position.Left} isConnectable={isConnectable} />
      {/*<MDBox align="center">*/}
      {/*  <Tooltip key={data.label} title={data.label} placement="bottom">*/}
      {/*    <MDAvatar*/}
      {/*      src={media}*/}
      {/*      alt={data.label}*/}
      {/*      size="lg"*/}
      {/*      sx={({ borders: { borderWidth }, palette: { white } }) => ({*/}
      {/*        border: `${borderWidth[2]} solid ${white.main}`,*/}
      {/*        cursor: "pointer",*/}
      {/*        position: "relative",*/}
      {/*        ml: -1.25,*/}

      {/*        "&:hover, &:focus": {*/}
      {/*          zIndex: "10",*/}
      {/*        },*/}
      {/*      })}*/}
      {/*    />*/}
      {/*  </Tooltip>*/}
      {/*  /!*<MDTypography variant="h6" color="text">{data.label}</MDTypography>*!/*/}
      {/*</MDBox>*/}
      <MDBox width="100%" mr={{ xs: 0, sm: 3 }} mb={{ xs: 3, sm: 0 }}>
        <DefaultInfoNodeCard color={data.color || "warning"}
          icon={data.icon || "info"}
          title={data.label}
          description={data.description }
          // value={data.value }
        />
      </MDBox>
      {/*<Handle*/}
      {/*  type="source"*/}
      {/*  position={Position.Left}*/}
      {/*  id="a"*/}
      {/*  style={handleStyle}*/}
      {/*  isConnectable={isConnectable}*/}
      {/*/>*/}
      <Handle type="source" position={Position.Right} id="b" isConnectable={isConnectable} />
    </MDBox>
  );
}

export default CardNode;
