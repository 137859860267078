import React from "react";
import Card from "@mui/material/Card";

import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";

import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import { workflowNodeTypes } from "static-data/wf-node-types";

function SubmissionStepsNav({ wfSteps, activeStep }) {
  const getNodeDetails = (nodeType) => workflowNodeTypes.find(nt => nt.nodeType === nodeType);


  return (
    <Card sx={{
      // height: "100%",
      // borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
      position: "sticky",
      top: "1%"
    }}>
      <MDBox m={2} pt={2} px={2}
             display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h6" fontWeight="medium">
          Workflow / process
        </MDTypography>
        {/*<MDButton variant="outlined" color="info" size="small">*/}
        {/*  view all*/}
        {/*</MDButton>*/}
      </MDBox>

      {/*{s.seq===activeStep.seq ?"h5": "h6"}*/}

      {wfSteps &&
        <MDBox m={2} px={3} mb={2} lineHeight={1}>
          <Grid container spacing={2} alignItems="center">
            {wfSteps.map((s) => {
                const nodeType = getNodeDetails(s.sourceType);
                return <Grid item xs={12} key={s.seq}>
                  <MDBox
                    sx={({
                           borders: { borderRadius },
                           functions: { pxToRem },
                           palette: { light, primary },
                           transitions
                         }) => ({
                      // display: "flex",
                      // alignItems: "center",
                      borderRadius: borderRadius.sm,
                      padding: `${pxToRem(6)} ${pxToRem(4)}`,
                      transition: transitions.create("background-color", {
                        easing: transitions.easing.easeInOut,
                        duration: transitions.duration.shorter
                      })
                      // backgroundColor: {s.seq===activeStep.seq ? primary.main : "transparent"} ,
                      // "&:hover": {
                      //   backgroundColor: light.main,
                      // },
                    })}
                  >
                    <MDTypography variant="h6" fontWeight={s.seq === activeStep.seq ? "bold" : "regular"}
                                  color={s.seq === activeStep.seq ? "primary" : "text"}>
                      <MDBadge variant="contained" color={"secondary"} badgeContent={"" + s.seq} />
                      &nbsp;
                      <MDTypography display="inline" component="small" variant="h6"
                                    color={nodeType ? nodeType.color : "text"}>
                        {"|"}
                      </MDTypography>
                      &nbsp;
                      {nodeType ? nodeType.label : s.sourceType}
                    </MDTypography>
                    {/*<MDBox*/}
                    {/*  display="grid"*/}
                    {/*  justifyContent="center"*/}
                    {/*  alignItems="center"*/}
                    {/*  bgColor={nodeType? nodeType.color : "secondary"}*/}
                    {/*  color="white"*/}
                    {/*  width="2rem"*/}
                    {/*  height="2rem"*/}
                    {/*  shadow="sm"*/}
                    {/*  borderRadius="md"*/}
                    {/*  variant="gradient"*/}
                    {/*>*/}
                    {/*  <Icon fontSize="small">*/}
                    {/*    {nodeType? nodeType.icon : "question"}*/}
                    {/*  </Icon>*/}
                    {/*</MDBox>*/}
                    {/*<MDTypography variant="h6" fontWeight="regular" color={nodeType? nodeType.color : "text"}>*/}
                    {/* */}
                    {/*</MDTypography>*/}
                    <MDBox px={2} ml={2.5} lineHeight={0}>
                      {s.sourceData && s.sourceData.name !== "None" &&
                        <Tooltip title={s.sourceData.key} placement="bottom">
                          <MDTypography variant="caption" color="text" fontWeight="regular">
                            {s.sourceData.name}
                          </MDTypography>

                        </Tooltip>}
                    </MDBox>
                  </MDBox>
                </Grid>;
              }
            )}
            {/*{JSON.stringify(activeStep)}*/}
          </Grid>

        </MDBox>}
    </Card>
  );
}

export default SubmissionStepsNav;