import React, { useState } from "react";

import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import CircularProgress from "@mui/material/CircularProgress";
import DataTable from "examples/Tables/DataTable";

import Tooltip from "@mui/material/Tooltip";
// Data
import { useEForms } from "../../../hooks";
import { Application, EForm } from "../../../models";
import { useNavigate } from "react-router-dom";
import IdCell from "../../../layouts/ecommerce/orders/order-list/components/IdCell";
import DefaultCell from "../../../layouts/ecommerce/orders/order-list/components/DefaultCell";
import Link from "../application-setup/components/Link";
import UtilsService from "../../../services/UtilsService";
import EFormFieldsView from "./components/EFormFieldsView";
import { useDateTimeFormatter, useSettings } from "hooks";


function EFormsTableView({eForms}) {
  const navigate = useNavigate();

  const [menu, setMenu] = useState(null);

  const [filter, setFilter] = useState("");
  const [selected, setSelected] = useState<Array<EForm>>([]);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const dateFormatter = useDateTimeFormatter();

  const getEFormsTableData = () => {
    return {
      columns: [
        {
          Header: "Form",
          accessor: "key",
          Cell: ({ row }) => {
            const eForm = row.original as EForm;
            return (
              <Tooltip title={eForm.key} placement="top">
                <MDTypography
                  component={Link}
                  to={`/admin/e-form/edit/${eForm.key}`}
                  variant="h6"
                  color="info"
                  fontWeight="bold"
                >
                  {eForm?.defaultVal?.title}
                  {/*{eForm.name ? eForm.name : "(empty)"}*/}
                  {/*{JSON.stringify( eForm)}*/}
                </MDTypography>
              </Tooltip>
            );
          }
        },
        // { Header: "id", accessor: "key", Cell: ({ value }) => <IdCell id={value} /> },
        { Header: "Description", accessor: "description", Cell: ({ value }) => <DefaultCell value={value} /> },
        {
          Header: "Form",
          accessor: "defaultVal",
          Cell: ({ row }) => {
            const eForm = row.original as EForm;
            const schemaProperties = eForm.defaultVal.properties;
            return (

                <MDTypography
                  // component={Link}
                  // to={`/admin/eform/edit/${eForm.configId}`}
                  variant="caption"
                  // color="info"
                  // fontWeight="bold"
                >
                  {/*<EFormFieldsView schemaProperties={eForm.defaultVal.properties} />*/}
                  {Object.keys(schemaProperties).map((columnName) => (
                        schemaProperties[columnName].title + " "
                  ))}

                  {/*{JSON.stringify( eForm)}*/}
                </MDTypography>
            );
          }
        },


        {
          Header: "Updated",
          accessor: "updatedAt",
          Cell: ({ value }) => <DefaultCell value={dateFormatter(value)} />
        },
        {
          Header: "Created",
          accessor: "createdAt",
          Cell: ({ value }) => <DefaultCell value={dateFormatter(value)} />
        }

        // { Header: "revenue", accessor: "given_name", Cell: ({ value }) => <DefaultCell value={value} /> },
      ],

      rows: eForms
      // rows: [
      //   {
      //     id: "#10421",
      //     date: "1 Nov, 10:20 AM",
      //     status: "paid",
      //     customer: ["Orlando Imieto", { image: team2 }],
      //     product: "Nike Sport V2",
      //     revenue: "$140,20",
      //   },
      //
      // ],
    };
  };


  // const getEFormRows = () => {
  //   users.map(usr => {})
  // }
  const addEForms = () => {
    navigate("/admin/users/add");
  };

  const changeRole = () => {
    // navigate("/admin/users/changerole", {
    //   emails: selected.map((s) => s.email).join(", "),
    //   usernames: selected.map((s) => s.userId),
    // });
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={closeMenu}>Status: Paid</MenuItem>
      <MenuItem onClick={closeMenu}>Status: Refunded</MenuItem>
      <MenuItem onClick={closeMenu}>Status: Canceled</MenuItem>
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <MDTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  return (


    <MDBox my={3}>
      <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>

        <MDBox display="flex">
          <MDButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
            filters&nbsp;
            <Icon>keyboard_arrow_down</Icon>
          </MDButton>
          {renderMenu}
          <MDBox ml={1}>
            <MDButton variant="outlined" color="dark">
              <Icon>description</Icon>
              &nbsp;export csv
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
      <Card>
        {eForms ? <DataTable table={getEFormsTableData()} entriesPerPage={false} canSearch />
          :
          <MDBox sx={{ display: "flex" }} py={4} px={4}>
            <CircularProgress />
          </MDBox>
        }
      </Card>
    </MDBox>


  );
}

export default EFormsTableView;
