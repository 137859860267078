import React, { lazy, Suspense, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// ProductPage page components
// Data
import ConsumerLayout from "../../../LayoutContainers/ConsumerLayout";
import SubmissionHeader from "./components/SubmissionHeader";

import useApplicationStore from "../../../store/application-store";
import MDButton from "../../../components/MDButton";
import { useTranslation } from "react-i18next";
import SubmissionInfo from "./components/SubmissionInfo";
// import RenderConsumerApplication from "../../custom-components/RenderConsumerApplication";
import { useMaterialUIController } from "../../../context";
import { useSubmissionContextHook } from "../../../hooks/submission-hooks";
import withSubmission from "../../../LayoutContainers/ConsumerLayout/withSubmission";
import Spinner from "../../custom-components/Spinner/Spinner";
import SubmissionStepsNav from "./components/SubmissionStepsNav/SubmissionStepsNav";
import { workflowNodeTypes } from "../../../static-data/wf-node-types";
import SubmissionPreview from "views/shared/SubmissionPreview";
import DisplayKeyValueCard from "../../custom-components/DisplayKeyValueCard";
// import useConsumerSubmissionStore from "../../../store/consumer-submission-store-ctx";
// import useConsumerSubmissionStoreBase from "../../../store/consumer-submission-store-ctx";
// import createSelectors from "../../../store/selectors";

const RenderConsumerApplication = lazy(() => import("../../custom-components/RenderConsumerApplication"));

function getPCPSteps(submissionPCP) {
  if (!submissionPCP) return;
  return submissionPCP.workflow && submissionPCP.userFlow.steps;
}

function getWFSteps(pcpSteps) {
  if (!pcpSteps) return;
  return pcpSteps.sort((a, b) => a.seq > b.seq ? 1 : -1);
}

function SubmissionPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  // const { tenantConfig, reloadTenantConfig, loadingTenantConfig } = usePublicTenantConfig();
  const [loadingData, setLoadingData] = useState<boolean>(false);
  // const {offerings,reloadOfferings, loading} = useOfferings();
  const { submissionId, tenantName } = useParams();
  // const [submission, setSubmission] = useState<Submission>();
  const {
    loadingSubmission: loading,
    submissionRows,
    submissionPCP,
    summary,
    submissionMain,
    submissionLoaded,
    editMode,
    setEditMode,
    reloadSubmission
  } = useSubmissionContextHook(submissionId);

  const pcpSteps = getPCPSteps(submissionPCP);
  const wfSteps = getWFSteps(pcpSteps);

  //Application
  const initApplStore = useApplicationStore.use.initStore();
  // const getFullApplication = useConsumerSingleSubmissionStore.use.getFullApplication();
  const applications = useApplicationStore.use.applications();
  const loadingAppl = useApplicationStore.use.loading();
  const transparent = true;
  const light = true;
  const [lights, setLights] = useState(false);

  const handleSetLights = () => setLights(!lights);
  // const {workflow, loadingWF} = useConsumerWorkflow(submissionPCP.workflow.workflowId);
  const getNodeDetails = (nodeType) => workflowNodeTypes.find(nt => nt.nodeType === nodeType);
  // const [workflow, setWorkflow] = useState();
  // const [offering, setOffering] = useState();
  const [activeStep, setActiveStep] = useState(0);
  // const [wfSteps, setWFSteps] = useState();


  // const steps = getSteps();
  // const isLastStep = activeStep === steps.length - 1;

  // useEffect(() => {
  //   console.log("SubmissionId from useParams:", submissionId);
  //   console.log("is submissionLoaded:", submissionLoaded);
  //
  //   if (submissionId && !submissionLoaded) {
  //     // window.sessionStorage.setItem('submissionId', submissionId);
  //     // reloadSubmission(submissionId)
  //   }
  // }, [submissionId]);

  useEffect(() => {
    if (!pcpSteps) return;
    const nextStep = pcpSteps.find(s => !s.sourceState || s.sourceState !== "DONE");
    // setActiveStep(nextStep.seq);
    setActiveStep(2);
  }, [pcpSteps]);

  // useEffect(() => {
  //   //console.log"Store storeSubmissionId:", storeSubmissionId);
  //     if (!submissionPCP) return;
  //     const pcpSteps = submissionPCP.workflow && submissionPCP.userFlow.steps;
  //     if (!pcpSteps) return;
  //     setWFSteps(pcpSteps.sort((a, b) => a.seq > b.seq ? 1 : -1));
  //     const nextStep = pcpSteps.find(s => !s.sourceState || s.sourceState !== "DONE");
  //     setActiveStep(nextStep.seq);
  //     setActiveStep(1);
  // }, [submissionPCP]);

  // const [progress, setProgress] = React.useState(0);
  //
  // React.useEffect(() => {
  //   const timer = setInterval(() => {
  //     setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
  //   }, 800);
  //
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);


  // useEffect(() => {
  //   //console.log"Store storeSubmissionId:", storeSubmissionId);
  //   if (!submissionId) return;
  //
  //   initStore(submissionId).then(() => {
  //     if (!submissionPCP) return;
  //     const pcpSteps = submissionPCP.workflow && submissionPCP.userFlow.steps;
  //     if (!pcpSteps) return;
  //     setWFSteps(pcpSteps.sort((a, b) => a.seq > b.seq ? 1 : -1));
  //     const nextStep = pcpSteps.find(s => !s.sourceState || s.sourceState !== "DONE");
  //     setActiveStep(nextStep.seq);
  //     setActiveStep(1);
  //   });
  // }, [storeSubmissionId]);

  const handleNext = () => {
    if (activeStep >= wfSteps.length - 1) return;
    setActiveStep(activeStep + 1);
  };
  const handleBack = () => {
    if (activeStep === 0) return;
    setActiveStep(activeStep - 1);
  };

  // function getSteps() {
  //   if (!wfSteps) return [];
  //   return wfSteps;
  // }

  function getCurrentStep() {
    if (!wfSteps) return;
    //console.log"getCurrentStep ", activeStep, wfSteps);
    return wfSteps.find(s => s.seq === activeStep);
  }

  const preloadStepContent = (stepData) => {
    //console.log"?preloadStepContent - ", stepData);
    switch (stepData.targetType) {
      case "applicationNode":
        const appKey = stepData.targetData.key.split(/[?:;|$]+/)[1];
        getFullApplication(appKey);
    }
  };

  const getCurrentStepNavContent = (stepIndex) => {
    if (!wfSteps) return;
    const currStep = wfSteps.find(s => s.seq === stepIndex);
    if (!currStep) {
      //console.log"Current step not found with index {}", stepIndex, wfSteps);
      return;
    }
    console.log("Current step - ", stepIndex, currStep);
    return currStep;
  };

  const getCurrentStepHeader = () => {
    if (!pcpSteps) return;
    const currStep = pcpSteps.find(s => s.seq === activeStep);
    if (!currStep) {
      //console.log"Current step not found with index {}", stepIndex, wfSteps);
      return "None";
    }
    const nodeType = getNodeDetails(currStep.sourceType);
    return <>
      <MDBox mb={1}>
        <MDTypography variant="h4" fontWeight="medium">
          {nodeType ? nodeType.label : currStep.sourceType}
        </MDTypography>
      </MDBox>
      {currStep.sourceData && currStep.sourceData.name !== "None" &&
        <MDTypography variant="body2" color="text" fontWeight="regular">
          {currStep.sourceData.name}
        </MDTypography>

      }

    </>;
  };
  const getStepContent =  () => {
    if (!wfSteps) return;
    const stepIndex = activeStep;
    const currStep = wfSteps.find(s => s.seq === stepIndex);
    if (!currStep) {
      //console.log"Current step not found with index {}", stepIndex, wfSteps);
      return;
    }
    console.log("Current step - ", stepIndex, currStep);
    //
    // setEditMode(currStep.sourceType!=="reviewNode").then(()=>{
    //
    // })
    // preloadStepContent(currStep);
    if (!editMode && currStep.sourceType!=="reviewNode") {
      console.log(" == getStepContent setting editMode to true", stepIndex, currStep);
      setEditMode(true);
    }

    switch (currStep.sourceType) {
      case "startNode":
        return <SubmissionInfo />;
      // case "dataNode":
      //   return <Pricing />;
      case "applicationNode":
        const appKey = currStep.sourceData.key.split(/[?:;|$]+/)[1];
        //return <RenderApplication applicationId={"t4-9|APPHASHSYMbf9d7137-a01f-4f5d-8c92-b5278995a604:APPHASHSYMbf9d7137-a01f-4f5d-8c92-b5278995a604"}/>;
        return <Suspense fallback={<div>Rendering your application... </div>}>
          <RenderConsumerApplication applicationId={appKey}
            // submissionPCP={submissionPCP}
          />
        </Suspense>;
      case "reviewNode":
        return <SubmissionPreview
          submissionPCP={submissionPCP}
          oSteps={getPCPSteps(submissionPCP)}
          loading={loading}
          submissionLoaded={submissionLoaded}
        > <MDBox>children</MDBox> </SubmissionPreview>;
      case "tncNode":
        return <MDBox> TBD: TnC Screen </MDBox>;
      default:
        return <MDBox>
            Default Screen {JSON.stringify(currStep)}
        </MDBox>;
    }
  };

  // useEffect(() => {

  // }, []);


  // useEffect(() => {
  //   console.log("SubmissionPage loadingTenantConfig " + loadingTenantConfig);
  //   console.log("SubmissionPage submissionId " + submissionId);
  //   // console.log("ProviderHome loaded tenantConfig " + JSON.stringify(tenantConfig));
  //   if (!loadingTenantConfig && tenantConfig.userPoolId){
  //     // fetchData();
  //     console.log("SubmissionPage tenant loaded " );
  //   }
  // }, [loadingTenantConfig]);

  if (loading && !submissionMain && !submissionPCP) {
    return (
      <ConsumerLayout background={"default"} loading={loading}>
        <MDBox mt={2} minHeight="20vh">
          <Spinner minHeight={400} />
        </MDBox>
      </ConsumerLayout>
    );
  }

  return (
    <ConsumerLayout background={"default"} loading={loading}>

      {/*<SubmissionStoreProvider>*/}
      <MDBox mt={2} minHeight="20vh">
        <SubmissionHeader
          //     loadingSubmission={loading}
          // submissionPCP={submissionPCP}
          // submissionMain={submissionMain}
          // submissionLoaded={submissionLoaded}
        />

        <MDBox mb={3}>


          <MDBox>


            {/*<Card>*/}

            {/*  <MDBox mx={4} my={2} >*/}
            {/*    <Grid container spacing={3} alignItems="center">*/}
            {/*      /!*<Grid item>*!/*/}
            {/*      /!*  <MDAvatar src={burceMars} alt="profile-image" size="xl" shadow="sm" />*!/*/}
            {/*      /!*</Grid>*!/*/}
            {/*      <Grid item>*/}
            {/*        <MDBox height="100%" mt={0.5} lineHeight={1}>*/}
            {/*          <MDTypography variant="h5" fontWeight="medium">*/}
            {/*            {submissionPCP && submissionPCP.participant}*/}
            {/*          </MDTypography>*/}
            {/*          <MDTypography variant="button" color="text" fontWeight="medium">*/}
            {/*            {submissionPCP && submissionPCP.participantType}*/}
            {/*          </MDTypography>*/}
            {/*        </MDBox>*/}
            {/*      </Grid>*/}
            {/*      <Grid item xs={12} md={6} lg={6}>*/}
            {/*        <MDTypography variant="h6" fontWeight="regular" color={ "text"}>*/}
            {/*          Notes:*/}
            {/*        </MDTypography>*/}
            {/*      </Grid>*/}
            {/*      <Grid item xs={12} md={6} lg={2} sx={{ ml: "auto" }}>*/}
            {/*        <MDBox*/}
            {/*          display="flex"*/}
            {/*          justifyContent={{ md: "flex-end" }}*/}
            {/*          alignItems="center"*/}
            {/*          lineHeight={1}*/}
            {/*        >*/}
            {/*          /!*<MDTypography variant="caption" fontWeight="regular">*!/*/}
            {/*          /!*  Invite others to join*!/*/}
            {/*          /!*</MDTypography>*!/*/}
            {/*          <MDBox ml={1}>*/}
            {/*            <MDButton*/}
            {/*              // disabled={isSubmitting}*/}
            {/*              // type="submit"*/}
            {/*              variant="text"*/}
            {/*              color="secondary" size="small"*/}
            {/*            >*/}
            {/*              Invite to join*/}
            {/*            </MDButton>*/}
            {/*          </MDBox>*/}
            {/*        </MDBox>*/}

            {/*      </Grid>*/}
            {/*    </Grid>*/}
            {/*  </MDBox>*/}
            {/*</Card>*/}
          </MDBox>
          <MDBox>
            {/*<Card>*/}
            <MDBox m={2} pt={2} minHeight="40px">
              {submissionLoaded ?
                getCurrentStepHeader()
                :
                <MDBox mt={2}>
                  ...
                </MDBox>
              }
            </MDBox>

            {/*Edit Mode : {JSON.stringify( editMode )}*/}

            {/*</Card>*/}
            <MDBox py={2} minHeight="50vh">
              <Grid container spacing={3}>
                {/*<Grid item xs={12}>*/}
                {/*  <MDBox*/}
                {/*    // py={1}*/}
                {/*    px={{ xs: 4, sm: transparent ? 2 : 3, lg: transparent ? 0 : 2 }}*/}
                {/*    my={-10}*/}
                {/*    mx={4}*/}
                {/*    // width="calc(100% - 48px)"*/}
                {/*    borderRadius="lg"*/}
                {/*    shadow={transparent ? "none" : "md"}*/}
                {/*    color={light ? "white" : "dark"}*/}
                {/*    // position="absolute"*/}
                {/*    left={0}*/}
                {/*    zIndex={98}*/}
                {/*    sx={({*/}
                {/*           palette: { transparent: transparentColor, white, background },*/}
                {/*           functions: { rgba }*/}
                {/*         }) => ({*/}
                {/*      backgroundColor: transparent*/}
                {/*        ? transparentColor.main*/}
                {/*        : rgba(darkMode ? background.sidenav : white.main, 0.8),*/}
                {/*      backdropFilter: transparent ? "none" : `saturate(200%) blur(30px)`*/}
                {/*    })}*/}
                {/*  >*/}
                {/*    <WorkflowConsumerView userFlow={submissionPCP.userFlow} activeStep={activeStep} />*/}
                {/*  </MDBox>*/}
                {/*</Grid>*/}

                <Grid item xs={9}>
                  {/*{(!loading && submissionPCP) && getStepContent(activeStep)}*/}
                  <Card>
                    <MDBox m={4} minHeight="20vh">
                      {submissionLoaded ?
                        <Fade in={submissionLoaded} timeout={1300}>
                          <MDBox>
                            {getStepContent()}
                          </MDBox>
                        </Fade>
                        :
                        <MDBox mt={2}>
                          <Spinner minHeight={300} />
                        </MDBox>
                      }
                    </MDBox>
                  </Card>
                  {wfSteps &&
                    <MDBox mt={4}>
                      <MDBox ml="auto" justifyContent="space-between" display="flex"
                             alignItems={{ xs: "flex-end", sm: "center" }}
                             flexDirection={{ xs: "column", sm: "row" }}>
                        <MDButton variant="gradient" color="secondary" size="large"
                                  disabled={activeStep === 0}
                                  onClick={handleBack}
                        >
                          {t("Back")}
                        </MDButton>
                        <MDButton
                          disabled={activeStep === wfSteps.length - 1}
                          onClick={handleNext}
                          type="submit"
                          variant="gradient"
                          color="primary" size="large"
                        >
                          Next
                        </MDButton>

                        {/* <MDButton variant="gradient" color="secondary" size="large" */}
                        {/*           onClick={onCancel}> */}
                        {/*   {t("Cancel")} */}
                        {/* </MDButton> */}
                      </MDBox>

                    </MDBox>}
                </Grid>

                <Grid item xs={3}>
                  <SubmissionStepsNav wfSteps={pcpSteps} activeStep={getCurrentStepNavContent(activeStep)} />
                  {/*<MDTypography variant="caption" color="text" fontWeight="regular">*/}
                  {/*  {wfSteps && JSON.stringify(getCurrentStep())}*/}
                  {/*</MDTypography>*/}
                  {/*<SubmissionStepsNav wfSteps={wfSteps} />*/}
                </Grid>

              </Grid>

            </MDBox>


          </MDBox>


          {/*{submissionRows &&*/}
          {/*    <MDBox py={3}>*/}
          {/*      submissionRows*/}
          {/*      <MDTypography variant="caption">*/}
          {/*      {submissionRows.map(r=>(*/}
          {/*              <p key={r.sk}>*/}
          {/*                {r.sk}  - {JSON.stringify(r.content)}*/}
          {/*              </p>*/}
          {/*          ))*/}
          {/*      }*/}
          {/*      </MDTypography>*/}
          {/*    </MDBox>*/}
          {/*}*/}

          {/*{wfSteps &&*/}
          {/*  <MDBox py={3}>*/}
          {/*    wfSteps*/}
          {/*    <MDTypography variant="caption">*/}
          {/*      {wfSteps.map((r,idx)=>(*/}
          {/*        <p key={r.seq}>*/}
          {/*          {JSON.stringify(r)}*/}
          {/*        </p>*/}
          {/*      ))*/}
          {/*      }*/}
          {/*    </MDTypography>*/}
          {/*  </MDBox>*/}
          {/*}*/}

          {/*{submissionPCP &&*/}
          {/*  <MDBox py={3}>*/}
          {/*    submissionPCP*/}
          {/*    <MDTypography variant="caption">*/}
          {/*      {JSON.stringify(submissionPCP)}*/}
          {/*    </MDTypography>*/}
          {/*  </MDBox>*/}
          {/*}*/}
          {/*{submissionMain &&*/}
          {/*  <MDBox py={3}>*/}
          {/*    submissionMain*/}
          {/*    <MDTypography variant="caption">*/}
          {/*      {JSON.stringify(submissionMain)}*/}
          {/*    </MDTypography>*/}
          {/*  </MDBox>*/}
          {/*}*/}

          {/*Offering:*/}
          {/*{submissionMain && submissionMain.refData && <MDTypography variant="caption">*/}
          {/*  /!*<RenderApplication applicationId={workflow.settings.applicationId}*!/*/}
          {/*  /!*                  />*!/*/}
          {/*  {JSON.stringify(submissionMain.refData)}*/}
          {/*</MDTypography>}*/}


          {/*<MDBox>*/}
          {/*  <MDBox*/}
          {/*    display="flex"*/}
          {/*    justifyContent="space-between"*/}
          {/*    alignItems={{ xs: "flex-start", sm: "center" }}*/}
          {/*    flexDirection={{ xs: "column", sm: "row" }}*/}
          {/*  >*/}
          {/*    <MDTypography variant="body2" color="text">*/}
          {/*      SMS number*/}
          {/*    </MDTypography>*/}
          {/*    <MDBox*/}
          {/*      display="flex"*/}
          {/*      alignItems={{ xs: "flex-start", sm: "center" }}*/}
          {/*      flexDirection={{ xs: "column", sm: "row" }}*/}
          {/*    >*/}
          {/*      <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>*/}
          {/*        <MDTypography variant="button" color="text" fontWeight="regular">*/}
          {/*          +3012374423*/}
          {/*        </MDTypography>*/}
          {/*      </MDBox>*/}
          {/*      <MDButton variant="outlined" color="dark" size="small">*/}
          {/*        edit*/}
          {/*      </MDButton>*/}
          {/*    </MDBox>*/}
          {/*  </MDBox>*/}
          {/*</MDBox>*/}

          {/*</Grid>*/}
          {/*<Grid item xs={12} lg={3}>*/}
          {/*  /!*<Invoices />*!/*/}
          {/*  <SubmissionStepsNav wfSteps={wfSteps} />*/}

          {/*</Grid>*/}
          {/*</Grid>*/}
        </MDBox>

      </MDBox>

      {/*<MDBox mt={4}>*/}

      {/*  <MDBox mb={3}>*/}
      {/*    <Grid container spacing={3}>*/}
      {/*      <Grid item xs={12} md={7}>*/}
      {/*        <BillingInformation />*/}
      {/*      </Grid>*/}
      {/*      <Grid item xs={12} md={5}>*/}
      {/*        <Transactions />*/}
      {/*      </Grid>*/}
      {/*    </Grid>*/}
      {/*  </MDBox>*/}
      {/*</MDBox>*/}

      {/*</SubmissionStoreProvider>*/}
    </ConsumerLayout>
  );
}

export default withSubmission(SubmissionPage);
