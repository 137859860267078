function generateFromName(name: string): string {
  return name
    .trim()
    .toLocaleLowerCase()
    .replace(/[!#$&'\(\)\*\+,\/:;=\?@\[\]]+/g, " ") // remove RFC-3986 reserved characters
    .replace(/\s+/g, "-") // replace spaces for dashes
    .replace(/-+/g, "-") // convert consecutive dashes to singular dash
    .replace(/^-+|-+$/g, ""); // remove dashes at the end and beginning
}

function getHostPathForFriendlyUrl(module:string,subdomain?:string, tenantName?:string): string {
  const host=window.location.hostname;
  if (subdomain && subdomain.trim().length>1){
    switch (module) {
      case 'offering':
        return `https://${host}/app/offering/`;
      // case 'application':
      //   return `https://${host}/app/application/`;
      case 'submission':
        return `https://${host}/app/submission/`;
    }
  }else {
    switch (module) {
      case 'offering':
        return `https://${host}/${tenantName}/app/offering/`;
      // case 'application':
      //   return `https://${host}/{domain}/app/application/`;
      case 'submission':
        return `https://${host}/${tenantName}/app/submission/`;
    }
  }
}

const FriendlyURLGenerator = {
  generateFromName,
  getHostPathForFriendlyUrl
};

export default FriendlyURLGenerator;
