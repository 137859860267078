import PropTypes from "prop-types";

import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDProgress from "components/MDProgress";
import Card from "@mui/material/Card";
import MDButton from "../../../components/MDButton";

// Custom styles for the Card

function ActionCard({ image, title, subtitle, badge, content,
                      progress, attachedFiles, children }) {
  // const renderMembers = members.map((member, key) => {
  //   const imageAlt = `image-${key}`;
  //
  //   return (
  //     <MDAvatar
  //       key={imageAlt}
  //       src={member}
  //       alt={imageAlt}
  //       size="xs"
  //       sx={{
  //         border: ({ borders: { borderWidth }, palette: { white } }) =>
  //           `${borderWidth[2]} solid ${white.main}`,
  //         cursor: "pointer",
  //         position: "relative",
  //         ml: -1,
  //         mr: 0,
  //
  //         "&:hover, &:focus": {
  //           zIndex: "10",
  //         },
  //       }}
  //     />
  //   );
  // });

  return (
    <Card
      // sx={({ palette: { gradients }, functions: { linearGradient }, boxShadows: { xl } }) => ({
      //   background: gradients[badge.color]
      //     ? linearGradient(gradients[badge.color].main, gradients[badge.color].state)
      //     : linearGradient(gradients.dark.main, gradients.dark.state),
      //   boxShadow: xl,
      //   position: "relative",
      // })}
    >
      {image && <MDBox display="flex" justifyContent="center" alignItems="center" >
        <MDBox component="img" src={image} width="50%" borderRadius="lg" mb={1} />
      </MDBox>}
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} mb={2} px={3}>
        <MDBox pt={1} lineHeight={1}>
          <MDTypography variant="h6" fontWeight="medium">
            {title}
          </MDTypography>
          {subtitle &&           <MDTypography variant="button" color="text" fontWeight="regular">
            {subtitle}
          </MDTypography>          }
        </MDBox>

        {badge && <MDBadge variant="contained" color={badge.color} badgeContent={badge.label} container />}
      </MDBox>

      <MDBox mt={1} px={3} mb={2}>
        <MDTypography variant="body2" color="text">
          {content}
        </MDTypography>
        {progress > 0 && (
          <MDBox mt={0.25}>
            <MDProgress variant="gradient" value={progress} color={badge.color} />
          </MDBox>
        )}
      </MDBox>
      <MDBox display="flex" justifyContent="space-between" alignItems="center">
        <MDBox px={2} display="flex" alignItems="center" color="text">
          {attachedFiles && (
            <>
              <MDTypography variant="body2" color="text" sx={{ lineHeight: 0 }}>
                <Icon sx={{ fontWeight: "bold" }}>attach_file</Icon>
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text">
                &nbsp;{attachedFiles}
              </MDTypography>
            </>
          )}
        </MDBox>
        <MDBox p={3}>
          <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
            {children}
          </MDBox>
        </MDBox>
        {/* <MDBox display="flex">{renderMembers}</MDBox> */}
      </MDBox>
    </Card>
  );
}

// Setting default props for the Card
ActionCard.defaultProps = {
  image: "",
  progress: 0,
  attachedFiles: "",
};

// Typechecking props for the Card
ActionCard.propTypes = {
  image: PropTypes.string,
  badge: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string,
  }).isRequired,
  content: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  progress: PropTypes.number,
  attachedFiles: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
};

export default ActionCard;
