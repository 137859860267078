import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Images
import logoSlack from "assets/images/small-logos/logo-slack.svg";
import logoCS from "assets/images/cs-gray-sym.svg";


import { useMaterialUIController } from "context";
import NoContentBox from "../../../../custom-components/QuickMessageBox";
import OrderingService, { WidgetTreeData } from "../../../../../services/OrderingService";
import AppWidgetTreeItem from "../../../application-widgets-edit/components/AppWidgetTreeItem";
import { widgetItemTypes } from "../../../../../static-data/app-widget-types";
import { WidgetType } from "../../../../../models";

function ManageContentCard({ application }) {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    const { t } = useTranslation();
    const { key: applicationKey, appId, widgets } = application;
    widgets.sort((a, b) => a.order - b.order);
    const navigate = useNavigate();
    const [tree, setTree] = useState<WidgetTreeData>();

    const [slack2FA, setSlack2FA] = useState(true);
    const [spotify2FA, setSpotify2FA] = useState(true);
    const [atlassian2FA, setAtlassian2FA] = useState(true);
    const [asana2FA, setAsana2FA] = useState(false);

    const handleSetSlack2FA = () => setSlack2FA(!slack2FA);
    const handleSetSpotify2FA = () => setSpotify2FA(!spotify2FA);
    const handleSetAtlassian2FA = () => setAtlassian2FA(!atlassian2FA);
    const handleSetAsana2FA = () => setAsana2FA(!asana2FA);

    const widgetItems = widgetItemTypes;

    useEffect(() => {
        const newNodes = OrderingService.buildTree(widgets);
        setTree(newNodes);
    }, [widgets]);

    const onEditWidgets = async () => {
        navigate(`/admin/application/${application.appId}/widgets-edit`);
    };

    const renderWidgetItems = (nodes) => {
      if (!nodes || nodes.length<1) return null;
        return nodes.map(node => {
          if (!node.widget) return null;
            const wi = widgetItems.find(w => w.href === node.widget.widgetType);
            return (
                <MDBox
                    key={node.label}
                    component="li"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    // alignItems="center"
                    borderRadius="lg"
                    py={1}
                    pr={2}
                    mb={tree.nodes.length - 1 === node.label ? 0 : 1}
                >

                    <MDBox display="flex" alignItems="center">
                        <MDTypography variant="caption" fontWeight="regular" gutterBottom color={"text"}>
                            {node.label}. &nbsp;
                        </MDTypography>
                        <MDBox
                            display="grid"
                            alignItems="center"
                            justifyContent="center"
                            bgColor={"transparent"}
                            borderRadius="lg"
                            shadow="sm"
                            color={wi.color}
                            width="3rem"
                            height="3rem"
                            mr={2}
                            variant="gradient"
                            fontSize="0.875rem"
                        >
                            <Icon
                                fontSize="medium"
                                  sx={{
                                      // color: wi.color,
                                      display: "grid",
                                      placeItems: "center"
                                  }}
                            >
                                {wi.icon}
                            </Icon>
                        </MDBox>
                        <MDBox display="flex" flexDirection="column">
                            <MDTypography variant="button" fontWeight="medium" gutterBottom >
                                {node.widget.name}
                            </MDTypography>
                            <MDTypography variant="caption" color="text">
                                {node.widget.content?.title}
                                {node.widget.content?.text}
                                {/*- {JSON.stringify(node.widget.content)}*/}
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                    <MDBox display="flex">

                      {node.widget && node.widget.widgetType && node.widget.widgetType === WidgetType.Section &&
                          <MDBox ml={2} mt={1} py={1} px={2}
                                 sx={({ palette: { light }, typography: { size },
                                          functions: { pxToRem },borders: { borderRadius } }) => ({
                                     fontSize: size.sm,
                                     backgroundColor:light.main,
                                     borderRadius: borderRadius.md,
                                     // padding: `${pxToRem(16)} ${pxToRem(30)}`,
                                 })}
                          >
                              {renderWidgetItems(node.children)}
                          </MDBox>
                      }

                        {/*<MDTypography*/}
                        {/*    component={Link}*/}
                        {/*    variant="button"*/}
                        {/*    color={color}*/}
                        {/*    to={route}*/}
                        {/*    sx={{*/}
                        {/*      lineHeight: 0,*/}
                        {/*      transition: "all 0.2s cubic-bezier(.34,1.61,.7,1.3)",*/}
                        {/*      p: 0.5,*/}

                        {/*      "&:hover, &:focus": {*/}
                        {/*        transform: "translateX(5px)",*/}
                        {/*      },*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*  <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>*/}
                        {/*</MDTypography>*/}
                    </MDBox>

                </MDBox>
            );
        });
    };

    return (
        <Card id="widgets">
            {/*<MDBox p={3} lineHeight={1}>*/}
            {/*  <MDBox mb={1}>*/}
            {/*    <MDTypography variant="h5">Content</MDTypography>*/}
            {/*  </MDBox>*/}
            {/*  <MDTypography variant="button" color="text">*/}
            {/*    Here you can setup and manage your integration settings.*/}
            {/*  </MDTypography>*/}
            {/*</MDBox>*/}

            <MDBox p={3}
                   display="flex"
                   justifyContent="space-between"
                   alignItems={{ xs: "flex-start", sm: "center" }}
                   flexDirection={{ xs: "column", sm: "row" }}
            >
                <MDBox display="flex" alignItems="center">
                    <MDBox ml={2} lineHeight={0}>
                        <MDTypography variant="h5" fontWeight="medium">
                            Application Content
                        </MDTypography>
                        {/*<MDTypography variant="button" color="text">*/}
                        {/*  Application, Reorder or change details*/}
                        {/*</MDTypography>*/}
                    </MDBox>
                </MDBox>
                <MDBox
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    width={{ xs: "100%", sm: "auto" }}
                    mt={{ xs: 1, sm: 0 }}
                >

                    <MDBox mr={1}>
                        <MDBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">

                            <MDBox ml="auto">
                                {widgets && widgets.length > 0 &&
                                    <MDButton variant="outlined" color="primary" size="medium"
                                              onClick={onEditWidgets}>
                                        Modify
                                    </MDButton>}
                            </MDBox>
                        </MDBox>
                    </MDBox>
                </MDBox>
            </MDBox>


            <MDBox pt={2} pb={3} px={3}>
                {widgets && widgets.length > 0 && tree ?
                    <MDBox ml={2} component="ul" display="flex" flexDirection="column">
                        {renderWidgetItems(tree.nodes)}
                        {/*{tree && tree.nodes && tree.nodes.map((node) => {*/}
                        {/*  const wi = widgetItems.find(w=>w.href===node.widget.widgetType);*/}
                        {/*  return (*/}
                        {/*      <MDBox*/}
                        {/*          key={node.label}*/}
                        {/*          component="li"*/}
                        {/*          display="flex"*/}
                        {/*          justifyContent="space-between"*/}
                        {/*          alignItems="center"*/}
                        {/*          borderRadius="lg"*/}
                        {/*          py={1}*/}
                        {/*          pr={2}*/}
                        {/*          // mb={tree.nodes.length - 1 === node.label ? 0 : 1}*/}
                        {/*      >*/}
                        {/*        <MDBox display="flex" alignItems="center">*/}
                        {/*          <MDBox*/}
                        {/*              display="grid"*/}
                        {/*              alignItems="center"*/}
                        {/*              justifyContent="center"*/}
                        {/*              // bgColor={""}*/}
                        {/*              borderRadius="lg"*/}
                        {/*              shadow="md"*/}
                        {/*              color={wi.color}*/}
                        {/*              width="3rem"*/}
                        {/*              height="3rem"*/}
                        {/*              mr={2}*/}
                        {/*              variant="gradient"*/}
                        {/*              fontSize="0.875rem"*/}
                        {/*          >*/}
                        {/*            <Icon fontSize="medium"*/}
                        {/*                sx={{*/}
                        {/*                  // color: wi.color,*/}
                        {/*                  display: "grid",*/}
                        {/*                  placeItems: "center",*/}
                        {/*                }}*/}
                        {/*            >*/}
                        {/*              {wi.icon}*/}
                        {/*            </Icon>*/}
                        {/*          </MDBox>*/}
                        {/*          <MDBox display="flex" flexDirection="column">*/}
                        {/*            <MDTypography variant="button" fontWeight="medium" gutterBottom>*/}
                        {/*              {node.widget.name}*/}
                        {/*            </MDTypography>*/}
                        {/*            <MDTypography variant="caption" color="text">*/}
                        {/*              {JSON.stringify( node.widget.content)}*/}
                        {/*            </MDTypography>*/}
                        {/*          </MDBox>*/}
                        {/*        </MDBox>*/}
                        {/*        <MDBox display="flex">*/}
                        {/*          /!*<MDTypography*!/*/}
                        {/*          /!*    component={Link}*!/*/}
                        {/*          /!*    variant="button"*!/*/}
                        {/*          /!*    color={color}*!/*/}
                        {/*          /!*    to={route}*!/*/}
                        {/*          /!*    sx={{*!/*/}
                        {/*          /!*      lineHeight: 0,*!/*/}
                        {/*          /!*      transition: "all 0.2s cubic-bezier(.34,1.61,.7,1.3)",*!/*/}
                        {/*          /!*      p: 0.5,*!/*/}

                        {/*          /!*      "&:hover, &:focus": {*!/*/}
                        {/*          /!*        transform: "translateX(5px)",*!/*/}
                        {/*          /!*      },*!/*/}
                        {/*          /!*    }}*!/*/}
                        {/*          /!*>*!/*/}
                        {/*          /!*  <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>*!/*/}
                        {/*          /!*</MDTypography>*!/*/}
                        {/*        </MDBox>*/}
                        {/*      </MDBox>*/}
                        {/*  );*/}
                        {/*})}*/}
                    </MDBox>
                    :
                    <NoContentBox message={"No Content Found"}
                                  hint={"Please add text, image and forms to use this application"}
                                  action={onEditWidgets} />

                }


            </MDBox>
        </Card>
    );
}

export default ManageContentCard;
