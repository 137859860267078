import React, { useEffect } from "react";
import PropTypes from "prop-types";

import {
  Authenticator,
  Button,
  CheckboxField,
  Heading,
  Image,
  Text,
  useAuthenticator,
  useTheme,
  View
} from "@aws-amplify/ui-react";

import Link from "@mui/material/Link";
import Container from "@mui/material/Container";

import { useLocation, useNavigate } from "react-router-dom";
import { setLayout, useMaterialUIController } from "context";

import MDBox from "components/MDBox";
import { useCurrentAuthenticatedUser, usePublicTenantConfig } from "hooks";
import Spinner from "../../views/custom-components/Spinner/Spinner";
import consumerRoutes from "../../consumer.routes";
import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";
import Footer from "../../examples/Footer";
import LinearLoading from "../../views/custom-components/LinearLoading";
import brandWhite from "../../logo.svg";
import "@aws-amplify/ui-react/styles.css";
import "../cognito-style.css";
import withSAMLAuthenticator from "../SAMLAuthenticator";
import ConsumerFooter from "./ConsumerFooter";


const authServices = {
  async validateCustomSignUp(formData) {
    if (!formData.acknowledgement) {
      return {
        acknowledgement: "You must agree to the Terms & Conditions"
      };
    }
  }
};

const formFields = {
  signIn: {
    username: {
      placeholder: "Enter your email"
    }
  },
  signUp: {
    email: {
      placeholder: "Enter your email:",
      isRequired: true,
      order: 1
    },
    // given_name: {
    //   label: "given_name:",
    //   placeholder: "Enter your given_name:",
    //   isRequired: false,
    //   order: 2
    // },
    // family_name: {
    //   label: "family_name:",
    //   placeholder: "Enter your family_name:",
    //   isRequired: false,
    //   order: 3
    // },
    password: {
      label: "Password:",
      placeholder: "Enter your Password:",
      isRequired: true,
      order: 4
    },
    confirm_password: {
      label: "Confirm Password:",
      isRequired: true,
      order: 5
    },
    'custom:tenantId': {
      isRequired: true,
      order: 9,
      hidden: false
    },
  },
  forceNewPassword: {
    password: {
      placeholder: "Enter your Password:"
    }
  },
  resetPassword: {
    email: {
      placeholder: "Enter your email:"
    }
  },
  confirmResetPassword: {
    confirmation_code: {
      placeholder: "Enter your Confirmation Code:",
      label: "New Label",
      isRequired: false
    },
    confirm_password: {
      placeholder: "Enter your Password Please:"
    }
  },
  setupTOTP: {
    QR: {
      totpIssuer: "test issuer",
      totpUsername: "amplify_qr_test_user"
    },
    confirmation_code: {
      label: "New Label",
      placeholder: "Enter your Confirmation Code:",
      isRequired: false
    }
  },
  confirmSignIn: {
    confirmation_code: {
      label: "New Label",
      placeholder: "Enter your Confirmation Code:",
      isRequired: false
    }
  }
};


function ConsumerLayout({ background, children, loading = false }) {
  const [, dispatch] = useMaterialUIController();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { tenantConfig, reloadTenantConfig, loadingTenantConfig , tenantConfigLoaded} = usePublicTenantConfig();
  const { username, isAdmin, isFederatedId, isEditor, isPublic, hasRole, loading: userLoading } =
    useCurrentAuthenticatedUser();

  const authComponents = {
    Header() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Image maxWidth={"180px"} paddingTop={"100px"}
                 alt={tenantConfigLoaded ? tenantConfig.bizName : ""}
                 src={tenantConfigLoaded && tenantConfig.branding && tenantConfig.branding.logo
                   ? tenantConfig.branding.logo : brandWhite}
          />
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={2}
          >
            Welcome to {tenantConfigLoaded ? tenantConfig.bizName : "Consen"}
          </Heading>
        </View>
      );
    },

    Footer() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={`${tokens.colors.neutral["80"]}`} fontSize={"0.7em"}>
            <Link href={"https://aceoar.io"} target="_blank">
              Consen.co Powered by Ace|Oar Technologies {" "}
            </Link>
            &copy; All Rights Reserved
          </Text>
        </View>
      );
    },

    SignIn: {
      Header() {
        const { tokens } = useTheme();

        return (
          // <MDBox pb={3}>
          //   <Grid container alignItems="center">
          //     <Grid item xs={12} md={7}>
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Sign in to your account
          </Heading>
          //     </Grid>
          //   </Grid>
          // </MDBox>
        );
      },
      Footer() {
        const { toResetPassword } = useAuthenticator();

        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toResetPassword}
              size="small"
              variation="link"
            >
              Reset Password
            </Button>
          </View>
        );
      }
    },

    SignUp: {
      Header() {
        const { tokens } = useTheme();

        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Create a new account
          </Heading>
        );
      },
      FormFields() {
        const { validationErrors } = useAuthenticator();

        return (
          <>
            {/* Re-use default `Authenticator.SignUp.FormFields` */}
            <Authenticator.SignUp.FormFields />

            {/* Append & require Terms & Conditions field to sign up  */}
            <CheckboxField
              errorMessage={validationErrors.acknowledgement}
              hasError={!!validationErrors.acknowledgement}
              name="acknowledgement"
              value="yes"
              label="I agree with the Terms & Conditions"
            />
          </>
        );
      },
      Footer() {
        const { toSignIn } = useAuthenticator();

        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toSignIn}
              size="small"
              variation="link"
            >
              Back to Sign In
            </Button>
          </View>
        );
      }
    },
    ConfirmSignUp: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      }
    },
    SetupTOTP: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      }
    },
    ConfirmSignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      }
    },
    ResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      }
    },
    ConfirmResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      }
    }
  };

  useEffect(() => {
    setLayout(dispatch, "consumer");
  }, [pathname]);

  useEffect(() => {
    if (!tenantConfig.userPoolId && !loadingTenantConfig && !userLoading) {
      navigate("/provider-lookup");
    }
  }, [loadingTenantConfig]);

  return (
    <MDBox

    >

      {!loadingTenantConfig && tenantConfig.userPoolId &&
        // <SettingsProvider>
        // <ThemeProvider>
        <Authenticator loginMechanisms={["username"]}
                       // usernameAlias="email"
                       hideSignUp={false}
                       formFields={formFields}
                       components={authComponents}
                       services={authServices}

        >
          {({ signOut, user }) => (
            <MDBox >
              <DefaultNavbar
                routes={consumerRoutes}
                // action={{
                //   type: "external",
                //   route: "https://signup.consen.co/registration/",
                //   label: "SignUp now"
                // }}
              />
              {/*<MDBox*/}
              {/*  // width="98vw"*/}
              {/*  height="100%"*/}
              {/*  minHeight="80vh"*/}
              {/*  bgColor={background}*/}
              {/*  sx={{ overflowX: "hidden" }}*/}
              {/*>*/}
                <Container>
                  <MDBox >
                    {loading && <LinearLoading />}
                    <MDBox pt={12}>
                      <MDBox pt={4}>
                        {children}
                      </MDBox>
                    </MDBox>
                  </MDBox>
                  <ConsumerFooter />
                </Container>
                  {/*:*/}
                  {/*<>*/}
                  {/*  <Spinner label="Loading Config..." />*/}
                  {/*</>*/}

              </MDBox>
            // </MDBox>
          )}
        </Authenticator>
        // </ThemeProvider>
        // </SettingsProvider>
      }
    </MDBox>
  );
}

// Setting default values for the props for PageLayout
ConsumerLayout.defaultProps = {
  background: "default",
  loading: false
};

// Typechecking props for the PageLayout
ConsumerLayout.propTypes = {
  background: PropTypes.oneOf(["white", "light", "default"]),
  children: PropTypes.node.isRequired
};


export const withConsumerLayout = (component: React.ComponentType): React.FunctionComponent<{}> => {
  return function () {
    // return <ConsumerLayout>{React.createElement(withSAMLAuthenticator(component))}</ConsumerLayout>;
    return <ConsumerLayout>{React.createElement(withSAMLAuthenticator(component))}</ConsumerLayout>;
  };
};

// export const withConsumerLayout = (component: React.ComponentType): React.FunctionComponent<{}> => {
//   return function () {
//     return <ConsumerLayout>{React.createElement(component)}</ConsumerLayout>;
//   };
// };

export default ConsumerLayout;
