import { create } from "zustand";
import ProviderConfigService from "../services/ProviderConfigService";
import createSelectors from "./selectors";
import OfferingService from "../services/OfferingService";
import { Auth } from "aws-amplify";
import SubmissionService from "../services/SubmissionService";

interface ConsumerOfferingsStore {
  offeringConfigs: {};
  offerings: any[];
  loadedTenantId: string | undefined;
  loading: boolean;
  working: boolean;
  getByKey: (key: string) => any;
  initStore: (authRequired?: boolean, tenantId?: string, isAdmin?: boolean) => Promise<void>;
  getOptionList: () => Promise<any[]>;
  applyForOffering: (offeringKey: string) => Promise<any>;
}

const useConsumerOfferingsStoreBase = create<ConsumerOfferingsStore>()((set, get) => ({
  // const defaultVals = await OfferingService.fetchOfferings();
  offeringConfigs: {},
  offerings: [],
  loadedTenantId: undefined,
  loading: false,
  working: false,
  getByKey: (key: string) => {
    const currList = get().offerings;
    if (!currList || currList.length === 0 || !key) {
      //console.log("..... Store : getByKey <<<<<<<<<< None", key);
      //console.log("..... Store : currList ", currList);
      return;
    }

    return currList.find(obj => key === obj.key);
  },
  getOptionList: async () => {
    //console.log("..... Store : getOptionList <<<<<<<<<< offering");
    let offs = get().offerings;
    // //console.log("..... Store : getOptionList >> ALL applications " + apps.length);
    let options = [{ name: "None", key: "NONE" }];
    set({ loading: true });
    if (!offs || (offs && offs.length == 0)) {
      await get().initStore();
      offs = get().offerings;
    }
    // //console.log("..... Store : getOptionList +++++ apps ++ length" + apps.length);
    offs.reduce((accumArr, thisObj) => {
      if (thisObj.name && thisObj.name !== "") {
        accumArr.push({ name: thisObj.name, key: thisObj.key });
      }
      // //console.log("..... Store : getOptionList +++++++ length" + accumAppArr.length);
      return accumArr;
    }, options);

    set({ loading: false });
    return options;
  },

  initStore: async (authRequired?: boolean, tenantId?: string, isAdmin?: boolean) => {
    if (get().offerings.length > 0) return;
    //console.log("..... Store : initStore <<<<<<<<<< offerings");
    set({ loading: true });
    const user = await Auth.currentUserInfo().catch(e => null);
    try {
      let data = [];
      if (!user) {
        if (!authRequired) {
          //console.log("useOfferings (public) for " + tenantId);
          data = await OfferingService.fetchPublicOfferings(tenantId);
        }
      } else {
        //console.log("useOfferings (public) for " + tenantId);
        data = await OfferingService.fetchPublicOfferings(tenantId);

        const pConfigs = await ProviderConfigService.fetchProviderConfigsSelect("category", "OFFERING");
        set({ offeringConfigs: pConfigs });
      }
      if (data && data.length > 1) {
        data.sort((a, b) => a.updatedAt > b.updatedAt ? 1 : -1);
      }
      set({ offerings: data, loadedTenantId:tenantId });
    } catch (e) {
      //console.log(e);
    } finally {
      set({ loading: false });
    }
  },
  applyForOffering: async (key: string) => {
    const currList = get().offerings;
    if (!currList || currList.length === 0 || !key) {
      //console.log("..... Store : getByKey <<<<<<<<<< None", key);
      //console.log("..... Store : currList ", currList);
      return;
    }
    set({ working: true });
    const offering = currList.find(obj => key === obj.key);

    if (!offering) {
      // @Todo: look into DB?
      throw new Error(`Offering not found with key : ${key}`);
    }
    try {
      const submission = await SubmissionService.createSubmission(offering);
      //console.log("Submission created " + JSON.stringify(submission));
      return submission;
    } finally {
      set({ working: false });
    }


  }
}));

const useConsumerOfferingsStore = createSelectors(useConsumerOfferingsStoreBase);
export default useConsumerOfferingsStore;