import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";


import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import Tooltip from "@mui/material/Tooltip";
// Material Dashboard 2 PRO React page layout routes
import consumerRoutes from "consumer.routes";

// Images
import bgImage from "assets/images/application-bg1.png";
import MDBadge from "components/MDBadge";
import csIconGray from "assets/images/consen-gr-n.svg";
import MDButton from "components/MDButton";
import logoImage from "assets/images/cn-logo-full.svg";
import productImage from "assets/images/application-bg1.png";
import { useCurrentAuthenticatedUser, usePublicTenantConfig } from "../../../hooks";
import { useMaterialUIController } from "../../../context";
function ConsumerHeader({title, subTitle, children, image, isSmall, hideNavbar, isTextDark}
                        // { tabValue, tabHandler, children }
) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const { tenantConfig, reloadTenantConfig, loadingTenantConfig,tenantConfigLoaded } = usePublicTenantConfig();
  const { userFirstName, isAdmin, isEditor, isLoggedIn, hasRole, loading:userLoading } =
    useCurrentAuthenticatedUser();

  const getActionButton = ()=>{
    if (tenantConfigLoaded && isLoggedIn) {
      if (isAdmin || isEditor) {
        return {
          type: "internal",
          route: "/admin",
          label: "Admin Dashboard",
          color: "error",
        }
      } else {
        return {
          type: "internal",
          route: "/app/dashboard",
          label: "View My Dashboard",
          color: "primary",
        }
      }
    }

    return {
      type: "internal",
      route: "/app/auth/consumer-login",
      label: "Client Login",
      color: "white",
    }
  }


  return (
    <>
    {!hideNavbar && <DefaultNavbar
        routes={consumerRoutes}
        // action={{
        //   type: "internal",
        //   route: "/app/dashboard",
        //   label: "Consumer Login",
        //   color: "light",
        // }}
        action={getActionButton()}
        transparent
        light
      />}
      <MDBox
        position="relative"
        minHeight={isSmall?"30vh": "34vh"}
        height={isSmall?"42vh": "50vh"}
        borderRadius="lg"
        m={1}
        pt={0.5}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { white, primary } }) => image ?
            ` url(${image})` :
            `${linearGradient(rgba(primary.main, 0.95), rgba(primary.main, 0.55))}`,
          backgroundSize: "cover",
          backgroundPosition: "top"
        }}
      >
        <Grid
          container
          spacing={3}
          justifyContent="center"
          sx={{ position: "relative", my: isSmall?16:24, textAlign: "center" }}
        >
          <Grid item xs={11} lg={7}>
            <MDBox mb={1}>
              <MDTypography variant="h1" color={isTextDark ? "dark" : "white"} fontWeight="bold">
                {title}
              </MDTypography>
            </MDBox>
            <MDBox mb={2}>
              <MDTypography variant="body1" color={isTextDark ? "dark" : "white"} fontWeight="light">
                {subTitle}
              </MDTypography>
            </MDBox>
          </Grid>
          {children &&
          <Grid item xs={11} lg={6}>
            {children}
          </Grid>}
        </Grid>

            {/* {children} */}

      </MDBox>

    </>
  );
}

ConsumerHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  children: PropTypes.node
};

export default ConsumerHeader;
