import React from "react";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";

function FullBodyCard({title, description, badge}) {
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} mb={2} px={3}>
        <MDTypography variant="h6" color="text">
          {title}
        </MDTypography>
        <MDBadge variant="contained" color="info" badgeContent={badge} container />
      </MDBox>
      <MDBox pb={3} px={3}>
        <MDTypography variant="body2" color="text">
          {description}
        </MDTypography>
      </MDBox>
    </Card>
  );
}

export default FullBodyCard;
