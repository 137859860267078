import { API } from "aws-amplify";
import Auth from "@aws-amplify/auth";
import authHeaders, { execTenantAPIget } from "./services-base/api-base";

const apiName = "BackendApi";
const regApiGatewayUrl = "regApiGatewayUrl";



async function fetchSubmissions() {
  const headers = await authHeaders();
  return execTenantAPIget( "submissions");
}

async function fetchProtectedSubmissions() {
  // const headers = await authHeaders();
  return await API.get(apiName, "protected/submissions", {  });
}

async function fetchConsumerSubmissions() {
  return execTenantAPIget( "usersubmissions");
}

async function fetchSubmissionById(submissionId: string) {
  return execTenantAPIget( `submission/${submissionId}`)
}

async function createSubmission(offering) {

  const submissionInfo = {
    offering: offering.key,
    offeringId: offering.offeringId,
    shardId: offering.shardId,
    name: offering.name,
    sku: offering.sku,
    categoryId: offering.categoryId,
    categoryName: offering.categoryName
  }

  const headers = await authHeaders();
  return await API.post(apiName, "submission", {
    headers,
    body: submissionInfo,
  });
}

async function editSubmission( submissionKey: string,  submissionInfo) {
  const headers = await authHeaders();
  return await API.put(apiName, `submission/${submissionKey}`, {
    headers,
    body: submissionInfo,
  });
}
//
async function deleteSubmission(submissionId: string) {
  const headers = await authHeaders();
  return await API.del(apiName, `submission/${submissionId}`, {
    headers,
  });
}

const SubmissionService = {
  fetchSubmissions,
  fetchProtectedSubmissions,
  fetchSubmissionById,
  editSubmission,
  createSubmission,
  deleteSubmission,
  fetchConsumerSubmissions
}

export default SubmissionService;
