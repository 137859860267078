import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";


import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Material Dashboard 2 PRO React page layout routes
import pageRoutes from "page.routes";
import publicRoutes from "public.routes";

// Images
import bgImage from "assets/images/wide_headers/8.png";
import MDBadge from "../../../../components/MDBadge";
import ActionCard from "../../../custom-components/ActionCard";
import csIconGray from "assets/images/consen-gr-n.svg";
import MDButton from "../../../../components/MDButton";
import logoImage from "assets/images/cn-logo-full.svg";
import productImage from "assets/images/cn-team-site.svg";
import WorkflowDisplayCard from "../../../admin/workflow-list/component/WorkflowDisplayCard";
import { defaultWFEdges, defaultWFNodes } from "../../../admin/workflow/wf-data/default-workflow-01";
import { useCurrentAuthenticatedUser, usePublicTenantConfig } from "../../../../hooks";
function Header(
  // { tabValue, tabHandler, children }
) {
  const { tenantConfig, reloadTenantConfig, loadingTenantConfig,tenantConfigLoaded } = usePublicTenantConfig();
  const { userFirstName, isAdmin, isEditor, isLoggedIn, hasRole, loading:userLoading } =
    useCurrentAuthenticatedUser();

  const getActionButton = ()=>{
    if (tenantConfigLoaded && isLoggedIn) {
        if (isAdmin || isEditor) {
          return {
            type: "internal",
            route: "/admin",
            label: "View Dashboard",
            color: "error",
          }
        } else {
          return {
            type: "internal",
            route: "/app/dashboard",
            label: "View My Dashboard",
            color: "primary",
          }
        }
      }

      return {
        type: "external",
        route: "https://consen.co/contact",
        label: "SignUp now",
        color: "white",
      }
  }

  const getroutes = ()=>{
    if (isLoggedIn){
      if (isAdmin || isEditor) {
        return pageRoutes;
      }
    }
      return publicRoutes;

  }

  return (
    <>
      <DefaultNavbar
        routes={pageRoutes}
        action={getActionButton()}
        transparent
        light

      />
      <MDBox
        position="relative"
        minHeight="22vh"
        height="42vh"
        borderRadius="xl"
        m={2}
        pt={2}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { white, info } }) =>
            `${linearGradient(rgba(info.main, 0.55), rgba(white.main, 0.35))}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top"
        }}
      >

        <Grid
          container
          spacing={3}
          justifyContent="center"
          sx={{ position: "relative", my: 12, textAlign: "center" }}
        >

          <Grid item xs={11} lg={6}>
            <MDBox mb={1}>
              <MDTypography variant="h1" color="white" fontWeight="bold">
                Welcome to CONSEN
              </MDTypography>
            </MDBox>
            <MDBox mb={2}>
              <MDTypography variant="h4" color="white" fontWeight="light">
                Your workflow platform for any application intake
              </MDTypography>
            </MDBox>
          </Grid>

        </Grid>
      </MDBox>

    </>
  );
}

// // Typechecking props for the Header
// Header.propTypes = {
//   tabValue: PropTypes.number.isRequired,
//   tabHandler: PropTypes.func.isRequired,
//   children: PropTypes.node.isRequired
// };

export default Header;
