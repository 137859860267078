import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Link, useNavigate, useParams } from "react-router-dom";


import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Tab from "@mui/material/Tab";

import MDBox from "../../../components/MDBox";
import MDButton from "../../../components/MDButton";
import MDTypography from "../../../components/MDTypography";


import { useTranslation } from "react-i18next";
import { useChangeBackgroundColor, useDateTimeFormatter, useTopicAreas } from "../../../hooks";
import Spinner from "../../custom-components/Spinner/Spinner";
import useWorkflowStore from "../../../store/workflow-store";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import AdminHeader from "../../custom-components/AdminHeader";
import LinearLoading from "../../custom-components/LinearLoading";
import DashboardLayout from "../../../LayoutContainers/DashboardLayout";
import WorkflowAddEdit from "../workflow-list/component/WorkflowDetailsEdit/WorkflowAddEdit";
import CustomDialog from "../../custom-components/CustomDialog";
import DialogContent from "@mui/material/DialogContent";
import MDBadge from "../../../components/MDBadge";
import WorkflowStepsDisplay from "../workflow/component/WorkflowStepsDisplay";
import WorkflowDisplayCard from "../workflow-list/component/WorkflowDisplayCard";
import { AppBar, Tabs } from "@mui/material";

import { useMaterialUIController } from "../../../context";
import AdminDetailsHeader from "../../custom-components/AdminDetailsHeader";


interface FormValues {
  shardId: string;
  workflowId: string;
  key: string;
  name: string;
  description: string;
  state: string;
  // content?: string;
  scope: string;
  images?: {};
  tags?: {};
}

interface PathParams {
  workflowKey: string;
}

function WorkflowDetailsEdit() {
  const navigate = useNavigate();
  const dateFormatter = useDateTimeFormatter();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { workflowKey } = useParams<PathParams>();
  const { t } = useTranslation();
  const [openModalMode, setOpenModalMode] = useState("");
  const [tabValue, setTabValue] = useState("details");

  // const [creatingWorkflow, setCreatingWorkflow] = useState(false);

  const initStore = useWorkflowStore.use.initStore();
  const getByKey = useWorkflowStore.use.getByKey();
  const loading = useWorkflowStore.use.loading();
  const workflowConfigs = useWorkflowStore.use.workflowConfigs();
  const saveWorkflow = useWorkflowStore.use.save();
  const savingData = useWorkflowStore.use.saving();

  const [workflow, setWorkflow] = useState();
  const [alertMessage, setAlertMessage] = useState("");
  const { topicareas, loading: loadingCategory } = useTopicAreas();
  const sortedTopicAreas = topicareas.sort((a, b) =>
    a.name > b.name ? 1 : -1
  );
  const [initValue, setInitValue] = useState({});
  // const formik = useFormik({ initialValues: initValue });

  const form = {
    formId: "workflow-edit-form",
    formField: {
      name: {
        name: "name",
        label: t("NameUpperCase"),
        type: "text",
        errorMsg: "Name is required.",
        invalidMsg: "Name is invalid."
      },
      scope: {
        name: "scope",
        label: t("Scope"),
        type: "text",
        errorMsg: "scope is required.",
        placeholder: t("WorkflowEdit.WorkflowTitleHint")
      },
      state: {
        name: "state",
        label: t("State"),
        type: "text",
        errorMsg: "state is required."
      },

      description: {
        name: "description",
        label: t("Description"),
        type: "text",
        errorMsg: "description is required.",
        placeholder: t("WorkflowEdit.WorkflowTitleHint")
      }

      // content: {
      //   name: "content",
      //   label: "Workflow Content",
      //   type: "text",
      //   placeholder: "Some description"
      // }
    }
  };
  const {
    formId,
    formField: {
      name, description,
      state, scope
      // content
    }
  } = form;
  const validations = Yup.object().shape({
    [name.name]: Yup.string().required(name.errorMsg).min(6, name.invalidMsg),
    [description.name]: Yup.string().required(description.errorMsg).min(10, description.invalidMsg),
    [scope.name]: Yup.string().required(scope.errorMsg).oneOf(
      ["Public", "Private", "Protected"],
      t("WorkflowEdit.ScopeRequired")
    ),
    [state.name]: Yup.string().required(state.errorMsg).oneOf(
      ["Draft", "Review", "Published", "Inactive"],
      t("WorkflowEdit.StateRequired")
    )
  });

  useEffect(() => {
    initStore().then(() => {
      const wf = getByKey(workflowKey);
      // getByKey(workflowKey).then((wf) =>{
      setWorkflow(wf);
      setInitValue(wf);
      // })
    });
  }, [initStore]);

  const openEditModal = () => {
    setOpenModalMode("edit");
  };

  const closeModal = () => {
    setOpenModalMode("");
  };

  const openPublishModal = () => {
    setOpenModalMode("publish");
  };

  // useChangeBackgroundColor();
  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
  };
  // if (!loading) {
  //   crumbs.push({
  //     label: t("WorkflowEdit.AddWorkflow"),
  //     url: "",
  //   });
  // }
  const onSubmit = async (values: FormValues) => {
    try {
      setCreatingWorkflow(true);
      await saveWorkflow(values);
      setCreatingWorkflow(false);
      if (onEditWorkflowUpdate) onEditWorkflowUpdate();
    } catch (err) {
      console.log(t("AddContentFailure"), err);
      setAlertMessage(t("AddContentFailure") + ": " + err?.message);
    } finally {
      setCreatingWorkflow(false);
    }
  };

  const handlePublish = async () => {
    if (!workflow || !workflow.steps) {
      console.log("Steps missing. Please revisit / fix the Flow.");
      return;
    }

    const wfs = workflow.steps;

    if (!wfs || wfs.length == 0) {
      console.log("Steps are missing / failed to generate");
      return;
    }
    // if (wfs.length > 1) {
    //   console.log("Multi-path steps... not supported");
    //   return;
    // }
    try {
      const workflowData = {
        ...workflow,
        state: "Published"
      };
      console.log("workflowData to update : ", JSON.stringify(workflowData));
      // console.log(JSON.stringify(values));
      await saveWorkflow(workflowData);
      closeModal();
    } catch (e) {
      console.log(t("AddContentFailure"), e);
      setAlertMessage(t("AddContentFailure") + ": " + e?.message);
    } finally {
      // setSavingFlow(false);
    }

    // await WorkflowService.editWorkflow(workflowId, workflowData);
  };
  return (
    <DashboardLayout>
      <MDBox width="calc(100% - 48px)" position="absolute" top="1.75rem">
        <DashboardNavbar light absolute />
      </MDBox>

      <AdminHeader title={`Workflow : ${workflow ? workflow.name : ""}`}
                   icon="airline_stops_outlined"
                   description={workflow ? workflow.description : ""}
                   loading={loading}>

        {/*<MDBox mr={1}>*/}
        {/*  <MDButton variant="outlined" color="success"*/}
        {/*            onClick={openEditModal}>*/}
        {/*    validate*/}
        {/*  </MDButton>*/}
        {/*</MDBox>*/}
        {savingData && <LinearLoading />}

        <MDBox mr={2}>
          {workflow?.state === "Draft" &&
            <MDButton variant="outlined" color="primary" disabled={savingData}
                      onClick={openEditModal}>
              Edit Basic Info
            </MDButton>
          }
        </MDBox>
        {/*{workflow.state === "Published" &&*/}
        {/*  <MDButton variant="gradient" color="warning" sx={{ height: "100%" }}*/}
        {/*            disabled={!workflow.settings || !workflow.steps}>*/}
        {/*    Archive*/}
        {/*  </MDButton>}*/}
        {workflow?.state === "Draft" &&
          <MDButton variant="gradient" color="error" sx={{ height: "100%" }}
                    disabled={!workflow.steps}
                    onClick={openPublishModal}>
            Publish
          </MDButton>}
      </AdminHeader>


      {loading || !workflow ? (
        <Spinner
          label={t("WorkflowEdit.Loading")}
        />
      ) : (
        <MDBox>
          <MDBox mb={2}>
            <MDBox display="flex" justifyContent="space-between" alignItems="center">
              <MDTypography variant="h5">
                {workflow && workflow.workflowId ? t("WorkflowEdit.EditWorkflow") : t("WorkflowEdit.AddWorkflow")}
                {/*{workflowKey}*/}
              </MDTypography>

            </MDBox>
          </MDBox>

          <MDBox>
            <MDBox mb={1.5} mt={1.5}>
              <Card>

                <MDBox mt={4} px={4} mb={1}>
                  <MDBox display="flex" justifyContent="space-between" alignItems="center">
                    <MDBox>
                      <MDBox mb={0}>
                        <MDTypography variant="h5" fontWeight="medium">
                          {workflow.name} &nbsp;
                          <MDBadge variant="contained" color={workflow.state === "Draft" ? "secondary" : "info"}
                                   badgeContent={workflow.state} />
                        </MDTypography>
                      </MDBox>
                      <MDTypography variant="body2">
                        {workflow.description}

                      </MDTypography>

                      {/* <MDTypography variant="caption"> */}
                      {/*   Updated {dateFormatter(workflow.updatedAt)} */}
                      {/* </MDTypography> */}
                      {/* <MDTypography component="p" variant="button" fontWeight="regular" color="text"> */}
                      {/*   Code: <b>KF332</b> */}
                      {/* </MDTypography> */}
                    </MDBox>
                    <MDBox minWidth={{ xs: "10rem", md: "12rem" }}>
                      <AppBar position="static">
                        <Tabs value={tabValue} onChange={handleSetTabValue}>
                          <Tab label={"Details"} value={"details"} />
                          <Tab label={"Preview"} value={"preview"} />
                        </Tabs>
                      </AppBar>
                    </MDBox>
                  </MDBox>
                  <MDBox
                    // bgColor={darkMode ? "grey-900" : "grey-100"}
                    // borderRadius="lg"
                    display="flex"
                    justifyContent="space-between"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    flexDirection={{ xs: "column", sm: "row" }}
                    my={1}
                    py={1}
                    pl={{ xs: 1, sm: 2 }}
                    pr={1}
                  >

                    <MDTypography variant="caption" color="text">
                      Steps: {workflow.steps && workflow.steps.length}
                    </MDTypography>
                    <MDTypography variant="caption" color="text">
                      Scope: {workflow.scope}
                    </MDTypography>
                    <MDTypography variant="caption" color="text">
                      Updated {dateFormatter(workflow.updatedAt)} by {workflow.updatedBy}
                    </MDTypography>
                  </MDBox>
                  <Grid container spacing={3} alignItems="center">
                    {/* <Grid item xs={12} md={6} lg={6} sx={{ ml: "auto" }}> */}
                    {/*   <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} mb={2} px={3}> */}
                    {/*     <MDBox pt={1} lineHeight={1}> */}
                    {/*       <MDTypography variant="h6" fontWeight="medium"> */}
                    {/*         {title} */}
                    {/*       </MDTypography> */}
                    {/*       {subtitle && <MDTypography variant="button" color="text" fontWeight="regular"> */}
                    {/*         {subtitle} */}
                    {/*       </MDTypography>} */}
                    {/*     </MDBox> */}
                    {/*      */}
                    {/*   </MDBox> */}
                    {/* </Grid> */}

                    {/* <Grid item xs={12} md={8} lg={6} sx={{ ml: "auto" }}> */}
                    {/*    */}
                    {/* </Grid> */}


                    <Grid item xs={12} md={12}>
                      <MDBox mt={1} px={3} mb={2}>
                        <Grid container spacing={1} alignItems="center">

                          <Grid item xs={12} md={6}>
                            <MDBox pt={2} lineHeight={0}>
                              <MDTypography variant="body2" fontWeight="medium" textTransform="capitalize">
                                Steps
                              </MDTypography>
                              {workflow.steps && workflow.steps.length < 1 &&
                                <MDTypography variant="caption" color="error">
                                  Workflow cannot work without any {" "}
                                  <MDTypography component="a" href="#" variant="caption" fontWeight="medium"
                                                color="error">
                                    Step
                                  </MDTypography>
                                  . <br />Please Edit and fix errors.
                                </MDTypography>
                              }
                            </MDBox>

                            <WorkflowStepsDisplay wfSteps={workflow.steps} />
                            {/* <Grid container spacing={1} alignItems="center"> */}
                            {/*   {steps.map((s) => ( */}
                            {/*     <Grid item xs={12} key={s.source}> */}
                            {/*       <MDTypography variant="body2"> */}
                            {/*         <MDBadge variant="contained" color={"secondary"} badgeContent={s.seq} /> */}
                            {/*         {" "} */}
                            {/*         {s.sourceType} */}
                            {/*       </MDTypography> */}
                            {/*       <MDBox px={2} ml={2.5} lineHeight={0}> */}
                            {/*         <MDTypography variant="button" color="text" fontWeight="regular"> */}
                            {/*           {s.sourceData || ""} */}
                            {/*         </MDTypography> */}
                            {/*       </MDBox> */}
                            {/*     </Grid> */}
                            {/*   ))} */}

                            {/* </Grid> */}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <MDBox
                              bgColor={darkMode ? "grey-900" : "grey-100"}
                              borderRadius="lg"
                              display="flex"
                              justifyContent="space-between"
                              // alignItems={{ xs: "flex-start", md: "center" }}
                              flexDirection={"column"}
                              my={1}
                              py={1}
                              pl={{ xs: 1, sm: 2 }}
                              pr={1}
                            >
                              <MDTypography variant="caption" color="text">
                                settings: {JSON.stringify(workflow.settings)} <br />____
                              </MDTypography>
                              <MDTypography variant="caption" color="text">

                                rulez: {JSON.stringify(workflow.rulez)} <br />____
                              </MDTypography>
                              <MDTypography variant="caption" color="text">
                                integrations: {JSON.stringify(workflow.integrations)} <br />____
                              </MDTypography>
                            </MDBox>
                            <MDBox>
                              {/* <MDBox p={3}> */}
                              {/*   <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }}> */}

                              {/*     <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}> */}
                              {/*       {workflow.state === "Draft" && */}
                              {/*         <MDButton variant="gradient" color="primary" sx={{ height: "100%" }} */}
                              {/*                   disabled={!workflow.settings || !workflow.settings.steps}> */}
                              {/*           Publish */}
                              {/*         </MDButton>} */}
                              {/*       {workflow.state === "Published" && */}
                              {/*         <MDButton variant="gradient" color="warning" sx={{ height: "100%" }} */}
                              {/*                   disabled={!workflow.settings || !workflow.settings.steps}> */}
                              {/*           Archive */}
                              {/*         </MDButton>} */}
                              {/*     </MDBox> */}
                              {/*   </MDBox> */}
                              {/* </MDBox> */}
                            </MDBox>
                          </Grid>
                        </Grid>
                      </MDBox>


                    </Grid>

                  </Grid>
                  <MDBox display="flex" justifyContent="space-between" alignItems="center">

                    <MDBox px={0} display="flex" alignItems="center" color="text">
                      <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
                        {/*<MDButton variant="outlined" color="secondary"*/}
                        {/*          onClick={() => {*/}
                        {/*            // setSelected(workflow);*/}
                        {/*            openEditModal();*/}
                        {/*          }}>*/}
                        {/*  Edit Info*/}
                        {/*</MDButton>*/}
                        <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                          {workflow.state === "Draft" &&
                            <MDButton variant="outlined" color="error" sx={{ height: "100%" }}
                                      component={Link} to={`/admin/workflow/edit/${workflow.key}/edit-flow`}>
                              Edit Flow
                            </MDButton>}

                        </MDBox>
                      </MDBox>

                    </MDBox>
                    <MDBox p={3}>
                      <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
                        {/*{workflow.state === "Draft" &&*/}
                        {/*  <MDButton variant="outlined" color="error" sx={{ height: "100%" }}*/}
                        {/*            component={Link} to={`/admin/workflow/edit/${workflow.key}/edit-flow`}>*/}
                        {/*    Edit Flow*/}
                        {/*  </MDButton>}*/}
                        <MDButton variant="outlined" color="secondary"
                                  onClick={() => {
                                    // setSelected(workflow);
                                    openEditModal();
                                  }}>
                          Edit Info
                        </MDButton>
                        <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>


                          {workflow.state === "Published" &&
                            <MDButton variant="gradient" color="warning" sx={{ height: "100%" }}
                                      disabled={!workflow.settings || !workflow.steps}>
                              Archive
                            </MDButton>}
                        </MDBox>
                      </MDBox>
                    </MDBox>

                    {/* <MDBox display="flex">{renderMembers}</MDBox> */}
                  </MDBox>
                </MDBox>


              </Card>
            </MDBox>

            <MDBox mb={2} mt={1.5}>
              <Card>

                <MDBox mt={4} px={4} mb={1}>
                  <MDBox display="flex" justifyContent="space-between" alignItems="center">
                    <MDBox minWidth={{ xs: "10rem", md: "12rem" }}>
                      <MDTypography variant="h6" color="text">
                        Flow / Process
                      </MDTypography>
                    </MDBox>
                    <MDBox minWidth={{ xs: "10rem", md: "12rem" }}>
                      <MDTypography variant="caption" color="text">
                        Updated {dateFormatter(workflow.updatedAt)} by {workflow.updatedBy}
                      </MDTypography>
                    </MDBox>
                  </MDBox>

                  <MDBox
                    // bgColor={darkMode ? "grey-900" : "grey-100"}
                    // borderRadius="lg"
                    display="flex"
                    justifyContent="space-between"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    flexDirection={{ xs: "column", sm: "row" }}
                    my={1}
                    py={1}
                    pl={{ xs: 1, sm: 2 }}
                    pr={1}
                  >

                    <MDTypography variant="caption" color="text">
                      Steps: {workflow.steps && workflow.steps.length}
                    </MDTypography>
                    <MDTypography variant="caption" color="text">
                      State: {workflow.state}
                    </MDTypography>

                  </MDBox>
                  <Grid container spacing={3} alignItems="center">
                    {/* <Grid item xs={12} md={6} lg={6} sx={{ ml: "auto" }}> */}
                    {/*   <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} mb={2} px={3}> */}
                    {/*     <MDBox pt={1} lineHeight={1}> */}
                    {/*       <MDTypography variant="h6" fontWeight="medium"> */}
                    {/*         {title} */}
                    {/*       </MDTypography> */}
                    {/*       {subtitle && <MDTypography variant="button" color="text" fontWeight="regular"> */}
                    {/*         {subtitle} */}
                    {/*       </MDTypography>} */}
                    {/*     </MDBox> */}
                    {/*      */}
                    {/*   </MDBox> */}
                    {/* </Grid> */}

                    {/* <Grid item xs={12} md={8} lg={6} sx={{ ml: "auto" }}> */}
                    {/*    */}
                    {/* </Grid> */}


                    <Grid item xs={12} md={12}>


                      {workflow.content.nodes && workflow.content.edges ?
                        <MDBox mt={1} px={1}>
                          <WorkflowDisplayCard name="Default Workflow"
                                               initialNodes={workflow.content.nodes}
                                               initialEdges={workflow.content.edges}
                          />
                        </MDBox>
                        :
                        <MDBox mt={1} px={3} mb={2}>None</MDBox>
                      }
                    </Grid>

                  </Grid>
                  <MDBox display="flex" justifyContent="space-between" alignItems="center">

                    <MDBox px={0} display="flex" alignItems="center" color="text">
                      <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
                        {/*<MDButton variant="outlined" color="secondary"*/}
                        {/*          onClick={() => {*/}
                        {/*            // setSelected(workflow);*/}
                        {/*            openEditModal();*/}
                        {/*          }}>*/}
                        {/*  Edit Info*/}
                        {/*</MDButton>*/}
                        <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                          {workflow?.state === "Draft" &&
                            <MDButton variant="outlined" color="error" sx={{ height: "100%" }}
                                      component={Link} to={`/admin/workflow/edit/${workflow.key}/edit-flow`}>
                              Edit Flow
                            </MDButton>}

                        </MDBox>
                      </MDBox>

                    </MDBox>
                    <MDBox p={3}>
                      <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
                        {/*{workflow.state === "Draft" &&*/}
                        {/*  <MDButton variant="outlined" color="error" sx={{ height: "100%" }}*/}
                        {/*            component={Link} to={`/admin/workflow/edit/${workflow.key}/edit-flow`}>*/}
                        {/*    Edit Flow*/}
                        {/*  </MDButton>}*/}

                        {/*{workflow?.state === "Draft" &&*/}
                        {/*<MDButton variant="outlined" color="secondary"*/}
                        {/*          onClick={() => {*/}
                        {/*            // setSelected(workflow);*/}
                        {/*            openEditModal();*/}
                        {/*          }}>*/}
                        {/*  Edit Info*/}
                        {/*</MDButton>*/}
                        {/*}*/}
                        <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>


                          {workflow?.state === "Published" &&
                            <MDButton variant="gradient" color="warning" sx={{ height: "100%" }}
                                      disabled={!workflow.steps}>
                              Archive
                            </MDButton>}
                        </MDBox>
                      </MDBox>
                    </MDBox>

                    {/* <MDBox display="flex">{renderMembers}</MDBox> */}
                  </MDBox>
                </MDBox>


              </Card>
            </MDBox>
            <MDBox mb={1.5} mt={1.5}>
              <Card>

                <MDBox mt={4} px={4} mb={1}>
                  <MDBox display="flex" justifyContent="space-between" alignItems="center">
                    <MDBox>
                      <MDBox mb={0}>
                        <MDTypography variant="h5" fontWeight="medium">
                          {workflow.name} &nbsp;
                          <MDBadge variant="contained" color={workflow.state === "Draft" ? "secondary" : "info"}
                                   badgeContent={workflow.state} />
                        </MDTypography>
                      </MDBox>
                      <MDTypography variant="body2">
                        {workflow.description}

                      </MDTypography>

                      {/* <MDTypography variant="caption"> */}
                      {/*   Updated {dateFormatter(workflow.updatedAt)} */}
                      {/* </MDTypography> */}
                      {/* <MDTypography component="p" variant="button" fontWeight="regular" color="text"> */}
                      {/*   Code: <b>KF332</b> */}
                      {/* </MDTypography> */}
                    </MDBox>
                    <MDBox minWidth={{ xs: "10rem", md: "12rem" }}>
                      <AppBar position="static">
                        <Tabs value={tabValue} onChange={handleSetTabValue}>
                          <Tab label={"Details"} value={"details"} />
                          <Tab label={"Preview"} value={"preview"} />
                        </Tabs>
                      </AppBar>
                    </MDBox>
                  </MDBox>
                  <MDBox
                    // bgColor={darkMode ? "grey-900" : "grey-100"}
                    // borderRadius="lg"
                    display="flex"
                    justifyContent="space-between"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    flexDirection={{ xs: "column", sm: "row" }}
                    my={1}
                    py={1}
                    pl={{ xs: 1, sm: 2 }}
                    pr={1}
                  >

                    <MDTypography variant="caption" color="text">
                      Steps: {workflow.steps && workflow.steps.length}
                    </MDTypography>
                    <MDTypography variant="caption" color="text">
                      Scope: {workflow.scope}
                    </MDTypography>
                    <MDTypography variant="caption" color="text">
                      Updated {dateFormatter(workflow.updatedAt)} by {workflow.updatedBy}
                    </MDTypography>
                  </MDBox>
                  <Grid container spacing={3} alignItems="center">
                    {/* <Grid item xs={12} md={6} lg={6} sx={{ ml: "auto" }}> */}
                    {/*   <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} mb={2} px={3}> */}
                    {/*     <MDBox pt={1} lineHeight={1}> */}
                    {/*       <MDTypography variant="h6" fontWeight="medium"> */}
                    {/*         {title} */}
                    {/*       </MDTypography> */}
                    {/*       {subtitle && <MDTypography variant="button" color="text" fontWeight="regular"> */}
                    {/*         {subtitle} */}
                    {/*       </MDTypography>} */}
                    {/*     </MDBox> */}
                    {/*      */}
                    {/*   </MDBox> */}
                    {/* </Grid> */}

                    {/* <Grid item xs={12} md={8} lg={6} sx={{ ml: "auto" }}> */}
                    {/*    */}
                    {/* </Grid> */}


                    <Grid item xs={12} md={12}>
                      <MDBox mt={1} px={3} mb={2}>
                        <Grid container spacing={1} alignItems="center">

                          <Grid item xs={12} md={6}>
                            <MDBox pt={2} lineHeight={0}>
                              <MDTypography variant="body2" fontWeight="medium" textTransform="capitalize">
                                Steps
                              </MDTypography>
                              {workflow.steps && workflow.steps.length < 1 &&
                                <MDTypography variant="caption" color="error">
                                  Workflow cannot work without any {" "}
                                  <MDTypography component="a" href="#" variant="caption" fontWeight="medium"
                                                color="error">
                                    Step
                                  </MDTypography>
                                  . <br />Please Edit and fix errors.
                                </MDTypography>
                              }
                            </MDBox>

                            <WorkflowStepsDisplay wfSteps={workflow.steps} />
                            {/* <Grid container spacing={1} alignItems="center"> */}
                            {/*   {steps.map((s) => ( */}
                            {/*     <Grid item xs={12} key={s.source}> */}
                            {/*       <MDTypography variant="body2"> */}
                            {/*         <MDBadge variant="contained" color={"secondary"} badgeContent={s.seq} /> */}
                            {/*         {" "} */}
                            {/*         {s.sourceType} */}
                            {/*       </MDTypography> */}
                            {/*       <MDBox px={2} ml={2.5} lineHeight={0}> */}
                            {/*         <MDTypography variant="button" color="text" fontWeight="regular"> */}
                            {/*           {s.sourceData || ""} */}
                            {/*         </MDTypography> */}
                            {/*       </MDBox> */}
                            {/*     </Grid> */}
                            {/*   ))} */}

                            {/* </Grid> */}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <MDBox
                              bgColor={darkMode ? "grey-900" : "grey-100"}
                              borderRadius="lg"
                              display="flex"
                              justifyContent="space-between"
                              // alignItems={{ xs: "flex-start", md: "center" }}
                              flexDirection={"column"}
                              my={1}
                              py={1}
                              pl={{ xs: 1, sm: 2 }}
                              pr={1}
                            >
                              <MDTypography variant="caption" color="text">
                                settings: {JSON.stringify(workflow.settings)} <br />____
                              </MDTypography>
                              <MDTypography variant="caption" color="text">

                                rulez: {JSON.stringify(workflow.rulez)} <br />____
                              </MDTypography>
                              <MDTypography variant="caption" color="text">
                                integrations: {JSON.stringify(workflow.integrations)} <br />____
                              </MDTypography>
                            </MDBox>
                            <MDBox>
                              {/* <MDBox p={3}> */}
                              {/*   <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }}> */}

                              {/*     <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}> */}
                              {/*       {workflow.state === "Draft" && */}
                              {/*         <MDButton variant="gradient" color="primary" sx={{ height: "100%" }} */}
                              {/*                   disabled={!workflow.settings || !workflow.settings.steps}> */}
                              {/*           Publish */}
                              {/*         </MDButton>} */}
                              {/*       {workflow.state === "Published" && */}
                              {/*         <MDButton variant="gradient" color="warning" sx={{ height: "100%" }} */}
                              {/*                   disabled={!workflow.settings || !workflow.settings.steps}> */}
                              {/*           Archive */}
                              {/*         </MDButton>} */}
                              {/*     </MDBox> */}
                              {/*   </MDBox> */}
                              {/* </MDBox> */}
                            </MDBox>
                          </Grid>
                        </Grid>
                      </MDBox>


                    </Grid>

                  </Grid>
                  <MDBox display="flex" justifyContent="space-between" alignItems="center">

                    <MDBox px={0} display="flex" alignItems="center" color="text">
                      <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
                        {/*<MDButton variant="outlined" color="secondary"*/}
                        {/*          onClick={() => {*/}
                        {/*            // setSelected(workflow);*/}
                        {/*            openEditModal();*/}
                        {/*          }}>*/}
                        {/*  Edit Info*/}
                        {/*</MDButton>*/}
                        <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                          {workflow.state === "Draft" &&
                            <MDButton variant="outlined" color="error" sx={{ height: "100%" }}
                                      component={Link} to={`/admin/workflow/edit/${workflow.key}/edit-flow`}>
                              Edit Flow
                            </MDButton>}

                        </MDBox>
                      </MDBox>

                    </MDBox>
                    <MDBox p={3}>
                      <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
                        {/*{workflow.state === "Draft" &&*/}
                        {/*  <MDButton variant="outlined" color="error" sx={{ height: "100%" }}*/}
                        {/*            component={Link} to={`/admin/workflow/edit/${workflow.key}/edit-flow`}>*/}
                        {/*    Edit Flow*/}
                        {/*  </MDButton>}*/}
                        <MDButton variant="outlined" color="secondary"
                                  onClick={() => {
                                    // setSelected(workflow);
                                    openEditModal();
                                  }}>
                          Edit Info
                        </MDButton>
                        <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>


                          {workflow.state === "Published" &&
                            <MDButton variant="gradient" color="warning" sx={{ height: "100%" }}
                                      disabled={!workflow.settings || !workflow.steps}>
                              Archive
                            </MDButton>}
                        </MDBox>
                      </MDBox>
                    </MDBox>

                    {/* <MDBox display="flex">{renderMembers}</MDBox> */}
                  </MDBox>
                </MDBox>


              </Card>
            </MDBox>

          </MDBox>
          <CustomDialog onClose={closeModal} open={openModalMode === "edit"} fullWidth={true} maxWidth={"sm"}
                        aria-labelledby="customized-dialog-title">
            <DialogContent>
              <WorkflowAddEdit workflowData={workflow}
                               onEditWorkflowCancel={closeModal}
                               onEditWorkflowUpdate={closeModal} />
            </DialogContent>
          </CustomDialog>

          <CustomDialog onClose={closeModal} open={openModalMode === "publish"} fullWidth={true} maxWidth={"sm"}
                        aria-labelledby="customized-dialog-title">
            <DialogContent>
              Confirm publish?

              <MDBox ml="auto" justifyContent="space-between"
                     width={{ xs: "100%", sm: "auto" }}
                     display="flex"
                     alignItems={{ xs: "flex-end", sm: "center" }}
                     flexDirection={{ xs: "column", sm: "row" }}>
                <MDButton variant="outlined" color="secondary"
                          onClick={closeModal}>
                  {t("Cancel")}
                </MDButton>
                <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                  <MDButton onClick={handlePublish}
                            disabled={savingData}
                            type="submit"
                            variant="gradient"
                            color="primary"
                  >
                    Confirm & Publish Workflow
                  </MDButton>
                </MDBox>
              </MDBox>
            </DialogContent>
          </CustomDialog>
        </MDBox>
      )}
    </DashboardLayout>
  );
}

export default WorkflowDetailsEdit;
