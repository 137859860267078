import { useEffect, useState, useCallback } from "react";
import Auth from "@aws-amplify/auth";

import {
  Submission,
  SubmissionAuditLog,
  SubmissionState,
  SubmissionVersion,
  SubmissionParticipant
} from "../models";
import SubmissionService from "../services/SubmissionService";
import AuditTrailService from "../services/AuditTrailService";
import FriendlyURLGenerator from "../services/FriendlyURLGenerator";
// import SubmissionService from "../services/SubmissionService";

const ROW_TYPE_MAIN = "SBT";
const ROW_TYPE_PCP = "PCP";

type UseSubmissionHook = {
  loading: boolean;
  submissionMain?: Submission;
  submissionPCP?: SubmissionParticipant;
  submissionRows?: Submission[];
  reloadSubmission: Function;
  setSubmissionPCP: Function;
  setSubmissionMain: Function;
};

export function useConsumerSubmission(submissionId: string): UseSubmissionHook {
  const [loading, setLoading] = useState(false);
  const [submissionMain, setSubmissionMain] = useState<Submission | undefined>(undefined);
  const [submissionPCP, setSubmissionPCP] = useState<SubmissionParticipant | undefined>(undefined);
  const [submissionRows, setSubmissionRows] = useState<Submission[] | undefined>(undefined);

  const fetchData = useCallback(
    async (updateLoading: boolean = true) => {
      if (!submissionId) return;
      updateLoading && setLoading(true);
      const data = await SubmissionService.fetchSubmissionById(submissionId);
      updateLoading && setLoading(false);
      if (data) {
        const sRows = data;
        const user = await Auth.currentAuthenticatedUser();
        setSubmissionRows(sRows);
        setSubmissionMain(sRows.filter((s) => s.rowType === "SBT")[0]);
        setSubmissionPCP(sRows.filter((s) => s.rowType === "PCP" && s.participant === user.username)[0]);
        // if (widgets) {
        //  widgets.sort((a, b) => a.order - b.order);
        // }else{
        //   widgets = [];
        // }
        // data.widgets = widgets;

      }
    },
    [submissionId]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    loading,
    submissionRows,
    submissionPCP,
    setSubmissionPCP,
    submissionMain,
    setSubmissionMain,
    reloadSubmission: fetchData
  };
}

type UseSubmissionsHook = {
  loading: boolean;
  submissions: Array<Submission>;
  draftsSubmissions: Array<Submission>;
  submittedSubmissions: Array<Submission>;
  reviewSubmissions: Array<Submission>;
  archivedSubmissions: Array<Submission>;
  cancelledSubmissions: Array<Submission>;
  reloadSubmissions: Function;
};

export function useConsumerSubmissions(): UseSubmissionsHook {
  const [loading, setLoading] = useState(false);
  const [submissions, setSubmissions] = useState<Submission[]>([]);
  const [draftsSubmissions, setDraftsSubmissions] = useState<Submission[]>([]);
  const [submittedSubmissions, setSubmittedSubmissions] = useState<Submission[]>(
    []
  );
  const [reviewSubmissions, setReviewSubmissions] = useState<Submission[]>(
    []
  );
  const [archivedSubmissions, setArchivedSubmissions] = useState<Submission[]>([]);
  const [cancelledSubmissions, setCancelledSubmissions] = useState<Submission[]>([]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const data = await SubmissionService.fetchConsumerSubmissions();
    console.log(JSON.stringify(data));
    // const data = await SubmissionService.fetchSubmissions();
    // console.log(JSON.stringify(data));
    if (data) {
      const jsonData = data;
      // console.log(JSON.stringify(jsonData));
      const sbtRows = jsonData.filter((r) => r.rowType === ROW_TYPE_PCP);

      setSubmissions(sbtRows.filter((submission) => submission.state !== SubmissionState.Cancelled
        && submission.state !== SubmissionState.Archived )); // All Active PCP rows

      setDraftsSubmissions(
        sbtRows.filter((submission) => submission.state === SubmissionState.Draft)
      );
      setSubmittedSubmissions(
        sbtRows.filter((submission) => submission.state === SubmissionState.Submitted)
      );
      setReviewSubmissions(
        sbtRows.filter((submission) => submission.state === SubmissionState.Review || submission.state === SubmissionState.InProgress)
      );
      setArchivedSubmissions(
        sbtRows.filter((submission) => submission.state === SubmissionState.Archived)
      );
      setCancelledSubmissions(
        sbtRows.filter((submission) => submission.state === SubmissionState.Cancelled)
      );
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    loading,
    submissions,
    draftsSubmissions,
    submittedSubmissions,
    reviewSubmissions,
    archivedSubmissions,
    cancelledSubmissions,
    reloadSubmissions: fetchData
  };
}

type UseSubmissionParticipantHook = {
  loading: boolean;
  submission?: SubmissionParticipant;
  reloadSubmission: Function;
  submissionNotFound?: boolean;
};

export function useSubmissionParticipant(
  friendlyURL: string
): UseSubmissionParticipantHook {
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [submission, setSubmission] = useState<SubmissionParticipant | undefined>(
    undefined
  );

  const setSubmissionAndSortWidgets = (submission: SubmissionParticipant) => {
    if (submission) {
      submission.widgets.sort((a, b) => a.order - b.order);
      setSubmission(submission);
    }
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const data = await SubmissionService.fetchSubmissionById(friendlyURL);
      setSubmissionAndSortWidgets(data);
    } catch (error) {
      try {
        /**
         * If fetching the submission by URL fails. Try loading the submission
         * by ID as a last resource. This allows us to be backwards compatible
         * so submissions that don't currently have a friendlyURL can still
         * load for public users. We may remove this in the future.
         */
        const submissionId = friendlyURL; // use the friendlyURL as an ID
        const data = await SubmissionService.fetchSubmissionParticipant(submissionId);
        setSubmissionAndSortWidgets(data);
      } catch (err) {
        /**
         * If fetching the submission by ID did not work either. Then consider
         * we can consider the submission to be NotFound.
         */
        setNotFound(true);
      } finally {
        setLoading(false);
      }
    } finally {
      setLoading(false);
    }
  }, [friendlyURL]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    loading,
    submission,
    reloadSubmission: fetchData,
    submissionNotFound: notFound
  };
}

type UseVersionsHook = {
  loading: boolean;
  versions: Array<SubmissionVersion>;
};

export function useSubmissionVersions(
  parentSubmissionId: string | undefined
): UseVersionsHook {
  const [loading, setLoading] = useState<boolean>(false);
  const [versions, setVersions] = useState<SubmissionVersion[]>([]);

  useEffect(() => {
    if (parentSubmissionId) {
      const fetchData = async () => {
        setLoading(true);
        const data = await SubmissionService.fetchSubmissionVersions(
          parentSubmissionId
        );
        setVersions(data);
        setLoading(false);
      };
      fetchData();
    }
  }, [parentSubmissionId]);

  return {
    loading,
    versions
  };
}

type UseHistoryHook = {
  loading: boolean;
  auditlogs: SubmissionAuditLog[];
};

export function useSubmissionHistory(
  parentSubmissionId?: string
): UseHistoryHook {
  const [loading, setLoading] = useState<boolean>(false);
  const [auditlogs, setAuditlogs] = useState<SubmissionAuditLog[]>([]);

  useEffect(() => {
    if (parentSubmissionId) {
      const fetchData = async () => {
        setLoading(true);
        const data = await SubmissionService.fetchSubmissionHistory(
          parentSubmissionId
        );

        // Filter out noisy logs first
        const logs = AuditTrailService.removeNosiyAuditLogs(
          data as SubmissionAuditLog[]
        );

        setAuditlogs(logs);
        setLoading(false);
      };
      fetchData();
    }
  }, [parentSubmissionId]);

  return {
    loading,
    auditlogs
  };
}

type UseFriendlyUrlHook = {
  friendlyURL: string;
};

export function useFriendlyUrl(
  submission?: Submission,
  versions?: SubmissionVersion[]
): UseFriendlyUrlHook {
  const [friendlyURL, setFriendlyURL] = useState("");
  useEffect(() => {
    if (submission) {
      const submitted = versions?.find(
        (version) => version.state === SubmissionState.Submitted
      );

      if (submission.friendlyURL) {
        setFriendlyURL(submission.friendlyURL);
      } else if (submitted && submitted.friendlyURL) {
        setFriendlyURL(submitted.friendlyURL);
      } else {
        setFriendlyURL(
          FriendlyURLGenerator.generateFromName(submission.name)
        );
      }
    }
  }, [submission, versions]);

  return {
    friendlyURL
  };
}
