import React, { lazy, Suspense, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// ProductPage page components
// Data
import ConsumerLayout from "../../../LayoutContainers/ConsumerLayout";
import SubmissionHeader from "./components/SubmissionHeader";

import useApplicationStore from "../../../store/application-store";
import MDButton from "../../../components/MDButton";
import { useTranslation } from "react-i18next";
import SubmissionInfo from "./components/SubmissionInfo";
// import RenderConsumerApplication from "../../custom-components/RenderConsumerApplication";
import { useMaterialUIController } from "../../../context";
import { useSubmissionContextHook } from "../../../hooks/submission-hooks";
import withSubmission from "../../../LayoutContainers/ConsumerLayout/withSubmission";
import Spinner from "../../custom-components/Spinner/Spinner";
import SubmissionStepsNav from "./components/SubmissionStepsNav/SubmissionStepsNav";
import { workflowNodeTypes } from "../../../static-data/wf-node-types";
import SubmissionPreview from "views/shared/SubmissionPreview";
import DisplayKeyValueCard from "../../custom-components/DisplayKeyValueCard";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import AdminHeader from "../../custom-components/AdminHeader";
import DashboardLayout from "LayoutContainers/DashboardLayout";
import breakpoints from "../../../assets/theme/base/breakpoints";
// import useConsumerSubmissionStore from "../../../store/consumer-submission-store-ctx";
// import useConsumerSubmissionStoreBase from "../../../store/consumer-submission-store-ctx";
// import createSelectors from "../../../store/selectors";

const RenderConsumerApplication = lazy(() => import("../../custom-components/RenderConsumerApplication"));

function getPCPSteps(submissionPCP) {
  if (!submissionPCP) return;
  return submissionPCP.workflow && submissionPCP.userFlow.steps;
}

function getWFSteps(pcpSteps) {
  if (!pcpSteps) return;
  return pcpSteps.sort((a, b) => a.seq > b.seq ? 1 : -1);
}

function AdminReviewSubmission() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  // const { tenantConfig, reloadTenantConfig, loadingTenantConfig } = usePublicTenantConfig();
  const [loadingData, setLoadingData] = useState<boolean>(false);
  // const {offerings,reloadOfferings, loading} = useOfferings();
  const { submissionId, tenantName } = useParams();
  // const [submission, setSubmission] = useState<Submission>();
  const {
    loadingSubmission: loading,
    submissionRows,
    submissionPCP,
    summary,
    submissionMain,
    submissionLoaded,
    editMode,
    setEditMode,
    reloadSubmission
  } = useSubmissionContextHook(submissionId, false);

  const pcpSteps = getPCPSteps(submissionPCP);
  const wfSteps = getWFSteps(pcpSteps);

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  const getNodeDetails = (nodeType) => workflowNodeTypes.find(nt => nt.nodeType === nodeType);
  const [activeStep, setActiveStep] = useState(0);


  useEffect(() => {
    if (!pcpSteps) return;
    const nextStep = pcpSteps.find(s => !s.sourceState || s.sourceState !== "DONE");
    // setActiveStep(nextStep.seq);
    setActiveStep(2);
  }, [pcpSteps]);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /**
     The event listener that's calling the handleTabsOrientation function when resizing the window.
     */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  const getCurrentStepNavContent = (stepIndex) => {
    if (!wfSteps) return;
    const currStep = wfSteps.find(s => s.seq === stepIndex);
    if (!currStep) {
      //console.log"Current step not found with index {}", stepIndex, wfSteps);
      return;
    }
    console.log("Current step - ", stepIndex, currStep);
    return currStep;
  };

  const getCurrentSelectionHeader = () => {
    if (!pcpSteps) return;
    const currStep = pcpSteps.find(s => s.seq === activeStep);
    if (!currStep) {
      //console.log"Current step not found with index {}", stepIndex, wfSteps);
      return "None";
    }
    const nodeType = getNodeDetails(currStep.sourceType);
    return <>
      <MDBox mb={1}>
        <MDTypography variant="h4" fontWeight="medium">
          {nodeType ? nodeType.label : currStep.sourceType}
        </MDTypography>
      </MDBox>
      {currStep.sourceData && currStep.sourceData.name !== "None" &&
        <MDTypography variant="body2" color="text" fontWeight="regular">
          {currStep.sourceData.name}
        </MDTypography>

      }

    </>;
  };
  const getReviewContent =  () => {
    if (!wfSteps) return;
    const stepIndex = activeStep;
    const currStep = wfSteps.find(s => s.seq === stepIndex);
    if (!currStep) {
      //console.log"Current step not found with index {}", stepIndex, wfSteps);
      return;
    }
    console.log("Review Pane - ", stepIndex, currStep);


        return <SubmissionPreview
          submissionPCP={submissionPCP}
          oSteps={getPCPSteps(submissionPCP)}
          loading={loading}
          submissionLoaded={submissionLoaded}
        > <MDBox>children</MDBox> </SubmissionPreview>;


  };

  // useEffect(() => {

  // }, []);


  // useEffect(() => {
  //   console.log("AdminReviewSubmission loadingTenantConfig " + loadingTenantConfig);
  //   console.log("AdminReviewSubmission submissionId " + submissionId);
  //   // console.log("ProviderHome loaded tenantConfig " + JSON.stringify(tenantConfig));
  //   if (!loadingTenantConfig && tenantConfig.userPoolId){
  //     // fetchData();
  //     console.log("AdminReviewSubmission tenant loaded " );
  //   }
  // }, [loadingTenantConfig]);

  if (loading && !submissionMain && !submissionPCP) {
    return (
      <ConsumerLayout background={"default"} loading={loading}>
        <MDBox mt={2} minHeight="20vh">
          <Spinner minHeight={400} />
        </MDBox>
      </ConsumerLayout>
    );
  }

  return (
    <DashboardLayout>
      <MDBox width="calc(100% - 48px)" position="absolute" top="1.75rem">
        <DashboardNavbar light absolute />
      </MDBox>
      <AdminHeader title="Submissions" icon="list_alt"
                   description="View / Manage Submissions"
                   loading={loading}/>

      {/*<SubmissionStoreProvider>*/}
      <MDBox mt={2} minHeight="20vh">
        <SubmissionHeader
          //     loadingSubmission={loading}
          // submissionPCP={submissionPCP}
          // submissionMain={submissionMain}
          // submissionLoaded={submissionLoaded}
        />

        <MDBox mb={3}>

          <MDBox>
            {/*<Card>*/}
            <MDBox m={2} pt={2} minHeight="40px">
              {submissionLoaded ?
                getCurrentSelectionHeader()
                :
                <MDBox mt={2}>
                  ...
                </MDBox>
              }
            </MDBox>

            {/*</Card>*/}
            <MDBox py={2} minHeight="50vh">
              <Grid container spacing={3}>
                <Grid item xs={9}>
                  {/*{(!loading && submissionPCP) && getReviewContent(activeStep)}*/}
                  <Card>
                    <MDBox m={4} minHeight="20vh">
                      <Grid container>
                        <Grid item xs={12} sm={8} lg={4}>

                          {/*<AppBar position="static">*/}
                          <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                            <Tab label="Summary" />
                            <Tab label="Applicants" />
                            <Tab label="Next Step" />
                            <Tab label="Status Check" />
                          </Tabs>
                          {/*</AppBar>*/}

                        </Grid>
                      </Grid>

                      {submissionLoaded ?
                        <Fade in={submissionLoaded} timeout={1300}>
                          <MDBox>
                            {getReviewContent()}
                          </MDBox>
                        </Fade>
                        :
                        <MDBox mt={2}>
                          <Spinner minHeight={"20vh"} />
                        </MDBox>
                      }
                    </MDBox>
                  </Card>
                  {/*{wfSteps &&*/}
                  {/*  <MDBox mt={4}>*/}
                  {/*    <MDBox ml="auto" justifyContent="space-between" display="flex"*/}
                  {/*           alignItems={{ xs: "flex-end", sm: "center" }}*/}
                  {/*           flexDirection={{ xs: "column", sm: "row" }}>*/}
                  {/*      <MDButton variant="gradient" color="secondary" size="large"*/}
                  {/*                disabled={activeStep === 0}*/}
                  {/*                onClick={handleBack}*/}
                  {/*      >*/}
                  {/*        {t("Back")}*/}
                  {/*      </MDButton>*/}
                  {/*      <MDButton*/}
                  {/*        disabled={activeStep === wfSteps.length - 1}*/}
                  {/*        onClick={handleNext}*/}
                  {/*        type="submit"*/}
                  {/*        variant="gradient"*/}
                  {/*        color="primary" size="large"*/}
                  {/*      >*/}
                  {/*        Next*/}
                  {/*      </MDButton>*/}

                  {/*      /!* <MDButton variant="gradient" color="secondary" size="large" *!/*/}
                  {/*      /!*           onClick={onCancel}> *!/*/}
                  {/*      /!*   {t("Cancel")} *!/*/}
                  {/*      /!* </MDButton> *!/*/}
                  {/*    </MDBox>*/}

                  {/*  </MDBox>}*/}
                </Grid>

                <Grid item xs={3}>
                  <SubmissionStepsNav wfSteps={pcpSteps} activeStep={getCurrentStepNavContent(activeStep)} />
                  {/*<MDTypography variant="caption" color="text" fontWeight="regular">*/}
                  {/*  {wfSteps && JSON.stringify(getCurrentStep())}*/}
                  {/*</MDTypography>*/}
                  {/*<SubmissionStepsNav wfSteps={wfSteps} />*/}

                  {/*Edit Mode : {JSON.stringify( editMode )}*/}

                  {/*Admin Tasks <br/>*/}
                  {/*Admin Notes <br/>*/}
                  {/*Admin Other <br/>*/}
                </Grid>

              </Grid>

            </MDBox>


          </MDBox>

        </MDBox>

      </MDBox>

    </DashboardLayout>
  );
}

export default withSubmission(AdminReviewSubmission);
