import React from 'react';
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

// formik components
import { ErrorMessage, Field  } from "formik";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import AOSelect from "../AOSelect";

function FormSelect({ label, name, ...rest }) {
  return (
    <MDBox mb={0}>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 40 }} fullWidth>
        <InputLabel id={`${name}-label`} >{label}</InputLabel>
      <Field {...rest} name={name} as={AOSelect} variant="standard" label={label}
             labelId={`${name}-label`}/>

      <MDBox mt={0}>
        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
          <ErrorMessage name={name} />
        </MDTypography>
      </MDBox>
      </FormControl>
    </MDBox>
  );
}

// typechecking props for FormSelect
FormSelect.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default FormSelect;
