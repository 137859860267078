import React, { useContext, useEffect, useState } from "react";

// react-router components
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Backdrop from '@mui/material/Backdrop';

import Icon from "@mui/material/Icon";
import { withAuthenticator, useAuthenticator } from "@aws-amplify/ui-react";

import { Auth } from "aws-amplify";
// import { onAuthUIStateChange, AuthState } from "@aws-amplify/ui-components";

import { Logger } from "@aws-amplify/core";

import Sidenav from "./examples/Sidenav";
import Configurator from "./examples/Configurator";

import theme from "./assets/theme";
import themeDark from "./assets/theme-dark";

import routes from "./routes";

import { setMiniSidenav, setOpenConfigurator, useMaterialUIController } from "./context";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Images
import logoImage from "assets/images/cn-logo-full.svg";

import brandDark from "assets/images/app-brand/consen-logo-col.svg";
import brandWhite from "assets/images/app-brand/consen-logo-halfcol.svg";

// import withAdminLayout from "./LayoutContainers/AdminLayout";
import { environment } from "./environments/environment";

// import SettingsProvider from "./context/SettingsProvider";
// import withPublicLayout from "./LayoutContainers/PublicLayout";
import { usePublicTenantConfig } from "./hooks/tenant-config-hooks";
import csIconGray from "./assets/images/consen-gr-n.svg";
import { useCurrentAuthenticatedUser } from "./hooks";
import UserPreferencesProvider from "./context/UserPreferencesProvider";
import Spinner from "./views/custom-components/Spinner/Spinner";
import withSAMLAuthenticator from "./LayoutContainers/SAMLAuthenticator";
// import { useSettings } from "./hooks";
// import TenantConfigProvider, { TenantConfigContext } from "./context/TenantConfigProvider";
// import { ApplicationState } from "./models";
// import withPageLayout from "./LayoutContainers/PageLayout";
const logger = new Logger("AmplifyAuthenticator");

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const { tenantConfig, tenantConfigLoaded, reloadTenantConfig, loadingTenantConfig } = usePublicTenantConfig();
  const { username, tenant, isAdmin, isLoggedIn } =
    useCurrentAuthenticatedUser();
  // const { settings } = useSettings();
  // const [signedIn, setSignedIn] = React.useState(false);
  // const { user, signOut } = useAuthenticator((context) => [context.user]);
  // const tenantContext = useContext( TenantConfigContext);
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  // useEffect(() => {
  //   document.body.setAttribute("dir", direction);
  // }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    // if (tenantConfigLoaded && isLoggedIn){
    //   console.log("logged in user belongs to " + tenant)
    //   // Auth.currentAuthenticatedUser().then(u=>console.log(u)).catch(e=>console.log(e));
    //
    // }

  }, [tenantConfigLoaded]);

  const getRoutes = (allRoutes) =>

    // if (tenantContext.loadingTenantConfig){
    //   return <h1>T.Config Loading ... </h1>;
    // }
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        const component: React.FunctionComponent =
          route.public && environment.authRequired
            ? withSAMLAuthenticator(route.component)
            : route.component;

        return <Route exact path={route.route} element={component} key={route.key} />;
      }

      return null;
    });



  return (
    // <TenantConfigProvider>
    <>

        <ThemeProvider theme={darkMode ? themeDark : theme}>
          <CssBaseline />
          {loadingTenantConfig ?
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loadingTenantConfig}
              // onClick={loadingTenantConfig}
            >
            <Grid container spacing={1} justifyContent="center" alignItems="center" sx={{ px: 4, my: 4 }}>
              <Grid item xs={12} md={8}  align={"center"}>
                <MDBox component="img" src={logoImage} width="40%" borderRadius="sm" mb={1} />
                <MDBox mt={6} mb={3}>
                  <Spinner/>
                  <MDTypography variant="h6" fontWeight="medium">
                    Loading Configurations.
                  </MDTypography>
                  <MDTypography variant="h2" fontWeight="medium">
                    Please wait ...
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
            </Backdrop>
            :
            <UserPreferencesProvider>
            <Routes>
              {getRoutes(routes)}
              <Route path="*" element={<Navigate to="/landing" />} />
            </Routes>
            </UserPreferencesProvider>
          }
        </ThemeProvider>


    </>
    // </TenantConfigProvider>
  );
}
