import { API } from "aws-amplify";
import Auth from "@aws-amplify/auth";
import { basicWFSteps } from "../views/admin/workflow/wf-data/basic-workflow-01";
import { defaultWFSteps } from "../views/admin/workflow/wf-data/default-workflow-01";
import { advancedWFSteps } from "../views/admin/workflow/wf-data/advance-workflow-01";
import authHeaders, { apiName } from "./services-base/api-base";
import ProviderConfigService from "./ProviderConfigService";


async function fetchWorkflows() {
  const headers = await authHeaders();
  return await API.get(apiName, "workflows", { headers });
}

async function fetchProtectedWorkflows() {
  // const headers = await authHeaders();
  return await API.get(apiName, "protected/workflows", {  });
}

async function fetchWorkflowById(workflowKey: string) {
  const headers = await authHeaders();
  return await API.get(apiName, `workflow/${workflowKey}`, { headers });
}

async function createWorkflow(workflowInfo) {
  const headers = await authHeaders();
  delete workflowInfo.shardId;
  delete workflowInfo.workflowId;
  console.log(" ---> " + JSON.stringify(workflowInfo));
  return await API.post(apiName, "workflow", {
    headers,
    body: workflowInfo,
  });
}

async function editWorkflow( workflowKey: string,  workflowInfo) {
  const headers = await authHeaders();
  return await API.put(apiName, `workflow/${workflowKey}`, {
    headers,
    body: workflowInfo,
  });
}
async function deleteWorkflow(workflowId: string) {
  const headers = await authHeaders();
  return await API.del(apiName, `workflow/${workflowId}`, {
    headers,
  });
}

async function fetchWorkflowConfigs() {
  // const headers = await authHeaders();
  // return await API.get(apiName, "workflows", { headers });
  // await SettingsService.fetchSettings();
  return await ProviderConfigService.fetchProviderConfigsSelect('category', 'WORKFLOW');
  // @TODO: Temp solution. Should use the call above
  // return await ProviderConfigService.fetchProviderConfigs().then(res=>{
  //   return res.filter(pc=> pc.category==="EFORM");
  // });
}



const WorkflowService = {
  fetchWorkflows,
  fetchProtectedWorkflows,
  fetchWorkflowById,
  editWorkflow,
  createWorkflow,
  deleteWorkflow,
  fetchWorkflowConfigs,
}

export default WorkflowService;
