import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import { useTranslation } from "react-i18next";

// Images

import { useMaterialUIController } from "context";
import WidgetLibrary from "../WidgetLibrary";
import { widgetItemTypes } from "static-data/app-widget-types";

function WidgetToolbar(props) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { t } = useTranslation();

  const widgetItems = widgetItemTypes;
  const renderSideNavWidgetItems = widgetItems.map(({ icon, label, href, color }, key) => {
    const itemKey = `item-${key}`;

    return (
      <MDBox px={1} key={itemKey} component="li"
             sx={({
               cursor: "pointer"})}
             onClick={() => {
               if (props.onSelect) {
                 props.onSelect(href);
               }
             }}
             aria-label={t("PlusAddContentItem")}
      >
        <MDTypography
          component="a"
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          sx={({
                 borders: { borderRadius, borderWidth, borderColor },
                 functions: { pxToRem },
                 palette: { light },
                 transitions,
               }) => ({
            display: "flex",
            flexDirection:"column",
            alignItems: "center",
            minWidth:"5rem",
            minHeight:"3rem",
            borderRadius: borderRadius.md,
            border:`${borderWidth[1]} solid ${borderColor}`,
            padding: `${pxToRem(16)} ${pxToRem(30)}`,
            transition: transitions.create("background-color", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter,
            }),

            "&:hover": {
              backgroundColor: light.main,
            },
            cursor: "pointer"
          })}
        >
          <MDBox lineHeight={1} color={darkMode ? "white" : color} >
            <Icon fontSize="large">{icon}</Icon>
          </MDBox>


          {/* <MDButton */}
          {/*   variant="contained" */}
          {/*   onClick={() => { */}
          {/*     if (props.onSelect) { */}
          {/*       props.onSelect(href); */}
          {/*     } */}
          {/*   }} */}
          {/*   aria-label={t("PlusAddContentItem")} */}
          {/* > */}
          {/*   <Icon fontSize="small">add</Icon> */}

          {/* </MDButton> */}
          {label}
        </MDTypography>

      </MDBox>
    );
  });

  return (
    <MDBox mx={2} px={2} pb={4}>
      <MDBox px={2} pb={2} >
      <MDTypography variant="h6" fontWeight="medium" color={"info"}>
        Add Content
      </MDTypography>
      </MDBox>
      <Card  sx={{ overflow: "visible", boxShadow: "none"  }}>
        {/* <MDBox pt={2} px={2} display="flex" alignItems="center" justifyContent="flex-end"> */}

        {/*   <MDButton variant="text" color="text"> */}
        {/*     <Icon sx={{ fontWeight: "bold" }}>info_outlined</Icon> */}
        {/*     &nbsp;INFO */}
        {/*   </MDButton> */}
        {/* </MDBox> */}
        <MDBox p={0} alignItems="center" justifyContent="center">

          <MDBox
            component="ul"
            display="flex"
            flexDirection="row"
            px={1}
            m={1}
            alignItems="center"
            justifyContent="space-between"
            // flexWrap="wrap"
            sx={{ listStyle: "none" }}
          >
            {renderSideNavWidgetItems}
          </MDBox>

        </MDBox>
      </Card>
    </MDBox>
  );
}

WidgetToolbar.propTypes = {
  onSelect: PropTypes.func.isRequired,
};
export default WidgetToolbar;
