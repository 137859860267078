import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Backdrop from '@mui/material/Backdrop';

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";


import DashboardLayout from "LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// ProductPage page components
import ProductImages from "layouts/ecommerce/products/product-page/components/ProductImages";
import ProductInfo from "layouts/ecommerce/products/product-page/components/ProductInfo";

// Data
import dataTableData from "layouts/ecommerce/products/product-page/data/dataTableData";
import ConsumerLayout from "../../../LayoutContainers/ConsumerLayout";
import ConsumerHeader from "../../../LayoutContainers/ConsumerLayout/ConsumerHeader";
import { useCurrentAuthenticatedUser, useOffering, useOfferings, usePublicTenantConfig } from "../../../hooks";
import OfferingPageInfo from "./components/OfferingPageInfo";
import WorkflowService from "../../../services/WorkflowService";
import MDButton from "../../../components/MDButton";
import PageLayout from "../../../LayoutContainers/PageLayout";
import withPublicLayout from "../../../LayoutContainers/PublicLayout";
import LinearLoading from "../../custom-components/LinearLoading";
import OfferingImages from "./components/OfferingImages";
import tenantLogo from "../../../assets/images/cs-site.svg";
import useConsumerOfferingsStore from "../../../store/consumer-offering-store";
import MDBadge from "../../../components/MDBadge";

function OfferingPage() {

  const navigate = useNavigate();
  const { username, tenant, isAdmin, isLoggedIn } =
    useCurrentAuthenticatedUser();
  const { tenantConfig,tenantConfigLoaded,
    reloadTenantConfig, loadingTenantConfig } = usePublicTenantConfig();
  // const {offerings,reloadOfferings, loading} = useOfferings();

  const { offeringId, tenantName,friendlyURL } = useParams();
  // const { offering, loading } = useOffering(offeringId );
  const initOffsStore = useConsumerOfferingsStore.use.initStore();
  const getByKey = useConsumerOfferingsStore.use.getByKey();
  const loading = useConsumerOfferingsStore.use.loading();
  const applyForOffering = useConsumerOfferingsStore.use.applyForOffering();

  const [offering, setOffering] = useState();

  useEffect(() => {
    console.log(">> OfferingPage offeringId " + offeringId);
  }, [offeringId]);



  useEffect(() => {
    if (!loadingTenantConfig) {
      console.log(">> ProviderHome loadingTenantConfig " + loadingTenantConfig);
      if (tenantConfigLoaded) {
        initOffsStore(tenantConfig.authRequired, tenantConfig.tenantId)
          .then(()=>setOffering(getByKey(offeringId)));
      }
    }
  }, [loadingTenantConfig]);


  const handleApply = async (offeringKey) => {
    try {
      const submission = await applyForOffering(offeringKey);
      navigate(`/app/submission/${submission.metadata.submissionId}`);
    } catch (e) {
      console.error(e);
    }
  };
  const loginToApply = async (offeringKey) => {
    console.log("ProviderHome : offering key " , offeringKey);
    if (!offeringKey) {
      console.log("ProviderHome : offering not determined " + JSON.stringify(offeringKey));
      return;
    }
    navigate(`/app/auth/offering/details/${offeringKey}`);
  }

  const tenantPublicHeader = () => {
    return <ConsumerHeader isSmall
                           title={`${tenantConfig.pubConfigs.homepageTitle}`}
                           subTitle={tenantConfig.pubConfigs.slogan}
      // image={csSiteImg}
    />
  }


  if (!offering  ) {
    return (

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!offering && loading}
        // onClick={loadingTenantConfig}
      >
        {/*<ConsumerHeader isSmall*/}
        {/*                title={`${tenantConfig.pubConfigs.homepageTitle}`}*/}
        {/*                subTitle={tenantConfig.pubConfigs.slogan}*/}
        {/*  // image={csSiteImg}*/}
        {/*/>*/}
        <Grid container spacing={1} justifyContent="center" alignItems="center" sx={{ px: 4, my: 4 }}>
          <Grid item xs={12} md={8}  align={"center"}>
            <MDBox component="img" src={tenantLogo} width="40%" borderRadius="sm" mb={1} />
            <MDBox mt={6} mb={3} minHeight="30vh">
              <MDTypography variant="h6" fontWeight="medium">
                Loading {tenantConfig.topicAreaLabels.singular}
              </MDTypography>
              <MDTypography variant="h2" fontWeight="medium">
                Please wait ...
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </Backdrop>



      // <MDBox minHeight="60vh">
      //   {tenantPublicHeader}
      //
      //   <MDBox py={3} minHeight={"30vh"}>
      //     <LinearLoading />
      //   </MDBox>
      // </MDBox>

    );
  }
  return (

<>
      <ConsumerHeader isSmall
                      title={`${tenantConfig.pubConfigs.homepageTitle}`}
                      subTitle={tenantConfig.pubConfigs.slogan}
        // title={offering.name}
        //               subTitle={offering.description}
      >
        {isLoggedIn ?
          <MDButton variant="gradient" color="white" size={"large"} disabled={loading}
                    onClick ={()=>handleApply(offering.key)}>
            Apply Now
          </MDButton>
          :
          <MDButton variant="outlined" color="white" size={"large"}
                    onClick={() => loginToApply(offering.key)}>
            Login to Apply
          </MDButton>
        }
      </ConsumerHeader>
  <Container >
      <MDBox py={3} >




        <Card sx={{ overflow: "visible" ,  mt: -12}} >
          <MDBox p={3} m={4}>
            <MDBox mb={3}>
            {/*  <MDTypography variant="h5" fontWeight="medium">*/}
            {/*    {tenantConfig.topicAreaLabels.singular} Details*/}
            {/*  </MDTypography>*/}
              <MDTypography variant="h3" fontWeight="bold">
                {offering.name}
              </MDTypography>
              {/*<MDTypography variant="body1" fontWeight="medium">*/}
              {/*  {offering.description}*/}
              {/*</MDTypography>*/}
            </MDBox>

            <Grid container spacing={3}>
              <Grid item xs={12} lg={5}>
                <MDBox py={3}>
                <OfferingImages />
                </MDBox>
              </Grid>
              <Grid item xs={12} lg={6} sx={{ mx: "auto" }}>
                <MDBox mt={3} mb={1} >
                  <MDTypography variant="h6" fontWeight="regular" color="text">
                    {offering.description}
                  </MDTypography>
                </MDBox>
                <MDBox mt={1}>
                  <MDTypography variant="h6" fontWeight="regular">
                    {offering.priceType}
                  </MDTypography>
                </MDBox>
                <MDBox mb={4}>
                  <MDTypography variant="h5" fontWeight="medium">
                    ${offering.price} / {offering.priceLabel}
                  </MDTypography>
                </MDBox>
                {/*<MDBadge variant="contained" color="success" badgeContent={offering.categoryName} container />*/}


                {isLoggedIn ?
                  <MDButton variant="gradient" color="primary" disabled={loading} size={"large"}
                            onClick ={()=>handleApply(offering.key)}>
                    Apply Now
                  </MDButton>
                  :
                  <MDButton variant="outlined" color="info" size={"large"}
                            onClick={() => loginToApply(offering.key)}>
                    Login to Apply
                  </MDButton>
                }
              </Grid>
            </Grid>

            <OfferingPageInfo offering={offering}/>

            <MDBox mt={8} mb={2}>
              {isLoggedIn ?
                <MDButton variant="gradient" color="primary" disabled={loading} size={"large"} fullWidth
                          onClick ={()=>handleApply(offering.key)}>
                  Apply Now
                </MDButton>
                :
                <MDButton variant="outlined" color="info" size={"large"} fullWidth
                          onClick={() => loginToApply(offering.key)}>
                  Login to Apply
                </MDButton>
              }

              {/*<MDBox mb={1} ml={2}>*/}
              {/*  <MDTypography variant="h5" fontWeight="medium">*/}
              {/*    Other Products*/}
              {/*  </MDTypography>*/}
              {/*</MDBox>*/}
              {/*<DataTable*/}
              {/*  table={dataTableData}*/}
              {/*  entriesPerPage={false}*/}
              {/*  showTotalEntries={false}*/}
              {/*  isSorted={false}*/}
              {/*/>*/}
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>


    </Container>
</>
  );
}

export default withPublicLayout(OfferingPage);
