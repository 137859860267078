import React, { useEffect, useState, useCallback } from "react";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import DialogContent from "@mui/material/DialogContent";

import MDBox from "components/MDBox";
import CircularProgress from "@mui/material/CircularProgress";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";

import {
    useDashboard,
    useDashboardVersions,
    useChangeBackgroundColor,
    useWindowSize
} from "hooks";

import Spinner from "../../custom-components/Spinner/Spinner";

// Settings page components

import BaseLayout from "layouts/pages/account/components/BaseLayout";
import Sidenav from "./components/Sidenav";
import BasicInfo from "./components/BasicInfo";
import ChangePassword from "./components/ChangePassword";
import Authentication from "./components/Authentication";

import Notifications from "./components/Notifications";
import Sessions from "./components/Sessions";
import DeleteAccount from "./components/DeleteAccount";
import { useSettings } from "../../../hooks";
import BackendService from "../../../services/BackendService";
import GlobalService from "services/GlobalService";
import WidgetsView from "./components/ManageContentCard/ManageContentCard";
import { useApplication } from "../../../hooks/application-hooks";
import WorkflowView from "./workflow-view";
import RenderApplication from "../../custom-components/RenderApplication";
import DashboardLayout from "../../../LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import LinearLoading from "../../custom-components/LinearLoading";
import AdminHeader from "../../custom-components/AdminHeader";
import useWorkflowStore from "../../../store/workflow-store";
import useApplicationStore from "../../../store/application-store";
import UtilsService from "../../../services/UtilsService";
import ApplicationAdd from "../application-add";
import CustomDialog from "../../custom-components/CustomDialog";
import WorkflowAddEdit from "../workflow-list/component/WorkflowDetailsEdit/WorkflowAddEdit";
import ApplicationAddEdit from "../application/component/ApplicationAddEdit";
import useSingleApplicationStore from "../../../store/single-application-store";
import BasicContainerCard from "../../custom-components/BasicContainerCard";
import QuickMessageBox from "../../custom-components/QuickMessageBox";
import RenderConsumerApplication from "../../custom-components/RenderConsumerApplication";
import ManageContentCard from "./components/ManageContentCard/ManageContentCard";

interface PathParams {
    appId: string;
}

function ApplicationDetailEdit() {
    const { settings, loadingSettings } = useSettings();
    // const [loading, setLoading] = useState<boolean>(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { appId } = useParams<PathParams>();
    // const  applicationId  = UtilsService.getDecodedHashSymRevertedKey(applicationId);
    // const { application, reloadApplication, setApplication, loading } =
    //   useApplication(appId );

    const initStore = useSingleApplicationStore.use.initStore();
    const application = useSingleApplicationStore.use.applicationMain();
    // // const getByKey = useSingleApplicationStore.use.getByKey();
    // const fetchById = useSingleApplicationStore.use.fetchById();
    const loading = useSingleApplicationStore.use.loading();
    const applicationConfigs = useSingleApplicationStore.use.applicationConfigs();
    const saveApplication = useSingleApplicationStore.use.save();
    const savingData = useSingleApplicationStore.use.saving();
    const [alertMessage, setAlertMessage] = useState("");
    // const [application, setApplication] = useState();
    const [initValue, setInitValue] = useState({});

    const [openModalMode, setOpenModalMode] = useState("");

    // @ts-ignore
    // const [ tenantDetails, setTenantDetails ] = useState<any>({});
    // const [tenantLoading, setTenantLoading] = useState<boolean>(false);

    // useEffect(() => {
    //   console.log("settings : >>>>>>>>>>>>> " + JSON.stringify(settings));
    //
    // }, [settings]);

    const fetchData = useCallback(async () => {
        const data = await fetchById(appId);
        setApplication(data);
        setInitValue(data);
    }, []);

    useEffect(() => {
        initStore(appId, true).then(() => {
            console.log("getByKey " + appId);
            // fetchData();

        });
    }, [initStore]);
    const onEditWidgets = async () => {
        navigate(`/admin/application/${appId}/edit-widgets`);
    };

    const openEditModal = () => {
        setOpenModalMode("edit");
    };

    const closeModal = (refreshData?: boolean) => {
        // if (refreshData) fetchData();
        setOpenModalMode("");
    };

    const openPublishModal = () => {
        setOpenModalMode("publish");
    };
    const handlePublish = async () => {
        if (!application) {
            setAlertMessage("Application Info missing. Please revisit / fix.")
            console.log("Application Info missing. Please revisit / fix.");
            return;
        }

        const wfs = application.widgets;

        if (!wfs || wfs.length <1) {
            setAlertMessage("Content missing / failed to generate schema")
            console.log("Content missing / failed to generate schema");
            return;
        }
        // if (wfs.length > 1) {
        //   console.log("Multi-path steps... not supported");
        //   return;
        // }
        try {
            const applicationData = {
                ...application,
                state: "Published"
            };
            console.log("applicationData to update : ", JSON.stringify(applicationData));
            // console.log(JSON.stringify(values));
            await saveApplication(applicationData);
            closeModal();
        } catch (e) {
            console.log(t("AddContentFailure"), e);
            setAlertMessage(t("AddContentFailure") + ": " + e?.message);
        } finally {
            // setSavingFlow(false);
            setAlertMessage("");
        }

        // await ApplicationService.editApplication(applicationId, applicationData);
    };

    return (
        <DashboardLayout loading={loading}>
            <MDBox width="calc(100% - 48px)" position="absolute" top="1.75rem">
                <DashboardNavbar light absolute />
            </MDBox>

            <AdminHeader title={`Application : ${application ? application.name : ""}`}
                         icon="airline_stops_outlined"
                         description={application ? application.description : ""}
                         loading={loading}>

                {/*<MDBox mr={1}>*/}
                {/*  <MDButton variant="outlined" color="success"*/}
                {/*            onClick={openEditModal}>*/}
                {/*    validate*/}
                {/*  </MDButton>*/}
                {/*</MDBox>*/}
                {savingData && <LinearLoading />}



                {/*<MDBox mr={2}>*/}

                {/*  <MDButton variant="outlined" color="primary" disabled={savingData}*/}
                {/*            onClick={openEditModal}>*/}
                {/*    Edit Basic Info*/}
                {/*  </MDButton>*/}

                {/*</MDBox>*/}
                {/*{application.state === "Published" &&*/}
                {/*  <MDButton variant="gradient" color="warning" sx={{ height: "100%" }}*/}
                {/*            disabled={!application.settings || !application.steps}>*/}
                {/*    Archive*/}
                {/*  </MDButton>}*/}
                {application && application.state === "Draft" &&
                    <MDButton variant="gradient" color="error" sx={{ height: "100%" }}
                              disabled={savingData}
                              onClick={openPublishModal}>
                        Publish
                    </MDButton>}
            </AdminHeader>


            {!loading && application ?
                <MDBox mt={4}>


                    <Grid container spacing={3}>

                        <Grid item xs={12} md={12}>
                            <MDBox mb={3}>
                                <Grid container spacing={3}>

                                    <Grid item xs={6}>
                                        <BasicContainerCard dataObj={application}
                                                            leftButton={<MDButton variant="outlined" color="primary"
                                                                                  disabled={savingData}
                                                                                  onClick={openEditModal}>
                                                                Edit Basic Info
                                                            </MDButton>}
                                                            // rightButton={application && application.state === "Draft" &&
                                                            //     <MDButton variant="gradient" color="error"
                                                            //               sx={{ height: "100%" }}
                                                            //               disabled={!application.settings || !application.steps}
                                                            //               onClick={openPublishModal}>
                                                            //         Publish
                                                            //     </MDButton>}
                                        >
                                            <MDTypography variant="caption" color="text">
                                                More details
                                            </MDTypography>

                                        </BasicContainerCard>

                                    </Grid>

                                    <Grid item xs={6}>

                                      {/*{application.widgets && application.widgets.length>0 ?*/}
                                      {/*    <RenderConsumerApplication applicationId={appId}*/}
                                      {/*                               appData={application} />*/}
                                      {/*    :*/}
                                      {/*<QuickMessageBox message={"No Settings"}*/}
                                      {/*              hint={"Settings shoud be here"}*/}
                                      {/*              action={onEditWidgets}/>*/}
                                      {/*}*/}
                                    </Grid>
                                    {/*<BasicInfo dataObj={application}/>*/}
                                    {/*<Grid item xs={12}>*/}
                                    {/*  <Notifications />*/}
                                    {/*</Grid>*/}
                                    {/*<Grid item xs={12}>*/}
                                    {/*  <Sessions />*/}
                                    {/*</Grid>*/}
                                    {/*<Grid item xs={12}>*/}
                                    {/*  <DeleteAccount />*/}
                                    {/*</Grid>*/}
                                </Grid>
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <ManageContentCard application={application} />
                          {/*<RenderApplication applicationId={applicationId}*/}
                          {/*                   appData={application} />*/}
                        </Grid>
                    </Grid>

                </MDBox>
                :
                <Card>
                    <Spinner minHeight={200} absoluteTop={"40%"} />
                </Card>
            }

            <CustomDialog onClose={closeModal} open={openModalMode === "edit"} fullWidth={true} maxWidth={"sm"}
                          aria-labelledby="customized-dialog-title">
                <DialogContent>

                    <ApplicationAddEdit applicationData={application}
                                        onEditApplicationCancel={closeModal}
                                        onEditApplicationUpdate={closeModal} />
                </DialogContent>
            </CustomDialog>

            <CustomDialog onClose={closeModal} open={openModalMode === "publish"} fullWidth={true} maxWidth={"sm"}
                          aria-labelledby="customized-dialog-title">
                <DialogContent>
                    Confirm publish?

                    <MDBox ml="auto" justifyContent="space-between"
                           width={{ xs: "100%", sm: "auto" }}
                           display="flex"
                           alignItems={{ xs: "flex-end", sm: "center" }}
                           flexDirection={{ xs: "column", sm: "row" }}>
                        <MDButton variant="outlined" color="secondary"
                                  onClick={closeModal}>
                            {t("Cancel")}
                        </MDButton>
                        <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                            <MDButton onClick={handlePublish}
                                      disabled={savingData}
                                      type="submit"
                                      variant="gradient"
                                      color="primary"
                            >
                                Confirm & Publish Application
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </DialogContent>
            </CustomDialog>

        </DashboardLayout>
    );
}

export default ApplicationDetailEdit;
