import React, { ComponentType, ComponentPropsWithRef, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Auth, appendToCognitoUserAgent } from "@aws-amplify/auth";

import {
  Authenticator,
  Button,
  CheckboxField,
  Heading,
  Image,
  Text,
  View,
  useTheme,
  useAuthenticator,
} from "@aws-amplify/ui-react";


import { onAuthUIStateChange, AuthState } from "@aws-amplify/ui-components";
import { Logger } from "@aws-amplify/core";

import logoImg from "logo.svg";
import Link from "@mui/material/Link";
import EnvConfig from "../services/EnvConfig";
import { useMaterialUIController } from "../context";
import { usePublicTenantConfig } from "../hooks";
import brandWhite from "../logo.svg";
import "./cognito-style.css";
import UtilsService from "../services/UtilsService";
import { AuthError } from "@aws-amplify/auth/lib-esm/Errors";
import { AuthErrorTypes } from "@aws-amplify/auth/lib-esm/types";
// import "./amplify-auth-style.css";

const logger = new Logger("withAuthenticator");







export function withSAMLAuthenticator(
  Component: ComponentType,
  authenticatorProps?: ComponentPropsWithRef<typeof Authenticator>
) {

  return function (props) {
    const [signedIn, setSignedIn] = React.useState(false);
    const { t } = useTranslation();
    const [controller] = useMaterialUIController();

    const {      darkMode,    } = controller;
    const {    tenantConfig, tenantConfigLoaded,
      reloadTenantConfig, loadingTenantConfig  } = usePublicTenantConfig();
    const isHideSignUp = true;

    const formFields = {
      signIn: {
        username: {
          placeholder: "Enter your email",
        },
      },
      signUp: {
        username: {
          label: "Email:",
          placeholder: "Enter your email",
          isRequired: true,
          order: 1,
        },
        // email: {
        //   label: "Email:",
        //   order: 1,
        // },
        password: {
          label: "Password:",
          placeholder: "Enter your Password:",
          isRequired: false,
          order: 2,
        },
        confirm_password: {
          label: "Confirm Password:",
          order: 3,
        },
        // username: {
        //   // placeholder: "Enter your email",
        //   // order: 4,
        //   type: "hidden"
        // },
        'custom:tenantId': {
          // placeholder: "Enter your provider id",
          value:tenantConfig.tenantId,
          isRequired: true,
          // order: 5,
          // hidden: true
          type: "hidden"
        }
      },
      forceNewPassword: {
        password: {
          placeholder: "Enter your Password:",
        },
      },
      resetPassword: {
        username: {
          placeholder: "Enter your email",
        },
      },
      confirmResetPassword: {
        confirmation_code: {
          placeholder: "Enter your Confirmation Code:",
          label: "Code",
          isRequired: false,
        },
        confirm_password: {
          placeholder: "Enter your Password Please:",
        },
      },
      setupTOTP: {
        QR: {
          totpIssuer: "test issuer",
          totpUsername: "amplify_qr_test_user",
        },
        confirmation_code: {
          label: "Code",
          placeholder: "Enter your Confirmation Code:",
          isRequired: false,
        },
      },
      confirmSignIn: {
        confirmation_code: {
          label: "Code",
          placeholder: "Enter your Confirmation Code:",
          isRequired: false,
        },
      },
    };
    const authServices = {
      async handleSignUp(formData) {
        let { username, password, attributes } = formData;
        // custom username
        console.log(JSON.stringify(formData));
        const isValid = UtilsService.validateEmails(username);
        if (!isValid) {
          console.log("username is not a valid email", username);
          throw new AuthError(AuthErrorTypes.InvalidUsername);
        }
        username =  username.toLowerCase();
        attributes.email = username;
        attributes["custom:tenantId"] = tenantConfig.tenantId;
        return Auth.signUp({
          username,
          password,
          attributes,
          autoSignIn: {
            enabled: true,
          },
        });
      },
      async validateCustomSignUp(formData) {
        if (!formData.acknowledgement) {
          return {
            acknowledgement: "You must agree to the Terms & Conditions",
          };
        }
        return null;
      },
    };
    const authComponents = {
      Header() {
        const { tokens } = useTheme();

        return (
          <View textAlign="center" padding={tokens.space.large}>
            <Image maxWidth={"160px"} paddingTop={"100px"}
                   alt="CONSEN logo"
                   src={brandWhite}
            />
            <Heading
              padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
              level={3}
            >
              Welcome to {tenantConfig.bizName}
            </Heading>

          </View>
        );
      },

      Footer() {
        const { tokens } = useTheme();

        return (
          <View textAlign="center" padding={tokens.space.large}>
            <Text color={`${tokens.colors.neutral["80"]}`} fontSize={"0.7em"}>
              Powered by
              <Link href={"https://aceoar.io"} target="_blank">
                {" "} Ace|Oar Technologies {" "}
              </Link>
              &copy; All Rights Reserved
            </Text>
          </View>
        );
      },

      // SignIn: {
      //   Header() {
      //     const { tokens } = useTheme();
      //
      //     return (
      //       // <MDBox pb={3}>
      //       //   <Grid container alignItems="center">
      //       //     <Grid item xs={12} md={7}>
      //       <Heading
      //         padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
      //         level={3}
      //       >
      //         Sign in to your account
      //       </Heading>
      //       //     </Grid>
      //       //   </Grid>
      //       // </MDBox>
      //     );
      //   },
      //   Footer() {
      //     const { toResetPassword } = useAuthenticator();
      //
      //     return (
      //       <View textAlign="center">
      //         <Button
      //           fontWeight="normal"
      //           onClick={toResetPassword}
      //           size="small"
      //           variation="link"
      //         >
      //           Reset Password
      //         </Button>
      //       </View>
      //     );
      //   }
      // },

      SignUp: {
        // Header() {
        //   const { tokens } = useTheme();
        //
        //   return (
        //     <Heading
        //       padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
        //       level={3}
        //     >
        //       Create a new account
        //     </Heading>
        //   );
        // },
        FormFields() {
          const { validationErrors } = useAuthenticator();

          return (
            <>
              {/* Re-use default `Authenticator.SignUp.FormFields` */}
              <Authenticator.SignUp.FormFields />

              {/* Append & require Terms & Conditions field to sign up  */}
              <CheckboxField
                errorMessage={validationErrors.acknowledgement}
                hasError={!!validationErrors.acknowledgement}
                name="acknowledgement"
                value="yes"
                label="I agree with the Terms & Conditions"
              />
            </>
          );
        },
        // Footer() {
        //   const { toSignIn } = useAuthenticator();
        //
        //   return (
        //     <View textAlign="center">
        //       <Button
        //         fontWeight="normal"
        //         onClick={toSignIn}
        //         size="small"
        //         variation="link"
        //       >
        //         Back to Sign In
        //       </Button>
        //     </View>
        //   );
        // }
      },
      ConfirmSignUp: {
        Header() {
          const { tokens } = useTheme();
          return (
            <Heading
              padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
              level={3}
            >
              Enter Information:
            </Heading>
          );
        },
        // Footer() {
        //   return <Text>Footer Information</Text>;
        // }
      },
      // SetupTOTP: {
      //   Header() {
      //     const { tokens } = useTheme();
      //     return (
      //       <Heading
      //         padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
      //         level={3}
      //       >
      //         Enter Information:
      //       </Heading>
      //     );
      //   },
      //   Footer() {
      //     return <Text>Footer Information</Text>;
      //   }
      // },
      // ConfirmSignIn: {
      //   Header() {
      //     const { tokens } = useTheme();
      //     return (
      //       <Heading
      //         padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
      //         level={3}
      //       >
      //         Enter Information:
      //       </Heading>
      //     );
      //   },
      //   Footer() {
      //     return <Text>Footer Information</Text>;
      //   }
      // },
      // ResetPassword: {
      //   Header() {
      //     const { tokens } = useTheme();
      //     return (
      //       <Heading
      //         padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
      //         level={3}
      //       >
      //         Enter Information:
      //       </Heading>
      //     );
      //   },
      //   Footer() {
      //     return <Text>Footer Information</Text>;
      //   }
      // },
      // ConfirmResetPassword: {
      //   Header() {
      //     const { tokens } = useTheme();
      //     return (
      //       <Heading
      //         padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
      //         level={3}
      //       >
      //         Enter Information:
      //       </Heading>
      //     );
      //   },
      //   Footer() {
      //     return <Text>Footer Information</Text>;
      //   }
      // }
    };
    async function setUser() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        if (user) setSignedIn(true);
      } catch (err) {
        logger.debug(err);
      }
    }
    function checkUser() {
      setUser();

      return onAuthUIStateChange((authState) => {
        if (authState === AuthState.SignedIn) {
          setSignedIn(true);
        } else if (authState === AuthState.SignedOut) {
          setSignedIn(false);
        }
      });
    }

    React.useEffect(() => {
      appendToCognitoUserAgent("withAuthenticator");

      // checkUser returns an "unsubscribe" function to stop side-effects
      return checkUser();
    }, []);

    function signInWithSAML(event: any) {
      event.preventDefault();
      if (EnvConfig.samlProvider) {
        Auth.federatedSignIn({
          customProvider: EnvConfig.samlProvider,
        });
      } else {
        Auth.federatedSignIn();
      }
    }

    if (!signedIn) {
      return (
        // <ThemeProvider theme={theme}>

        <Authenticator components={authComponents} services={authServices}
                       formFields={formFields} hideSignUp={isHideSignUp}
        >
          {({ signOut, user }) => (
            <main>
              {/* <h1>Hello {user.username}</h1> */}
              <button onClick={signOut} type="button">
                Sign out
              </button>
            </main>
          )}
        </Authenticator>
        // </ThemeProvider>
      );
    }
    return <Component />;
  };
}

export default withSAMLAuthenticator;
