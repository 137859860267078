import React, { useEffect, memo } from "react";

// prop-types is library for typechecking of props
import validator from "@rjsf/validator-ajv8";
import Form from "@rjsf/mui";

import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React base styles
// @mui icons
// import EFormDisplayCard from "../EFormDisplayCard";
import MDButton from "components/MDButton";
import { eFormOptions } from "static-data/eform-data-static";

function EFormFieldsView({ schemaProperties }) {



  const renderItems1 = Object.keys(schemaProperties).map((columnName) => (
                      <Grid container>
                        <Grid item xs={4}>
                          <MDTypography variant="caption" color="text" fontWeight="regular">
                            {schemaProperties[columnName].title}
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4}>
                          <MDTypography variant="caption" color="text" fontWeight="regular">
                            {schemaProperties[columnName].type}
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4}>
                          <MDTypography variant="caption" color="text" fontWeight="regular">
                            {schemaProperties[columnName].format}
                          </MDTypography>
                        </Grid>
                      </Grid>

  ));

  return (
    <MDBox p={3}>
        {renderItems1}
    </MDBox>
  );
}

export default memo(EFormFieldsView);