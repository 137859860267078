import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Form, Formik, useFormik } from "formik";
import PropTypes from "prop-types";
import { Link, useNavigate, useParams } from "react-router-dom";


import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

import Autocomplete from "@mui/material/Autocomplete";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";


import { useTranslation } from "react-i18next";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { useSettings, useTopicAreas } from "hooks";
import Spinner from "views/custom-components/Spinner/Spinner";
import MDInput from "components/MDInput";

import { Category, Workflow } from "models";
import {
  useDashboard,
  useFullPreview,
  useChangeBackgroundColor,
  useWindowSize
} from "hooks";

import WorkflowService from "services/WorkflowService";
import UtilsService from "services/UtilsService";
import { priceTypeOptions, scopeOptions } from "../../../../../static-data/dropdownData";
import useWorkflowStore from "../../../../../store/workflow-store";


interface FormValues {
  shardId: string;
  workflowId: string;
  key: string;
  name: string;
  description: string;
  state: string;
  // content?: string;
  scope: string;
  categoryId: string;
  categoryName: string;
  images?: {};
  tags?: {};
}

interface PathParams {
  workflowKey: string;
}

function WorkflowAddEdit({ workflowData, onEditWorkflowCancel, onEditWorkflowUpdate }) {
  const navigate = useNavigate();
  const { workflowKey } = useParams<PathParams>();
  const { t } = useTranslation();

  const [creatingWorkflow, setCreatingWorkflow] = useState(false);

  const saveWorkflow= useWorkflowStore.use.save();
  const savingData= useWorkflowStore.use.saving();

  const [alertMessage, setAlertMessage] = useState("");
  const { topicareas, loading } = useTopicAreas();
  const sortedTopicAreas = topicareas.sort((a, b) =>
    a.name > b.name ? 1 : -1
  );
  const [initValue, setInitValue] = useState({
    shardId: workflowData?.shardId ,
    workflowId: workflowData?.workflowId ,
    key: workflowData?.key ,
    name: workflowData?.name || "",
    state: workflowData?.state || "Draft",
    // content: workflowData?.content || { text: "" },
    description: workflowData?.description || "",
    scope: workflowData?.scope || "Public",
    // tags: workflowData?.tags
  });
  const formik = useFormik({ initialValues: initValue });

  const form = {
    formId: "workflow-edit-form",
    formField: {
      name: {
        name: "name",
        label: t("NameUpperCase"),
        type: "text",
        errorMsg: "Name is required.",
        invalidMsg: "Name is invalid.",
      },
      scope: {
        name: "scope",
        label: t("Scope"),
        type: "text",
        errorMsg: "scope is required.",
        placeholder: t("WorkflowEdit.WorkflowTitleHint")
      },
      state: {
        name: "state",
        label:t("State"),
        type: "text",
        errorMsg: "state is required."
      },

      description: {
        name: "description",
        label: t("Description"),
        type: "text",
        errorMsg: "description is required.",
        placeholder: t("WorkflowEdit.WorkflowTitleHint")
      },

      // content: {
      //   name: "content",
      //   label: "Workflow Content",
      //   type: "text",
      //   placeholder: "Some description"
      // }
    }
  };
  const {
    formId,
    formField: { name,  description,
      state, scope,
      // content
    }
  } = form;
  const validations = Yup.object().shape({
    [name.name]: Yup.string().required(name.errorMsg).min(6, name.invalidMsg),
    [description.name]: Yup.string().required(description.errorMsg).min(10, description.invalidMsg),
    [scope.name]: Yup.string().required(scope.errorMsg).oneOf(
      ["Public", "Private", "Protected"],
      t("WorkflowEdit.ScopeRequired")
    ),
    [state.name]: Yup.string().required(state.errorMsg).oneOf(
      ["Draft", "Review", "Published", "Inactive"],
      t("WorkflowEdit.StateRequired")
    ),
  });

  useEffect(() => {
    // console.log("workflowData: " + JSON.stringify(workflowData));
    // setInitValue(workflowData);
    console.log("savingData" + savingData);

  }, [savingData]);


  // useChangeBackgroundColor();

  // if (!loading) {
  //   crumbs.push({
  //     label: t("WorkflowEdit.AddWorkflow"),
  //     url: "",
  //   });
  // }
  const onSubmit = async (values: FormValues) => {
    try {
      setCreatingWorkflow(true);
      values.categoryId='None';
      values.categoryName='None';
      await saveWorkflow(values);
      setCreatingWorkflow(false);
      if (onEditWorkflowUpdate) onEditWorkflowUpdate();
    } catch (err) {
      console.log(t("AddContentFailure"), err);
      setAlertMessage(t("AddContentFailure") + ": " + err?.message);
    }finally {
      setCreatingWorkflow(false);
    }
  };


  return (
    <MDBox m={3}>

      {creatingWorkflow || savingData ? (
        <Spinner
          label={t("WorkflowEdit.SavingData")}
        />
      ) : (
        <MDBox>
          <MDBox mb={2} >
            <MDTypography variant="h5">
              {workflowData && workflowData.shardId ? t("WorkflowEdit.EditWorkflow") : t("WorkflowEdit.AddWorkflow")}
              {workflowKey}
            </MDTypography>
          </MDBox>

          <MDBox>
            <MDBox>
              <Formik enableReinitialize={true}
                      initialValues={formik.initialValues}
                      validationSchema={validations}
                      onSubmit={onSubmit}
              >
                {({ values, errors, touched, isSubmitting, setFieldValue, success }) => (
                  <Form id={formId} autoComplete="off"
                  >
                    <Grid container spacing={3}>

                      <Grid item xs={12}>
                        <FormField
                          type={name.type}
                          label={name.label}
                          name={name.name}
                          value={values.name}
                          placeholder={"Title"}
                          error={errors.name && touched.name}
                          success={values.name.length > 5 && !errors.name}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormField
                          type={description.type}
                          label={description.label}
                          name={description.name}
                          value={values.description}
                          placeholder={"Title"}
                          error={errors.description && touched.description}
                          success={values.description.length > 5 && !errors.description}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Autocomplete
                          defaultValue="Public"
                          name="scope"
                          value={values.scope}
                          options={scopeOptions}
                          onChange={(e, value) => {
                            setFieldValue("scope",
                              value ? value.id : "Other"
                            );
                          }}

                          isOptionEqualToValue={(option, value) =>  option.id === value }
                          getOptionLabel={(option: Object | string) => option["name"] || option}
                          renderInput={(params) =>
                            <MDInput {...params} variant="standard"
                                     label={scope.label}
                                     hint={`${t("SelectExistingLeading")} ${scope.label}`}
                                     error={errors.scope && touched.scope}
                                     success={values.scope.length > 0 && !errors.scope}
                              // required
                            />}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {alertMessage && (
                          <MDAlert color="error" dismissible>
                            <MDTypography variant="body2" color="white">
                              {t("WorkflowEdit.AddWorkflowError")}
                            </MDTypography>
                          </MDAlert>
                        )}


                      </Grid>
                      <Grid item xs={12} md={12}>
                        <MDBox ml="auto" justifyContent="space-between"
                                width={{ xs: "100%", sm: "auto" }} display="flex"
                                alignItems={{ xs: "flex-end", sm: "center" }}
                                flexDirection={{ xs: "column", sm: "row" }}>
                          <MDButton variant="gradient" color="secondary" size="large"
                                    onClick={onEditWorkflowCancel}>
                            {t("Cancel")}
                          </MDButton>
                          <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="primary" size="large"
                          >
                            {workflowData && workflowData.workflowId ? t("Update") : t("Create")}
                          </MDButton>

                        </MDBox>
                      </Grid>

                    </Grid>

                  </Form>
                )}
              </Formik>
            </MDBox>

          </MDBox>
        </MDBox>
      )}
    </MDBox>
  );
}

export default WorkflowAddEdit;
