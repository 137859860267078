import React, {memo} from "react";
// import MarkdownRender from "./MarkdownRender";
import { Widget } from "models";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

interface Props {
  widget: Widget;
  hideTitle?: boolean;
}

function TextWidget(props: Props) {
  const { content, showTitle, name } = props.widget;

  return (
    <MDBox mt={props.hideTitle ? 2 : 1} mb={2}
      aria-label={name}
      tabIndex={-1}
    >
      {!props.hideTitle && showTitle &&
          <MDBox mb={1}>
            <MDTypography variant="h4" fontWeight="medium">
              {name}
            </MDTypography>
          </MDBox>
      }
      {/*<MarkdownRender*/}
      {/*  className="usa-prose textOrSummary"*/}
      {/*  source={content.text}*/}
      {/*/>*/}
      <MDBox mt={0} mb={2}>
        <MDTypography variant="body2" fontWeight="regular" color="text">
          {content.text}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

export default memo(TextWidget);
