import React, { ComponentType, ComponentPropsWithRef, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Auth, appendToCognitoUserAgent } from "@aws-amplify/auth";

import { onAuthUIStateChange, AuthState } from "@aws-amplify/ui-components";
import { Logger } from "@aws-amplify/core";

import logoImg from "logo.svg";
import Link from "@mui/material/Link";
import EnvConfig from "../services/EnvConfig";
import { useMaterialUIController } from "context";
import SubmissionProvider from "context/SubmissionProvider";
import { usePublicTenantConfig } from "../../hooks";

const logger = new Logger("withAuthenticator");


export function withSubmission(
  Component: ComponentType,
  submissionProps?: ComponentPropsWithRef
) {
  return function (props) {
    const [signedIn, setSignedIn] = React.useState(false);
    const { t } = useTranslation();
    const [controller, dispatch] = useMaterialUIController();
    const { tenantConfig, reloadTenantConfig, loadingTenantConfig } = usePublicTenantConfig();


    const {
      darkMode,
    } = controller;

    async function setUser() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        if (user) setSignedIn(true);
      } catch (err) {
        logger.debug(err);
      }
    }
    function checkUser() {
      setUser();

      return onAuthUIStateChange((authState) => {
        if (authState === AuthState.SignedIn) {
          setSignedIn(true);
        } else if (authState === AuthState.SignedOut) {
          setSignedIn(false);
        }
      });
    }

    React.useEffect(() => {
      appendToCognitoUserAgent("withAuthenticator");

      // checkUser returns an "unsubscribe" function to stop side-effects
      return checkUser();
    }, []);




      return (
        <SubmissionProvider>
        {/*<main>*/}
        {/*      /!* <h1>Hello {user.username}</h1> *!/*/}
        {/*      <button onClick={signOut} type="button">*/}
        {/*        Sign out*/}
        {/*      </button>*/}
        {/*    </main>*/}
          <Component/>
        </SubmissionProvider>
      );

  };
}

export default withSubmission;
