import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Form, Formik, useFormik } from "formik";
import PropTypes from "prop-types";
import { Link, useNavigate, useParams } from "react-router-dom";


import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

import Autocomplete from "@mui/material/Autocomplete";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";


import { useTranslation } from "react-i18next";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { useSettings, useTopicAreas } from "hooks";
import Spinner from "views/custom-components/Spinner/Spinner";
import MDInput from "components/MDInput";

import { Category, Application } from "models";
import {
  useDashboard,
  useFullPreview,
  useChangeBackgroundColor,
  useWindowSize
} from "hooks";

import ApplicationService from "services/ApplicationService";
import UtilsService from "services/UtilsService";
import { priceTypeOptions, scopeOptions } from "static-data/dropdownData";
import useApplicationStore from "store/application-store";


interface FormValues {
  key: string;
  name: string;
  description: string;
  state: string;
  // content?: string;
  scope: string;
  tags?: [];
}

interface PathParams {
  applicationKey: string;
}

function ApplicationAddEdit({ applicationData, onEditApplicationCancel, onEditApplicationUpdate }) {
  const navigate = useNavigate();
  const { applicationKey } = useParams<PathParams>();
  const { t } = useTranslation();

  // const [creatingApplication, setCreatingApplication] = useState(false);

  const saveApplication= useApplicationStore.use.save();
  const savingData= useApplicationStore.use.saving();

  const [alertMessage, setAlertMessage] = useState("");
  // const { topicareas, loading } = useTopicAreas();
  // const sortedTopicAreas = topicareas.sort((a, b) =>
  //   a.name > b.name ? 1 : -1
  // );
  const [initValue, setInitValue] = useState({
    name:  "",
    // state:  "Draft",
    description:  "",
    // scope:  "Public",
    ...applicationData
    // tags: applicationData?.tags
  });
  const formik = useFormik({ initialValues: initValue });

  const form = {
    formId: "application-edit-form",
    formField: {
      name: {
        name: "name",
        label: t("NameUpperCase"),
        type: "text",
        errorMsg: "Name is required.",
        invalidMsg: "Name is invalid.",
      },
      // scope: {
      //   name: "scope",
      //   label: t("Scope"),
      //   type: "text",
      //   errorMsg: "scope is required.",
      //   placeholder: t("ApplicationEdit.ApplicationTitleHint")
      // },
      // state: {
      //   name: "state",
      //   label:t("State"),
      //   type: "text",
      //   errorMsg: "state is required."
      // },

      description: {
        name: "description",
        label: t("Description"),
        type: "text",
        errorMsg: "description is required.",
        placeholder: t("ApplicationEdit.ApplicationTitleHint")
      },


    }
  };
  const {
    formId,
    formField: { name,  description,
      // state, scope,
      // content
    }
  } = form;
  const validations = Yup.object().shape({
    [name.name]: Yup.string().required(name.errorMsg).min(6, name.invalidMsg),
    // [description.name]: Yup.string().required(description.errorMsg).min(10, description.invalidMsg),
    // [scope.name]: Yup.string().required(scope.errorMsg).oneOf(
    //   ["Public", "Private", "Protected"],
    //   t("ApplicationEdit.ScopeRequired")
    // ),
    // [state.name]: Yup.string().required(state.errorMsg).oneOf(
    //   ["Draft", "Review", "Published", "Inactive"],
    //   t("ApplicationEdit.StateRequired")
    // ),
  });

  useEffect(() => {
    // console.log("applicationData: " + JSON.stringify(applicationData));
    // setInitValue(applicationData);
    console.log("savingData" + savingData);

  }, [savingData]);


  // useChangeBackgroundColor();

  // if (!loading) {
  //   crumbs.push({
  //     label: t("ApplicationEdit.AddApplication"),
  //     url: "",
  //   });
  // }
  const onSubmit = async (values: FormValues) => {
    console.log("onSubmit values " + JSON.stringify(values));
    try {
      await saveApplication(values);
      if (onEditApplicationUpdate) onEditApplicationUpdate(true);
    } catch (err) {
      console.log(t("AddContentFailure"), err);
      setAlertMessage(t("AddContentFailure") + ": " + err?.message);
    }finally {
      // setCreatingApplication(false);
    }
  };


  return (
    <MDBox m={3}>


        <MDBox>
          <MDBox mb={2} >
            <MDTypography variant="h5">
              {applicationData && applicationData.shardId ? t("ApplicationEdit.EditApplication") : t("ApplicationEdit.AddApplication")}
              {applicationKey}
            </MDTypography>
          </MDBox>

          <MDBox>
            <MDBox>
              <Formik enableReinitialize={true}
                      initialValues={formik.initialValues}
                      validationSchema={validations}
                      onSubmit={onSubmit}
                      initialTouched={{
                        name: true,
                      }}
                      validateOnMount
              >
                {({ values, errors, touched, isSubmitting, setFieldValue,status,
                  dirty, initialTouched, isValid}) => (
                  <Form id={formId} autoComplete="off"
                  >
                    <Grid container spacing={3}>

                      <Grid item xs={12}>
                        <FormField
                          type={name.type}
                          label={name.label}
                          name={name.name}
                          value={values.name}
                          placeholder={"Title"}
                          error={errors.name && touched.name}
                          success={values.name && values.name.length > 5 && !errors.name}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormField
                          type={description.type}
                          label={description.label}
                          name={description.name}
                          value={values.description}
                          placeholder={"Title"}
                          error={errors.description && touched.description}
                          success={values.description.length > 5 && !errors.description}
                        />
                      </Grid>
                      {/*<Grid item xs={12} sm={12}>*/}
                      {/*  <Autocomplete*/}
                      {/*    defaultValue="Public"*/}
                      {/*    name="scope"*/}
                      {/*    value={values.scope}*/}
                      {/*    options={scopeOptions}*/}
                      {/*    onChange={(e, value) => {*/}
                      {/*      setFieldValue("scope",*/}
                      {/*        value ? value.id : "Other"*/}
                      {/*      );*/}
                      {/*    }}*/}

                      {/*    isOptionEqualToValue={(option, value) =>  option.id === value }*/}
                      {/*    getOptionLabel={(option: Object | string) => option["name"] || option}*/}
                      {/*    renderInput={(params) =>*/}
                      {/*      <MDInput {...params} variant="standard"*/}
                      {/*               label={scope.label}*/}
                      {/*               hint={`${t("SelectExistingLeading")} ${scope.label}`}*/}
                      {/*               error={errors.scope && touched.scope}*/}
                      {/*               success={values.scope.length > 0 && !errors.scope}*/}
                      {/*        // required*/}
                      {/*      />}*/}
                      {/*  />*/}
                      {/*</Grid>*/}
                      <Grid item xs={12}>
                        {alertMessage && (
                          <MDAlert color="error" dismissible>
                            <MDTypography variant="body2" color="white">
                              {t("ApplicationEdit.AddApplicationError")}
                            </MDTypography>
                          </MDAlert>
                        )}


                      </Grid>
                      <Grid item xs={12} md={12}>
                        <MDBox ml="auto" justifyContent="space-between"
                                width={{ xs: "100%", sm: "auto" }} display="flex"
                                alignItems={{ xs: "flex-end", sm: "center" }}
                                flexDirection={{ xs: "column", sm: "row" }}>
                          <MDButton variant="gradient" color="secondary" size="large"
                                    onClick={onEditApplicationCancel}>
                            {t("Cancel")}
                          </MDButton>
                          <MDButton
                            disabled={(errors && errors.name) || savingData}
                            type="submit"
                            variant="gradient"
                            color="primary" size="large"
                          >
                            {applicationData && applicationData.key ? t("Update") : t("Create")}
                          </MDButton>

                        </MDBox>
                        ---<br/>
                        errors: {JSON.stringify(errors)} <br/>
                        ---
                        isValid: {JSON.stringify(isValid)}<br/>
                        ---
                        dirty: {JSON.stringify(dirty)}<br/>
                        ---
                        touched:{JSON.stringify(touched)}<br/>
                        ---
                        {JSON.stringify(isValid)}<br/>
                      </Grid>

                    </Grid>

                  </Form>
                )}
              </Formik>
            </MDBox>

          </MDBox>
        </MDBox>

    </MDBox>
  );
}

export default ApplicationAddEdit;
