import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ELK from "elkjs/lib/elk.bundled.js";

import { useNavigate, useParams } from "react-router-dom";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";


import DashboardLayout from "LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Project page components
// import Header from "./components/Header";
// Images
import { Workflow } from "models";
import { useTranslation } from "react-i18next";
import { defaultWFEdges, defaultWFNodes } from "../workflow/wf-data/default-workflow-01";

import ReactFlow, {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Background,
  Controls,
  getConnectedEdges,
  getIncomers,
  getOutgoers,
  MiniMap,
  Panel,
  ReactFlowProvider,
  updateEdge,
  useEdgesState,
  useNodesState,
  useReactFlow
} from "reactflow";

// import WFToolbar from "./components/wf-toolbar";
import WFToolbar from "./components/workflow-toolbar";

import "./wf-styles.css";
import WorkflowService from "../../../services/WorkflowService";
import { workflowNodeComponentTypes } from "../workflow/wf-data/wf-node-component-types";
import useWorkflowStore from "store/workflow-store";
import useApplicationStore from "store/application-store";
import CustomDialog from "../../custom-components/CustomDialog";
import WorkflowStepsDisplay from "../workflow/component/WorkflowStepsDisplay";
import { workflowNodeTypes } from "static-data/wf-node-types";
import Spinner from "../../custom-components/Spinner/Spinner";
import MDTypography from "../../../components/MDTypography";
import ButtonGroup from "@mui/material/ButtonGroup";
import ActionCard from "views/custom-components/ActionCard";

const elk = new ELK();

interface Props {
  workflow: Workflow;
  reloadWorkflow: Function;
}

interface PathParams {
  workflowId: string;
}

const useLayoutedElements = () => {
  const { getNodes, setNodes, getEdges, fitView } = useReactFlow();
  const defaultOptions = {
    "elk.algorithm": "layered",
    "elk.layered.spacing.nodeNodeBetweenLayers": 100,
    "elk.spacing.nodeNode": 80
  };

  const getLayoutedElements = useCallback((options) => {
    const layoutOptions = { ...defaultOptions, ...options };
    const graph = {
      id: "root",
      layoutOptions: layoutOptions,
      children: getNodes(),
      edges: getEdges()
    };

    elk.layout(graph).then(({ children }) => {
      // By mutating the children in-place we saves ourselves from creating a
      // needless copy of the nodes array.
      children.forEach((node) => {
        node.position = { x: node.x, y: node.y };
      });

      setNodes(children);
      window.requestAnimationFrame(() => {
        fitView();
      });
    });
  }, []);

  return { getLayoutedElements };
};

function AdminWorkflowEditFlow(props: Props) {

  //
  // const {workflow, loading:loadingWF} = useWorkflow();
  const { t } = useTranslation();
  const navigate = useNavigate();
  let { workflowId } = useParams<PathParams>();
  const [workflow, setWorkflow] = useState(props.workflow);
  const [loading, setLoading] = useState(false);
  const [savingFlow, setSavingFlow] = useState(false);

  const [calcWFSteps, setCalcWFSteps] = useState([]);

  const initApplStore = useApplicationStore.use.initStore();
  // const applications = useApplicationStore.use.applications();
  // const loadingAppl = useApplicationStore.use.loading();

  const initStore = useWorkflowStore.use.initStore();
  const saveWorkflow = useWorkflowStore.use.save();

  const [applOptions, setApplOptions] = useState([
    { name: "No Default", key: "NO_DEF" }
  ]);

  const [isOpenEditModal, setIsOpenEditModal] = useState(false);


  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const edgeUpdateSuccessful = useRef(true);
  const { fitView } = useReactFlow();


  const snapGrid = [20, 20];
  const connectionLineStyle = { stroke: "#78f" };
  const defaultViewport = { x: 0, y: 0, zoom: 1.25 };
  const [nodes, setNodes] = useNodesState(defaultWFNodes);
  const [edges, setEdges] = useEdgesState(defaultWFEdges);
  const { getLayoutedElements } = useLayoutedElements();

  const nodeTypes = useMemo(() => (workflowNodeComponentTypes), []);


  const fetchData = useCallback(async () => {
    if (workflowId) {
      setLoading(true);
      const wfData = await WorkflowService.fetchWorkflowById(workflowId);
      setWorkflow(wfData);
      if (wfData && wfData.content && wfData.content.nodes && wfData.content.edges) {
        // console.log("fetchData : settingNodes - " + loadingAppl);
        setNodes(wfData.content.nodes);
        setEdges(wfData.content.edges);
        fitView();
      }
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    initApplStore();
  }, [initApplStore]);

  useEffect(() => {
    initStore();
  }, [initStore]);

  useEffect(() => {
    if (props.workflow && props.workflow.key && workflowId !== props.workflow.key) {
      workflowId = props.workflow.key;
      fetchData();
    }
  }, [props.workflow]);

  useEffect(() => {
    if (!props.workflow) {
      fetchData();
    }
  }, [workflowId]);

  useEffect(() => {
    if (!loading && workflow && nodes && edges) {
      fitView();
    }
  }, [loading]);



  // HIDE NODES/EDGES
  // useEffect(() => {
  //   setNodes((nds) => nds.map(hide(hidden)));
  //   setEdges((eds) => eds.map(hide(hidden)));
  // }, [hidden]);


  // useEffect(() => {
  //   console.log("loadingAppl: " + loadingAppl);
  //   // console.log("applications: " + JSON.stringify(applications));
  //   // setInitValue(offeringData);
  //   if (!loading && applications && applications.length>0){
  //     const apOpts =[{name: "No Default", key:"NO_DEF"}];
  //     applications.filter(app=> app.publishedBy!==undefined && app.name!=="")
  //       .map(ap=>apOpts.push({name: ap.name, key:ap.key}))
  //     // const nv = applications.filter(app=> app.publishedBy!==undefined);
  //     // const nv2= nv.map(ap=>apOpts.push({name: ap.name, key:ap.key}))
  //     setApplOptions(apOpts);
  //     // console.log("setting appSelectOptions 1: " + JSON.stringify(apOpts));
  //     setNodes((nds) =>
  //       nds.map((node) => {
  //         if (node.type !== 'applicationNode') {
  //           return node;
  //         }
  //         console.log('Setting appSelectOptions');
  //         return {
  //           ...node,
  //           data: {
  //             ...node.data,
  //             label: 'Application',
  //             dataOptions:{apps:apOpts}
  //           }
  //         };
  //       })
  //     );
  //   }
  //
  // }, [loadingAppl ]);

  // useEffect(() => {
  //   console.log('useEffect nodes,loadingAppl');
  //   console.log("setting appSelectOptions 1: " + JSON.stringify(applOptions));
  //   if (loadingAppl || !nodes) return;
  //   // if (applOptions.length===1) return;
  //   console.log("setting appSelectOptions 1: " + JSON.stringify(applOptions));
  //   setNodes((nds) =>
  //     nds.map((node) => {
  //       if (node.type !== 'applicationNode') {
  //         return node;
  //       }
  //       console.log('Setting appSelectOptions');
  //       return {
  //         ...node,
  //         data: {
  //           ...node.data,
  //           appSelectOptions: applOptions,
  //         },
  //       };
  //     })
  //   );
  //
  // }, [loadingAppl]);

  // we are using a bit of a shortcut here to adjust the edge type
  // this could also be done with a custom edge for example
  // const edgesWithUpdatedTypes = edges.map((edge) => {
  //   if (edge.sourceHandle) {
  //     const edgeType = nodes.find((node) => node.type === 'tnc').data.selects[edge.sourceHandle];
  //     edge.type = edgeType;
  //   }
  //
  //   return edge;
  // });

  const openEditModal = () => {
    // validateSteps().then(wfs=>setIsOpenEditModal(true));
    validateSteps().then(wfs => setIsOpenEditModal(true));


  };

  const closeEditModal = async () => {
    setIsOpenEditModal(false);
  };

  // const onInit = (reactFlowInstance) => console.log("flow loaded:", reactFlowInstance);

  let id = 0;
  const getId = (droppedNodeType) => `${droppedNodeType}_${nodes.length++}`;
  const getNodeDetails = (nodeType) => workflowNodeTypes.find(nt => nt.nodeType === nodeType);


  const validateSteps = async () => {
    const connectedEdges = getConnectedEdges(nodes, edges);
    const effectiveNodes = [];
    const startNodes = new Set<string>;
    const endNodes = new Set<string>;
    connectedEdges.reduce((effNs, thisEdg) => {
      if (!effNs.includes(thisEdg.source)) {
        effNs.push(thisEdg.source);
      }
      if (!effNs.includes(thisEdg.target)) {
        effNs.push(thisEdg.target);
      }
      startNodes.add(thisEdg.source);
      endNodes.add(thisEdg.target);

      return effNs;
    }, effectiveNodes);

    let cp_startNodes = new Set<string>(startNodes);
    // Object.assign(cp_startNodes, startNodes)
    startNodes.forEach((sn, _, snds) => {
      if (endNodes.has(sn)) snds.delete(sn);
    });

    endNodes.forEach((en, _, ends) => {
      if (cp_startNodes.has(en)) ends.delete(en);
    });

    const warnings = [];
    let wfs = [];

    startNodes.forEach(startPathN => {
      let seqCount = 0;
      let sn = startPathN;
      const steps = [];
      do {
        const src = nodes.find(n => n.id === sn) || {};
        const edg = edges.find(e => e.source === sn) || {};
        const tar = nodes.find(n => n && n.id === edg.target);

        let step = {
          sourceId: src.id,
          sourceType: src.type,
          seq: seqCount++,
          sourceData: src.data ? src.data.value : {},
          targetId: tar?.id,
          targetType: tar?.type,
          targetData: tar?.data ? tar.data?.value : {}
        };

        steps.push(step);
        sn = tar?.id;
      } while (sn);

      //console.log(" ******** steps for Path " + startPathN, steps);
      // wfs[sn] = steps;
      wfs.push({
        path: startPathN,
        steps: steps
      });

    });
    //console.log(wfs);
    setCalcWFSteps(wfs);
    return wfs;
  };

  const onConnect = useCallback((params) => {
    setEdges((eds) => addEdge({ ...params, animated: true, style: { stroke: "#55f" } }, eds));
    //console.log("onConnect : " + JSON.stringify(edges));
    // Calc steps
    validateSteps();
    //console.log(getConnectedEdges(nodes, edges));
  }, []);

  const onNodesChange = useCallback((changes) => {
    setNodes((nds) => applyNodeChanges(changes, nds));
    // console.log("onNodesChange : " + JSON.stringify(nodes));
    // validateSteps();
  }, [setNodes]);

  const onNodeClick = (event, node) => {
    // setNodes((nds) => applyNodeChanges(changes, nds));
    // console.log("onNodesClick : " + JSON.stringify(event));
    //console.log("onNodesClick : " + JSON.stringify(node));
    // validateSteps();
  };

  const onEdgesChange = useCallback((changes) => {
    setEdges((eds) => applyEdgeChanges(changes, eds));
    //console.log("onEdgesChange : " + JSON.stringify(edges));
  }, [setEdges]);

  // gets called after end of edge gets dragged to another source or target
  const onEdgeUpdate = useCallback((oldEdge, newConnection) => {
    edgeUpdateSuccessful.current = true;
    setEdges((els) => updateEdge(oldEdge, newConnection, els));
    //console.log("onEdgeUpdate : " + JSON.stringify(oldEdge));
  }, []);
  // const onEdgeUpdate = useCallback(
  //   (oldEdge, newConnection) => setEdges((els) => updateEdge(oldEdge, newConnection, els)),
  //   []
  // );
  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false;
    //console.log("onEdgeUpdateStart : ");
  }, []);


  const onEdgeUpdateEnd = useCallback((_, edge) => {
    if (!edgeUpdateSuccessful.current) {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id));
    }
    //console.log("onEdgeUpdateEnd : " + JSON.stringify(edge));
    edgeUpdateSuccessful.current = true;
    // console.log(getOutgoers(nodes[0], nodes, edges));
    // validateSteps();
  }, []);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
    // console.log(getConnectedEdges(nodes,edges));
    // console.log ("onDragOver : " + JSON.stringify(event.target));
  }, []);

  const onNodesDelete = useCallback(
    (deleted) => {
      setEdges(
        deleted.reduce((acc, node) => {
          const incomers = getIncomers(node, nodes, edges);
          const outgoers = getOutgoers(node, nodes, edges);
          const connectedEdges = getConnectedEdges([node], edges);

          const remainingEdges = acc.filter((edge) => !connectedEdges.includes(edge));

          const createdEdges = incomers.flatMap(({ id: source }) =>
            outgoers.map(({ id: target }) => ({ id: `${source}->${target}`, source, target }))
          );

          return [...remainingEdges, ...createdEdges];
        }, edges)
      );
    },
    [nodes, edges]
  );


  const onDrop = useCallback((event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const droppedNodeType = event.dataTransfer.getData("application/reactflow");
      // check if the dropped element is valid
      if (typeof droppedNodeType === "undefined" || !droppedNodeType) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top
      });
      let nodeDetail = getNodeDetails(droppedNodeType);

      //console.log("nodeDetail - " + JSON.stringify(nodeDetail));
      //console.log("droppedNodeType - " + JSON.stringify(droppedNodeType));

      // if (droppedNodeType==="applicationNode"){
      //   nodeDetail = {
      //     ...nodeDetail,
      //     dataOptions:{apps:applOptions}
      //   }
      //
      // }

      const newNode = {
        id: getId(droppedNodeType),
        type: droppedNodeType,
        position,
        data: { ...nodeDetail }
        // data: { label: `${droppedNodeType}` },
      };

      setNodes((nds) => nds.concat(newNode));
      // console.log ("onDrop : " + JSON.stringify();
      // console.log(getOutgoers(nodes[0], nodes, edges));

    },
    [reactFlowInstance]
  );

  const handleUpdate = async () => {
    validateSteps().then(async (wfs) => {
      if (!wfs || wfs.length == 0) {
        //console.log("Steps are missing / failed to generate");
        return;
      }
      if (wfs.length > 1) {
        //console.log("Multi-path steps... not supported");
        return;
      }
      setSavingFlow(true);
      try {
        const workflowData = {
          ...workflow,
          content: {
            nodes: nodes,
            edges: edges
          },
          // settings: {
          //   ...workflow.settings,
          //   steps: wfs[0].steps
          // },
          steps: wfs[0].steps

        };
        // console.log(JSON.stringify(workflowData));
        // console.log(JSON.stringify(values));
        await saveWorkflow(workflowData);
        setIsOpenEditModal(false);
      } finally {
        setSavingFlow(false);
      }
    });

    // await WorkflowService.editWorkflow(workflowId, workflowData);
  };

  return (
    <MDBox mt={12}>

      {/*<AdminHeader title={`Edit Flow : ${workflow ? workflow.name : ""}`}*/}
      {/*             icon="airline_stops_outlined"*/}
      {/*             description={workflow ? workflow.description : ""}*/}
      {/*             loading={loading} isMini>*/}

      {/*  /!*<MDBox mr={1}>*!/*/}
      {/*  /!*  <MDButton variant="outlined" color="success"*!/*/}
      {/*  /!*            onClick={openEditModal}>*!/*/}
      {/*  /!*    validate*!/*/}
      {/*  /!*  </MDButton>*!/*/}
      {/*  /!*</MDBox>*!/*/}
      {/*  {savingFlow && <LinearLoading />}*/}
      {/*  <MDBox mr={2}>*/}

      {/*    <MDButton variant="contained" color="primary" disabled={savingFlow}*/}
      {/*              onClick={openEditModal}>*/}
      {/*      Validate And Save*/}
      {/*    </MDButton>*/}
      {/*  </MDBox>*/}
      {/*</AdminHeader>*/}

      <CustomDialog onClose={closeEditModal}
                    open={isOpenEditModal} maxWidth={"sm"}
                    aria-labelledby="customized-dialog-title">
        <DialogContent>

          <MDBox>
            {savingFlow ?
              <Spinner label={"Saving ..."} />
              :
              <MDBox>
                {calcWFSteps && calcWFSteps.length === 1 ? calcWFSteps.map((wfPath) =>
                    <WorkflowStepsDisplay wfSteps={wfPath.steps} key={wfPath.path} />
                  )
                  :
                  <MDBox>
                    Invalid Or Not supported Steps
                  </MDBox>
                }
              </MDBox>
            }
          </MDBox>

          {!savingFlow &&
            <MDBox ml="auto" justifyContent="space-between"
                   width={{ xs: "100%", sm: "auto" }}
                   display="flex"
                   alignItems={{ xs: "flex-end", sm: "center" }}
                   flexDirection={{ xs: "column", sm: "row" }}>
              <MDButton variant="outlined" color="secondary"
                        onClick={closeEditModal}>
                {t("Cancel")}
              </MDButton>
              <MDButton onClick={handleUpdate}
                        disabled={savingFlow || !calcWFSteps || calcWFSteps.length !== 1}
                        type="submit"
                        variant="gradient"
                        color="primary"
              >
                Confirm & Save Workflow
              </MDButton>
            </MDBox>}
        </DialogContent>
      </CustomDialog>

      <MDBox mb={3}>

      </MDBox>

      <MDBox mb={3}>
        {/*<Card>*/}


        <MDBox className="dndflow"
               display="flex"
               flexDirection="row"

        >
          <WFToolbar />

          <MDBox p={2} mb={2} lineHeight={1} className="reactflow-wrapper" ref={reactFlowWrapper}
                 sx={({
                        borders: { borderRadius, borderWidth, borderColor },
                        functions: { pxToRem },
                        palette: { white },
                        transitions
                      }) => ({
                   borderRadius: borderRadius.lg,
                   border: `${borderWidth[1]} solid ${borderColor}`,
                   backgroundColor: white.main
                 })}
          >
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onInit={setReactFlowInstance}
              // onInit={onInit}
              onNodesChange={onNodesChange}
              onNodeClick={onNodeClick}
              onEdgesChange={onEdgesChange}
              onEdgeUpdate={onEdgeUpdate}
              onEdgeUpdateStart={onEdgeUpdateStart}
              onEdgeUpdateEnd={onEdgeUpdateEnd}
              onConnect={onConnect}
              onNodesDelete={onNodesDelete}
              nodeTypes={nodeTypes}
              connectionLineStyle={connectionLineStyle}
              snapToGrid={true}
              snapGrid={snapGrid}
              defaultViewport={defaultViewport}
              onDrop={onDrop}
              onDragOver={onDragOver}
              fitView
              attributionPosition="bottom-right"


            >
              <MDBox p={3} lineHeight={1} minHeight="60vh">
                <MiniMap
                  nodeStrokeColor={(n) => {
                    if (n.type === "cardNode") return "#0041d0";
                    if (n.type === "applicationNode") return "#0041d0";
                    if (n.type === "verifyNode") return "#ff0072";
                  }}
                  nodeColor={(n) => {
                    if (n.type === "textNode") return "#272e3d";
                    return "#ccf";
                  }}
                />
                <Controls />
                <Background />
              </MDBox>


              <Panel position="top-left">
                <MDBox ml="auto" justifyContent="space-between"
                       width={{ xs: "100%", sm: "auto" }}
                       display="flex"
                       alignItems={{ xs: "flex-end", sm: "center" }}
                       flexDirection={{ xs: "column", sm: "row" }}>
                  <MDTypography variant="button" fontWeight="medium">
                    {`Edit Flow : ${workflow ? workflow.name : ""}`}
                  </MDTypography>

                </MDBox>

              </Panel>
              <Panel position="top-right">
                <MDBox ml="auto" justifyContent="space-between"
                       width={{ xs: "100%", sm: "auto" }}
                       display="flex"
                       alignItems={{ xs: "flex-end", sm: "center" }}
                       flexDirection={{ xs: "column", sm: "row" }}>
                  {/*<MDTypography variant="button" fontWeight="regular">*/}
                  {/*  {`Edit ${workflow ? workflow.name : ""}`}*/}
                  {/*</MDTypography>*/}
                  <MDBox ml={2}>

                    <MDButton variant="contained" color="primary" disabled={savingFlow}
                              onClick={openEditModal}>
                      Validate And Save
                    </MDButton>
                  </MDBox>
                </MDBox>

              </Panel>

              <Panel position="top-center">
                <MDBox ml="auto" justifyContent="space-between"
                       width={{ xs: "50%", sm: "auto" }}
                       display="flex"
                       alignItems={{ xs: "flex-end", sm: "center" }}
                       flexDirection={{ xs: "column", sm: "row" }}>
                  <MDTypography variant="button" fontWeight="regular">
                    Layout
                  </MDTypography>
                  <MDBox ml={{ xs: 0, sm: 1 }}>
                    <ButtonGroup size="small" variant={"text"} aria-label="small button group">
                      <MDButton variant="outlined" color="secondary" size="small"
                                onClick={() =>
                                  getLayoutedElements({ "elk.algorithm": "layered", "elk.direction": "DOWN" })
                                }
                      >
                        vertical
                      </MDButton>
                      <MDButton variant="outlined" color="secondary" size="small"
                                onClick={() =>
                                  getLayoutedElements({ "elk.algorithm": "layered", "elk.direction": "RIGHT" })
                                }
                      >
                        horizontal
                      </MDButton>
                      {/*<MDButton variant="outlined" color="secondary" size="small"*/}
                      {/*          onClick={() =>*/}
                      {/*            getLayoutedElements({*/}
                      {/*              "elk.algorithm": "org.eclipse.elk.radial"*/}
                      {/*            })*/}
                      {/*          }*/}
                      {/*>*/}
                      {/*  radial*/}
                      {/*</MDButton>*/}
                      {/*<MDButton variant="outlined" color="secondary" size="small"*/}
                      {/*          onClick={() =>*/}
                      {/*            getLayoutedElements({*/}
                      {/*              "elk.algorithm": "org.eclipse.elk.force"*/}
                      {/*            })*/}
                      {/*          }*/}
                      {/*>*/}
                      {/*  force*/}
                      {/*</MDButton>*/}
                    </ButtonGroup>

                  </MDBox>
                  <MDBox ml={{ xs: 0, sm: 2 }}>
                  <MDButton variant="outlined" color="secondary" size="small"
                            onClick={
                              fitView
                            }
                  >
                    Fit to screen
                  </MDButton>
                  </MDBox>
                </MDBox>
              </Panel>



            </ReactFlow>
          </MDBox>
        </MDBox>

        {/*</Card>*/}
        {/*<MDBox>*/}
        {/*  /!*{JSON.stringify(nodes)}*!/*/}
        {/*  /!*{JSON.stringify(workflow)}*!/*/}
        {/*  ...............*/}
        {/*  /!*{JSON.stringify(edges)}*!/*/}


        {/*</MDBox>*/}
      </MDBox>

      {/*<MDBox>*/}
      {/*  <MDBox mt={4}>*/}
      {/*    <MDBox mb={3} mx={1}>*/}
      {/*      <MDTypography variant="body1" fontWeight="bold">*/}
      {/*        Advanced Configurations*/}
      {/*      </MDTypography>*/}
      {/*    </MDBox>*/}
      {/*    <Grid container spacing={3}>*/}
      {/*      <Grid item xs={12} md={6} lg={6}>*/}
      {/*        <MDBox mb={3}>*/}
      {/*              {calcWFSteps && calcWFSteps.length > 0 && calcWFSteps.map((wfPath) =>*/}
      {/*                (<MDBox key={wfPath.path}>*/}

      {/*                  <WorkflowStepsDisplay wfSteps={wfPath.steps} />*/}

      {/*                </MDBox>)*/}
      {/*              )}*/}
      {/*        </MDBox>*/}
      {/*      </Grid>*/}

      {/*      <Grid item xs={12} md={6} lg={6}>*/}
      {/*        <MDBox mb={3}>*/}
      {/*          <ActionCard*/}
      {/*            title="Invite & Communications "*/}
      {/*            content="Invite new clients or communicate with existing ones." >*/}
      {/*            <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>*/}
      {/*              <Link to={"admin/workflow"}>*/}
      {/*                <MDButton variant="contained" color={"success"}>Communications</MDButton>*/}
      {/*              </Link>*/}
      {/*            </MDBox>*/}
      {/*          </ActionCard>*/}
      {/*        </MDBox>*/}
      {/*      </Grid>*/}

      {/*    </Grid>*/}
      {/*  </MDBox>*/}
      {/*</MDBox>*/}


      {/*<MDBox mr={2}>*/}

      {/*  <MDBox>*/}
      {/*    <MDButton onClick={validateSteps} variant="contained" color={"success"}>*/}
      {/*      Validate*/}
      {/*    </MDButton>*/}

      {/*    {calcWFSteps && calcWFSteps.length > 0 && calcWFSteps.map((wfPath) =>*/}
      {/*      (<MDBox key={wfPath.path}>*/}

      {/*        <WorkflowStepsDisplay wfSteps={wfPath.steps} />*/}
      {/*        <hr />*/}
      {/*      </MDBox>)*/}
      {/*    )}*/}


      {/*  </MDBox>*/}
      {/*</MDBox>*/}
    </MDBox>
  );
}

function AdminWorkflowEdit(props: Props) {
  return (
    <DashboardLayout>
      <MDBox width="calc(100% - 48px)" position="absolute" top="1.75rem">
        <DashboardNavbar absolute />
      </MDBox>
      <ReactFlowProvider>
        <AdminWorkflowEditFlow {...props} />
      </ReactFlowProvider>
    </DashboardLayout>
  );
}

export default AdminWorkflowEdit;
