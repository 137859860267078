import { API } from "aws-amplify";
import Auth from "@aws-amplify/auth";

export const apiName = "BackendApi";
export const regApiGatewayUrl = "regApiGatewayUrl";
export async function getAuthToken() {
  try {
  const session = await Auth.currentSession();
  const idToken = session.getIdToken();
  return idToken.getJwtToken();
  } catch (error) {
    console.error('error REST API:', error);
  }
}

// export async function generateToken() {
//   try {
//     const user = await Auth.currentAuthenticatedUser();
//     const token = user.getSignInUserSession().getIdToken().getJwtToken();
//     return token;
//   } catch (error) {
//     console.error('error REST API:', error);
//   }
// }

export default async function authHeaders() {
  const token = await getAuthToken();
  return {
    // 'Access-Control-Allow-Origin': '*',
    Authorization: "Bearer ".concat(token),
  };
}


export async function execTenantAPIget(uri:string) {
  const headers = await authHeaders();
  return await API.get(apiName, uri, { headers })
  //   .then(res=>{
  //   // console.log(JSON.stringify(res));
  //   return res;
  // })
  //   .catch(e=>{
  //     console.log(JSON.stringify(e));
  //     return {status: "Error", message:"Something went wrong!"}
  //   })
}

export async function execTenantAPIpost(uri:string, payload) {
  const headers = await authHeaders();
  return await API.post(apiName, uri, {
    headers,
    body: payload,
  });

  //   .then(res=>{
  //   // console.log(JSON.stringify(res));
  //   return res;
  // })
  //   .catch(e=>{
  //     console.log(JSON.stringify(e));
  //     return {status: "Error", message:"Something went wrong!"}
  //   })
}